import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { useLocation } from 'react-router-dom'

import Breadcrumbs from '../component/breadcrumbs'
import DisputeInformationFilter from '../container/dispute-information-filter'
import { TabPanel } from '../container/document-tab'
import DisputesInformation from '../container/disputes-information'
import Loading from '../component/loading'

const getSelects = (state) => get(state, 'getFilters.selects', [])
const getLoading = (state) => get(state, 'getFilters.loading', true)

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const DisputeInformation = () => {
  const { t } = useTranslation()
  const selects = useSelector(getSelects)
  const loadingStatus = useSelector(getLoading)

  const query = useQuery()

  const documentNumber = query.get('documentNumber')
  const customerCode = query.get('customerCode')

  useEffect(() => {
    if (documentNumber && customerCode) {
      localStorage.setItem(
        '@number',
        JSON.stringify({
          label: documentNumber,
          value: documentNumber
        })
      )

      localStorage.setItem(
        '@customerDispute',
        JSON.stringify({
          label: customerCode,
          value: customerCode
        })
      )

      return (window.location.href = `${process.env.REACT_APP_INVOICES_UI}/app/dispute-information`)
    }
  }, [])

  return (
    <div>
      <div className='i-container'>
        <Breadcrumbs title={t('breadcrumbs-title-dispute-information')} />
      </div>

      <DisputeInformationFilter />

      <TabPanel>
        <div className='i-container'>
          {loadingStatus ? (
            <Loading />
          ) : (
            <DisputesInformation selects={selects} />
          )}
        </div>
      </TabPanel>
    </div>
  )
}

export default DisputeInformation
