import { GET_CUSTOMER_PAYMENT_INFO } from './actions'

const INITIAL_STATE = {
  paymentInfo: {}
}

export const getCustomerPaymentInfo = (state = INITIAL_STATE, action) => {
  if (action.type === GET_CUSTOMER_PAYMENT_INFO) {
    return { paymentInfo: { ...action.paymentInfo.data.body } }
  }
  return state
}
