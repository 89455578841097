import React from 'react'

import { HeaderNewUserContainer } from './style'

import logoHeader from '../../assets/images/logo-embraer.png'

export default ({ children }) => {
  return (
    <HeaderNewUserContainer>
      <div className='logo-header'>
        <img src={logoHeader} alt='Embraer S.A.' />
      </div>
      {children}
    </HeaderNewUserContainer>
  )
}
