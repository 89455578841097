import { Grid } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { findAllCrmCsi } from '../../domain/identity'
import Pagination from '@material-ui/lab/Pagination'

import InputText from '../../component/input'
import ButtonSearch from '../button-search'
import {
  TitlePage,
  GridContainer,
  TableListCrmCsi,
  ContainerPagination,
  FormSearchCustomer,
  Container
} from './styles'

const CustomerUpdateCrm = () => {
  const { t } = useTranslation()
  const [listAccounts, setListAccounts] = useState([
    {
      accountLogin: '',
      fullName: '',
      managerLogin: '',
      type: '',
      customerCode: ''
    }
  ])
  const [listTotalAccouts, setListTotalAccouts] = useState(1)
  const [statusLoading, setStatusLoading] = useState(true)
  const [tablePage, setTablePage] = useState(1)
  const [inputSearch, setInputSearch] = useState('')

  const handleFindAllCrmCsi = async ({ page = tablePage, inputSearch }) => {
    await findAllCrmCsi({ page, criteria: inputSearch }).then((response) => {
      setListAccounts(response.data.rows)
      setListTotalAccouts(response.data.count)
      setStatusLoading(false)
    })
  }

  const handleSelectPage = async ({ event, p }) => {
    event.preventDefault()

    setStatusLoading(true)

    handleFindAllCrmCsi({
      page: p || tablePage,
      inputSearch
    })
  }

  const handleSubmitSearch = async (event) => {
    event.preventDefault()

    setTablePage(1)
    setStatusLoading(true)

    handleFindAllCrmCsi({
      page: 1,
      inputSearch
    })
  }

  useEffect(() => {
    handleFindAllCrmCsi({ page: 1 })
  }, [])

  return (
    <div>
      <Container>
        <TitlePage style={{ padding: '10px' }}>
          <h3>{t('cam-dash-label')}</h3>
          <small>{`(Showing ${listAccounts.length} ${
            listAccounts.length > 1 ? 'entries' : 'entry'
          })`}</small>
        </TitlePage>
        <GridContainer container>
          <Grid item xs={12} md={4}>
            <FormSearchCustomer onSubmit={handleSubmitSearch}>
              <InputText
                type='text'
                placeholder={t('crm-csi-search-placeholder')}
                defaultValue={inputSearch}
                onChange={(input) => setInputSearch(input.target.value)}
              />
              <ButtonSearch type='submit' />
            </FormSearchCustomer>
          </Grid>
        </GridContainer>
        {listAccounts.length > 0 && statusLoading === false ? (
          <>
            <TableListCrmCsi>
              <table width='100%' className='table table-crm-csi'>
                <thead>
                  <tr>
                    <th>{t('crm-csi-column-login')}</th>
                    <th>{t('crm-csi-column-name')}</th>
                    <th>{t('crm-csi-column-leader')}</th>
                    <th>{t('crm-csi-column-type')}</th>
                    <th>{t('crm-csi-column-customer-relation')}</th>
                  </tr>
                </thead>
                <tbody>
                  {listAccounts.map((account, index) => (
                    <tr key={index}>
                      <td>{account.accountLogin}</td>
                      <td>{account.fullName}</td>
                      <td>{account.managerLogin}</td>
                      <td>{account.type}</td>
                      <td>{account.customerCode}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableListCrmCsi>
            <ContainerPagination>
              <Pagination
                defaultPage={tablePage}
                disabled={false}
                count={Math.ceil(listTotalAccouts / 10)}
                color='primary'
                onChange={(event, page) => {
                  setTablePage(page)
                  setStatusLoading(true)
                  handleSelectPage({ event, p: page })
                }}></Pagination>
            </ContainerPagination>
          </>
        ) : null}
      </Container>
    </div>
  )
}

export default CustomerUpdateCrm
