import React, { memo } from 'react'
import { filesize } from 'filesize'

import { Container, FileInfo, Preview, TotalSize } from './styles'

const FileList = memo(
  ({
    files,
    handleDelete,
    t,
    uploadedTotalSize,
    maxHeight,
    minHeight,
    overflow
  }) => (
    <Container
      style={{ maxHeight, minHeight, overflowY: overflow ? 'auto' : null }}>
      {files
        ? files.map((uploadedFile, i) => (
            <li key={i}>
              <FileInfo>
                <Preview src={uploadedFile.preview} />
                <div>
                  <strong>{uploadedFile.name}</strong>
                  <span>
                    {filesize(uploadedFile.size)}
                    {handleDelete ? (
                      <button type='button' onClick={() => handleDelete(i)}>
                        {t('customer-support-delete-upload')}
                      </button>
                    ) : null}
                  </span>
                </div>
              </FileInfo>
            </li>
          ))
        : null}
      {uploadedTotalSize ? (
        <TotalSize className='total-size'>{`${t(
          'customer-support-uploaded-size-total'
        )}: ${filesize(uploadedTotalSize)}`}</TotalSize>
      ) : null}
    </Container>
  )
)

export default FileList
