import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import PDFViewer from '../../component/pdf-viewer'

import { verifyUserTcs } from '../../domain/identity'
import { setDataTcs } from '../pdf/__redux__/action'
import { fetchAuth } from '../auth/__redux__/actions'

import { getRevalidateToken } from '../../domain/auth'
import { sendAcceptUser } from '../../domain/tcs'

import { PdfView } from './styles'

const PDFViewerDocument = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatchRedux = useDispatch()
  const [source, setSource] = useState('')
  const [language, setLanguage] = useState('en')
  const [checkboxAccept, setCheckboxAccept] = useState(false)
  const [loadPage, setLoadPage] = useState(true)
  const [loadAccepted, setLoadAccepted] = useState(false)
  const dataPdfs = useSelector((state) => state.dataTcs)
  const user = useSelector((state) => state.auth)
  const [isCustomerEmptyErrorShowed, setIsCustomerEmptyErrorShowed] =
    useState(false)

  const handleAccepted = async () => {
    setLoadAccepted(true)

    try {
      const sendDataAcceptTcs = {
        name_user: user.user.fullName,
        email_user: user.user.email,
        login_user: user.user.login,
        customers: user.user.customers,
        portal_account_id: user.user.accountCode,
        user_ip: '',
        pdf_version: dataPdfs.version.toString(),
        system_code: 'OLP',
        language: language
      }

      await sendAcceptUser(sendDataAcceptTcs)

      const dataPdfsNewInfo = dataPdfs
      dataPdfsNewInfo['accepted'] = true

      dispatchRedux(setDataTcs(dataPdfsNewInfo))

      history.push('/app/invoices')
    } catch (error) {
      setLoadAccepted(false)
      const message = t('customers-not-informed')
      Swal.fire('Alert!', message, 'warning')
    }
  }

  const changeLanguagePdfView = (code) => {
    setLoadPage(true)
    setTimeout(() => {
      setLanguage(code)
      loadPdfView(code)
    }, 2000)
  }

  const loadPdfView = async (code) => {
    const message = t('customers-not-informed')

    if (dataPdfs.noCustomersFoundError && !isCustomerEmptyErrorShowed) {
      setIsCustomerEmptyErrorShowed(true)
      Swal.fire('Alert!', message, 'warning')
    }

    setSource(null)

    if (dataPdfs.pdfs !== undefined && dataPdfs.pdfs.length > 0) {
      const sourceGet = dataPdfs.pdfs.filter((pdf) => pdf.code === code)
      setSource(`${sourceGet[0].source}?f=true`)
    }

    setLoadPage(false)
  }

  const verifyUserAssignTcs = async () => {
    try {
      await verifyUserTcs({
        system: 'OLP',
        userLogin: user.user.login,
        userEmail: user.user.email,
        customers: user.user.customers
      }).then((response) => {
        const assignTcs = response.data
        dispatchRedux(setDataTcs(assignTcs))
      })
    } catch (err) {
      console.log('Error TCS!')
    }
  }

  useEffect(() => {
    loadPdfView(language)
  }, [dataPdfs])

  useEffect(() => {
    if (!user.user) {
      getRevalidateToken().then((response) => {
        fetchAuth(response.data.body)(dispatchRedux)
      })
    } else if (!dataPdfs.pdfs[0]) {
      verifyUserAssignTcs()
    }
  }, [user])

  return (
    <PdfView>
      <div className='center-width'>
        <div className='list-languages'>
          <ul>
            {dataPdfs.pdfs && dataPdfs.pdfs.length > 0
              ? dataPdfs.pdfs.map((pdf, i) => (
                <li
                  key={i}
                  onClick={() => changeLanguagePdfView(pdf.code)}
                  className={language === pdf.code ? 'active' : ''}>
                  {pdf.name}
                </li>
              ))
              : null}
          </ul>
        </div>

        {loadPage ? (
          <div className='loading-pdf-text'>
            <p>loading...</p>
          </div>
        ) : (
          <PDFViewer source={source} />
        )}

        <div className='btns-tcs'>
          <div className='checkbox-accept'>
            <input
              type='checkbox'
              checked={checkboxAccept}
              onChange={() => setCheckboxAccept(!checkboxAccept)}
            />
            <p>{t('tcs-text-accept')}</p>
          </div>
          {checkboxAccept ? (
            <button onClick={handleAccepted} disabled={loadAccepted}>
              {loadAccepted === false ? t('tcs-button-accept') : '...'}
            </button>
          ) : (
            <button className='disabled' disabled>
              {loadAccepted === false ? t('tcs-button-accept') : '...'}
            </button>
          )}
        </div>
      </div>
    </PdfView>
  )
}

export default PDFViewerDocument
