import React from 'react'
import Form from './form'

import Breadcrumbs from '../../component/breadcrumbs'

const CustomerSupport = () => {
  return (
    <>
      <div className='i-container'>
        <Breadcrumbs />
      </div>

      <div className='border-bottom'></div>

      <Form />
    </>
  )
}

export default CustomerSupport
