import React from 'react'
import renderHTML from 'react-render-html'
import { useTranslation } from 'react-i18next'
import Tooltip from '../../component/tooltip'
import './styles.css'

export default () => {
  const { t } = useTranslation()

  return (
    <Tooltip
      textTooltip={renderHTML(t('tooltip-invoices').toString())}
      color='#ffffff'
      position='top'
      backgroundColor='rgba(0,0,0,0.75)'>
      <span>
        <i className='material-icons i-ico-help-color'>help</i>
      </span>
    </Tooltip>
  )
}
