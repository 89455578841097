import styled from 'styled-components'

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;

  .body-login {
    width: 370px;
    display: block;

    .logo-header {
      display: block;
      text-align: center;

      img {
        width: 250px;
      }
    }

    .forms-login {
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      width: 100%;
      display: block;
    }
  }
`
