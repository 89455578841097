import React from 'react'
import headRows from './head-row.json'
import { useTranslation } from 'react-i18next'
import TableHeadMUI from '../../../component/table/table-head'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableRow from '../../../component/table/table-row'
import TableCell from '../../../component/table/table-cell'
import filterTableCell from './filter-table-cell'
import showCheckBox from './show-checkbox'
import TooltipPdfHead from '../../../component/tooltip-pdf-head'

export default (props) => {
  const { t } = useTranslation()
  return (
    <TableHeadMUI>
      <TableRow>
        {headRows
          .filter((row) => filterTableCell(row)(props))
          .map((row) => (
            <TableCell
              className={`table-cell-${row.id}`}
              key={row.id}
              align={'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={props.orderBy === row.id ? props.order : false}
              style={
                props.isClosed && row.id === 'division'
                  ? { display: 'none' }
                  : {}
              }>
              {row.sortable ? (
                <TableSortLabel
                  active={props.orderBy === row.id}
                  direction={props.order}
                  onClick={(event) => props.onRequestSort(event, row.id)}>
                  {row.label !== '' && row.id
                    ? t(`head-row-table-${row.id}`)
                    : row.label}
                </TableSortLabel>
              ) : (
                <span>
                  {row.label !== '' && row.id
                    ? t(`head-row-table-${row.id}`)
                    : row.label}
                </span>
              )}
              {row.id === 'view' ? (
                <TooltipPdfHead textTooltip={t('head-row-table-pdf-info')} />
              ) : (
                ''
              )}
            </TableCell>
          ))}
        <TableCell
          padding='checkbox'
          style={{ width: '150px', textAlign: 'right' }}>
          <label
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}>
            <span>
              {t('head-row-table-select-all-p-1')}
              <br />
              {t('head-row-table-select-all-p-2')}
            </span>
            {showCheckBox(props)}
          </label>
        </TableCell>
      </TableRow>
    </TableHeadMUI>
  )
}
