import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from '../../component/select'
import { fetchCamCsi } from './__redux__/actions'
import { orderData } from '../../domain/document/'
import { get } from 'lodash'

const getCamCsi = (state) => get(state, 'getCamCsi.camCsi', [])

export default (props) => {
  const { t } = useTranslation()
  const camCsi = useSelector(getCamCsi)
  const dispatch = useDispatch()
  const orderedCamCsi = orderData({
    collection: camCsi,
    field: 'accountManagerName',
    order: 'asc'
  })

  const [inputValue, setInputValue] = useState('')

  function handleInputValueChange(value) {
    setInputValue(value)
  }

  function onChangeValue(selected, name) {
    if (name) props.onSelect({ name, selected: selected || '' }, props.filters)

    selected && selected.value !== ''
      ? handleInputValueChange(selected.label)
      : handleInputValueChange('')
  }

  useEffect(() => {
    dispatch(fetchCamCsi())
  }, [])

  useEffect(() => {
    if (props.updateValues) handleInputValueChange('')
  }, [props.updateValues])

  return (
    <Select
      handleInputChange={handleInputValueChange}
      onChangeValue={onChangeValue}
      inputSearchValue={inputValue}
      name='accountManagerLogin'
      suggestions={orderedCamCsi}
      label={t('cam-dash-label')}
      placeholder={t('cam-dash-placeholder')}
      useAttributesAsValue={['accountManagerLogin']}
      showAttributesAsLabel={['accountManagerName']}
      onSelect={props.onSelect}
      limitSuggestion={500}
      notifySuggestionNotFound={(notification) => {
        return { label: '', value: '' }
      }}
    />
  )
}
