import 'date-fns'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DateFnsUtils from '@date-io/date-fns'
import getLocale from './locale'
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button'
import { customStyle } from './custom-style'
import { MuiPickersUtilsProviderUi, KeyboardDatePickerUi, PickerDate } from './pickers-style'

export default (props) => {

  const { i18n } = useTranslation()

  const pickerLocale = getLocale(i18n.language)

  const theme = createMuiTheme(customStyle)
  const [isOpen, setIsOpen] = useState(false);

  const styleButton = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: '5',
    opacity: 0
  }

  return (
    <PickerDate>
      <Button
        onClick={() => setIsOpen(true)}
        style={styleButton}>Open picker </Button>

      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProviderUi utils={DateFnsUtils} locale={pickerLocale}>
          <KeyboardDatePickerUi
            margin="normal"
            id={props.label}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            format="yyyy/MM/dd"
            label={props.label}
            value={props.value}
            minDate={props.minDate}
            maxDate={props.maxDate}
            onChange={props.onSelect}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            animateYearScrolling
          />
        </MuiPickersUtilsProviderUi>
      </MuiThemeProvider>
    </PickerDate>
  )
}