import * as crm from './crm/actions'
// import crm_reducer from './crm/reducer'
import * as csi from './csi/actions'
// import csi_reducer from './csi/reducer'
import * as crmLeader from './crm-leader/actions'
// import crm_leader_reducer from './crm-leader/reducer'
import * as customer from './customer/actions'
// import customer_reducer from './customer/reducer'
import * as customerGroup from './customer-group/actions'
// import customer_group_reducer from './customer-group/reducer'

export const actions = {
  crm,
  csi,
  crmLeader,
  customer,
  customerGroup
}
