import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import {
  applySelectFilter,
  resetSelectsSearch,
  changeCriteriaFilter,
  changeSelectFilter,
  changeLoading
} from '../document-filter/__redux__/actions'
import { fetchCompanies } from '../company-documents/__redux__/actions'
import { changeInputSearch } from '../input-search-invoices/__redux__/actions'
import { actions as selectActions } from '../invoices-selects/__redux__'

import Filter from '../fixed-filter-invoices'
import FilterSelect from '../invoices-selects'
import CustomerGroupCheckbox from '../customer-group-checkbox'

import { isEmployee } from '../../domain/security'
import {
  onResetField,
  onSelect,
  resetSelects,
  setSelects
} from './__redux__/invoices-filter/actions'

import {
  getSelectsOnLocalstorage,
  mountSelectsToRedux
} from '../../helpers/get-selects-on-localstorage'
import CustomerType from '../customer-type'
import { applyChangeCustomerTypeFilter } from '../customer-type/__redux__/actions'
import { applyChangeFinancialStatusFilter } from '../dispute-filter/__redux__/actions'
import DisputeFilter from '../dispute-filter'

const getStatus = (state) => get(state, 'getFilters.status', [])
const getCriteria = (state) => get(state, 'getFilters.criteria')
const getAuth = (state) => get(state, 'auth.user')
const getFiltersAnalytical = (state) =>
  get(state, 'getFiltersAnalytical.selects', [])
const getFilter = (state) => get(state, 'invoicesFilters')
const getCheckbox = (state) => get(state, 'invoicesCheckbox.checked')
const getLoading = (state) => get(state, 'getFilters.loading')

export default ({ setInputSearch }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const status = useSelector(getStatus)
  const criteria = useSelector(getCriteria)
  const user = useSelector(getAuth)
  const filtersAnalytical = useSelector(getFiltersAnalytical)
  const filters = useSelector(getFilter)
  const checkboxChecked = useSelector(getCheckbox)
  const loading = useSelector(getLoading)

  const useUpdateEffect = (effect, deps) => {
    const isFirstMount = useRef(true)
    useEffect(() => {
      if (!isFirstMount.current) effect()
      else isFirstMount.current = false
    }, deps)
  }

  function clearLocalStorage() {
    localStorage.removeItem('@inputSearch')
    localStorage.removeItem('@leader')
    localStorage.removeItem('@camCsi')
    localStorage.removeItem('@csi')
    localStorage.removeItem('@customerGroup')
    localStorage.removeItem('@customer')
    localStorage.removeItem('@financialStatus')
  }

  function verifyStorage() {
    const selects = getSelectsOnLocalstorage()
    const {
      inputSearch,
      leader,
      camCsi,
      customerGroup,
      customer,
      csi,
      financialStatus
    } = selects

    const selectsStorage = mountSelectsToRedux(selects)

    return {
      selectsStorage,
      localStorage: {
        inputSearch,
        crmLeader: leader,
        crm: camCsi,
        csi,
        customer,
        customerGroup,
        financialStatus
      }
    }
  }

  function loadingDataApi(selectsStorage, criteriaStorage) {
    clearLocalStorage()

    //Reseta filtros de busca
    dispatch(changeCriteriaFilter(criteriaStorage))
    dispatch(changeSelectFilter(selectsStorage))

    //Reseta os selects
    ;['crmLeader', 'crm', 'csi', 'customer', 'customerGroup'].forEach(
      (select) => {
        dispatch(selectActions[select].setSelected({ value: '', label: '' }))
      }
    )

    //Atribui aos selects os valores contidos no localStorage
    selectsStorage.forEach((select) => {
      if (select.name === 'financialStatus') {
        dispatch(applyChangeFinancialStatusFilter(select.selected))
      } else {
        dispatch(selectActions[select.name].setSelected(select.selected))
      }
    })

    //Dispara a atualizacao dos filtros de busca para comecar o fetch
    dispatch(setSelects(selectsStorage))
  }

  function clearFilterHandler() {
    clearFilterInvoices()
  }

  function applyFilterHandler() {
    if (!loading) {
      dispatch(changeLoading(true))
      dispatch(fetchCompanies({ selects: filters.selected, status, criteria }))
    }
  }

  function clearFilterInvoices() {
    dispatch({ type: 'unCheckCheckbox' })
    dispatch(resetSelects([]))
    ;['crmLeader', 'crm', 'csi', 'customer', 'customerGroup'].forEach(
      (select) => {
        dispatch(selectActions[select].setSelected({ value: '', label: '' }))
      }
    )
    dispatch(changeCriteriaFilter(''))
    dispatch(resetSelectsSearch())
    dispatch(changeInputSearch(''))
    setInputSearch('')
    dispatch(applyChangeCustomerTypeFilter({ label: 'All', value: 'ALL' }))
    dispatch(applyChangeFinancialStatusFilter({ label: 'All', value: 'ALL' }))
  }

  const handleSelect = (selected) => {
    let newSelects = filtersAnalytical
    newSelects = newSelects.filter((sel) => sel.name !== selected.name)
    if (selected.selected) newSelects.push(selected)
    else newSelects = newSelects.filter((sel) => sel.name !== selected.name)
    if (selected.selected) dispatch(onSelect(selected))
    else dispatch(onResetField(selected))
  }

  useUpdateEffect(() => {
    onChangeFilterApply(filters.selected)
    syncSelectsUpdate(filters.selected)
    if (!loading) {
      dispatch(changeLoading(true))
      dispatch(fetchCompanies({ selects: filters.selected, status, criteria }))
    }
  }, [filters.selected])

  function syncSelectsUpdate(selected) {
    let params = {}
    let fetchParams
    selected.forEach((s) => {
      if (s.name) params[s.name] = s.selected.value
    })

    if (checkboxChecked) {
      fetchParams = { ...params, customer: null }
      delete fetchParams.customer
    } else {
      fetchParams = { ...params, customerGroup: null }
      delete fetchParams.customerGroup
    }
    let { customer, ...customerGroupParams } = fetchParams
    let { customerGroup, ...customerParams } = fetchParams

    if (!params.crm) dispatch(selectActions.crm.fetchList(params))
    if (!params.csi) dispatch(selectActions.csi.fetchList(params))
    if (!params.customer && !checkboxChecked)
      dispatch(selectActions.customer.fetchList(customerParams))
    if (!params.customerGroup && checkboxChecked)
      dispatch(selectActions.customerGroup.fetchList(customerGroupParams))
    if (!params.crmLeader) dispatch(selectActions.crmLeader.fetchList(params))
  }

  function onChangeFilterApply(selects) {
    dispatch(applySelectFilter(selects))
  }

  function loadInitialData() {
    clearFilterInvoices()
  }

  function startDataLoad() {
    let { localStorage, selectsStorage } = verifyStorage()

    let verifyCriteria = localStorage.inputSearch || criteria

    if (localStorage.customerGroup && localStorage.customerGroup.value) {
      dispatch({ type: 'checkCheckbox' })
    }

    selectsStorage.length || verifyCriteria
      ? loadingDataApi(selectsStorage, verifyCriteria)
      : loadInitialData()
  }

  function handleChangeCheckboxGroup() {
    dispatch({ type: 'toggleCheckbox' })
    let selectToReset = checkboxChecked ? 'customerGroup' : 'customer'
    dispatch(selectActions[selectToReset].setSelected({ value: '', label: '' }))
    dispatch(onResetField({ name: selectToReset }))
  }

  useEffect(() => {
    dispatch(changeLoading(false))
    startDataLoad()
  }, [])

  return isEmployee(user) ? (
    <Filter
      buttonName='btn-apply'
      menuFilter='document-filter'
      filterFooter='drop-filter-footer'
      clearInput={true}
      searchInput={true}
      interceptSelectedValue={false}
      onChangeFilter={(selects) => onChangeFilterApply(selects)}
      onClearClick={clearFilterHandler}
      clearTitle={t('clear')}
      applyTitle={t('apply')}
      gridTemplateColumns='20% 20% 20% 20% 20%'
      buttonTitleCustomerFilter={t('filter')}
      onSelect={handleSelect}
      applyFilter={applyFilterHandler}>
      <FilterSelect
        name='crmLeader'
        labelFromItem='fullName'
        valueFromItem='accountLogin'
        translationLabel='crm-leader-label'
        translationPlaceholder='crm-leader-placeholder'
        useAttributesAsValue={['accountLogin']}
        showAttributesAsLabel={['fullName']}
        storageKey='leader'
      />
      <FilterSelect
        name='crm'
        labelFromItem='fullName'
        valueFromItem='accountLogin'
        translationLabel='crm-label'
        translationPlaceholder='crm-placeholder'
        useAttributesAsValue={['accountLogin']}
        showAttributesAsLabel={['fullName']}
        storageKey='camCsi'
      />
      <FilterSelect
        name='csi'
        labelFromItem='fullName'
        valueFromItem='accountLogin'
        translationLabel='csi-dash-label'
        translationPlaceholder='csi-dash-placeholder'
        useAttributesAsValue={['accountLogin']}
        showAttributesAsLabel={['fullName']}
      />
      <CustomerType onChangeFilterApply={syncSelectsUpdate} />
      <CustomerGroupCheckbox
        onChange={handleChangeCheckboxGroup}
        label='Group'
      />
      {checkboxChecked ? (
        <FilterSelect
          name='customerGroup'
          labelFromItem='name'
          valueFromItem='code'
          translationLabel='customer-group-label'
          translationPlaceholder='customer-group-placeholder'
          useAttributesAsValue={['code']}
          showAttributesAsLabel={['code', 'name']}
          fetchNotFound={true}
          triggerOnHide
        />
      ) : (
        <FilterSelect
          name='customer'
          labelFromItem='name'
          valueFromItem='code'
          translationLabel='customer-list-label'
          translationPlaceholder='customer-list-placeholder'
          useAttributesAsValue={['code']}
          showAttributesAsLabel={['code', 'name']}
          fetchNotFound={true}
          triggerOnHide
        />
      )}
      <DisputeFilter />
    </Filter>
  ) : (
    <Filter
      buttonName='btn-apply'
      menuFilter='document-filter'
      filterFooter='drop-filter-footer'
      clearInput={true}
      searchInput={true}
      interceptSelectedValue={false}
      onChangeFilter={(selects) => onChangeFilterApply(selects)}
      onClearClick={clearFilterHandler}
      clearTitle={t('clear')}
      applyTitle={t('apply')}
      buttonTitleCustomerFilter={t('filter')}
      isAdmin={isEmployee(user)}
      onSelect={handleSelect}
      applyFilter={applyFilterHandler}>
      <FilterSelect
        name='customer'
        labelFromItem='name'
        valueFromItem='code'
        translationLabel='customer-list-label'
        translationPlaceholder='customer-list-placeholder'
        useAttributesAsValue={['code']}
        showAttributesAsLabel={['code', 'name']}
        fetchNotFound={true}
        triggerOnHide
      />
      <DisputeFilter />
    </Filter>
  )
}
