import { getBearerAuthorization } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/financial`

const findAllFinancial = async () =>
  await axios.get(`${url}`, {
    headers: getBearerAuthorization()
  })

const upsert = async (dataSend) =>
  await axios.post(
    `${url}`,
    { data: JSON.stringify(dataSend) },
    {
      headers: getBearerAuthorization()
    }
  )

const del = async (id) => await axios.delete(
    `${url}/${id}`,
    {
      headers: getBearerAuthorization()
    }
  )

export { findAllFinancial, upsert, del }
