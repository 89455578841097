import React from 'react'
import ListSubheader from '@material-ui/core/ListSubheader'
import InputSearch from '../input-search'
import PropTypes from 'prop-types'

const ListSubHeader = (props) => (
  <ListSubheader component="div" style={{padding: '0', lineHeight: 'initial'}}>
    <InputSearch
      name={props.searchName}
      placeholder={props.searchPlaceholder}
      onClick={(inputValue) => props.onFilter(inputValue)}
    />
  </ListSubheader>
)

ListSubHeader.propTypes = {
  searchName: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  onFilter: PropTypes.func.isRequired
}

export default ListSubHeader