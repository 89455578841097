const findItem = (selected) => (name) =>
  selected.find((item) => item.name === name)

const filterSelected = (selected) => (name) =>
  selected.filter((item) => item.name !== name)

const updateDataSelecteds = (selected, id) => {
  selected = filterSelected(selected)(id.name)
  selected.push(id)
  return [...selected]
}

export default (selected = []) =>
  (id) => {
    const itemSelected = findItem(selected)(id.name)
    return !itemSelected ? [...selected, id] : updateDataSelecteds(selected, id)
  }
