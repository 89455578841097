import React from 'react'
import { Loading } from './styles'
import loading from '../../assets/images/loading.svg'

export default ({ report, downloadSmall, downloadBig, size = '70px' }) => {
  return (
    <Loading
      report={report}
      downloadSmall={downloadSmall}
      downloadBig={downloadBig}
      size={size}>
      <img src={loading} alt='Loading' />
    </Loading>
  )
}
