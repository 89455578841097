import { findAggBySegment } from '../../../domain/document/'

const GET_SEGMENTS = "GET_SEGMENT"
const getSegments = segments => ({
  type: GET_SEGMENTS,
  segments
})

const fetchSegments = (query) => (dispatch) => findAggBySegment(query)
  .then(segments => dispatch(getSegments(segments)))

export { 
  GET_SEGMENTS,
  fetchSegments,
}
