import styled, { css } from 'styled-components'
import theme from '../theme'

const WrapAlert = styled.div`
  font-family: 'Roboto', sans-serif;
  position: relative;
  padding: 10px 10px 10px 75px;
  margin: 15px 0 40px;
  border-left: 5px solid ${theme.colors.black};
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: ${theme.colors.white};

  ${(props) =>
    props.type === 'success' &&
    css`
      border-color: ${theme.colors.techColor};

      h6,
      .alert-big-ico {
        color: ${theme.colors.techColor};
      }
    `}

  ${(props) =>
    props.type === 'info' &&
    css`
      border-color: ${theme.colors.lightBlue};

      h6,
      .alert-big-ico {
        color: ${theme.colors.lightBlue};
      }
    `}

  ${(props) =>
    props.type === 'warning' &&
    css`
      border-color: ${theme.colors.nearYellowOrange};

      h6,
      .alert-big-ico {
        color: ${theme.colors.nearYellowOrange};
      }
    `}

  ${(props) =>
    props.type === 'danger' &&
    css`
      border-color: ${theme.colors.redOrange};

      h6,
      .alert-big-ico {
        color: ${theme.colors.redOrange};
      }
    `}
`

const AlertTitle = styled.h6`
  font-size: 1.8rem;
  padding-bottom: 10px;
  margin: 0;
`

const AlertBigIco = styled.i`
  font-size: 4rem;
  position: absolute;
  top: 50%;
  left: 37px;
  line-height: 0;
  transform: translateX(-50%);
`

const AlertInfo = styled.div`
  font-size: 1.4rem;
  line-height: 1.3;
`

const AlertIcoClose = styled.a`
  color: ${theme.colors.doveGray};
  position: absolute;
  top: 3px;
  right: 6px;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.doveGray};
  }

  .close-ico {
    font-size: 2rem;
  }
`

export { WrapAlert, AlertTitle, AlertInfo, AlertIcoClose, AlertBigIco }
