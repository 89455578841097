import { findBySite } from '../../../domain/type'

const GET_VIEW_TYPES = "GET_VIEW_TYPES"
const getViewTypes = viewTypes => ({
  type: GET_VIEW_TYPES,
  viewTypes
})

const fetchViewTypes = (query) => (dispatch) => findBySite(query)
  .then(viewTypes => dispatch(getViewTypes(viewTypes)))

export { GET_VIEW_TYPES, fetchViewTypes }



