import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslation } from 'react-i18next'
import { TableBody, TableCell, TableRow } from '../../component/table'
import moment from 'moment'
import Loading from '../../component/loading'
import ReportActionsButtons from '../report-actions-buttons'
import TooltipErrorMessage from '../../component/tooltip-error-message'

const TableBodyRep = ({ reports, deleteReportById }) => {
  const { t } = useTranslation()

  return (
    <TableBody>
      {reports.length > 0 &&
        reports.map((report) => (
          <TableRow key={report.id}>
            <TableCell align='center'>{report.customer}</TableCell>
            <TableCell align='center'>
              {report.status !== 'error'
                ? t(`report-status-${report.status.toLowerCase()}`)
                : ''}
            </TableCell>
            <TableCell align='center'>
              {report.status !== 'error'
                ? moment(report.createdAt).format('DD/MM/YYYY HH:mm:ss')
                : ''}
            </TableCell>
            <TableCell align='center'>
              {t(`report-${report.processStatus}`)}
            </TableCell>
            <TableCell align='center'>
              {report.processStatus === 'finished' ? (
                <ReportActionsButtons
                  report={report}
                  deleteReportById={deleteReportById}
                />
              ) : report.processStatus === 'processing' ? (
                <Loading report />
              ) : (
                <>
                  <TooltipErrorMessage error={report.message} />
                  <IconButton
                    disabled={false}
                    onClick={() => deleteReportById(report.id)}>
                    <DeleteIcon className='icon-actions' />
                  </IconButton>
                </>
              )}
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  )
}

export default TableBodyRep
