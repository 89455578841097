import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowLeft from '@material-ui/icons/ArrowBack'

import HeaderUI from './header-style'
import UserPanel from '../user-panel'
import { updateStatusShowMenu } from './__redux__/actions'

const getShowMenuView = (state) => get(state, 'headerReducer.showMenuView')

const Header = (props) => {
  const dispatchRedux = useDispatch()
  const statusShowViewMenu = useSelector(getShowMenuView)

  return (
    <HeaderUI className='i-header'>
      <div className='i-container'>
        <h1>
          <IconButton
            aria-label='Menu'
            className='i-btn-toggle-aside'
            onClick={() => dispatchRedux(updateStatusShowMenu())}>
            {statusShowViewMenu ? (
              <ArrowLeft style={{ fontSize: 24 }} />
            ) : (
              <MenuIcon style={{ fontSize: 24 }} />
            )}
          </IconButton>
          <a href=' ' className='i-logo-flyembraer'>
            FlyEmbraer
          </a>
          <span className='i-header-label'>Invoices & Payments</span>
        </h1>

        <UserPanel user={props.user} />

      </div>
    </HeaderUI>
  )
}

export default Header
