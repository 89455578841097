import styled from 'styled-components'

export const HeaderNewUserContainer = styled.header`
  margin-top: 56px;  
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;  

  .logo-header {
      padding: 20px 0;
      display: block;
      text-align: center;

      img {
        width: 170px;
      }
    }

  h3 {
    color: #000;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
  }

  p {
    margin: auto;
    color: #3e3e3e;
    font-size: 14px;
    line-height: 15px;
    max-width: 450px;      
  }
`
