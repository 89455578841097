import {
  GET_DOCUMENTS,
  GET_PAGINATION_DOCUMENTS,
  LOAD_SHOW_MORE
} from './actions'
import { pipe, set, get } from 'lodash/fp'

const INITIAL_STATE = {
  loadShowMore: false
}

const _compose = (documents) =>
  pipe(
    set('id')(get('id', documents)),
    set('documents')([
      ...get('documents', INITIAL_STATE.documents.get(get('id', documents))),
      ...get('documents', documents)
    ]),
    set('page')(get('page', documents)),
    set('pageSize')(get('pageSize', documents)),
    set('count')(get('count', documents))
  )({})

export const getDocuments = (state = INITIAL_STATE, action) => {
  if (action.type === GET_DOCUMENTS) {
    if (!INITIAL_STATE.documents) INITIAL_STATE.documents = new Map()
    INITIAL_STATE.documents.set(action.row.id, action.row)
    return { ...state, documents: INITIAL_STATE.documents }
  }

  if (action.type === GET_PAGINATION_DOCUMENTS) {
    INITIAL_STATE.documents.set(action.row.id, _compose(action.row))
    return { ...state, documents: INITIAL_STATE.documents }
  }

  if (action.type === LOAD_SHOW_MORE) {
    return { ...state, loadShowMore: action.loadShowMore }
  }

  return state
}
