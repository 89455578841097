import { GET_CAM_CSI } from './actions'

const INITIAL_STATE = {
  camCsi: []
}

export const getCamCsi = (state = INITIAL_STATE, action) => {
  if(action.type === GET_CAM_CSI) {
    return { camCsi: [...action.camCsi.data.body] }
  }
  return state
}