import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
  } from '@material-ui/core'

import warningPt from '../../assets/images/warning-pt.png'
import warningEn from '../../assets/images/warning-en.png'

export default () => {

  const { t, i18n } = useTranslation()

  const [warning, setWarning] = useState(() => {
    const isWarning = localStorage.getItem('warning')

    if (isWarning === 'false'){
        return false
    }

    return true
  })

  const handleClose = async () => {
    localStorage.setItem('warning', false)
    setWarning(false)
  }

  return (
    <Dialog
        open={warning}
        onClose={handleClose}
        aria-labelledby='warnings'>
        <DialogContent>
          <img src={i18n.language === 'pt-BR' ? warningPt : warningEn} alt='Payment Embraer' />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {t("warning-payment-confirm")}
          </Button>
        </DialogActions>
      </Dialog>
  )
}
