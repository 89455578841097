const SET_DATA_TCS = 'SET_DATA_TCS'

const setDataTcs = (dataTcs) => {
  return {
    type: SET_DATA_TCS,
    ...dataTcs
  }
}

export { SET_DATA_TCS, setDataTcs }
