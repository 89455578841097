import React from 'react'
import TableCell from '../../../component/table/table-cell'
import { isEmployee } from '../../../domain/security'
import getCellStyles from './get-cell-styles'

export default ({row, labelId, user, isClosed}) => {
  if (isEmployee(user)) {
    return  (
              <TableCell className={getCellStyles(row, isClosed)} data-label-thead="Customer" component="th" id={labelId} scope="row" padding="none">
                {row.customerCode}
              </TableCell>
            )
  } 
}