import { list } from '../../../domain/identity'

const GET_CAMS = 'GET_CAMS'
const getCams = (cams) => ({
  type: GET_CAMS,
  cams
})

const SET_CAM_SELECTED = 'SET_CAM_SELECTED'
const setCamSelected = (camSelected) => ({
  type: SET_CAM_SELECTED,
  camSelected
})

const fetchCams = (query) => (dispatch) =>
  list(query).then((cams) => dispatch(getCams(cams.data)))

export { GET_CAMS, SET_CAM_SELECTED, fetchCams, setCamSelected }
