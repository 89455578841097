import axios from 'axios'

export default async (emailData) => {
  return await axios.post(
    `${process.env.REACT_APP_INVOICES_API}/new-user/send-authorization-request`,
    {
      ...emailData
    }
  )
}
