import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux'
import { onSelect } from '../document-fixed-filter/__redux__/invoices-filter/actions'
import DatePicker from '../../component/pickers'
import moment from 'moment'

export default (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleStartDate = (date, selected) => {
    props.setSelectedStartDate(date)

    return dispatch(
      onSelect({
        name: 'startDate',
        selected: {
          label: 'startDate',
          value: moment(selected).format('YYYY-MM-DD')
        }
      })
    )
  }

  const handleEndDate = (date, selected) => {
    props.setSelectedEndDate(date)

    return dispatch(
      onSelect({
        name: 'endDate',
        selected: {
          label: 'endDate',
          value: moment(selected).format('YYYY-MM-DD')
        }
      })
    )
  }

  return (
    <Fragment>
      <DatePicker
        label={t('dispute-picker-start-date')}
        value={props.selectedStartDate}
        onSelect={handleStartDate}
      />
      <DatePicker
        label={t('dispute-picker-end-date')}
        value={props.selectedEndDate}
        onSelect={handleEndDate}
      />
    </Fragment>
  )
}
