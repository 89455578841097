import { Menu, Button } from '@material-ui/core'
import styled from 'styled-components'

export const MenuContainer = styled(Menu)`
  .action-icons {
    color: #6a6b6b;
    padding-right: 8px;
    font-size: 20px;
  }
`

export const ActionsButton = styled(Button)`
  min-width: 20px;
  height: 27px;
`
