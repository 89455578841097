import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { updateStatusShowMenu } from '../header/__redux__/actions'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import menu from './menu'
import filterMenuList from './filter-menu-list'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '../../component/expansion-panel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const EPAYMENTS_UI = process.env.REACT_APP_EPAYMENTS_UI
const getAuth = (state) => get(state, 'auth.user')

export default () => {
  const user = useSelector(getAuth)
  const params = useParams()
  const dispatchRedux = useDispatch()

  return (
    <div className='options-menu' role='presentation'>
      <List>
        {menu({ EPAYMENTS_UI, user })
          .filter((row) => filterMenuList(row, user))
          .map((text) => (
            <li key={text.id}>
              {!text.subMenu ? (
                !text.external ? (
                  <ListItem
                    component={Link}
                    to={text.route}
                    onClick={() => {
                      if (window.innerWidth < 1064)
                        dispatchRedux(updateStatusShowMenu())
                    }}
                    button
                    className={
                      `/app/${params[0]}` === text.route ? 'active' : ''
                    }
                    key={text.name}>
                    <ListItemIcon>
                      <i className='material-icons'>{text.icon}</i>
                    </ListItemIcon>
                    <ListItemText
                      className='i-nav-item-label'
                      primary={text.name}
                    />
                  </ListItem>
                ) : (
                  <a href={text.route} target={text.target} key={text.name}>
                    <ListItemIcon>
                      <i className='material-icons'>{text.icon}</i>
                    </ListItemIcon>
                    <ListItemText
                      className='i-nav-item-label'
                      primary={text.name}
                    />
                  </a>
                )
              ) : (
                <ExpansionPanel element='expansionMenu'>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'>
                    {!text.external ? (
                      <ListItem component={Link} to='#' button key={text.name}>
                        <ListItemIcon>
                          <i className='material-icons'>{text.icon}</i>
                        </ListItemIcon>
                        <ListItemText
                          className='i-nav-item-label'
                          primary={text.name}
                        />
                      </ListItem>
                    ) : (
                      // eslint-disable-next-line
                      <a href='#' key={text.name} button>
                        <ListItemIcon>
                          <i className='material-icons'>{text.icon}</i>
                        </ListItemIcon>
                        <ListItemText
                          className='i-nav-item-label'
                          primary={text.name}
                        />
                      </a>
                    )}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    {text.subMenuItem.map((item) =>
                      !text.external ? (
                        <ListItem
                          key={item.id}
                          component={Link}
                          to={item.route}
                          button>
                          <ListItemText
                            className='i-nav-item-label'
                            primary={item.name}
                          />
                        </ListItem>
                      ) : (
                        <a
                          key={item.id}
                          href={item.route}
                          target={item.target}
                          button>
                          <ListItemText
                            className='i-nav-item-label'
                            primary={item.name}
                          />
                        </a>
                      )
                    )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            </li>
          ))}
      </List>
    </div>
  )
}
