import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import Loading from '../../../component/loading'
import { CompanyInformationContainer } from './style'

import {
  setCompanyName,
  setAddress,
  setCity,
  setStateProvince,
  setCountry,
  setErrorCompanyName,
  setErrorAddress,
  setErrorCity,
  setErrorStateProvince,
  setErrorCountry
} from '../__redux__/actions'

export default () => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)

  const companyName = useSelector((state) => state.registerNewUser.companyName)
  const companyAddress = useSelector((state) => state.registerNewUser.address)
  const companyCity = useSelector((state) => state.registerNewUser.city)
  const companyState = useSelector(
    (state) => state.registerNewUser.stateProvince
  )
  const companyCountry = useSelector((state) => state.registerNewUser.country)

  const errorCompanyName = useSelector(
    (state) => state.registerNewUser.errorCompanyName
  )
  const errorCompanyAddress = useSelector(
    (state) => state.registerNewUser.errorAddress
  )
  const errorCompanyCity = useSelector(
    (state) => state.registerNewUser.errorCity
  )

  const errorCompanyStateProvince = useSelector(
    (state) => state.registerNewUser.errorStateProvince
  )

  const errorCompanyCountry = useSelector(
    (state) => state.registerNewUser.errorCountry
  )

  const dispatchRedux = useDispatch()

  const handleCompanyName = (value) => {
    dispatchRedux(setCompanyName(value))
    if (value.length > 2) dispatchRedux(setErrorCompanyName(false))
  }
  const handleCompanyAddress = (value) => {
    dispatchRedux(setAddress(value))
    if (value.length > 4) dispatchRedux(setErrorAddress(false))
  }
  const handleCompanyCity = (value) => {
    dispatchRedux(setCity(value))
    if (value.length > 3) dispatchRedux(setErrorCity(false))
  }
  const handleCompanyState = (value) => {
    dispatchRedux(setStateProvince(value))
    if (value.length > 1) dispatchRedux(setErrorStateProvince(false))
  }
  const handleCompanyCountry = (value) => {
    dispatchRedux(setCountry(value))
    if (value.length > 3) dispatchRedux(setErrorCountry(false))
  }

  useEffect(() => {
    setLoading(false)
  }, [])

  if (loading) return <Loading />

  return (
    <CompanyInformationContainer>
      <Grid container spacing={2} className='mb-16px'>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('company-information-name')}
            value={companyName}
            onChange={(input) => handleCompanyName(input.target.value)}
            required
            error={errorCompanyName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('company-information-address')}
            value={companyAddress}
            onChange={(input) => handleCompanyAddress(input.target.value)}
            required
            error={errorCompanyAddress}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className='mb-16px'>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('company-information-city')}
            value={companyCity}
            onChange={(input) => handleCompanyCity(input.target.value)}
            required
            error={errorCompanyCity}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('company-information-state-province')}
            value={companyState}
            onChange={(input) => handleCompanyState(input.target.value)}
            required
            error={errorCompanyStateProvince}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className='mb-16px'>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('company-information-country')}
            value={companyCountry}
            onChange={(input) => handleCompanyCountry(input.target.value)}
            required
            error={errorCompanyCountry}
          />
        </Grid>
      </Grid>
    </CompanyInformationContainer>
  )
}
