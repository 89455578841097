import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Breadcrumbs } from './breadcrumbs-style'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Route } from 'react-router'

export default function BreadcrumbsUi(props) {
  const { t } = useTranslation()

  const breadcrumbNameMap = {
    '/': t('breadcrumbs-title-app'),
    '/app': t('breadcrumbs-title-app'),
    '/app/dashboard': t('breadcrumbs-title-dashboard'),
    '/app/invoices': t('breadcrumbs-title-invoices'),
    '/app/quotations': t('breadcrumbs-title-quotations'),
    '/app/report': t('breadcrumbs-title-report'),
    '/app/administration': t('breadcrumbs-title-administration'),
    '/app/customer-setup': t('breadcrumbs-title-customer-setup'),
    '/app/accounting': t('breadcrumbs-title-accounting-document'),
    '/app/document-sap-web': t('menu-account-doc-sap-web'),
    '/app/interest-free-invoices': t(
      'breadcrumbs-title-interest-free-invoices'
    ),
    '/app/customer-support': t('breadcrumbs-title-customer-support'),
    '/app/customer-dashboard': t('breadcrumbs-title-customer-dashboard'),
    '/app/dispute-information': t('breadcrumbs-title-dispute-information')
  }

  const verifyTitle = (nameMap) => {
    return props.title ? props.title : nameMap
  }

  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.split('/').filter((x) => x)
        return (
          <Breadcrumbs separator={<NavigateNextIcon />} aria-label='breadcrumb'>
            {pathnames.length > 0 ? (
              pathnames.map((value, index) => {
                const last = index === pathnames.length - 1
                const to = `/${pathnames.slice(0, index + 1).join('/')}`

                return last ? (
                  <Typography color='textPrimary' key={to}>
                    {breadcrumbNameMap[to]}
                  </Typography>
                ) : null
              })
            ) : (
              <Typography color='textPrimary' key={'/'}>
                {verifyTitle(breadcrumbNameMap['/'])}
              </Typography>
            )}
          </Breadcrumbs>
        )
      }}
    </Route>
  )
}
