import { searchInterestFree } from '../../../domain/document/'

const SET_DOCUMENTS_INTEREST_FREE = 'SET_DOCUMENTS_INTEREST_FREE'
const SET_PAGINATION_DOCUMENTS = 'SET_PAGINATION_DOCUMENTS'
const LOAD_SHOW_MORE = 'LOAD_SHOW_MORE'
const TOGGLE_CONFIRM_SCREEN = 'TOGGLE_CONFIRM_SCREEN'

const setDocumentsInterestFree = () => (documents) => ({
  type: SET_DOCUMENTS_INTEREST_FREE,
  payload: {
    documents: documents.rows,
    page: parseInt(documents.page, 10),
    pageSize: parseInt(documents.pageSize, 10),
    count: documents.count
  }
})

const setPaginationDocuments = () => (documents) => ({
  type: SET_PAGINATION_DOCUMENTS,
  payload: {
    documents: documents.rows,
    page: parseInt(documents.page, 10),
    pageSize: parseInt(documents.pageSize, 10),
    count: documents.count
  }
})

const setLoadShowMore = (loadShowMore) => ({
  type: LOAD_SHOW_MORE,
  loadShowMore
})

const toggleConfirmScreen = () => ({
  type: TOGGLE_CONFIRM_SCREEN
})

const fetchPaginationDocuments = (query) => (dispatch) =>
  searchInterestFree(query).then((documents) => {
    dispatch(setPaginationDocuments(query)(documents.data))
    dispatch(setLoadShowMore(false))
  })

const fetchDocumentsInterestFree = (query) => (dispatch) =>
  searchInterestFree(query).then((documents) =>
    dispatch(setDocumentsInterestFree(query)(documents.data))
  )

export {
  setPaginationDocuments,
  fetchPaginationDocuments,
  fetchDocumentsInterestFree,
  setLoadShowMore,
  toggleConfirmScreen,
  SET_DOCUMENTS_INTEREST_FREE,
  SET_PAGINATION_DOCUMENTS,
  LOAD_SHOW_MORE,
  TOGGLE_CONFIRM_SCREEN
}
