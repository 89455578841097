import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import CurrencyInput from 'react-currency-input'
import styled from 'styled-components'
import Swal from 'sweetalert2'

import {
  Container,
  Grid,
  Fab,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

import { Add, Save, Delete, CloudDownload } from '@material-ui/icons'

import { findAllFinancial, upsert, del } from '../domain/financial'
import {
  upsertFileAgingReport,
  downloadFileAgingReport
} from '../domain/document'

import Breadcrumbs from '../component/breadcrumbs'

const getAuth = (state) => get(state, 'auth.user')

const Accounting = () => {
  const refFileUploadSap = useRef(null)
  const user = useSelector(getAuth)
  const [inputValue, setinputValue] = useState('')
  const [inputDescription, setInputDescription] = useState('')
  const [openConfirm, setOpenConfirm] = useState(false)
  const [deleteRegister, setDeleteRegister] = useState({})
  const [rows, setRows] = useState([])
  const [fileAgingReportSap, setFileAgingReportSap] = useState(null)

  const formAddValue = (event) => {
    event.preventDefault()

    if (inputValue === '' || inputDescription === '') {
      Swal.fire('Oops...', 'All fields are mandatory', 'warning')
      return {}
    }

    const addRow = {
      description: inputDescription,
      value: inputValue,
      user: user.fullName,
      id:
        'financial_' +
        inputDescription
          .toString()
          .toLocaleLowerCase()
          .split(' ')
          .join('_')
    }

    const newArrayRows = [...rows, addRow]
    setRows(newArrayRows)
    setInputDescription('')
    setinputValue('')
  }

  const handleAddRegisterDelete = (register) => {
    setDeleteRegister(register)
    setOpenConfirm(true)
  }

  const handleCancelDelete = () => {
    setDeleteRegister({})
    setOpenConfirm(false)
  }

  const handleConfirmDelete = async () => {
    const newArrayRows = rows.filter((row) => {
      return row.id !== deleteRegister.id
    })

    const confirmDelete = await del(deleteRegister.id)

    confirmDelete.status === 200
      ? Swal.fire('Success!', 'Data has been deleted!', 'success')
      : Swal.fire('Success!', 'Error deleting the record!', 'error')

    setRows(newArrayRows)
    setDeleteRegister({})
    setOpenConfirm(false)
  }

  const handleSalveDocs = async () => {
    const listRowsUpdate = rows
    const upInsert = await upsert(listRowsUpdate)
    upInsert.status === 200
      ? Swal.fire('Success!', 'All data has been saved.', 'success')
      : Swal.fire('Success!', 'An error occurred while saving data.', 'error')
  }

  const getListFinancialInitialData = async () => {
    const listFinancial = await findAllFinancial()
    setRows(listFinancial.data)
  }

  const handleSetFileAgingReportSap = (file) =>
    setFileAgingReportSap(file.target.files)

  const handleSendFileAgingReport = async (source) => {
    try {
      const formData = new FormData()

      if (fileAgingReportSap) { formData.set('file', fileAgingReportSap[0], fileAgingReportSap[0].name) }

      await upsertFileAgingReport(formData, source)

      Swal.fire('Success!', 'Successfully upload file', 'success')

      if (fileAgingReportSap) { refFileUploadSap.current.value = '' }
    } catch (error) {
      Swal.fire('Atention!', 'Error upload file', 'error')
    }
  }

  const handleDownloadFileAgingReport = useCallback(async (source) => {
    try {
      const downloadFile = await downloadFileAgingReport(source)

      const blob = new Blob([
        new Uint8Array(downloadFile.data.body.file.Body.data).buffer
      ])
      const fileName = downloadFile.data.body.info.file
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName)
      } else {
        const link = document.createElement('a')
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    } catch (error) {
      Swal.fire(
        'Atention!',
        'Error while downloading, please try again later.',
        'error'
      )
    }
  }, [])

  useEffect(() => {
    getListFinancialInitialData()
  }, [])

  return (
    <div>
      <div className='i-container'>
        <Breadcrumbs />
      </div>

      <div className='border-bottom'></div>

      <Grid container className='bg-container-accounting'>
        <Container>
          <form onSubmit={formAddValue}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <label htmlFor='input-description'>Description</label>
                <input
                  type='text'
                  onChange={(input) => setInputDescription(input.target.value)}
                  value={inputDescription}
                  className='input-description-doc'
                  placeholder='Enter your description...'
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <label htmlFor=''>Value</label>
                <CurrencyInput
                  decimalSeparator='.'
                  thousandSeparator=''
                  precision={2}
                  onChange={setinputValue}
                  value={inputValue}
                  placeholder='0'
                  className='input-description-doc'
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={2}
                className='mg-top-btn-add-doc-contabil'>
                <Fab
                  color='primary'
                  aria-label='add'
                  size='small'
                  type='submit'>
                  <Add />
                </Fab>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Grid>
      <Container style={{ marginTop: 32 }}>
        <Grid item xs={12}>
          <Table className='table-list-docs' aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>DESCRIPTION</TableCell>
                <TableCell align='right'>VALUE</TableCell>
                <TableCell align='right'>USER</TableCell>
                <TableCell align='right'>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    {row.description}
                  </TableCell>
                  <TableCell align='right'>{row.value}</TableCell>
                  <TableCell align='right'>{row.user}</TableCell>
                  <TableCell align='right'>
                    <Fab
                      color='secondary'
                      aria-label='add'
                      size='small'
                      className='mg-left-10'
                      onClick={() => handleAddRegisterDelete(row)}>
                      <Delete />
                    </Fab>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Container>
      <Container style={{ marginTop: 32, paddingBottom: 64 }}>
        <Grid
          container
          direction='row'
          justify='flex-end'
          alignItems='flex-end'>
          <Button
            variant='contained'
            color='primary'
            size='medium'
            startIcon={<Save />}
            onClick={handleSalveDocs}>
            Save
          </Button>
        </Grid>
      </Container>

      <Container style={{ paddingBottom: 64 }}>
        <Grid container>
          <Grid item xs={12} md={9}>
            <Grid item>
              <label htmlFor='input-file'>File Aging Report - SAP</label>
              <input
                name='fileAgingReport'
                type='file'
                onChange={(input) => handleSetFileAgingReportSap(input)}
                className='input-description-doc'
                placeholder='Select file...'
                ref={refFileUploadSap}
              />
            </Grid>

            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='flex-end'
              style={{ paddingTop: 16 }}>
              <Button
                variant='contained'
                color='primary'
                size='medium'
                startIcon={<Save />}
                onClick={() => handleSendFileAgingReport('SAP')}>
                Save File
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className='align-baseline'
            style={{ textAlign: 'right' }}>
            <Button
              style={{ marginTop: 24 }}
              variant='contained'
              color='default'
              size='large'
              startIcon={<CloudDownload />}
              onClick={() => handleDownloadFileAgingReport('SAP')}>
              File Aging Report - SAP
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openConfirm}
        onClose={handleCancelDelete}
        aria-labelledby='confirm-delete'>
        <DialogTitle id='confirm-delete' className='title-confirm-deleting'>
          Confirm the deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText className='text-confirm-deleting'>
            Attention, when deleting the record, it will be affected in the
            graph at the same time.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelDelete} color='primary'>
            CANCEL
          </Button>
          <Button onClick={handleConfirmDelete} color='primary'>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default styled(Accounting)``
