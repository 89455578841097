import { GET_AUTH } from "./actions"

const INITIAL_STATE = {
    auth: { 
        token: null,
        user: null
    }
}

export const getAuth = (state = INITIAL_STATE, action) => {
    if (action.type === GET_AUTH) return action.auth
    return state
}