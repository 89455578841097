import styled from 'styled-components'

export const AuthenticatedUi = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100vh - 50px);
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
`
