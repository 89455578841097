import * as actions from './actions'

const INITIAL_STATE = {
  list: [],
  selected: { label: '', value: '' }
}

export default (state = INITIAL_STATE, action) => {
  if (action.type === actions.SET_CRM_LEADER_LIST)
    return { ...state, list: [...action.list] }

  if (action.type === actions.SET_SELECTED_CRM_LEADER)
    return { ...state, selected: action.selected }

  return state
}
