import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

export const Container = styled.div`
  display: block;
  .btn-clear-history {
    width: 70px;
    height: 30px;
    background-color: #b4b4b4;
    border: 1 px solid #aeaeae;
    border-radius: 3 px;
    color: white;
  }
`

export const GridContainer = styled(Grid)`
  .title-grid {
    display: flex;
    flex-direction: column;
  }

  .download-button {
    max-width: 445px;
    min-width: 390px;
  }

  .i-collapsible-top {
    padding: 16px;
  }

  .total-row {
    width: 100%;
  }

  .tooltip-historic {
    align-items: 'flex-end';
  }

  .clear-container {
    margin: 0px 53px 0px auto;
    padding: 5px;
  }

  .date-label {
    white-space: nowrap;
  }
`

export const TitlePage = styled.div`
  display: flex;
  align-items: center;

  h3 {
    color: rgb(16, 6, 144);
    padding-right: 10px;
    font-size: 1.17em;
  }

  small {
    color: #666;
    font-size: 1.4rem;
  }
`

export const FormSearchCustomer = styled.form`
  display: flex;
  justify-content: center;
  position: relative;

  > div {
    padding-right: 61px;
  }

  button {
    position: relative;

    svg {
      font-size: 24px;
    }
  }
`

export const SelectUiActivity = styled.div`
  display: block;
  width: 100%;
  margin-right: 16px;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInput-input {
    font-size: 16px;
    padding: 9px;
    line-height: 24px;
  }
`

export const TableListErrosEmails = styled.div`
  display: block;
  background-color: #fff;
  padding: 16px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);

  .table-customers {
    width: 100%;
    border-collapse: collapse;

    thead tr th {
      padding: 8px;
      font-weight: 400;
      color: #9d9d9d;
      border-bottom: 1px solid #e2e2e2;
      text-align: left;
    }

    tbody tr td {
      padding: 8px;
      font-size: 14px;
      color: #9d9d9d;
      font-weight: 700;
      border-bottom: 1px solid #e2e2e2;
      justify-content: space-between;
    }

    tbody tr:last-child td {
      border-bottom: 1px solid #fff;
    }
  }
`

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;

  .MuiPaginationItem-root {
    font-size: 14px;
  }
`
