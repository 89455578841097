import { findAggByAccountManager } from '../../../domain/document/'

const GET_CAM_CSI = "GET_CAM_CSI"
const getCamCsi = camCsi => ({
  type: GET_CAM_CSI,
  camCsi
})

const fetchCamCsi = (query) => (dispatch) => findAggByAccountManager(query)
  .then(camCsi => dispatch(getCamCsi(camCsi)))

export { GET_CAM_CSI, fetchCamCsi }