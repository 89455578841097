import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_NOT_FOUND,
  SET_CUSTOMER_SELECTED
} from "./actions"

const INITIAL_STATE = {
  customers: [],
  selectedCustomer: { value: "", label: "" }
}

export const getCustomers = (state = INITIAL_STATE, action) => {

  if (action.type === GET_CUSTOMERS)
    return { ...state, customers: action.customers }

  if (action.type === GET_CUSTOMERS_NOT_FOUND)
    return { ...state, customers: action.customers }

  if (action.type === SET_CUSTOMER_SELECTED)
    return { ...state, selectedCustomer: action.customer }

  return state
}
