import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import Loading from '../../../component/loading'
import { CompanyInformationContainer } from './style'

export default ({ userInfo }) => {
  const { t } = useTranslation()

  const [info, setInfo] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setInfo(userInfo)
    setLoading(false)
  }, [userInfo])

  if (loading) return <Loading />

  return (
    <CompanyInformationContainer>
      <Grid container spacing={2} className='mb-16px'>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('company-information-name') + ' *'}
            disabled
            variant='filled'
            value={`${info.account.name}`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('company-information-address') + ' *'}
            disabled
            variant='filled'
            value={`${info.account.address}`}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className='mb-16px'>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('company-information-city') + ' *'}
            disabled
            variant='filled'
            value={`${info.account.city}`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('company-information-state-province')}
            disabled
            variant='filled'
            value={`${info.account.stateProvince}`}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className='mb-16px'>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('company-information-country')}
            disabled
            variant='filled'
            value={`${info.account.country}`}
          />
        </Grid>
      </Grid>
    </CompanyInformationContainer>
  )
}
