import colors from '../amcharts-colors/colors.json'
import config from './config.js'
import { pipe, set, merge } from 'lodash/fp'

export default (dataConfig, dataProvider) =>
  pipe(
    set('dataProvider')(dataProvider),
    set('colors')(colors),
    merge(dataConfig)
  )(config)
