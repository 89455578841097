import { findAggByRegion } from '../../../domain/document/'

const GET_REGIONS = "GET_REGIONS"
const getChartRegions = regions => ({
  type: GET_REGIONS,
  regions
})

const fetchChartRegions = (query) => (dispatch) => findAggByRegion(query)
  .then(regions => dispatch(getChartRegions(regions)))

export { GET_REGIONS, fetchChartRegions }
