import {
  CHANGE_INPUT_SEARCH,
  RESET_INPUT_SEARCH,
  CLOSE_SEARCH,
  INVERSE_MOBILE_SEARCH
} from './actions'

const initialState = { textFilter: '', modalMobile: false }

export const inputSearchRedux = (state = initialState, action) => {
  const actions = {
    [RESET_INPUT_SEARCH]: initialState,
    [CHANGE_INPUT_SEARCH]: { ...state, textFilter: action.textFilter },
    [CLOSE_SEARCH]: { ...state, modalMobile: false },
    [INVERSE_MOBILE_SEARCH]: { ...state, modalMobile: !state.modalMobile }
  }
  return actions[action.type] || state
}
