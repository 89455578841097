import { stringify } from 'query-string'
import { pick, get } from 'lodash'
import { pipe, set } from 'lodash/fp'
import { getBearerAuthorization } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/documents`
const SEARCH_FIELDS = [
  'customer',
  'customerGroup',
  'accountManager',
  'leader',
  'criteria',
  'page',
  'order'
]

const _composeFilter = ({ selects = [] }) =>
  selects
    ? selects.map((filter) =>
        pipe(set(get(filter, 'name'))(get(filter, 'selected.value')))({})
      )
    : {}

export default async (query = {}) => {
  const { customer } = query
  query = Object.assign(
    {},
    { customer, pageSize: 10, page: 0 },
    pick(query, SEARCH_FIELDS),
    ..._composeFilter(query)
  )
  return await axios.get(`${url}/interest-free-invoices?${stringify(query)}`, {
    headers: getBearerAuthorization()
  })
}
