import { Accordion } from '@material-ui/core'
// import ExpansionPanelMui from '@material-ui/core/ExpansionPanel'
import styled, { css } from 'styled-components'
import BR from '../../assets/images/br.png'
import CN from '../../assets/images/cn.png'
import FR from '../../assets/images/fr.png'
import NL from '../../assets/images/nl.png'
import SG from '../../assets/images/sg.png'
import US from '../../assets/images/us.png'
import theme from '../theme'

const ExpansionPanel = styled(Accordion)`
  border: 0px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 12px;

  .MuiAccordionSummary-content {
    margin: 0;
  }

  &.list-expansion-panel-admin {
    .i-collapsible-top-label {
      font-size: 1.4rem;
      font-weight: bold;
      padding: 15px 10px;
    }

    .MuiExpansionPanelDetails-root {
      display: block;
      border-top: none;
    }
  }

  ${(props) =>
    props.element === 'expansionMenu' &&
    css`
      border: none;
      background-color: transparent;
      box-shadow: none;

      .MuiExpansionPanelSummary-root {
        position: relative;
        padding: 0;
      }

      .MuiExpansionPanelDetails-root {
        padding: 0;
        display: block;
        border-top: none;

        .i-nav-item {
          padding-left: 65px;
        }
      }

      .MuiIconButton-root {
        color: ${theme.colors.silver};
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
      }

      @media (max-width: 1131px) {
        .MuiExpansionPanelSummary-root {
          box-shadow: none;
          background-color: transparent;
        }
      }
    `}

  .i-collapsible-top {
    .tooltip-summary {
      display: none;
    }
  }

  &:first-child .i-collapsible-top {
    &:first-child {
      .tooltip-summary {
        display: block;
        position: absolute;
        right: -20px;
        width: 20px;
        top: -40px;
        @media (max-width: 630px) {
          display: none;
        }
      }
    }

    .i-states-invoices {
      width: 100%;
      position: absolute;
      top: -30px;
      left: -20px;
      display: block;

      @media (max-width: 970px) {
        top: -88px;
        left: 0;
        text-align: center;
      }

      @media (max-width: 630px) {
        top: -95px;
      }

      i {
        vertical-align: middle;
        margin-right: 5px;

        @media (max-width: 970px) {
          display: block;
          margin: 0 auto;
        }
      }

      &:after {
        content: attr(data-label);
        font-weight: 700;
        display: inline-block;

        @media (max-width: 630px) {
          display: block;
          padding-bottom: 2px;
        }
      }
    }
  }

  &:before {
    background-color: transparent;
  }

  .i-states-invoices {
    display: none;
  }

  .i-collapsible-ico {
    width: 32px;
    height: 22px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  [data-flag='BR'] {
    background-image: url(${BR});
  }

  [data-flag='CN'] {
    background-image: url(${CN});
  }

  [data-flag='FR'] {
    background-image: url(${FR});
  }

  [data-flag='NL'] {
    background-image: url(${NL});
  }

  [data-flag='SG'] {
    background-image: url(${SG});
  }

  [data-flag='US'] {
    background-image: url(${US});
  }

  @media (max-width: 1131px) {
    border: none;
    background-color: transparent;
    box-shadow: none;
  }

  .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: auto;
  }
`

export default ExpansionPanel
