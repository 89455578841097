import React, {
  Children,
  cloneElement,
  useReducer,
  useEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { WrapFilter } from '../../component/fixed-filter/filter-style'
import {
  changeLoading,
  resetSelectsSearch
} from '../document-filter/__redux__/actions'
import Button from '../../component/button'
import reducer from './reducer'
import { closeModal } from './__redux__/actions'

const initialState = { anchorEl: null, selected: [] }

const getFiltersAnalytical = (state) =>
  get(state, 'getFiltersAnalytical.selects', [])
 
export default function Filter(props) {
  const dispatchRedux = useDispatch()
  const [state, dispatch] = useReducer(reducer, initialState)
  const filters = useSelector(getFiltersAnalytical)

  const setFiltersRedux = (selected) => {
    let newSelects = filters
    newSelects = newSelects.filter((sel) => sel.name !== selected.name)

    if (selected.selected) newSelects.push(selected)
    else newSelects = newSelects.filter((sel) => sel.name !== selected.name)

    if (selected.selected) dispatch({ type: 'onSelect', selected })
    else dispatch({ type: 'onResetField', selected })
  }

  useEffect(() => {
    if (props.interceptSelectedValue) {
      if (props.syncSelects) props.syncSelectsUpdate(state.selected)
      props.onChangeFilter(state.selected)
    }
  }, [state.selected])

  return (
    <WrapFilter>
      <div className='i-container hidden-button-filter'>
        <div className='fixed-filter' id={props.menuFilter}>
          <li className={`drop-filter ${props.isAdmin || props.isAdmin === undefined ? '' : 'drop-filter-customer' }`}>
            {Children.map(props.children, (child) =>
              cloneElement(child, {
                onSelect: props.interceptSelectedValue
                  ? setFiltersRedux
                  : props.onSelect
              })
            )}

            <div className={props.filterFooter}>
              {props.clearInput ? (
                <Button
                  onClick={() => {
                    if (props.onClearClick) props.onClearClick()
                    else {
                      dispatchRedux(changeLoading(true))
                      dispatchRedux(closeModal())
                      dispatch({ type: 'resetSelect', selected: [] })
                    }
                  }}
                  variant='contained'
                  className='btn-clear'>
                  {props.clearTitle}
                </Button>
              ) : null}
              <Button
                variant='contained'
                className={props.buttonName}
                disabled={props.disabled}
                id='btn-apply'
                onClick={() => {
                  if (props.applyFilter) props.applyFilter()
                  else {
                    dispatchRedux(changeLoading(true))
                    dispatchRedux(closeModal())
                    dispatchRedux(resetSelectsSearch())
                    props.onChangeFilter(state.selected)
                  }
                }}>
                <i className='material-icons'>search</i>
                {props.applyTitle}
              </Button>
            </div>
          </li>
        </div>
      </div>
    </WrapFilter>
  )
}
