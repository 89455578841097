export default (customersSelected) => {
  if (customersSelected.length) {
    const selected = customersSelected
      .filter((cust) =>
        cust.name === 'customerQuotation' || cust.name === 'customerGroup' ? true : false
      )
      .map((cust) => (cust.selected ? true : false))
    return selected[0] ? selected[0] : false
  }
  return false
}
