import React, {
  Children,
  cloneElement,
  useReducer,
  useEffect,
  memo,
  useCallback,
  useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import { uniqBy } from 'lodash'
import { WrapFilter } from '../../component/fixed-filter/filter-style'
import {
  changeLoading,
  resetSelectsSearch
} from '../document-filter/__redux__/actions'
import Button from '../../component/button'
import reducer from './reducer'
import { closeModal } from './__redux__/actions'

const initialState = { anchorEl: null, selected: [] }

const Filter = memo((props) => {
  const dispatchRedux = useDispatch()
  const [state, dispatch] = useReducer(reducer, initialState)

  const loadingInitialStatesPage = (selectsStorage) => {
    if (selectsStorage && selectsStorage.length > 0)
      dispatch({ type: 'onSelect', selected: selectsStorage })
  }

  const setFiltersRedux = useCallback(
    async (selected, filters) => {
      if (selected.selected.value !== '' && filters) {
        let newSelects = filters

        newSelects = newSelects.filter((sel) => sel.name !== selected.name)

        if (selected.selected) newSelects.push(selected)
        else newSelects = newSelects.filter((sel) => sel.name !== selected.name)

        let filtersArray = []
        if (newSelects.length > 0) filtersArray = uniqBy(newSelects, 'name')

        dispatch({ type: 'onSelect', selected })
        await props.onChangeFilter(filtersArray)
      } else {
        dispatch({ type: 'resetSelect', selected: [] })
        await props.onChangeFilter([])
      }
    },
    [props, dispatch]
  )

  const handleClear = useCallback(() => {
    dispatchRedux(changeLoading(true))
    dispatchRedux(closeModal())
    dispatch({ type: 'resetSelect', selected: [] })
    props.onClearClick()
  }, [props, dispatch])

  const handleClean = useCallback(async () => {
    dispatchRedux(changeLoading(true))
    dispatchRedux(closeModal())
    dispatch({ type: 'resetSelect', selected: [] })
    await props.onChangeFilter([])
  }, [props, dispatch])

  const handleSearch = useCallback(async () => {
    dispatchRedux(changeLoading(true))
    dispatchRedux(closeModal())
    dispatchRedux(resetSelectsSearch())
    await props.onChangeFilter(state.selected)
  }, [props, dispatch, state])

  useEffect(() => {
    if (props.selectsVerify) {
      dispatch({ type: 'onSelect', selected: [] })
      loadingInitialStatesPage(props.selectsStorage)
    }
  }, [props.selectsVerify, dispatch])

  useEffect(() => {
    if (props.syncSelects) props.syncSelectsUpdate(state.selected)
  }, [state.selected])

  const memoSelect = useMemo(() => {
    return Children.map(props.children, (child) =>
      cloneElement(child, {
        handleClean,
        onSelect: props.interceptSelectedValue
          ? setFiltersRedux
          : child.props.onSelect
      })
    )
  }, [props.children, props.interceptSelectedValue])

  return (
    <WrapFilter>
      <div className='i-container hidden-button-filter'>
        <div className='fixed-filter' id={props.menuFilter}>
          <li className={props.customerDashboardClassName || 'drop-filter'}>
            {memoSelect}
            <div className={props.filterFooter}>
              {props.clearInput ? (
                <Button
                  onClick={handleClear}
                  variant='contained'
                  className='btn-clear'>
                  {props.clearTitle}
                </Button>
              ) : null}
              <Button
                variant='contained'
                className={props.buttonName}
                disabled={props.disabled}
                id='btn-apply'
                onClick={handleSearch}>
                <i className='material-icons'>search</i>
                {props.applyTitle}
              </Button>
            </div>
          </li>
        </div>
      </div>
    </WrapFilter>
  )
})
export default Filter
