import React, { memo } from 'react'
import { TableCell, TableHead, TableRow } from '../../component/table'
import { useTranslation } from 'react-i18next'

const TableHeadRep = memo(() => {
  const { t } = useTranslation()
  return (
    <TableHead>
      <TableRow>
        <TableCell bold align='center'>
          {t('customer')}
        </TableCell>
        <TableCell bold align='center'>
          {t('report-status')}
        </TableCell>
        <TableCell bold align='center'>
          {t('report-created')}
        </TableCell>
        <TableCell bold align='center'>
          {t('report-processing-status')}
        </TableCell>
        <TableCell bold align='center'>
          {t('report-actions')}
        </TableCell>
      </TableRow>
    </TableHead>
  )
})

export default TableHeadRep
