import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import createLabel from './create-label'

const menuSuggestion = (suggestionProps) => {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    attributesAsLabel
  } = suggestionProps
  const isHighlighted = highlightedIndex === index

  return (
    <MenuItem
      {...itemProps}
      key={index}
      selected={isHighlighted}
      component='div'
      style={{ fontSize: 15 }}>
      {createLabel(attributesAsLabel, suggestion).label}
    </MenuItem>
  )
}

menuSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string
}

export default menuSuggestion
