import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import Header from '../../component/header'
import { MobileConfig } from './config-mobile'

const getAuth = (state) => get(state, 'auth.user')

const HeaderBox = ({ pageName }) => {
  const user = useSelector(getAuth)
  const config = MobileConfig[pageName] || MobileConfig['default']

  return (
    <>
      <Header user={user} {...config} />
    </>
  )
}

export default HeaderBox
