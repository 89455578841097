import React, { memo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import Select from '../../component/select'
import {
  fetchCustomers,
  setCustomerSelected,
  fetchCustomersNotFound
} from './__redux__/actions'

const getCustomers = (state) => get(state, 'getCustomers.customers', [])

const CustomerList = memo((props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const customers = useSelector(getCustomers)

  const [inputValue, setInputValue] = useState('')
  const [stateRedux, setStateRedux] = useState(false)

  const customerStorage = localStorage.getItem('@customer')
    ? JSON.parse(localStorage.getItem('@customer'))
    : { value: '', label: '' }

  const handleInputChange = (value) => {
    setInputValue(value)
  }

  const setInputsRedux = (selected, propsName = null) => {
    if (propsName) props.onSelect({ name: propsName, selected }, [])

    setInputValue(`${selected.label}`)
    dispatch(setCustomerSelected(selected))
  }

  const handleChangeValue = (selected, name) => {
    if (selected && selected.value !== '' && selected !== null)
      setInputsRedux(selected, name)
    else {
      setInputValue('')
      props.handleClean()
      setInputsRedux({ value: '', label: '' }, 'customer')
    }
  }

  const verifyCustomerStorage = (selectedCustomerStorage) => {
    dispatch(setCustomerSelected(selectedCustomerStorage))
    setInputValue(selectedCustomerStorage.label)
    localStorage.removeItem('@customer')
  }

  useEffect(() => {
    dispatch(fetchCustomers())
    return () => {
      customerStorage.value !== ''
        ? verifyCustomerStorage(customerStorage)
        : localStorage.removeItem('@customer')
    }
  }, [])

  useEffect(() => {
    if (props.updateValues) {
      handleChangeValue({ label: '', value: '' }, 'customer')
      setInputValue('')
    }
  }, [props.updateValues])

  useEffect(() => {
    if (customers.length === 1 && !stateRedux) {
      // setInputsRedux(
      //   { value: customers[0].code, label: customers[0].name },
      //   'customer'
      // )
      // setInputsRedux(`${customers[0].code} - ${customers[0].name}`)
      setStateRedux(true)
    }
  }, [customers])
  return (
    <Select
      handleInputChange={handleInputChange}
      onChangeValue={handleChangeValue}
      inputSearchValue={inputValue}
      name='customer'
      suggestions={customers}
      label={t('customer-list-label')}
      placeholder={t('customer-list-placeholder')}
      useAttributesAsValue={['code']}
      showAttributesAsLabel={['code', 'name']}
      notifySuggestionNotFound={(notification) => {
        dispatch(fetchCustomersNotFound(notification))
      }}
    />
  )
})
export default CustomerList
