import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import { verifyAccess } from '../domain/security'

import Breadcrumbs from '../component/breadcrumbs'

import { Tab, TabPanel } from '../container/document-tab'
import ConfigEmailJobs from '../container/confg-email-jobs'
import HistoricEmails from '../container/historic-emails'
import CustomerUpdateCrm from '../container/customer-setup-update-crm'
import PortalAccountSAPSync from '../container/portal-account-sap-sync'

import { rolesAdmin, rolesMananger } from '../App'

const CustomerSetup = () => {
  const { t } = useTranslation()
  const user = useSelector((state) => get(state, 'auth.user'))

  const hasTabs = useMemo(() => {
    const tabs = [
      {
        Component: PortalAccountSAPSync,
        label: 'CUSTOMERS',
        role: rolesAdmin
      },
      {
        Component: ConfigEmailJobs,
        label: t('customer-setup-config-email'),
        role: [...rolesAdmin, ...rolesMananger]
      },
      {
        Component: HistoricEmails,
        label: t('customer-setup-historic'),
        role: [...rolesAdmin, ...rolesMananger]
      },
      {
        Component: CustomerUpdateCrm,
        label: t('customer-setup-update-crm'),
        role: [...rolesAdmin, ...rolesMananger]
      }
    ]
    const permissions = tabs.filter((tab) => verifyAccess(user, tab.role))
    return (
      permissions &&
      permissions.map(({ label, Component }, index) => (
        <TabPanel key={index} label={label} index={index}>
          <div className='i-container'>
            <Component />
          </div>
        </TabPanel>
      ))
    )
  }, [])
  return (
    <div>
      <div className='i-container'>
        <Breadcrumbs />
      </div>

      <Tab activeChange={false}>{hasTabs}</Tab>
    </div>
  )
}

export default CustomerSetup
