import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchPaginationDocuments,
  fetchAllDocuments,
  setLoadShowMore
} from '../document-list/__redux__/actions'
import Table from '../table'
import { get } from 'lodash'

import Loading from '../../component/loading'

const getStatus = (state) => get(state, 'getFilters.status', [])
const getSelects = (state) => get(state, 'getFilters.selects', [])
const getCriteria = (state) => get(state, 'getFilters.criteria')
const getAuth = (state) => get(state, 'auth.user')

const getDocuments =
  ({ salesOrganization, currency }) =>
  (state) =>
    get(state, 'getDocuments.documents', new Map()).get(
      `${salesOrganization}-${currency}`
    )

const getCustomers = (state) => get(state, 'getFilters.selects')

const DocumentList = (props) => {
  const dispatch = useDispatch()
  const documents = useSelector(getDocuments(props))
  const status = useSelector(getStatus)
  const criteria = useSelector(getCriteria)
  const selects = useSelector(getSelects)
  const customersSelected = useSelector(getCustomers)
  const { salesOrganization, currency } = props
  const user = useSelector(getAuth)

  const [loadingStatus, setLoadingStatus] = useState(true)

  const setOnShowMore = useCallback(
    (page, salesOrganization, currency) => {
      dispatch(
        fetchPaginationDocuments({
          salesOrganization,
          currency,
          status,
          selects,
          criteria,
          page
        })
      )
      dispatch(setLoadShowMore(true))
    },
    [salesOrganization, currency]
  )

  const setOnShowAll = useCallback(
    (salesOrganization, currency) => {
      dispatch(
        fetchAllDocuments({
          salesOrganization,
          currency,
          status,
          selects,
          criteria
        })
      )
      dispatch(setLoadShowMore(true))
    },
    [salesOrganization, currency]
  )

  useEffect(() => {
    if (loadingStatus) {
      setTimeout(() => {
        setLoadingStatus(false)
      }, 1500)
    }
  }, [loadingStatus])

  return loadingStatus ? (
    <Loading />
  ) : (
    <Table
      {...documents}
      salesOrganization={salesOrganization}
      currency={currency}
      showSort={true}
      customersSelected={customersSelected}
      status={status}
      onShowMore={setOnShowMore}
      onShowAll={setOnShowAll}
      user={user}
    />
  )
}

export default DocumentList
