import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import axios from 'axios'

import { fetchAuth } from '../../../container/auth/__redux__/actions'

import { FormLoginContainer } from './style'

export default () => {
  const { t } = useTranslation()

  const history = useHistory()
  const dispatchRedux = useDispatch()
  const [emailState, setEmailState] = useState('')
  const [passwordState, setPasswordState] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmitLogin = (e) => {
    e.preventDefault()

    setLoading(true)

    const sendingFormLogin = {
      email: emailState.toLowerCase(),
      password: passwordState
    }

    axios
      .post(
        `${process.env.REACT_APP_INVOICES_API}/authentication`,
        sendingFormLogin
      )
      .then(
        ({
          data: {
            body: { tokenId, message, token, user, pinCode }
          }
        }) => {
          if (tokenId && message) {
            setLoading(false)
            if (
              message ===
              'Your password is expired, please update your password'
            ) {
              Swal.fire(
                t('attention') + '!',
                t('user-password-expired'),
                'warning'
              )
            }
            history.push(`/login/change-password/${tokenId}`)
            return
          }

          if (user && token) {
            sessionStorage.setItem('token', token)

            fetchAuth({ user, token })(dispatchRedux)

            setLoading(false)

            if (pinCode) history.push('/inform-pin-code')
          }
        }
      )
      .catch((error) => {
        let response = error.response
        let message = ''
        switch (response.data.body) {
          case 'User not found.':
            message = t('form-login-incorrect-user-password')
            break
          case 'User blocked.':
            message = t('new-user-user-disabled')
            break
          case 'Missing full name to user access.':
            message = t('user-info-missing')
            break
          case 'User not synced customer.':
            message = t('user-customer-missing')
            break
          default:
            message = response.data.body
            break
        }
        Swal.fire(t('attention') + '!', message, 'warning')
        setLoading(false)
      })
  }

  return (
    <FormLoginContainer onSubmit={handleSubmitLogin}>
      <Grid container>
        <Grid item xs={12}>
          <h2>{t('form-login-access')} Invoices & Payments</h2>
        </Grid>
      </Grid>
      <Grid container className='mb-16'>
        <Grid item xs={12}>
          <TextField
            label='E-mail'
            fullWidth
            value={emailState}
            onChange={(input) => setEmailState(input.target.value)}
            required
          />
        </Grid>
      </Grid>
      <Grid container className='mb-32'>
        <Grid item xs={12}>
          <TextField
            type='password'
            label={t('password')}
            fullWidth
            value={passwordState}
            onChange={(input) => setPasswordState(input.target.value)}
            required
          />
        </Grid>
      </Grid>
      <Grid container className='mb-32'>
        <Grid item xs={12}>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={loading}>
            {loading ? 'await...' : 'Login'}
          </Button>
        </Grid>
      </Grid>
      <Grid container className='mb-link'>
        <div className='flex-container'>
          <Grid item xs={12} md={6} className='text-center link-logins'>
            <Link to='/login/forgot-password'>
              {t('form-login-forgot-password')}
            </Link>
          </Grid>
          {/* <Grid item xs={12} md={6} className='text-center link-logins'>
            <Link to='/login/register-now'>{t('form-login-register-now')}</Link>
          </Grid> */}
          <Grid item xs={12} md={6} className='text-center link-logins'>
            <Link to='/login/first-access'>{t('form-login-first-access')}</Link>
          </Grid>
        </div>
        <div className='portal-link'>
          <Grid item xs={12} md={6} className='text-center link-logins'>
            <a href={process.env.REACT_APP_URL_PORTAL}>
              {t('form-login-got-to-portal')}
            </a>
          </Grid>
        </div>
      </Grid>
    </FormLoginContainer>
  )
}
