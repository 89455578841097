import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import i18n from './i18n/config'
import Routes from './routes'
import configureStore from './store/configureStore'

import './assets/scss/main.scss'

const store = configureStore(window.__REDUX_STATE__ || {})

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Routes />
      </I18nextProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
