import { findAggBySegment } from '../../../domain/document/'

const GET_AR_SEGMENTS = "GET_AR_SEGMENTS"
const getChartSegments = segments => ({
  type: GET_AR_SEGMENTS,
  segments
})

const fetchChartSegments = (query) => (dispatch) => findAggBySegment(query)
  .then(segments => dispatch(getChartSegments(segments)))

export { GET_AR_SEGMENTS, fetchChartSegments }
