import * as actions from './actions'

const INITIAL_STATE = {
  list: [],
  selected: { value: '', label: '' }
}

export default (state = INITIAL_STATE, action) => {
  if (action.type === actions.SET_SELECTED_CUSTOMER_GROUP)
    return { ...state, selected: action.selected }

  if (action.type === actions.SET_CUSTOMER_GROUP_LIST)
    return { ...state, list: action.list }

  if (action.type === actions.SET_CUSTOMER_GROUP_NOT_FOUND_LIST)
    return { ...state, list: action.list }

  if (action.type === actions.SET_CUSTOMER_GROUP_PARAMS)
    return { ...state, params: action.params }

  return state
}
