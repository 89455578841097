import styled from 'styled-components'

export const InformPinCodeContainer = styled.div`
  background-color: #fafafa;
  display: flex;
  justify-content: center;

  .center-page {
    width: 874px;

    .box-pin {
      margin: 0 auto;
      width: 100%;
      max-width: 413px;
      display: flex;
      flex-direction: column;
      padding: 30px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      border-radius: 4px;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #6e6e6e;
        margin-bottom: 32px;
      }

      .pincodeError {
        margin-top: 32px;
        color: #ff0000;
      }
    }
  }
`
