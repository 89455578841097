import { findAggByCustomer } from '../../../domain/document/'

const GET_OVERDUE_PER_CUSTOMERS = "GET_OVERDUE_PER_CUSTOMERS"
const getOverduePerCustomers = overduePerCustomers => ({
  type: GET_OVERDUE_PER_CUSTOMERS,
  overduePerCustomers
})

const fetchOverduePerCustomers = (query) => (dispatch) => findAggByCustomer(query)
  .then(overduePerCustomers => dispatch(getOverduePerCustomers(overduePerCustomers)))

export { GET_OVERDUE_PER_CUSTOMERS, fetchOverduePerCustomers }