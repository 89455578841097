import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Swal from 'sweetalert2'

import FormLoginContainer from '../../../component/form-login-container'
import isUserDisabledResponse from '../../../helpers/is-user-disabled-response'
import userAlreadyHasLogin from '../../../helpers/user-already-has-login'

export default () => {
  const { t } = useTranslation()

  const [emailState, setEmailState] = useState('')
  const [loadingState, setLoading] = useState(false)

  const handleSubmitLogin = (e) => {
    e.preventDefault()

    const sendingFirstAccess = {
      email: emailState.toLocaleLowerCase()
    }

    setLoading(true)

    axios
      .post(
        `${process.env.REACT_APP_INVOICES_API}/new-user/first-access`,
        sendingFirstAccess
      )
      .then((_) => {
        Swal.fire(t('success') + '!', t('first-access-email-sent'), 'success')
      })
      .catch((error) => {
        let msgResponse
        let response = error.response
        if (response) {
          msgResponse = isUserDisabledResponse(response.data.body)
            ? t('new-user-user-disabled')
            : t(userAlreadyHasLogin(response.data.body))
        } else msgResponse = error.message

        Swal.fire(t('error') + '!', msgResponse, 'error')
      })
      .finally((msg) => {
        setLoading(false)
        setEmailState('')
      })
  }

  return (
    <FormLoginContainer
      onSubmit={handleSubmitLogin}
      autoComplete='off'
      pageName='first-access-resend-email'
      pageText='first-access-resend-registration-email'
      value={emailState}
      loadingState={loadingState}
      onChange={(input) => setEmailState(input.target.value)}
      buttonText={t('forgot-password-send-email')}
    />
  )
}
