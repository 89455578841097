import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'

import {
  setFullName,
  setPassword,
  setPasswordConfirm,
  setPhoneNumber,
  setErrorFullName,
  setErrorPassword,
  setErrorPasswordConfirm
} from '../__redux__/actions'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import Loading from '../../../component/loading'

import { UserInformationContainer } from './style'
import { useTranslation } from 'react-i18next'

export default ({ userInfo }) => {
  const { t } = useTranslation()
  const dispatchRedux = useDispatch()

  const [info, setInfo] = useState({})
  const [loading, setLoading] = useState(true)
  const [errorPasswordValidation, setErrorPasswordValidator] = useState('')
  const [errorPasswordConfirmValidation, setErrorPasswordConfirmValidator] =
    useState('')

  const fullName = useSelector((state) => state.newUser.fullName)
  const password = useSelector((state) => state.newUser.password)
  const passwordConfirm = useSelector((state) => state.newUser.passwordConfirm)
  const phoneNumber = useSelector((state) => state.newUser.phoneNumber)

  const errorFullName = useSelector((state) => state.newUser.errorFullName)
  const errorPassword = useSelector((state) => state.newUser.errorPassword)
  const errorPasswordConfirm = useSelector(
    (state) => state.newUser.errorPasswordConfirm
  )

  const handleChangeFullName = (value) => {
    dispatchRedux(setFullName(value))

    if (value.length > 4) {
      dispatchRedux(setErrorFullName(false))
    }
  }

  const handleChangePassword = (value) => {
    dispatchRedux(setPassword(value))
    if (
      validator.isStrongPassword(value, {
        minLength: 11,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1
      })
    ) {
      dispatchRedux(setPassword(value))
      dispatchRedux(setErrorPassword(false))
      setErrorPasswordValidator('')
    } else {
      setErrorPasswordValidator(t('change-password-invalid-character'))
      dispatchRedux(setErrorPassword(true))
    }

    if (value.length > 0) {
      dispatchRedux(setErrorPassword(false))
    }
  }

  const handleChangePasswordConfirm = (value) => {
    dispatchRedux(setPasswordConfirm(value))
    if (
      validator.isStrongPassword(value, {
        minLength: 11,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1
      })
    ) {
      dispatchRedux(setPasswordConfirm(value))
      dispatchRedux(setErrorPasswordConfirm(false))
      setErrorPasswordConfirmValidator('')
    } else {
      setErrorPasswordConfirmValidator(t('change-password-invalid-character'))
      dispatchRedux(setErrorPasswordConfirm(true))
    }

    if (value.length > 0) {
      dispatchRedux(setErrorPasswordConfirm(false))
    }
  }

  const handleChangePhoneNumber = (value) => {
    dispatchRedux(setPhoneNumber(value))
  }

  useEffect(() => {
    setInfo(userInfo)
    setLoading(false)
  }, [userInfo])

  if (loading) return <Loading />

  return (
    <UserInformationContainer>
      <Grid container spacing={2} className='mb-16px'>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('user-information-full-name') + ' *'}
            onChange={(input) => handleChangeFullName(input.target.value)}
            value={`${fullName}`}
            error={errorFullName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            disabled
            variant='filled'
            label='E-mail *'
            value={`${info.email}`}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className='mb-16px'>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              label={t('password')}
              type='password'
              value={`${password}`}
              onChange={(input) => handleChangePassword(input.target.value)}
              error={errorPassword}
              className={`${errorPasswordValidation !== '' ? 'mb-16px' : null}`}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {errorPasswordValidation !== '' ? (
              <h5>{`* ${t('change-password-invalid-character')}`}</h5>
            ) : null}
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              label={t('user-information-password-confirm') + ' *'}
              type='password'
              value={`${passwordConfirm}`}
              onChange={(input) =>
                handleChangePasswordConfirm(input.target.value)
              }
              className={`${errorPasswordConfirmValidation !== '' ? 'mb-16px' : null}`}
              error={errorPasswordConfirm}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {errorPasswordConfirmValidation !== '' ? (
              <h5>{`* ${t('change-password-invalid-character')}`}</h5>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} className='mb-16px'>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('user-information-phone-number')}
            type='text'
            value={`${phoneNumber}`}
            onChange={(input) => handleChangePhoneNumber(input.target.value)}
          />
        </Grid>
      </Grid>
    </UserInformationContainer>
  )
}
