import TextFieldUi from '@material-ui/core/TextField'
import MenuItemUi from '@material-ui/core/MenuItem'
import styled from 'styled-components'
import theme from '../theme'

const WrapTextField = styled.div`
  position: absolute;
  right: 32px;
  width: 20%;
  z-index: 1;
  margin-top: 9px;

  .text-field-ico {
    font-size: 2.2rem;
    fill: #666666;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    z-index: 2;
  }

  .MuiFormControl-marginNormal {
    label + .MuiInput-formControl {
      margin-top: 0;
    }

    .MuiFormLabel-root {
      transform: translate(30px, 9px) scale(1);
    }

    .MuiFormLabel-root.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }

  .MuiFormControl-marginDense {
    background-color: #fafafa;

    .MuiInputBase-root {
      &::before {
        border-bottom: 1px solid ${theme.colors.paleSilver};
      }
      .MuiInputBase-input {
        padding-top: 10.5px;
        padding-bottom: 10.5px;
      }
    }

    .MuiFormLabel-root {
      transform: translate(30px, 12px) scale(1);
    }

    .MuiFormLabel-root.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }

  .MuiInputBase-input {
    padding-left: 30px;
  }
`

const TextField = styled(TextFieldUi)`
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  .MuiInputBase-root {
    .MuiInputBase-input {
      padding-top: 15px;
      padding-bottom: 14px;
    }
  }

  .MuiSelect-icon {
    font-size: 2rem;
  }

  .MuiFormLabel-root {
    font-size: initial;
  }

  label + .MuiInput-formControl {
    margin-top: 0;
  }

  [for='outlined-select-currency'] {
    transform: translate(0, 14px) scale(1);
  }

  [for='outlined-select-currency'].MuiInputLabel-shrink {
    transform: translate(0, -6px) scale(0.75);
  }

  .MuiInputBase-input {
    color: #666666;
    font-size: initial;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #100690;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #100690;
  }
`

const MenuItem = styled(MenuItemUi)`
  font-size: 1.4rem;
`

export { WrapTextField, TextField, MenuItem }
