import React, { useState } from 'react'

import toFirstUppercase from '../../helpers/to-first-uppercase'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FormLabel from '@material-ui/core/FormLabel'
import CustomerTypeUi from './style'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import { applyChangeFinancialStatusFilter } from './__redux__/actions'
import { onSelect } from '../document-fixed-filter/__redux__/invoices-filter/actions'

const getFinancialStatus = (state) => {
  return get(state, 'financialStatus.selected.value')
}
const getList = (state) => get(state, 'financialStatus.list')

export default (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [openSelect, setOpenSelect] = useState(false)
  const activeFinancialStatus = useSelector(getFinancialStatus)
  const financialStatuses = useSelector(getList)

  const handleSelectChange = ({ target: { value } }) => {
    dispatch(
      applyChangeFinancialStatusFilter({
        label: toFirstUppercase(value),
        value: value.toUpperCase()
      })
    )

    return dispatch(
      onSelect({
        name: 'financialStatus',
        selected: { value: value.toLowerCase() }
      })
    )
  }

  const handleSelectOpenClose = () => {
    setOpenSelect(!openSelect)
  }

  const menuItems = (items) =>
    items.map((type) => (
      <MenuItem style={{ fontSize: 16 }} key={type} value={type}>
        {toFirstUppercase(type)}
      </MenuItem>
    ))

  return (
    <CustomerTypeUi>
      <div className='label-position'>
        <FormLabel>{t('financial-status-filter')}</FormLabel>
      </div>
      <FormControl>
        <Select
          autoWidth
          open={openSelect}
          onClose={handleSelectOpenClose}
          onOpen={handleSelectOpenClose}
          value={activeFinancialStatus}
          onChange={handleSelectChange}>
          {props.isDisputeHistory
            ? menuItems(['ALL', 'OVERDUE'])
            : menuItems(financialStatuses)}
        </Select>
      </FormControl>
    </CustomerTypeUi>
  )
}
