import styled from 'styled-components'

export const ContainerStatusDocuments = styled.section`
  display: block;
  margin-bottom: 32px;

  table {
    width: 100%;

    thead {
      tr {
        th {
          text-align: left;
          font-weight: 400;
          color: #9e9e9e;
          font-size: 13px;
          padding: 12px;
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: left;
          font-weight: 400;
          color: #666666;
          font-size: 15px;
          padding: 12px;
          border-top: 1px solid #dedede;
        }
      }
    }
  }
`
