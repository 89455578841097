export const MobileConfig = {
  '/app/dashboard': {
    isFilter: true,
    iconFilter: 'Default',
    isSearch: false
  },
  '/app/invoices': {
    isFilter: true,
    iconFilter: 'Default',
    isSearch: true
  },
  '/app/reversal': {
    isFilter: null,
    iconFilter: null,
    isSearch: null
  },
  '/app/report': {
    isFilter: true,
    iconFilter: 'Add',
    isSearch: false
  },
  '/app/customer-setup': {
    isFilter: false,
    iconFilter: null,
    isSearch: false
  },
  default: {
    isFilter: false,
    iconFilter: null,
    isSearch: false
  }
}
