const CHANGE_SELECT_FILTER_ANALYTICAL = 'CHANGE_SELECT_FILTER_ANALYTICAL'
const changeSelectFilterAnalytical = (selects) => ({
  type: CHANGE_SELECT_FILTER_ANALYTICAL,
  selects
})

const applySelectFilterAnalytical = (query) => (dispatch) => {
  dispatch(changeSelectFilterAnalytical(query))
}

export { CHANGE_SELECT_FILTER_ANALYTICAL, applySelectFilterAnalytical }
