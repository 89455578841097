import TableHeadMui from '@material-ui/core/TableHead'
import styled from 'styled-components'

const TableHead = styled(TableHeadMui)`
  border-left: 5px solid transparent;

  .MuiTableCell-head {
    font-size: 1.4rem;
    padding: 0 5px 20px;

    &:first-child {
      text-align: left;
    }

    @media (max-width: 1244px) {
      font-size: 1.3rem;
    }
  }

  @media (max-width: 1131px) {
    display: none;
  }
`

export default TableHead
