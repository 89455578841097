import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { changeLoading } from '../document-filter/__redux__/actions'
import { fetchDocumentsInterestFree } from '../document-list-interest-free/__redux__/actions'
import Filter from '../fixed-filter-invoices'
import FilterSelect from '../invoices-selects-interest-free'
import { actions as selectActions } from '../invoices-selects-interest-free/__redux__'

const getPage = (state) => get(state, 'customerInterestFree.page')

export default () => {
  const { t } = useTranslation()
  const page = useSelector(getPage)
  const dispatch = useDispatch()
  function onChangeFilterApply(selects) {
    dispatch(fetchDocumentsInterestFree({ selects, page }))
  }

  function loadInitialData() {
    syncSelectsUpdate([])
    dispatch(fetchDocumentsInterestFree({ selects: [], page }))
  }

  useEffect(() => {
    dispatch(changeLoading(true))
    loadInitialData()
  }, [])

  function syncSelectsUpdate(selected) {
    let params = {}
    selected.forEach((s) => {
      if (s.name) params[s.name] = s.selected.value
    })
    if (!params.customer) dispatch(selectActions.customer.fetchList(params))
  }

  return (
    <Filter
      buttonName='btn-apply'
      menuFilter='document-filter'
      filterFooter='drop-filter-footer'
      searchInput={true}
      interceptSelectedValue={true}
      syncSelectsUpdate={syncSelectsUpdate}
      syncSelects={true}
      onChangeFilter={(selects) => onChangeFilterApply(selects)}
      selectsVerify={true}
      clearTitle={t('clear')}
      applyTitle={t('apply')}
      buttonTitleCustomerFilter={t('filter')}>
      <FilterSelect
        name='customer'
        labelFromItem='name'
        valueFromItem='code'
        translationLabel='customer-list-label'
        translationPlaceholder='customer-list-placeholder'
        useAttributesAsValue={['code']}
        showAttributesAsLabel={['code', 'name']}
        fetchNotFound={true}
        triggerOnHide
      />
    </Filter>
  )
}
