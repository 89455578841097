import { SET_FINANCIAL_STATUS, SET_FINANCIAL_STATUS_LIST } from './actions'

const initialState = {
  list: ['ALL', 'DISPUTE', 'OVERDUE', 'NOT OVERDUE', 'CREDIT'],
  selected: { label: 'All', value: 'ALL' }
}

export default (state = initialState, action) => {
  if (action.type === SET_FINANCIAL_STATUS)
    return { ...state, selected: action.selected }
  if (action.type === SET_FINANCIAL_STATUS_LIST)
    return { ...state, list: action.list }
  return state
}
