export default {
  graphs: [
    {
      balloonText:
        '<b>[[title]]</b><br><span>[[category]]: <b>[[value]] USD</b></span>',
      fillAlphas: 1,
      labelText: '[[value]]',
      lineAlpha: 0.3,
      title: 'Overdue',
      type: 'column',
      color: '#A9A9A9',
      valueField: 'overdue',
      showHandOnHover: true
    },
    {
      balloonText:
        '<b>[[title]]</b><br><span>[[category]]: <b>[[value]] USD</b></span>',
      fillAlphas: 1,
      labelText: '[[value]]',
      lineAlpha: 0.3,
      title: 'Credit',
      type: 'column',
      valueField: 'credit',
      showHandOnHover: true,
      hidden: true
    },
    {
      balloonText:
        '<b>[[title]]</b><br><span>[[category]]: <b>[[value]] USD</b></span>',
      fillAlphas: 1,
      labelText: '[[value]]',
      lineAlpha: 0.3,
      title: 'Dispute',
      type: 'column',
      valueField: 'dispute',
      showHandOnHover: true,
      hidden: true
    },
    {
      balloonText:
        '<b>[[title]]</b><br><span>[[category]]: <b>[[value]] USD</b></span>',
      fillAlphas: 1,
      labelText: '[[value]]',
      lineAlpha: 0.3,
      title: 'Not Overdue',
      type: 'column',
      color: '#A9A9A9',
      valueField: 'notOverdue',
      showHandOnHover: true,
      hidden: true
    }
  ],
  categoryAxis: {
    position: 'left',
    labelFunction: (text) =>
      text.length > 22 ? `${text.substring(0, 22)}...` : text
  },
  export: {
    enabled: true
  },
  method: (event, history) => {
    const groupCode = event.item.dataContext.groupCode
    const groupName = event.item.dataContext.groupName

    const customerGroup = {
      label: `${groupCode} - ${groupName}`,
      value: `${groupCode}`
    }

    localStorage.setItem('@customerGroup', JSON.stringify(customerGroup))

    history.push('/app/invoices')
  }
}
