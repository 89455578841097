import React, { useState, useEffect, memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import Breadcrumbs from '../component/breadcrumbs'
import ReportTable from '../container/report-table'
import ReportGenerate from '../container/report-generate-fixed'

import { findByUser } from '../domain/report'

const getAuth = (state) => get(state, 'auth.user')

const Report = memo(() => {
  const [reports, setReports] = useState([])
  const user = useSelector(getAuth)

  const reportsList = useCallback(async () => {
    try {
      const r = await findByUser(user.email)
      if (r.data) setReports(r.data)
    } catch (error) {
      setReports([])
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      await reportsList()
    })()

    return () => {
      setReports([])
    }
  }, [])

  return (
    <>
      <div className='i-container'>
        <Breadcrumbs />
      </div>
      <ReportGenerate
        reports={reports}
        setReports={setReports}
        reportsList={reportsList}
      />
      <div className='i-container'>
        <ReportTable reports={reports} setReports={setReports} />
      </div>
    </>
  )
})

export default Report
