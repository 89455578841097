import React, { useReducer } from 'react'
import { Table as TableUI } from '../../component/table'
import { hasGateway, hasAllowedCurrency } from '../../domain/document/'
import TableBody from './body'
import useStyles from './style'
import reducer from './reducer'
import TableHeader from './header'
import TableFooter from './footer'
import isClosed from './is-closed'
import showFiscalNumber from './show-fiscal-number'
import hasOneCustomerSelected from './has-one-customer-selected'
import getTotalAmount from './get-total-amount'
import getTotalCredit from './get-total-credit'
import isAmountValid from './amount-limit'
import { get } from 'lodash'

const initialState = {
  order: 'desc',
  orderBy: 'paidIn',
  selected: [],
  rowsSelecteds: [],
  page: 0,
  rowsPerPage: 50,
  statusCheckall: false
}

function Table(props) {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)

  const rows = get(props, 'quotations', [])
  const customersSelected = get(props, 'customersSelected', [])
  const salesOrganization = get(props, 'salesOrganization', '')
  const currency = get(props, 'currency', '')
  const isCLosedStatus = isClosed(rows)
  const canShowFiscalNumber = showFiscalNumber(rows)
  const hasCustomerSelected = hasOneCustomerSelected(customersSelected)
  const status = get(props, 'status', 'OPEN')
  const user = get(props, 'user')

  const updateOnClick = (id, isSelectable) =>
    isSelectable ? dispatch({ type: 'onClick', id }) : ''

  const updateRowsInvoices = (row, isSelectable) =>
    isSelectable ? dispatch({ type: 'addRow', row }) : ''

  const resetSelectAll = () => dispatch({ type: 'resetAll' })

  const totalAmount = getTotalAmount(rows, state.selected)
  const { message, isValid } = isAmountValid({
    totalAmount: totalAmount.totalAmount,
    salesOrganization,
    currency
  })

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <TableUI
          className={`${classes.table} i-table-invoices`}
          aria-labelledby='tableTitle'>
          <TableHeader
            numSelected={state.selected.length}
            order={state.order}
            orderBy={state.orderBy}
            onSelectAllClick={(event) =>
              dispatch({ type: 'selectAll', event, rows })
            }
            showFiscalNumber={canShowFiscalNumber}
            isClosed={isCLosedStatus}
            hasCustomerSelected={hasCustomerSelected}
            onRequestSort={(event, property) =>
              dispatch({ type: 'sort', property })
            }
            rowsPerPage={props.pageSize}
            rowCount={get(props, 'count', 0)}
            rowDisplaySize={get(props, 'quotations', []).length}
            id={props.id}
            status={status}
            user={user}
            statusCheckbox={state.statusCheckall}
          />

          <TableBody
            {...state}
            rows={props.quotations}
            showFiscalNumber={canShowFiscalNumber}
            isClosed={isCLosedStatus}
            hasCustomerSelected={hasCustomerSelected}
            status={status}
            user={user}
            onClick={updateOnClick}
            addRow={updateRowsInvoices}
          />
        </TableUI>

        <TableFooter
          {...state}
          classes
          isAmountValid={isValid}
          payNowWarning={message}
          hasCustomerSelected={hasCustomerSelected}
          hasGateway={hasGateway(salesOrganization)}
          hasAllowedCurrency={hasAllowedCurrency(currency)}
          getTotalAmount={totalAmount}
          getTotalCredit={getTotalCredit(rows, state.selected)}
          onChangePage={(event, page) => dispatch({ type: 'setPage', page })}
          onChangeRowsPerPage={(event) =>
            dispatch({ type: 'changeRowsPerPage', page: 0, event })
          }
          rowsPerPage={props.pageSize}
          rowCount={get(props, 'count', 0)}
          page={props.page}
          onShowMore={props.onShowMore}
          onShowAll={props.onShowAll}
          salesOrganization={props.salesOrganization}
          currency={props.currency}
          status={status}
          customersSelected={customersSelected}
          resetSelectAll={resetSelectAll}
          totalDocuments={props.count}
        />
      </div>
    </div>
  )
}

export default Table
