import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import NoData from '../../component/no-data'

import { listBanksInformation } from '../../domain/bank'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '../../component/expansion-panel'

import { ContainerBankList } from './styles'

export default () => {
  const { t } = useTranslation()

  const [listBanks, setListBanks] = useState([])

  useEffect(() => {
    listBanksInformation().then((response) => {
      setListBanks(response.data.body)
    })
  }, [])

  return (
    <ContainerBankList>
      {listBanks.length > 0 ? (
        listBanks.map((bank, idx) => (
          <ExpansionPanel key={`${idx}`} expanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel1a-header-122-332`}
              id={`panel1a-header-122-332`}>
              <div className='i-collapsible-top'>
                <div style={{ width: '100%' }}>
                  <span className='i-collapsible-ico' data-flag={bank.country}></span>
                  <span>{bank.name}</span>
                  {bank.message ? <span style={{ marginLeft: 16, color: '#100690' }}>{bank.message}</span> : null}
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <table className='table-dados-bancarios'>
                <thead>
                  <tr>
                    <th>{t('table-bank-information-type')}</th>
                    {bank.currency ? (
                      <th width='1'>{t('table-bank-information-currency')}</th>
                    ) : null}
                    <th>{t('table-bank-information-beneficiary')}</th>
                    {bank.cnpj ? (
                      <th>CNPJ</th>
                    ) : null}
                    {bank.identificador ? (
                      <th>IDENTIFICADOR</th>
                    ) : null}
                    {bank.endOne ? (
                      <th>{t('table-bank-information-beneficiary-address')}</th>
                    ) : null}
                    {bank.bankName ? (
                      <th>{t('table-bank-information-bank')}</th>
                    ) : null}
                    {bank.agency ? (
                      <th>{t('table-bank-information-agency')}</th>
                    ) : null}
                    {bank.account ? (
                      <th width='1'>{t('table-bank-information-account')}</th>
                    ) : null}
                    {bank.aba ? (
                      <th width='1'>{t('table-bank-information-aba')}</th>
                    ) : null}
                    {bank.swift ? (
                      <th width='1'>{t('table-bank-information-swift')}</th>
                    ) : null}
                    {bank.routing ? (
                      <th>DOMESTIC ROUTING</th>
                    ) : null}
                    {bank.iban ? (
                      <th width='1'>{t('table-bank-information-iban')}</th>
                    ) : null}
                    {bank.nib ? (
                      <th width='1'>{t('table-bank-information-nib')}</th>
                    ) : null}
                    {bank.bankAddress ? (
                      <th>{t('table-bank-information-bank-address')}</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{bank.type}</td>
                    {bank.currency ? <td>{bank.currency}</td> : null}
                    <td>{bank.beneficiaryName || '...'}</td>
                    {bank.cnpj ? <td>{bank.cnpj}</td> : null}
                    {bank.identificador ? <td>{bank.identificador}</td> : null}
                    {bank.endOne ? <td>{bank.endOne}</td> : null}
                    {bank.bankName ? <td>{bank.bankName}</td> : null}
                    {bank.agency ? <td>{bank.agency}</td> : null}
                    {bank.account ? <td>{bank.account}</td> : null}
                    {bank.aba ? <td>{bank.aba}</td> : null}
                    {bank.swift ? <td>{bank.swift}</td> : null}
                    {bank.routing ? <td>{bank.routing}</td> : null}
                    {bank.iban ? <td>{bank.iban}</td> : null}
                    {bank.nib ? <td>{bank.nib}</td> : null}
                    {bank.bankAddress ? <td>{bank.bankAddress}</td> : null}
                  </tr>
                </tbody>
              </table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))
      ) : (
        <NoData />
      )}
    </ContainerBankList>
  )
}
