import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import ExpansionPanelFooter from '../../../component/expansion-panel/expansion-panel-footer'
import TableToolbar from './table-toolbar'
import ShowTotalAndPaynow from './show-total-and-paynow'

const getInputSearch = (state) => get(state, 'inputSearchRedux.textFilter', '')
const getCustomerSelected = (state) =>
  get(state, 'customerQuotation.selected', { label: '', value: '' })

const TableFooter = (props) => {
  const inputSearchValueRedux = useSelector(getInputSearch)
  const selectedCustomerSelected = useSelector(getCustomerSelected)

  const [inputSearch, setInputSearch] = useState(inputSearchValueRedux)

  const [customerSelected, setCustomerSelected] = useState(
    selectedCustomerSelected
  )

  useEffect(() => {
    setInputSearch(inputSearchValueRedux)
    setCustomerSelected(selectedCustomerSelected)
  }, [inputSearchValueRedux, selectedCustomerSelected])

  return (
    <Fragment>
      <ExpansionPanelFooter>
        <TableToolbar {...props} />
        {props.payNowWarning ? (
          <div className='paynow-warning'>{props.payNowWarning}</div>
        ) : null}
        <ShowTotalAndPaynow
          {...props}
          inputSearch={inputSearch}
          customer={customerSelected}
        />
      </ExpansionPanelFooter>
    </Fragment>
  )
}

export default TableFooter
