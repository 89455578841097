import React from 'react'
import { TabBox } from '../../component/tab/tab-style'
import PropTypes from 'prop-types'

function TabPanel(props) {
  const { children, index, ...other } = props

  return (
    <div
      component='div'
      role='tabpanel'
      hidden={props.value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      <TabBox p={3}>{children}</TabBox>
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired
}

export default TabPanel
