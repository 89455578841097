import { findAggByGroup } from '../../../domain/account/customer'

const SET_CUSTOMER_GROUP_SELECTED = 'SET_CUSTOMER_GROUP_SELECTED'
const setCustomerGroupSelected = (customerSelectGroup) => ({
  type: SET_CUSTOMER_GROUP_SELECTED,
  customerSelectGroup
})

const GET_CUSTOMER_GROUPS = 'GET_CUSTOMER_GROUPS'
const getCustomerGroups = (customerGroups) => ({
  type: GET_CUSTOMER_GROUPS,
  customerGroups
})

const fetchCustomerGroups = (query) => (dispatch) => {
  // console.log('fetching customer groups')
  return findAggByGroup(query).then((customerGroups) => {
    // console.log('fecthed customer groups:')
    // console.log(customerGroups)
    dispatch(getCustomerGroups(customerGroups.data))
  })
}

const GET_PARAMS_CUSTOMER_GROUPS = 'GET_PARAMS_CUSTOMER_GROUPS'
const getParamsCustomerGroups = (customerGroups) => ({
  type: GET_PARAMS_CUSTOMER_GROUPS,
  customerGroups
})

const fetchParamsCustomerGroups = (query) => (dispatch) =>
  findAggByGroup(query).then((customerGroups) => {
    if (customerGroups.data.length)
      dispatch(getParamsCustomerGroups(customerGroups.data))
  })

const GET_CUSTOMER_GROUPS_NOT_FOUND = 'GET_CUSTOMER_GROUPS_NOT_FOUND'
const getCustomerGroupsNotFound = (customerGroups) => ({
  type: GET_CUSTOMER_GROUPS_NOT_FOUND,
  customerGroups
})

const fetchCustomerGroupsNotFound = (query) => (dispatch) =>
  findAggByGroup(query).then((customerGroups) => {
    if (customerGroups.data.length)
      dispatch(getCustomerGroupsNotFound(customerGroups.data))
  })

export {
  GET_CUSTOMER_GROUPS,
  fetchCustomerGroups,
  GET_PARAMS_CUSTOMER_GROUPS,
  fetchParamsCustomerGroups,
  GET_CUSTOMER_GROUPS_NOT_FOUND,
  fetchCustomerGroupsNotFound,
  SET_CUSTOMER_GROUP_SELECTED,
  setCustomerGroupSelected
}
