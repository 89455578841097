import * as actions from './actions'

const INITIAL_STATE = {
  list: [],
  selected: { label: '', value: '' },
  page: 0
}

export default (state = INITIAL_STATE, action) => {
  if (action.type === actions.SET_CUSTOMER_LIST_INTEREST_FREE)
    return { ...state, list: action.list }
  if (action.type === actions.SET_SELECTED_CUSTOMER_INTEREST_FREE)
    return { ...state, selected: action.selected }
  if (action.type === actions.SET_CUSTOMER_NOT_FOUND_LIST_INTEREST_FREE)
    return { ...state, list: action.list }
  if (action.type === actions.SET_PAGE_INTEREST_FREE)
    return { ...state, page: action.page }
  return state
}
