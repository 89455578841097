import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import validator from 'validator'

import { getDataNewUser, saveUserData } from '../../domain/new-user'

import {
  setErrorFullName,
  setErrorPassword,
  setErrorPasswordConfirm
} from './__redux__/actions'

import { fetchAuth } from '../../container/auth/__redux__/actions'

import HeaderCompanyInformation from '../../component/header-new-user'
import Loading from '../../component/loading'

import CompanyInformation from './company-information'
import UserInformation from './user-information'

import isUserDisabledResponse from '../../helpers/is-user-disabled-response'

import { NewUserContainer } from './style'

export default () => {
  const { t } = useTranslation()

  const history = useHistory()
  const dispatchRedux = useDispatch()
  const params = useParams()
  const fullName = useSelector((state) => state.newUser.fullName)
  const password = useSelector((state) => state.newUser.password)
  const passwordConfirm = useSelector((state) => state.newUser.passwordConfirm)
  const phoneNumber = useSelector((state) => state.newUser.phoneNumber)

  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(true)
  const [userInfo, setUserInfo] = useState({})
  const steps = [
    t('new-user-company-information'),
    t('new-user-user-information')
  ]

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <CompanyInformation userInfo={userInfo} />
      case 1:
        return <UserInformation userInfo={userInfo} />
      default:
        return <CompanyInformation userInfo={userInfo} />
    }
  }

  const handleNext = async () => {
    if (activeStep + 1 < steps.length)
      setActiveStep((prevActiveStep) => prevActiveStep + 1)

    if (activeStep + 1 === 2) {
      if (
        fullName.length < 5 ||
        password.length === 0 ||
        passwordConfirm.length === 0
      ) {
        if (fullName.length < 5) dispatchRedux(setErrorFullName(true))

        if (password.length === 0) dispatchRedux(setErrorPassword(true))

        if (passwordConfirm.length === 0)
          dispatchRedux(setErrorPasswordConfirm(true))
      } else if (password !== passwordConfirm) {
        dispatchRedux(setErrorPassword(true))
        dispatchRedux(setErrorPasswordConfirm(true))
        Swal.fire(
          t('attention') + '!',
          t('new-user-password-not-equal'),
          'warning'
        )
      } else if (password.length < 11 && passwordConfirm.length < 11) {
        dispatchRedux(setErrorPassword(true))
        dispatchRedux(setErrorPasswordConfirm(true))
        Swal.fire(
          t('attention') + '!',
          t('change-password-invalid-character'),
          'warning'
        )
      } else if (
        !validator.isStrongPassword(password, {
          minLength: 12,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1
        }) &&
        !validator.isStrongPassword(passwordConfirm, {
          minLength: 12,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1
        })
      ) {
        dispatchRedux(setErrorPassword(true))
        dispatchRedux(setErrorPasswordConfirm(true))
        Swal.fire(
          t('attention') + '!',
          t('change-password-invalid-character'),
          'warning'
        )
      } else {
        setLoading(true)

        try {
          const saveDataUser = {
            fullName,
            password,
            passwordConfirm,
            phoneNumber
          }

          const {
            data: {
              body: { token, user, pinCode }
            }
          } = await saveUserData(params.hash, saveDataUser)

          if (user && token) {
            sessionStorage.setItem('token', token)

            fetchAuth({ user, token })(dispatchRedux)

            setLoading(false)

            if (pinCode) history.push('/inform-pin-code')
          }
        } catch (error) {
          let response = error.response
          let message = response && response.data.message
          if (message) {
            message = isUserDisabledResponse(message)
              ? t('new-user-user-disabled')
              : message
          } else message = error.message
          Swal.fire(t('attention') + '!', t('new-user-error-saving-data'))
          setLoading(false)
        }
      }
    }
  }

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const handleClickCancel = () => {
    window.location.href = `${process.env.REACT_APP_URL_PORTAL}`
  }

  useEffect(() => {
    getDataNewUser(params.hash)
      .then((response) => {
        setUserInfo(response.data.body.row)
        setLoading(false)
      })
      .catch((error) => {
        let response = error.response
        let message =
          response && isUserDisabledResponse(response.data.message)
            ? t('new-user-user-disabled')
            : t('new-user-token-not-found')
        Swal.fire(t('attention'), message, 'warning').then((action) => {
          if (action.isConfirmed)
            window.location.href =
              window.location.href = `${process.env.REACT_APP_INVOICES_UI}/login`
        })
      })
  }, [])

  if (loading) return <Loading />

  return (
    <NewUserContainer>
      <div className='new-user-center'>
        <HeaderCompanyInformation>
          <h3>{t('new-user-company-registration')}</h3>
          <p>{t('new-user-fill-name')}</p>
          <h6>{t('new-user-fill-name-p-2')}</h6>
        </HeaderCompanyInformation>

        <div className='body-new-user'>
          <div className='header-new-user'>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div className='load-steps'>{getStepContent(activeStep)}</div>
          <div className='footer-new-user'>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                {t('back')}
              </Button>
            </div>
            <div>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleClickCancel}>
                {t('button-cancel-self-registration')}
              </Button>
              <Button variant='contained' color='primary' onClick={handleNext}>
                {activeStep === steps.length - 1 ? t('submit') : t('next')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </NewUserContainer>
  )
}
