import { stringify } from 'query-string'
import axios from 'axios'

const verifyUserTcs = async (query) =>
  await axios.get(
    `${process.env.REACT_APP_TCS_API}/verifyusersystem?${stringify(query)}`
  )

const sendAcceptUser = async (dataAccept) =>
  await axios.post(`${process.env.REACT_APP_TCS_API}/accept`, dataAccept)

export { verifyUserTcs, sendAcceptUser }
