import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import DatePicker from '../../component/pickers'
import { fetchReferenceDate, setReferenceDateFilter } from './__redux__/actions'
import { get } from 'lodash'

const getReferenceDate = (state) =>
  get(state, 'getReferenceDate.referenceDate', [])

export default (props) => {
  const referenceDate = useSelector(getReferenceDate)
  const firstOverduePositionFomatted = moment(
    referenceDate.first_overdue_position
  ).format('YYYY-MM-DD')
  const lastOverduePositionFomatted = moment(
    referenceDate.last_overdue_position
  )
    .add(1, 'days')
    .format('YYYY-MM-DD')
  const [selectedDate, setSelectedDate] = useState(
    moment(lastOverduePositionFomatted)
  )
  const dispatch = useDispatch()

  function handleDateChange(date, selected) {
    setSelectedDate(date)
    dispatch(setReferenceDateFilter(moment(selected).format('YYYY-MM-DD')))

    return props.onSelect(
      {
        name: 'executeAt',
        selected: {
          label: 'Overdue Position',
          value: moment(selected).format('YYYY-MM-DD')
        }
      },
      props.filters
    )
  }

  useEffect(() => {
    dispatch(fetchReferenceDate())
  }, [])

  useEffect(() => {
    setSelectedDate(moment().add(1, 'days').format('YYYY-MM-DD'))
    dispatch(setReferenceDateFilter(moment().format('YYYY-MM-DD')))
  }, [props.updateValues])

  return (
    <DatePicker
      value={selectedDate}
      minDate={firstOverduePositionFomatted}
      maxDate={lastOverduePositionFomatted}
      onSelect={handleDateChange}
    />
  )
}
