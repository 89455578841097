import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import PinInputField from './pincode-field'

import { PinInputContainer } from './styles'

import { validateToPattern } from '../../helpers/validate-pin-code-pattern'

export const PinCodeDefaultProps = {
  type: 'number',
  mask: false,
  showState: true,
  size: 'md',
  autoFocus: false,
  autoTab: true,
  borderColor: 'rgb(204,204,204)',
  errorBorderColor: 'rgb(220,53,69)',
  focusBorderColor: 'rgb(13,110,253)',
  validBorderColor: 'rgb(25,135,84)',
  containerStyle: {},
  inputStyle: {},
  autoComplete: 'off',
  placeholder: 'o',
  'aria-label': 'Please enter pin code'
}

const PinCodeInput = (props) => {
  const completed = useMemo(
    () => props.values.every((val) => val),
    [props.values]
  )

  if (completed && props.onComplete) {
    if (props.validate) {
      if (
        props.values.every((val) =>
          new RegExp(validateToPattern(props.validate)).test(val)
        )
      ) {
        props.onComplete(props.values)
      }
    } else {
      props.onComplete(props.values)
    }
  }

  return (
    <PinInputContainer
      className={props.containerClassName}
      style={props.containerStyle}>
      {props.values.map((value, i) => (
        <PinInputField
          key={props.id ? `${props.id}-${i}` : i}
          index={i}
          value={value}
          completed={completed}
          {...props}
        />
      ))}
    </PinInputContainer>
  )
}

PinCodeInput.displayName = 'PinCodeInput'
PinCodeInput.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: (PropTypes.oneOf < 'number') | ('text' > ['number', 'text']),
  mask: PropTypes.bool,
  validate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.instanceOf(RegExp)
  ]),
  format: PropTypes.func,
  showState: PropTypes.bool,
  size:
    (PropTypes.oneOf < 'xs') | 'sm' | 'md' | ('lg' > ['xs', 'sm', 'md', 'lg']),
  autoFocus: PropTypes.bool,
  autoTab: PropTypes.bool,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  inputClassName: PropTypes.string,
  inputStyle: PropTypes.object,
  onChange: PropTypes.func
}
PinCodeInput.defaultProps = PinCodeDefaultProps

export default PinCodeInput
