import { findAggCustomerDashboard } from '../../../domain/document/'

const GET_CARDS_ANALYTICAL_CUSTOMER = 'GET_CARDS_ANALYTICAL_CUSTOMER'
const getCardsAnalyticalCustomer = (cards) => ({
  type: GET_CARDS_ANALYTICAL_CUSTOMER,
  cards
})

const fetchCards = (query) => (dispatch) =>
  findAggCustomerDashboard(query).then((cards) =>
    dispatch(getCardsAnalyticalCustomer(cards))
  )

export { GET_CARDS_ANALYTICAL_CUSTOMER, fetchCards }
