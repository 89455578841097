import { findAggByRegion } from '../../../domain/document/'

const GET_REGIONS = "GET_REGIONS"
const getRegions = regions => ({
  type: GET_REGIONS,
  regions
})

const fetchRegions = (query) => (dispatch) => findAggByRegion(query)
  .then(regions => dispatch(getRegions(regions)))

export { GET_REGIONS, fetchRegions }