import { findPaymentPerformanceInfo } from '../../../domain/document'

const GET_CUSTOMER_PAYMENT_INFO = 'GET_CUSTOMER_PAYMENT_INFO'

const getCustomerPaymentInfo = (paymentInfo) => ({
  type: GET_CUSTOMER_PAYMENT_INFO,
  paymentInfo
})

const fetchPaymentInfo = (query) => (dispatch) =>
  findPaymentPerformanceInfo(query).then((paymentInfo) =>
    dispatch(getCustomerPaymentInfo(paymentInfo))
  )

export { GET_CUSTOMER_PAYMENT_INFO, fetchPaymentInfo }
