import styled from 'styled-components'

const WrapForm = styled.form`
  margin: 30px;

  .submit-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 520px;
    margin: 5px;
  }

  .submit-button {
    height: 60px;
    width: 100px;
    background: #88d48d;
    font: 'Regular 12px/15px Montserrat';
    letter-spacing: 0;
    color: #ffffff;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    max-width: 530px;
  }

  .MuiInputBase-root .MuiInputBase-input {
    line-height: 15px;
  }
`

export default WrapForm
