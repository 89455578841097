import { list } from '../../../../domain/identity'

const SET_SELECTED_CSI = 'SET_SELECTED_CSI'
const SET_CSI_LIST = 'SET_CSI_LIST'

const setSelected = (selected) => ({
  type: SET_SELECTED_CSI,
  selected
})
const setList = (list) => ({ type: SET_CSI_LIST, list })

const fetchList = (query) => (dispatch) => {
  return list({ ...query, type: 'CSI' }).then((res) =>
    dispatch(setList(res.data))
  )
}

export { SET_SELECTED_CSI, SET_CSI_LIST, setSelected, setList, fetchList }
