import axios from 'axios'

const invoicesApiUrl = process.env.REACT_APP_INVOICES_API
const auth = {
  username: process.env.REACT_APP_BASIC_AUTH_USERNAME_INVOICES_API,
  password: process.env.REACT_APP_BASIC_AUTH_PASSWORD_INVOICES_API
}

export const findHistoryEvents = async ({
  email = null,
  event,
  code = null,
  page,
  orderBy = 'desc'
}) => {
  let params = ''
  if (email) params = _addParam(email, params, 'email')
  if (event) params = _addParam(event, params, 'event')
  if (code) params = _addParam(code, params, 'code')
  if (page) params = _addParam(page, params, 'page')
  if (orderBy) params = _addParam(orderBy, params, 'order')

  const getHistoryEvents = await axios.get(
    `${invoicesApiUrl}/v1/email/find-email-register${params}`,
    { auth }
  )

  return getHistoryEvents
}

const _addParam = (param, params, paramName) => {
  if (params === '') return (params += `?${paramName}=${param}`)
  return (params += `&${paramName}=${param}`)
}

export const downloadEmails = async () =>
  fetch(`${invoicesApiUrl}/v1/email/download-emails`, {
    headers: {
      Authorization: `Basic ${btoa(
        `${process.env.REACT_APP_BASIC_AUTH_USERNAME_INVOICES_API}:${process.env.REACT_APP_BASIC_AUTH_PASSWORD_INVOICES_API}`
      )}`
    }
  })
