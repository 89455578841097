import React, { useReducer } from 'react'
import { Table as TableUI } from '../../component/table'
import TableBody from './body'
import useStyles from './style'
import reducer from './reducer'
import TableHeader from './header'
import TableFooter from './footer'
import { get } from 'lodash'

const initialState = {
  order: 'desc',
  orderBy: 'paidIn',
  page: 0,
  rowsPerPage: 10
}

function Table(props) {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)

  const user = get(props, 'user')
  const hasCustomerSelected = true

  const status = 'OPEN'

  return (
    <div className='i-container'>
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <TableUI
            className={`${classes.table} i-table-invoices`}
            aria-labelledby='tableTitle'>
            <TableHeader
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={(event, property) =>
                dispatch({ type: 'sort', property })
              }
              rowsPerPage={props.pageSize}
              rowCount={get(props, 'count', 0)}
              rowDisplaySize={get(props, 'documents', []).length}
              status={status}
              user={user}
            />

            <TableBody
              {...state}
              rows={props.documents}
              user={user}
              hasCustomerSelected={hasCustomerSelected}
              isClosed={false}
              status={status}
              removeInterestFree={props.removeInterestFree}
            />
          </TableUI>

          <TableFooter
            {...state}
            classes
            hasCustomerSelected={hasCustomerSelected}
            onChangePage={(event, page) => dispatch({ type: 'setPage', page })}
            onChangeRowsPerPage={(event) =>
              dispatch({ type: 'changeRowsPerPage', page: 0, event })
            }
            rowsPerPage={props.pageSize}
            rowCount={get(props, 'count', 0)}
            page={props.page}
            onShowMore={props.onShowMore}
            onShowAll={props.onShowAll}
            totalDocuments={props.count}
          />
        </div>
      </div>
    </div>
  )
}

export default Table
