import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { fetchAuth } from '../../container/auth/__redux__/actions'
import { getAuthenticatedUser } from '../../domain/auth'

import Loading from '../../component/loading'

import { AuthenticatedUi } from './style'

export default () => {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    sessionStorage.removeItem('token')
    getAuthenticatedUser(params.hash)
      .then((response) => {
        const body = response.data.body

        sessionStorage.setItem('token', body.token)
        fetchAuth(body)(dispatch)

        const disputeFilter = localStorage.getItem('@dispute-filter')

        if(disputeFilter) {
          const filter = JSON.parse(disputeFilter)

          if (filter.number && filter.customerDispute)
            return window.location.href = `/app/dispute-information?documentNumber=${filter.number}&customerCode=${filter.customerDispute}`
        }

        if (body.user.profile.indexOf('OLP_CUSTOMER') !== -1)
          return history.push('/app/customer-dashboard')
        
        if(body.user)
          return history.push('/app/dashboard')
      })
      .catch((err) => {
        window.location.href = process.env.REACT_APP_URL_PORTAL
      })
  }, [])

  return (
    <AuthenticatedUi>
      <Loading />
    </AuthenticatedUi>
  )
}
