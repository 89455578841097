import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { AiOutlineCheckCircle, AiOutlineWarning } from 'react-icons/ai'

import { findTotalDocumentsSapWeb } from '../domain/document'

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '../component/expansion-panel'

import Breadcrumbs from '../component/breadcrumbs'

import Loading from '../component/loading'

import { ContainerStatusDocuments } from './styles'

export default () => {
  const { t } = useTranslation()

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)

  const textRenderStatus = (value) => {
    if (value < 0) return t('document-web-sap-result-to-sap')
    else if (value === 0) return t('document-web-sap-result-to-equal')
    else return t('document-web-sap-result-to-web')
  }

  useEffect(() => {
    findTotalDocumentsSapWeb().then((response) => {
      setList(response.data.body)
      setLoading(false)
    })
  }, [])

  return (
    <>
      <div className='i-container'>
        <Breadcrumbs />
      </div>

      <div className='i-container'>
        <ContainerStatusDocuments>
          <ExpansionPanel key={`122-332`} expanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel1a-header-122-332`}
              id={`panel1a-header-122-332`}>
              <div className='i-collapsible-top'>
                <div>
                  <span>{t('document-web-sap-title-open')}</span>
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <table>
                <thead>
                  <tr>
                    <th>UNITS</th>
                    <th>SAP</th>
                    <th>WEB</th>
                    <th>RESULT</th>
                    <th>STATUS</th>
                    <th>LOG</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    list.map((row, idx) => (
                      <tr key={`${row.salesOrganization}-${idx}`}>
                        <td>{row.salesOrganization}</td>
                        <td>{row.ZOPEN}</td>
                        <td>{row.totalOpen}</td>
                        <td>{row.diferencaOpen}</td>
                        <td>
                          {row.diferencaOpen === 0 ? (
                            <AiOutlineCheckCircle size={20} color='#008000' />
                          ) : (
                            <AiOutlineWarning size={20} color='#b71540' />
                          )}
                        </td>
                        <td>{textRenderStatus(row.diferencaOpen)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </ContainerStatusDocuments>
        <ContainerStatusDocuments>
          <ExpansionPanel key={`122-332`} expanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel1a-header-122-332`}
              id={`panel1a-header-122-332`}>
              <div className='i-collapsible-top'>
                <div>
                  <span>{t('document-web-sap-title-closed')}</span>
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <table>
                <thead>
                  <tr>
                    <th>UNITS</th>
                    <th>SAP</th>
                    <th>WEB</th>
                    <th>RESULT</th>
                    <th>STATUS</th>
                    <th>LOG</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    list.map((row, idx) => (
                      <tr key={`${row.salesOrganization}-${idx}`}>
                        <td>{row.salesOrganization}</td>
                        <td>{row.ZCLOSED}</td>
                        <td>{row.totalClosed}</td>
                        <td>{row.diferencaClosed}</td>
                        <td>
                          {row.diferencaClosed === 0 ? (
                            <AiOutlineCheckCircle size={20} color='#008000' />
                          ) : (
                            <AiOutlineWarning size={20} color='#b71540' />
                          )}
                        </td>
                        <td>{textRenderStatus(row.diferencaClosed)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </ContainerStatusDocuments>
        <ContainerStatusDocuments>
          <ExpansionPanel key={`122-332`} expanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel1a-header-122-332`}
              id={`panel1a-header-122-332`}>
              <div className='i-collapsible-top'>
                <div>
                  <span>{t('document-web-sap-title-pdf')}</span>
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <table>
                <thead>
                  <tr>
                    <th>UNITS</th>
                    <th>SAP</th>
                    <th>WEB</th>
                    <th>RESULT</th>
                    <th>STATUS</th>
                    <th>LOG</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    list.map((row, idx) => (
                      <tr key={`${row.salesOrganization}-${idx}`}>
                        <td>{row.salesOrganization}</td>
                        <td>{row.ZPDF}</td>
                        <td>{row.totalPdf}</td>
                        <td>{row.diferencaPdf}</td>
                        <td>
                          {row.diferencaPdf === 0 ? (
                            <AiOutlineCheckCircle size={20} color='#008000' />
                          ) : (
                            <AiOutlineWarning size={20} color='#b71540' />
                          )}
                        </td>
                        <td>{textRenderStatus(row.diferencaPdf)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </ContainerStatusDocuments>
      </div>
    </>
  )
}
