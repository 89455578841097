import styled from 'styled-components'

export const PdfView = styled.section`
  background-color: #ececec;
  display: block;
  width: 100%;
  padding-bottom: 50px;
  padding-top: 40px;
  min-height: 100vh;

  h2 {
    font-size: 24px;
    text-align: center;
    padding: 32px 0px;
  }

  .center-width {
    width: 100%;
    max-width: 50%;
    margin: 0 auto;
  }

  .mgrpdf-navigation {
    padding: 8px;
    font-size: 20px;
  }

  .btns-tcs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;

    .checkbox-accept {
      display: flex;
      padding-right: 16px;
      align-items: center;

      input[type='checkbox'] {
        margin-right: 16px;
        cursor: pointer;
      }

      p {
        flex: 1;
        font-size: 14px;
        line-height: 20px;
      }
    }

    button {
      width: 200px;
      padding: 12px 16px;
      border: none;
      cursor: pointer;
      background-color: #669c0f;
      font-size: 12px;
      color: white;
      border: none;
      border-radius: 5px;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
      margin-right: 16px;
      outline: none;
      transition: background-color 0.3s;

      &:hover {
        background-color: #355600;
      }

      &.disabled {
        background-color: #ccc;
      }
    }
  }

  .list-languages {
    display: block;
    width: 100%;
    margin-bottom: 32px;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        padding: 10px 30px;
        background-color: #b5b5b5;
        margin: 0px 4px;
        border-radius: 5px;
        cursor: pointer;
        color: white;
      }

      li.active {
        background-color: #1655a0;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .loading-pdf-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vh;
    background-color: #525659;

    p {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      width: 80%;
    }
  }

  .columns {
    display: flex;
    .column {
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    .center-width {
      width: 100%;
      max-width: 90%;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 1024.01px) and (max-width: 1320px) {
    .center-width {
      width: 100%;
      max-width: 70%;
      margin: 0 auto;
    }
  }
`
