import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import AmChartsPie from '../amcharts-pie'
import IsEmptyChart from '../amcharts-is-empty'
import config from './config.json'
import { get, isEmpty } from 'lodash'
import { findDisputeStatusInformation } from '../../domain/document'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const getCustomerSelected = (state) => get(state, 'getFilters.selects')

const ChartDisputePerStatus = (props) => {
  const { t } = useTranslation()

  const [dataProvider, setDataProvider] = useState()
  const filters = useSelector(getCustomerSelected)

  const transformDisputeInfo = (disputeInfo) => {
    const {
      underAnalysis,
      creditDenied,
      fullCreditApplied,
      partialCreditApplied
    } = disputeInfo

    return [
      { value: underAnalysis || 0, description: t('dispute-under-analysis') },
      { value: creditDenied || 0, description: t('dispute-credit-denied') },
      {
        value: fullCreditApplied || 0,
        description: t('dispute-full-credit-applied')
      },
      {
        value: partialCreditApplied || 0,
        description: t('dispute-partial-credit-applied')
      }
    ]
  }

  useEffect(() => {
    findDisputeStatusInformation({ selects: filters }).then((dataProvider) => {
      setDataProvider(transformDisputeInfo(dataProvider.data.body))
    })
  }, [filters])

  return !isEmpty(dataProvider) ? (
    <Card>
      <CardContent>
        <Typography
          gutterBottom
          style={{ fontSize: '1.9rem', color: '#666666' }}>
          {props.title}
        </Typography>
        <AmChartsPie dataConfig={config} dataProvider={dataProvider} />
      </CardContent>
    </Card>
  ) : (
    <IsEmptyChart title={props.title} />
  )
}

export default ChartDisputePerStatus
