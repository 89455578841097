import { getToken } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/accounting/file-aging`

const upsertFileAgingReport = async (formData, source) => {
  return await axios.post(`${url}/${source}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`
    }
  })
}

export default upsertFileAgingReport
