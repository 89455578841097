import React, { useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import CachedIcon from '@material-ui/icons/Cached'
import Swal from 'sweetalert2'

import List from '../list-search'
import Button from '../button'
import composeSynchronization from './compose-synchronization'
import { reducer, initialState } from './reducer'

const ListRelationship = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [clean, setClean] = useState(false)

  const handleBtnSync = () => {
    if (state.firstListChecked.length !== 1) {
      Swal.fire('Atention!', 'Selecione um Portal Account.', 'warning')
      return
    }

    if (state.secondListChecked.length === 0) {
      Swal.fire('Atention!', 'Selecione um ou mais Customer.', 'warning')
      return
    }

    const compose = composeSynchronization(state.firstListChecked)(
      state.secondListChecked
    )
    props.onSync(compose)
    props.reloadData(true)
    setClean(true)
  }

  return (
    <div id='list-relationship-id' className={props.className}>
      <Grid container direction='row' spacing={3}>
        <List
          id='list-left-id'
          title={props.firstTitle}
          subTitle={props.firstSubTitle}
          values={props.firstList}
          searchPlaceholder={props.firstListPlaceholder}
          clean={clean}
          findOr={props.firstFindOr}
          onChecked={(checkeds) =>
            dispatch({ type: 'firstListUpdate', checkeds })
          }
          multipleSelections={props.firstListMultipleSelections}
        />

        <List
          id='list-right-id'
          title={props.secondTitle}
          subTitle={props.secondSubTitle}
          values={props.secondList}
          searchPlaceholder={props.secondListPlaceholder}
          clean={clean}
          findOr={props.secondFindOr}
          onChecked={(checkeds) =>
            dispatch({ type: 'secondListUpdate', checkeds })
          }
          multipleSelections={props.secondListMultipleSelections}
        />
      </Grid>

      <div className='container-button-relationship'>
        <Button
          onClick={handleBtnSync}
          variant='contained'
          color='secondary'
          size='large'>
          <CachedIcon
            style={{
              fontSize: '2.6rem',
              display: 'inline-block',
              paddingRight: '5px'
            }}
          />
          SINCHRONIZE
        </Button>
      </div>
    </div>
  )
}

ListRelationship.propTypes = {
  firstTitle: PropTypes.string,
  firstSubTitle: PropTypes.string,
  firstListMultipleSelections: PropTypes.bool,
  firstList: PropTypes.array.isRequired,
  firstListPlaceholder: PropTypes.string,
  secondTitle: PropTypes.string,
  secondSubTitle: PropTypes.string,
  secondListMultipleSelections: PropTypes.bool,
  secondList: PropTypes.array.isRequired,
  secondListPlaceholder: PropTypes.string,
  onSync: PropTypes.func.isRequired
}

export default ListRelationship
