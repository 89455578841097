import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import SideList from '../drawer/side-list'

import { MenuUi } from './style'
import { setStatusMenu } from '../header/__redux__/actions'

const getShowMenuView = (state) => get(state, 'headerReducer.showMenuView')

export default ({ versionProject }) => {
  const showMenuView = useSelector(getShowMenuView)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    function checkWindowSize() {
      if (window.innerWidth <= 1281 && showMenuView) {
        dispatch(setStatusMenu(false))
      }
    }
    window.addEventListener('resize', checkWindowSize)
    return () => window.removeEventListener('resize', checkWindowSize)
  }, [])

  return (
    <MenuUi showMenu={showMenuView}>
      <SideList />
      <p>{t('menu-version')}: {versionProject}</p>
    </MenuUi>
  )
}
