import styled from 'styled-components'

export const FormLoginContainer = styled.form`
  display: block;
  width: 100%;
  padding: 20px;

  h2 {
    color: #3f3f3c;
    font-size: 18px;
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    align-items: center;

    a {
      margin-right: 8px;
      color: #3f3f3c;
    }
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: #6e6e6e;
    margin-bottom: 16px;
  }

  .link-logins {
    a {
      font-size: 14px;
      color: #003a70;

      &:hover {
        text-decoration: underline;
        color: #005baf;
      }
    }
  }

  .text-center {
    text-align: center;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }

  .MuiInputBase-input,
  .MuiInputLabel-formControl {
    font-size: 16px;
  }

  .MuiButton-containedPrimary {
    padding: 12px;
    background-color: #005baf;
    &:hover {
      background-color: #004b90;
    }
  }
`
