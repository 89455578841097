import { pipe, set } from 'lodash/fp'
import { get } from 'lodash'
import { getBearerAuthorization } from '../auth'
import { stringify } from 'query-string'
import axios from "axios"

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/documents`

const _composeFilter = ({ selects=[] }) => selects
    ? selects.map(filter => pipe(set(get(filter, 'name'))(get(filter, 'selected.value')))({}))
    : {}

export default (query = {}) => {
  query = Object.assign({}, ..._composeFilter(query))

  return axios
    .get(`${url}/analytical-aggregate?${stringify(query)}`, {
      headers: getBearerAuthorization()
    })
}