import { GET_SITES } from './actions'

const INITIAL_STATE = {
  sites: []
}

export const getSites = (state = INITIAL_STATE, action) => {
  if (action.type === GET_SITES) {
    return { sites: [...action.sites.data.body] }
  }
  return state
}