import TableMui from '@material-ui/core/Table'
import styled, { css } from 'styled-components'

const Table = styled(TableMui)`
  
  .icon-actions {
    font-size: 2rem;
    color: #969696;
  }

  ${props =>
    props.report &&
    css`
      padding-top: 20px;
      border-spacing: 0 10px;
      border-collapse: inherit;

      thead tr {
        th {
          border: 0 transparent !important;
        }
      }
      tbody tr {
        background-color: #fff;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
        td {
          border: 0 transparent !important;
        }
      }

      @media (max-width: 1100px) {
        flex-direction: column;
      }

      @media (max-width: 1131px) {
        thead {
          border-left: none;
          display: table-header-group;

          tr {
            padding-bottom: 0;
            border-left: none;
            box-shadow: 0 0 0;  

            th {
              width: calc(100% / 5);
  
              &:last-child {
                width: calc(100% / 5);
                background-color: #fafafa;
                height: auto;
                padding-right: 23px;
                position: relative;
              }
            }
          }
        }

        tbody tr {
          padding-bottom: 0;
          border-left: none;
          align-items: center;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

          td{
            width: calc(100% / 5);
  
            &:last-child {
              width: calc(100% / 5);
              height: auto;
              position: relative;
            }
          } 
        }
      }
    `}

  ${props => props.className === 'simple-table' && css `
    .MuiTableBody-root {
      border-right: none;

      .MuiTableCell-body:first-child {
        border-left: none;
      }
    }
  `}

  @media (max-width: 1100px) {
    min-width: 100%;
    display: flex;
    flex-grow: 1;
  }
`

export default Table
