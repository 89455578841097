import { GET_REFERENCE_DATE, SET_DATE_FILTER } from './actions'

const INITIAL_STATE = {
  referenceDate: [],
  dateFilter: ''
}

export const getReferenceDate = (state = INITIAL_STATE, action) => {
  if (action.type === GET_REFERENCE_DATE) {
    return { ...state, referenceDate: action.referenceDate.data.body }
  }

  if (action.type === SET_DATE_FILTER) {
    return { ...state, dateFilter: action.dateFilter }
  }

  return state
}
