import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    'overflow-x': 'scroll'
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    maxHeight: '450px'
  }
}))
