import numeral from "numeral"
import moment from "moment"

const verifyDateUpdatedAt = card => {
  return card.value !== undefined
    ? moment(card.value).format("MMM/DD/YY hh:mm a")
    : "---"
}

const formatCardValue = card =>
  card.type === "number"
    ? `${numeral(card.value).format("0.00a")} USD`
    : card.type === "date"
    ? verifyDateUpdatedAt(card)
    : ""

export default formatCardValue
