import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import CheckboxComponent from '../../component/checkbox'

import { CustomerGroupCheckboxStyled } from './styles'
const getCheckbox = (state) => get(state, 'invoicesCheckbox.checked')
const CustomerGroupCheckbox = (props) => {
  const checked = useSelector(getCheckbox)
  return (
    <CustomerGroupCheckboxStyled>
      <CheckboxComponent
        checked={checked}
        disabled={props.disabled}
        onChange={props.onChange}
      />
      {props.label}
    </CustomerGroupCheckboxStyled>
  )
}

export default CustomerGroupCheckbox
