import React, { Fragment } from 'react'
import ExpansionPanelFooter from '../../../component/expansion-panel/expansion-panel-footer'
import TableToolbar from './table-toolbar'

const TableFooter = (props) => {
  return (
    <Fragment>
      <ExpansionPanelFooter>
        <TableToolbar {...props} />
      </ExpansionPanelFooter>
    </Fragment>
  )
}

export default TableFooter
