import React from 'react'
import TableCell from './table-cell'

export default (props) => {
  const actionRow = <TableCell key={`${props.actionLabel}`} align="center">{props.actionLabel}</TableCell>
  const columns = props.columns.map((column, idx) => (
    <TableCell key={`${idx}`} align="center">{column.title}</TableCell>
  ))
  if (props.action) return [...columns, actionRow]
  return columns
}