import React, { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import Table from '../container/table-interest-free'
import Breadcrumbs from '../component/breadcrumbs'
import DocumentFilter from '../container/document-fixed-filter-interest-free'
import { setInterest } from '../domain/document/index'
import {
  fetchPaginationDocuments,
  setLoadShowMore
} from '../container/document-list-interest-free/__redux__/actions'
import Swal from 'sweetalert2'

const getDocuments = (state) => get(state, 'documentsInterestFree.list')
const getCustomers = (state) => get(state, 'customersInterestFree.list')
const getAuth = (state) => get(state, 'auth.user')

const Invoices = () => {
  const { t } = useTranslation()
  const [shownDocuments, setShownDocuments] = useState([])
  const [open, setOpen] = useState(false)
  const documents = useSelector(getDocuments)
  const customersSelected = useSelector(getCustomers)
  const dispatch = useDispatch()
  const status = 'OPEN'
  const user = useSelector(getAuth)

  const handleOpen = (id) => {
    if (!open) {
      Swal.queue([
        {
          title: t('interest-free-modal-confirmation'),
          confirmButtonText: t('interest-free-confirm-button'),
          cancelButtonText: t('interest-free-cancel-button'),
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            try {
              const res = await setInterest(id, false)
              return Swal.insertQueueStep(t(res.message))
            } catch (error) {
              Swal.insertQueueStep({ icon: 'error', title: t(error.message) })
            }
          }
        }
      ]).then(() => {
        setOpen(false)
        setShownDocuments((prevDocuments) => ({
          ...prevDocuments,
          documents: prevDocuments.documents.filter((doc) => doc.id !== id)
        }))
      })
    }
  }

  useEffect(() => {
    setShownDocuments(documents)
  }, [documents])

  return (
    <Fragment>
      <div>
        <div className='i-container'>
          <Breadcrumbs title={t('breadcrumbs-title-interest-free-invoices')} />
        </div>

        <DocumentFilter />
        <Table
          {...shownDocuments}
          customersSelected={customersSelected}
          status={status}
          onShowMore={(page) => {
            dispatch(
              fetchPaginationDocuments({
                status,
                page
              })
            )
            dispatch(setLoadShowMore(true))
          }}
          removeInterestFree={handleOpen}
          user={user}
        />
      </div>
    </Fragment>
  )
}

export default Invoices
