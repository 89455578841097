import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DocumentList from '../disputes-information-list'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '../../component/expansion-panel'
import { fetchDisputesInformation } from '../disputes-information-list/__redux__/actions'
import { get, first } from 'lodash'

import NoData from '../../component/no-data'

const getCompaniesRedux = (state) =>
  get(state, 'getDisputeCompanies.companies', [])
const getDocuments = (state) =>
  get(state, 'getDisputesInformation.documents', new Map())

export default (props) => {
  const reduxCompanies = useSelector(getCompaniesRedux)
  const documents = useSelector(getDocuments)
  const dispatch = useDispatch()

  const [rowCompanies, setRowCompanies] = useState([])

  const fetchDocs = (company) =>
    fetchDisputesInformation({
      salesOrganization: company.salesOrganization,
      currency: company.currency,
      selects: props.selects
    })

  const fetchIfExpanded = (company, expanded) => {
    const docs = documents.get(
      `${company.salesOrganization}-${company.currency}`
    )
    const docsList = get(docs, 'documents', [])

    if (expanded && docsList.length < 1) {
      dispatch(fetchDocs(company))
    }
  }

  const hasOnlyOneCompany = (companies) => {
    if (companies.length === 1) {
      dispatch(fetchDocs(first(companies)))
      return true
    }
  }

  const reloadDoc = (company) => {
    const { salesOrganization, currency } = company
    if (documents.size > 0) {
      if (documents.has(`${salesOrganization}-${currency}`)) {
        dispatch(fetchDocs(company))
      }
    }
  }

  useEffect(() => {
    setRowCompanies(reduxCompanies)
  }, [reduxCompanies])

  return rowCompanies.length ? (
    <Fragment>
      {reduxCompanies.map((company, index) => (
        <ExpansionPanel
          key={`${company.id}`}
          expanded={hasOnlyOneCompany(reduxCompanies)}
          onChange={(event, newExpanded) => {
            fetchIfExpanded(company, newExpanded)
          }}>
          {reloadDoc(company)}
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel1a-header-${company.id}`}
            id={`panel1a-header-${company.id}`}>
            <div className='i-collapsible-top'>
              <div>
                <span
                  className='i-collapsible-ico'
                  data-flag={company.country}></span>
                <span>{company.name}</span>
              </div>

              <div className='i-collapsible-currency-area'>
                <span className='i-collapsible-label'>{company.currency}</span>
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <DocumentList
              salesOrganization={company.salesOrganization}
              currency={company.currency}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Fragment>
  ) : (
    <NoData />
  )
}
