import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

export const TextFieldUi = styled(TextField)`
  width: 100%;

  label {
    font-size: 16px;
  }

  fieldset {
    font-size: 16px;
  }

  input {
    font-size: 16px;
    padding: 12px 0px 11px 0px;
  }
`
