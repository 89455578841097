import React from 'react'
import { TextField } from '../../component/textField/textFieldStyle'

const InputSearch = (props) => {
  return (
    <TextField
      margin='dense'
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value}
      {...props.config}
    />
  )
}

export default InputSearch
