import { stringify } from 'query-string'
import { getBearerAuthorization } from '../../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/portal-accounts`

const findAll = (origin = '', criteria = '') => {
  const portalOrigin = origin ? `&origin=${origin}` : ''

  return axios.get(`${url}?criteria=${criteria}${portalOrigin}`, {
    headers: getBearerAuthorization()
  })
}

const findSynced = (accountType, origin = '', criteria = '', page = 1) =>
  axios.get(
    `${url}/synced?${stringify({ accountType, criteria, page, origin })}`,
    {
      headers: getBearerAuthorization()
    }
  )

export { findAll, findSynced }
