import React from 'react'
import AccountCircle from '@material-ui/icons/AccountCircle'
import formatterName from './formatterName'
import { get } from 'lodash'

import HelpIcon from '../../assets/images/help_outline_SVG.svg'

export default (props) => {
  return (
    <div className='i-profile'>
      <div style={{ position: 'absolute', left: '-40px', top: '-2px' }}>
        <a target='_blank' href='/tutorial-presentation.pdf'>
          <img
            src={HelpIcon}
            alt='Tutorial'
            style={{ width: 32, height: 32 }}
          />
        </a>
      </div>
      <div>
        <AccountCircle className='i-profile-ico' />
        <div className='profile-label-info'>
          <p className='i-profile-label'>
            {formatterName(get(props, 'user.fullName'))}
          </p>
          <span>{get(props, 'user.email')}</span>
        </div>
      </div>
    </div>
  )
}
