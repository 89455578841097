import styled from 'styled-components'

export const MenuUi = styled.div`
  width: 250px;
  background: rgb(16, 6, 144);
  background: linear-gradient(
    215deg,
    rgba(16, 6, 144, 1) 0%,
    rgba(0, 137, 137, 1) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  min-height: 100vh;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);

  @media (min-width: 0px) and (max-width: 1064px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
  }

  p {
    color: white;
    padding: 16px;
  }
  
  .options-menu {
    ul {
      li {
        .active {
          background-color: rgba(0, 0, 0, 0.16);

          span {
            color: #fff;
          }

          i {
            color: #fff;
          }
        }

        a {
          margin: 0px;
          display: flex;
          padding: 16px;

          span {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.4);
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.16);

            span {
              color: #fff;
            }

            i {
              color: #fff;
            }
          }

          i {
            color: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }
`
