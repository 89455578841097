import { listDisputeCompanies } from '../../../domain/account/company'
import { changeLoading } from '../../document-filter/__redux__/actions'

const GET_DISPUTE_COMPANIES = 'GET_DISPUTE_COMPANIES'

const getDisputeCompanies = (companies) => ({
  type: GET_DISPUTE_COMPANIES,
  companies
})

const fetchDisputeCompanies = (query) => (dispatch) => {
  return listDisputeCompanies(query).then((companies) => {
    if (companies.data.error) {
      dispatch(changeLoading(false))
      dispatch(getDisputeCompanies([]))
    } else {
      dispatch(changeLoading(false))
      dispatch(getDisputeCompanies(companies.data.body.rows))
    }
  })
}

export { GET_DISPUTE_COMPANIES, fetchDisputeCompanies, getDisputeCompanies }
