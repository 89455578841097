import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import {
  applySelectFilter,
  resetSelectsSearch,
  changeCriteriaFilter,
  changeSelectFilter,
  changeLoading
} from '../quotation-filter/__redux__/actions'
import { fetchCompaniesQuotations } from '../company-quotations/__redux__/actions'
import { changeInputSearch } from '../input-search-invoices/__redux__/actions'
import { actions as selectActions } from '../quotations-selects/__redux__'

import Filter from '../fixed-filter-invoices'
import FilterSelect from '../quotations-selects'

import { isEmployee } from '../../domain/security'
import {
  onResetField,
  onSelect,
  resetSelects,
  setSelects
} from './__redux__/quotations-filter/actions'

import {
  getSelectsOnLocalstorage,
  mountSelectsToRedux
} from '../../helpers/get-selects-on-localstorage'
import { applyChangeCustomerTypeFilter } from '../customer-type/__redux__/actions'

export default ({ setInputSearch }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { status, criteria, loading } = useSelector((state) =>
    get(state, 'getFiltersQuotations')
  )

  const filtersAnalytical = useSelector((state) =>
    get(state, 'getFiltersAnalytical.selects', [])
  )

  const user = useSelector((state) => get(state, 'auth.user'))

  const filters = useSelector((state) => get(state, 'quotationsFilters'))
  const checkboxChecked = useSelector((state) =>
    get(state, 'invoicesCheckbox.checked')
  )

  const clearLocalStorage = () => {
    localStorage.clear()
    localStorage.removeItem('@inputSearchQuotation')
    localStorage.removeItem('@customerQuotation')
  }

  const verifyStorage = () => {
    const selects = getSelectsOnLocalstorage()
    const { inputSearchQuotation, customerQuotation } = selects

    const selectsStorage = mountSelectsToRedux(selects)

    return {
      selectsStorage,
      localStorage: {
        inputSearchQuotation,
        customerQuotation
      }
    }
  }

  const loadingDataApi = (selectsStorage, criteriaStorage) => {
    clearLocalStorage()

    dispatch(changeCriteriaFilter(criteriaStorage))
    dispatch(changeSelectFilter(selectsStorage))
    ;['customerQuotation'].forEach((select) => {
      dispatch(selectActions[select].setSelected({ value: '', label: '' }))
    })

    selectsStorage.forEach((select) => {
      dispatch(selectActions[select.name].setSelected(select.selected))
    })

    dispatch(setSelects(selectsStorage))
  }

  const clearFilterHandler = () => {
    clearFilterInvoices()
  }

  const applyFilterHandler = () => {
    if (!loading) {
      dispatch(changeLoading(true))
      dispatch(
        fetchCompaniesQuotations({
          selects: filters.selected,
          status,
          criteria
        })
      )
    }
  }

  const clearFilterInvoices = () => {
    dispatch({ type: 'unCheckCheckbox' })
    dispatch(resetSelects([]))
    ;['customerQuotation'].forEach((select) => {
      dispatch(selectActions[select].setSelected({ value: '', label: '' }))
    })
    dispatch(changeCriteriaFilter(''))
    dispatch(resetSelectsSearch())
    dispatch(changeInputSearch(''))
    setInputSearch('')
    dispatch(applyChangeCustomerTypeFilter({ label: 'All', value: 'ALL' }))
  }

  const handleSelect = useCallback(
    (selected) => {
      let newSelects = filtersAnalytical
      newSelects = newSelects.filter((sel) => sel.name !== selected.name)
      if (selected.selected) newSelects.push(selected)
      else newSelects = newSelects.filter((sel) => sel.name !== selected.name)
      if (selected.selected) dispatch(onSelect(selected))
      else dispatch(onResetField(selected))
    },
    [filtersAnalytical]
  )

  const syncSelectsUpdate = (selected) => {
    let params = {}
    let fetchParams
    selected.forEach((s) => {
      if (['customerQuotation'].includes(s.name))
        params['customer'] = s.selected.value
    })

    if (checkboxChecked) {
      fetchParams = { ...params, customer: null }
      delete fetchParams.customer
    } else {
      fetchParams = { ...params, customerGroup: null }
      delete fetchParams.customerGroup
    }
    let { customerGroup, ...customerParams } = fetchParams

    if (!params.customer && !checkboxChecked)
      dispatch(selectActions.customerQuotation.fetchList(customerParams))
  }

  const onChangeFilterApply = (selects) => {
    dispatch(applySelectFilter(selects))
  }

  const loadInitialData = () => {
    clearFilterInvoices()
  }

  const startDataLoad = useCallback(() => {
    let { localStorage, selectsStorage } = verifyStorage()

    let verifyCriteria = localStorage.inputSearchQuotation || criteria

    selectsStorage.length || verifyCriteria
      ? loadingDataApi(selectsStorage, verifyCriteria)
      : loadInitialData()
  }, [])

  useEffect(() => {
    onChangeFilterApply(filters.selected)
    syncSelectsUpdate(filters.selected)
    if (!loading) {
      dispatch(changeLoading(true))
      dispatch(
        fetchCompaniesQuotations({
          selects: filters.selected,
          status,
          criteria
        })
      )
    }
  }, [filters.selected])

  useEffect(() => {
    dispatch(changeLoading(false))
    startDataLoad()
  }, [])

  const filterMemo = useMemo(() => {
    return isEmployee(user) ? (
      <Filter
        buttonName='btn-apply'
        menuFilter='document-filter'
        filterFooter='drop-filter-footer'
        clearInput={true}
        searchInput={true}
        interceptSelectedValue={false}
        onChangeFilter={(selects) => onChangeFilterApply(selects)}
        onClearClick={clearFilterHandler}
        clearTitle={t('clear')}
        applyTitle={t('apply')}
        gridTemplateColumns='20% 20% 20% 20% 20%'
        buttonTitleCustomerFilter={t('filter')}
        onSelect={handleSelect}
        applyFilter={applyFilterHandler}>
        <FilterSelect
          name='customerQuotation'
          labelFromItem='name'
          valueFromItem='code'
          translationLabel='customer-list-label'
          translationPlaceholder='customer-list-placeholder'
          useAttributesAsValue={['code']}
          showAttributesAsLabel={['code', 'name']}
          fetchNotFound={true}
          triggerOnHide
        />
      </Filter>
    ) : (
      <Filter
        buttonName='btn-apply'
        menuFilter='document-filter'
        filterFooter='drop-filter-footer'
        clearInput={true}
        searchInput={true}
        interceptSelectedValue={false}
        onChangeFilter={(selects) => onChangeFilterApply(selects)}
        onClearClick={clearFilterHandler}
        clearTitle={t('clear')}
        applyTitle={t('apply')}
        buttonTitleCustomerFilter={t('filter')}
        isAdmin={isEmployee(user)}
        onSelect={handleSelect}
        applyFilter={applyFilterHandler}>
        <FilterSelect
          name='customerQuotation'
          labelFromItem='name'
          valueFromItem='code'
          translationLabel='customer-list-label'
          translationPlaceholder='customer-list-placeholder'
          useAttributesAsValue={['code']}
          showAttributesAsLabel={['code', 'name']}
          fetchNotFound={true}
          triggerOnHide
        />
      </Filter>
    )
  }, [])
  return filterMemo
}
