import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import renderCards from './render-cards'
import { fetchCards } from './__redux__/actions'
import { get } from 'lodash'

const getCards = (state) => get(state, 'getCardsAnalytical.cards', [])  

export default (props) => {
  const cards = useSelector(getCards)
  const [state, setState] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCards())

    setState(true)
  }, [state])

  return (
    <div className="i-ar-cards">
      {renderCards(cards)}
    </div>
  )
}