import React, { useState, useEffect } from 'react'
import { unionBy, remove, uniqBy, isEqual } from 'lodash'
import { get, first, find, pipe, set } from 'lodash/fp'
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core'
import Swal from 'sweetalert2'

import { findAll, findSynced } from '../../domain/account/portal'
import { findUnsynced, sync, unsync } from '../../domain/account/customer'
import ListRelationship from '../../component/list-relationship'
import ListExpansionPanel from '../list-expansion-panel'
import PortalAccountSAPSyncUI from './style'

const _compose = (portalAccounts, portalAccountsSynced) => {
  const portalAccount = first(portalAccounts)
  const portalAccountSynced = find(
    { id: get('id', portalAccount) },
    portalAccountsSynced
  )
  const account = pipe(
    set('customers')(
      unionBy(
        get('customers', portalAccountSynced),
        get('customers', portalAccount),
        'id'
      )
    )
  )(portalAccount)

  return unionBy([account], portalAccountsSynced, 'id')
}

const _composeAfterRemove =
  (portalAccountsSynced) =>
  ({ id, customers }) => {
    const portalAccountSynced = find({ id }, portalAccountsSynced)
    remove(portalAccountSynced.customers, ({ id }) =>
      isEqual(id, get('0.id', customers))
    )
    return portalAccountSynced
  }

const PortalAccountSAPSync = () => {
  const [portalAccountsSynced, setPortalAccountsSynced] = useState([])
  const [portalAccounts, setPortalAccounts] = useState([])
  const [sapAccounts, setSapAccounts] = useState([])
  const [reloadData, setReloadData] = useState(false)
  const [selectedPortal, setSelectedPortal] = useState('MYTECHCARE')

  const handleChangePortal = (event) => {
    setPortalAccounts([])
    setSapAccounts([])
    setPortalAccountsSynced([])
    setSelectedPortal(event.target.value)
    setReloadData(true)
  }

  const loadDataInitial = () => {
    findAll(selectedPortal).then((response) => {
      setPortalAccounts(response.data)
    })

    findUnsynced(selectedPortal).then((response) => {
      setSapAccounts(response.data)
    })

    findSynced('customer', selectedPortal).then((response) => {
      setPortalAccountsSynced(uniqBy(get('data.rows', response), 'id'))
    })

    setReloadData(false)
  }

  const handleFirstFindOr = (criteria) => {
    findAll(selectedPortal, criteria).then((response) => {
      if (response.data.length > 0) setPortalAccounts(response.data)
    })
  }

  const handleSecondFindOr = (criteria) => {
    findUnsynced(selectedPortal, criteria).then((response) => {
      if (response.data.length > 0) setSapAccounts(response.data)
    })
  }

  const handleOnSync = async (accounts) => {
    const syncData = await sync(first(accounts))
    setPortalAccountsSynced(_compose(accounts, portalAccountsSynced))
    if (syncData.status === 200)
      Swal.fire('Success!', 'Sincronismo realizado com sucesso!', 'success')
  }

  const handleFindSyncedCustomer = (criteria) => {
    findSynced('customer', selectedPortal, criteria).then((response) => {
      setPortalAccountsSynced(uniqBy(get('data.rows', response), 'id'))
    })
  }

  const handleUnSync = async (selected) => {
    const handleUnsync = await unsync(
      get('id', selected),
      get('customers.0.id', selected),
      selectedPortal
    )
    setPortalAccountsSynced(
      unionBy(
        portalAccountsSynced,
        _composeAfterRemove(portalAccountsSynced)(selected),
        'id'
      )
    )
    if (handleUnsync.status === 200)
      Swal.fire('Success!', 'Sincronismo foi removido.', 'success')
  }

  const getTitle = () =>
    `${selectedPortal === 'MYTECHCARE' ? 'MyTechcare' : 'FlyEmbraer'} Company`

  useEffect(() => {
    loadDataInitial()
  }, [])

  useEffect(() => {
    loadDataInitial()
  }, [reloadData])

  return (
    <PortalAccountSAPSyncUI>
      <p
        style={{
          padding: '20px 0'
        }}>
        Select a Portal company (MyTechcare or FlyEmbraer) and an SAP, and then
        click on the "Synchronize"
      </p>

      <Grid container direction='column' className='portal-origin'>
        <FormLabel component='h3' className='portal-origin-title'>
          Portal Origin
        </FormLabel>
        <RadioGroup
          aria-label='origin'
          name='portal-origin'
          value={selectedPortal}
          onChange={handleChangePortal}>
          <Grid container direction='row'>
            <Grid xs={12} sm={2}>
              <FormControlLabel
                value='MYTECHCARE'
                className='portal-origin-label'
                control={<Radio />}
                label='MyTechcare'
              />
            </Grid>
            <Grid xs={12} sm={2}>
              <FormControlLabel
                value='FLYEMBRAER'
                className='portal-origin-label'
                control={<Radio />}
                label='FlyEmbraer'
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>

      <ListRelationship
        firstList={portalAccounts}
        firstTitle={getTitle()}
        firstSubTitle='(Showing 10 entries)'
        firstFindOr={handleFirstFindOr}
        firstListPlaceholder='Type Company name'
        firstListMultipleSelections={false}
        secondList={sapAccounts}
        secondTitle='SAP Company'
        secondSubTitle='(Showing 10 entries)'
        secondFindOr={handleSecondFindOr}
        secondListPlaceholder='Type Company code or name'
        secondListMultipleSelections={true}
        onSync={handleOnSync}
        reloadData={setReloadData}
      />

      <ListExpansionPanel
        className='list-expansion-panel-admin'
        title='Company'
        subTitle='(Show 5 entries)'
        panelTitleField='name'
        panelType='table'
        tableDataField='customers'
        tableAction={true}
        tableActionLabel='Actions'
        tableActionIcon='delete_outline'
        tableActionEvent={handleUnSync}
        searchPlaceholder='Search Company'
        onFilter={handleFindSyncedCustomer}
        selectedPortal={selectedPortal}
        tableColumns={[
          { title: 'MFIR', field: 'id' },
          { title: '', field: 'name' }
        ]}
        values={portalAccountsSynced}
      />
    </PortalAccountSAPSyncUI>
  )
}

export default PortalAccountSAPSync
