import styled from 'styled-components'
import ListRelationship from './list-relationship'

export default styled(ListRelationship)`
  .container-button-relationship {
    text-align: right;
    padding: 20px 50px 20px 0px;
  }

  /* text-align: center;
  width: 100%;
 
  #lists-relationship-id {
    display: inline-flex;
    width: 100%;
  }

  #list-left-id {
    margin: 0px 50px 0px 50px;
  }

  #list-right-id {
    margin: 0px 50px 0px 50px;
  }

  #container-button-relationship-id {
    text-align: right;
    padding: 20px 50px 0px 0px;
  }

  #button-relationship-id {
    font: Bold Roboto;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    background: #009898 0% 0% no-repeat padding-box;
    padding: 10px 15px 10px 10px;

    i {
      padding-right: 10px;
    }
    
  } */
`