import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  changeLoading,
  changeSelectFilter,
  applySelectFilter,
  resetSelectsSearch,
  changeCriteriaFilter
} from '../document-filter/__redux__/actions'
import {
  getSelectsOnLocalstorage,
  mountSelectsToRedux
} from '../../helpers/get-selects-for-dispute-on-localstorage'
import { actions as selectActions } from '../dispute-selects/__redux__'
import {
  setSelects,
  onSelect,
  onResetField,
  resetSelects
} from '../document-fixed-filter/__redux__/invoices-filter/actions'
import { fetchDisputeCompanies } from '../disputes-information/__redux__/actions'
import Filter from '../fixed-filter-invoices'
import FilterSelect from '../dispute-selects'
import DisputeFilter from '../dispute-filter'
import { applyChangeFinancialStatusFilter } from '../dispute-filter/__redux__/actions'
import DisputeStartAndEndDatesPicker from '../dispute-start-and-end-dates-picker'

const getLoading = (state) => get(state, 'getFilters.loading')
const getFilter = (state) => get(state, 'invoicesFilters')
const getFiltersAnalytical = (state) =>
  get(state, 'getFiltersAnalytical.selects', [])
const getCriteria = (state) => get(state, 'getFilters.criteria')

export default () => {
  const { t } = useTranslation()
  const loading = useSelector(getLoading)
  const filters = useSelector(getFilter)
  const filtersAnalytical = useSelector(getFiltersAnalytical)
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const criteria = useSelector(getCriteria)
  const dispatch = useDispatch()

  const useUpdateEffect = (effect, deps) => {
    const isFirstMount = useRef(true)
    useEffect(() => {
      if (!isFirstMount.current) effect()
      else isFirstMount.current = false
    }, deps)
  }

  function clearLocalStorage() {
    localStorage.removeItem('@customerDispute')
    localStorage.removeItem('@poNumber')
    localStorage.removeItem('@number')
    localStorage.removeItem('@status')
    localStorage.removeItem('@caseNumber')
    localStorage.removeItem('@financialStatus')
  }

  function verifyStorage() {
    const selects = getSelectsOnLocalstorage()
    const { customerDispute, poNumber, number, status, caseNumber } = selects

    const selectsStorage = mountSelectsToRedux(selects)

    return {
      selectsStorage,
      localStorage: {
        customerDispute,
        poNumber,
        number,
        status,
        caseNumber
      }
    }
  }

  function loadingDataApi(selectsStorage, criteriaStorage) {
    clearLocalStorage()

    dispatch(changeSelectFilter(selectsStorage))
    dispatch(changeCriteriaFilter(criteriaStorage))
    ;['customerDispute', 'poNumber', 'number', 'status', 'caseNumber'].forEach(
      (select) => {
        if (select.name === 'financialStatus') {
          dispatch(applyChangeFinancialStatusFilter(select.selected))
        } else {
          dispatch(selectActions[select].setSelected({ value: '', label: '' }))
        }
      }
    )

    selectsStorage.forEach((select) =>
      dispatch(selectActions[select.name].setSelected(select.selected))
    )

    dispatch(setSelects(selectsStorage))
  }

  function loadInitialData() {
    clearFilterInvoices()
  }

  function startDataLoad() {
    let { selectsStorage } = verifyStorage()

    let verifyCriteria = criteria

    selectsStorage.length
      ? loadingDataApi(selectsStorage, verifyCriteria)
      : loadInitialData()
  }

  const onChangeFilterApply = (selects) => {
    dispatch(applySelectFilter(selects))
  }

  const clearFilterHandler = () => {
    clearFilterInvoices()
  }

  function clearFilterInvoices() {
    dispatch(resetSelects([]))
    ;['customerDispute', 'poNumber', 'number', 'status', 'caseNumber'].forEach(
      (select) => {
        dispatch(selectActions[select].setSelected({ value: '', label: '' }))
      }
    )
    dispatch(resetSelectsSearch())
    dispatch(applyChangeFinancialStatusFilter({ label: 'All', value: 'ALL' }))
    dispatch(changeCriteriaFilter(''))
    setSelectedEndDate(null)
    setSelectedStartDate(null)
  }

  const applyFilterHandler = () => {
    if (!loading) {
      dispatch(changeLoading(true))
      dispatch(
        fetchDisputeCompanies({
          selects: filters.selected
        })
      )
    }
  }

  const handleSelect = useCallback(
    (selected) => {
      let newSelects = filtersAnalytical
      newSelects = newSelects.filter((sel) => sel.name !== selected.name)
      if (selected.selected) newSelects.push(selected)
      else newSelects = newSelects.filter((sel) => sel.name !== selected.name)
      if (selected.selected) dispatch(onSelect(selected))
      else dispatch(onResetField(selected))
    },
    [filtersAnalytical]
  )

  function syncSelectsUpdate(selected) {
    let params = {}
    selected.forEach((s) => {
      if (s.name) params[s.name] = s.selected.value
    })

    if (!params.poNumber) dispatch(selectActions.poNumber.fetchList(params))
    if (!params.customerDispute)
      dispatch(selectActions.customerDispute.fetchList(params))
    if (!params.number) dispatch(selectActions.number.fetchList(params))
    if (!params.status) dispatch(selectActions.status.fetchList(params))
    if (!params.caseNumber) dispatch(selectActions.caseNumber.fetchList(params))
  }

  useUpdateEffect(() => {
    onChangeFilterApply(filters.selected)
    syncSelectsUpdate(filters.selected)
    if (!loading) {
      dispatch(changeLoading(true))
      dispatch(fetchDisputeCompanies({ selects: filters.selected }))
    }
  }, [filters.selected])

  useEffect(() => {
    dispatch(changeLoading(false))
    startDataLoad()
  }, [])

  return (
    <Filter
      buttonName='btn-apply'
      menuFilter='dispute-filter'
      filterFooter='drop-filter-footer'
      clearInput={true}
      searchInput={true}
      interceptSelectedValue={false}
      onChangeFilter={(selects) => onChangeFilterApply(selects)}
      onClearClick={clearFilterHandler}
      clearTitle={t('clear')}
      applyTitle={t('apply')}
      gridTemplateColumns='20% 20% 20% 20% 20%'
      buttonTitleCustomerFilter={t('filter')}
      onSelect={handleSelect}
      applyFilter={applyFilterHandler}>
      <FilterSelect
        name='customerDispute'
        labelFromItem='name'
        valueFromItem='code'
        translationLabel='customer-list-label'
        translationPlaceholder='customer-list-placeholder'
        useAttributesAsValue={['code']}
        showAttributesAsLabel={['code', 'name']}
        fetchNotFound={true}
        triggerOnHide
      />
      <FilterSelect
        name='poNumber'
        labelFromItem='name'
        valueFromItem='purchaseOrderNumber'
        translationLabel='po-number-list-label'
        translationPlaceholder='po-number-list-placeholder'
        useAttributesAsValue={['purchaseOrderNumber']}
        showAttributesAsLabel={['purchaseOrderNumber']}
        fetchNotFound={true}
      />
      <FilterSelect
        name='number'
        labelFromItem='name'
        valueFromItem='number'
        translationLabel='document-number-list-label'
        translationPlaceholder='document-number-list-placeholder'
        useAttributesAsValue={['number']}
        showAttributesAsLabel={['number']}
        fetchNotFound={true}
      />
      <FilterSelect
        name='status'
        labelFromItem='name'
        valueFromItem='disposition'
        translationLabel='dispute-status-list-label'
        translationPlaceholder='dispute-status-list-placeholder'
        useAttributesAsValue={['disposition']}
        showAttributesAsLabel={['disposition']}
        fetchNotFound={true}
      />
      <FilterSelect
        name='caseNumber'
        labelFromItem='name'
        valueFromItem='caseNumber'
        translationLabel='dispute-case-number-list-label'
        translationPlaceholder='dispute-case-number-list-placeholder'
        useAttributesAsValue={['caseNumber']}
        showAttributesAsLabel={['caseNumber']}
        fetchNotFound={true}
      />
      <DisputeFilter isDisputeHistory={true} />
      <DisputeStartAndEndDatesPicker
        selectedStartDate={selectedStartDate}
        setSelectedStartDate={setSelectedStartDate}
        selectedEndDate={selectedEndDate}
        setSelectedEndDate={setSelectedEndDate}
      />
    </Filter>
  )
}
