import { GET_FINANCE_WEEKLY_POSITION } from './actions'

const INITIAL_STATE = {
  financeWeeklyPosition: []
}

export const getFinanceWeeklyPosition = (state = INITIAL_STATE, action) => {
  if(action.type === GET_FINANCE_WEEKLY_POSITION) {
    return { financeWeeklyPosition: [...action.financeWeeklyPosition.data.body] }
  }
  return state
}