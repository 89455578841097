const hasItemSelected = (selected, rows) => selected.length && rows.length

export default (rows, selected) => {
  if (hasItemSelected(selected, rows)) {
    return rows.reduce(
      (previous, next) => {
        if (selected.includes(next.id) && Number(next.totalAmount) < 0)
          previous.totalCredit += Number(next.totalAmount)
     
        return previous
      },
      { totalCredit: 0 }
    )
  }

  return ''
}
