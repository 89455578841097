import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import ExpansionPanelFooter from '../../../component/expansion-panel/expansion-panel-footer'
import TableToolbar from './table-toolbar'
import ShowTotalAndPaynow from './show-total-and-paynow'

const getInputSearch = (state) => get(state, 'inputSearchRedux.textFilter', '')
const getInputLeaderCamSelected = (state) =>
  get(state, 'crmLeader.selected', { label: '', value: '' })
const getAccountManagerSelected = (state) =>
  get(state, 'crm.selected', { label: '', value: '' })
const getCustomerGroupSelected = (state) =>
  get(state, 'customerGroup.selected', { label: '', value: '' })
const getCustomerSelected = (state) =>
  get(state, 'customer.selected', { label: '', value: '' })

const getCsiSelected = (state) =>
  get(state, 'csi.selected', { label: '', value: '' })

const TableFooter = (props) => {
  const inputSearchValueRedux = useSelector(getInputSearch)
  const selectInputLeaderCamSelected = useSelector(getInputLeaderCamSelected)
  const selectAccountManagerSelected = useSelector(getAccountManagerSelected)
  const selectCustomerGroupSelected = useSelector(getCustomerGroupSelected)
  const selectedCustomerSelected = useSelector(getCustomerSelected)
  const selectedCsiSelected = useSelector(getCsiSelected)

  const [inputSearch, setInputSearch] = useState(inputSearchValueRedux)
  const [inputLeaderCamSelected, setInputLeaderCamSelected] = useState(
    selectInputLeaderCamSelected
  )
  const [accountManagerSelected, setAccountManagerSelected] = useState(
    selectAccountManagerSelected
  )
  const [customerGroupSelected, setCustomerGroupSelected] = useState(
    selectCustomerGroupSelected
  )
  const [customerSelected, setCustomerSelected] = useState(
    selectedCustomerSelected
  )

  const [csiSelected, setCsiSelected] = useState(selectedCsiSelected)

  useEffect(() => {
    setInputSearch(inputSearchValueRedux)
    setInputLeaderCamSelected(selectInputLeaderCamSelected)
    setAccountManagerSelected(accountManagerSelected)
    setCustomerGroupSelected(selectCustomerGroupSelected)
    setCustomerSelected(selectedCustomerSelected)
    setCsiSelected(selectedCsiSelected)
  }, [
    inputSearchValueRedux,
    selectInputLeaderCamSelected,
    selectAccountManagerSelected,
    selectCustomerGroupSelected,
    selectedCustomerSelected,
    selectedCsiSelected
  ])

  return (
    <Fragment>
      <ExpansionPanelFooter>
        <TableToolbar {...props} />
        {props.payNowWarning ? (
          <div className='paynow-warning'>{props.payNowWarning}</div>
        ) : null}
        <ShowTotalAndPaynow
          {...props}
          inputSearch={inputSearch}
          leader={inputLeaderCamSelected}
          camCsi={accountManagerSelected}
          customerGroup={customerGroupSelected}
          customer={customerSelected}
          csi={csiSelected}
        />
      </ExpansionPanelFooter>
    </Fragment>
  )
}

export default TableFooter
