import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from '../../component/select'
import { orderData } from '../../domain/document/'
import { get } from 'lodash'

import {
  fetchCustomers,
  fetchCustomersNotFound
} from '../customer-list/__redux__/actions'

const getCustomers = (state) => get(state, 'getCustomers.customers', [])

export default (props) => {
  const { t } = useTranslation()
  const getAuth = (state) => get(state, 'auth.user')

  const user = useSelector(getAuth)
  const isCustomer = user.profile.includes('OLP_CUSTOMER')

  const dispatch = useDispatch()
  const customers = !isCustomer ? useSelector(getCustomers) : []

  const getCustomersToShow = (customers) =>
    customers.map((customer) => {
      if (isCustomer)
        return {
          ...customer,
          groupName: `${customer.code} - ${customer.name}`
        }

      return { ...customer, name: `${customer.code} - ${customer.name}` }
    })

  const orderedCustomers = (customers) =>
    orderData({
      collection: getCustomersToShow(customers),
      field: isCustomer ? 'groupName' : 'name',
      order: 'asc'
    })

  const getCustomersList = isCustomer
    ? orderedCustomers(user.customers)
    : orderedCustomers(customers)

  const [inputValue, setInputValue] = useState('')

  function handleInputValueChange(value) {
    setInputValue(value)
  }

  function onChangeValue(selected, name) {
    if (name) props.onSelect({ name, selected: selected || '' }, [])

    selected && selected.value !== ''
      ? handleInputValueChange(selected.label)
      : handleInputValueChange('')
  }

  useEffect(() => {
    if (props.updateValues) handleInputValueChange('')
  }, [props.updateValues])

  useEffect(() => {
    dispatch(fetchCustomers())
  }, [])

  return (
    <Select
      handleInputChange={handleInputValueChange}
      onChangeValue={onChangeValue}
      inputSearchValue={inputValue}
      name='code'
      suggestions={getCustomersList}
      label={t('customer-dashboard-customers-label')}
      placeholder={t('customer-dashboard-customers-placeholder')}
      useAttributesAsValue={['code']}
      showAttributesAsLabel={isCustomer ? ['groupName'] : ['name']}
      onSelect={props.onSelect}
      limitSuggestion={500}
      notifySuggestionNotFound={(notification) => {
        dispatch(fetchCustomersNotFound(notification))
      }}
    />
  )
}
