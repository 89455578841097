import { listManager } from '../../../../domain/identity'

const SET_CRM_LEADER_LIST = 'SET_CRM_LEADER_LIST'
const SET_SELECTED_CRM_LEADER = 'SET_SELECTED_CRM_LEADER'

const setList = (list) => ({
  type: SET_CRM_LEADER_LIST,
  list
})

const setSelected = (selected) => ({
  type: SET_SELECTED_CRM_LEADER,
  selected
})

const fetchList = (query) => (dispatch) => {
  return listManager(query).then((res) => dispatch(setList(res.data)))
}

export {
  SET_CRM_LEADER_LIST,
  SET_SELECTED_CRM_LEADER,
  fetchList,
  setSelected,
  setList
}
