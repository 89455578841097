import React from 'react'
import { PdfViewerUi } from './styles'

const PdfViewer = ({ source }) => {
  return (
    <PdfViewerUi>
      <iframe
        title='pdf-frame'
        src={source}
        frameBorder='0'
        className='pdf-view'
      />
    </PdfViewerUi>
  )
}

export default PdfViewer
