import { Close, WarningRounded } from '@material-ui/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoginAlert } from './styles'

export default () => {
  const { t } = useTranslation()
  const [show, toggleShow] = useState(false)
  return (
    show && (
      <LoginAlert>
        <WarningRounded className='login-alert-icon' />
        <div className='login-alert-text'>{t('form-login-alert')}</div>
        <Close
          className='login-alert-close'
          onClick={() => toggleShow(false)}
        />
      </LoginAlert>
    )
  )
}
