import styled from 'styled-components'

// const textColor = '#9d7377'
const textColor = '#666666'

export const LoginAlert = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #efefd2;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  margin-bottom: 20px;

  .login-alert-text {
    padding: 20px;
    color: ${textColor};
    font-weight: bold;
    text-align: center;
    font-size: smaller;
  }

  .login-alert-close {
    display: flex;
    width: 10%;
    align-self: center;
    cursor: pointer;
  }

  .login-alert-icon {
    display: flex;
    align-self: center;
    width: 10%;
  }
`
