import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    maxWidth: '96.6%',
    boxShadow: '0 3px 6px rgb(0 0 0 / 16%)'
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    backgroundColor: 'rgb(241,243,244)',
    padding: '20px 10px'
  }
}))
