import { SET_DATA_TCS } from './action'

const initialState = {
  accepted: false,
  id: null,
  document: '',
  version: null,
  pdfs: []
}

export const dataTcs = (state = initialState, action) => {
  const actions = {
    [SET_DATA_TCS]: {
      ...state,
      accepted: action.accepted,
      id: action.id,
      document: action.document,
      version: action.version,
      pdfs: action.pdfs,
      noCustomersFoundError: action.noCustomersFoundError
    }
  }

  return actions[action.type] || state
}
