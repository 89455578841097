import React from 'react'
import AmCharts from '../amcharts'
import settings from './settings'

const amChartsSerial = (props) => {
  const dataConfig = props.dataConfig
  const dataProvider = props.dataProvider
  const height = props.height
  const isCustomerDashboard = props.isCustomerDashboard

  return (
    <AmCharts
      height={height}
      config={settings(dataConfig, dataProvider, isCustomerDashboard)}
    />
  )
}

export default amChartsSerial
