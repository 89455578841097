import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const FooterDashboardUi = styled(Grid)`
  .botao-download-file-aging {
    padding: 0px 32px 32px 32px;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 16px;
    }
  }
`