import { SET_CUSTOMER_TYPE, SET_CUSTOMER_TYPE_LIST } from './actions'

const initialState = {
  list: ['ALL', 'CUSTOMER', 'INTERCOMPANY'],
  selected: { label: 'All', value: 'ALL' }
}

export default (state = initialState, action) => {
  if (action.type === SET_CUSTOMER_TYPE)
    return { ...state, selected: action.selected }
  if (action.type === SET_CUSTOMER_TYPE_LIST)
    return { ...state, list: action.list }
  return state
}
