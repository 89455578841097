import { stringify } from 'query-string'
import { getBearerAuthorization } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/documents`

export default async (query = {}) => {
  return axios.get(`${url}/list-dispute-po-numbers?${stringify(query)}`, {
    headers: getBearerAuthorization()
  })
}
