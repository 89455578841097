import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '../button'
import canShowMore from './can-show-more'
import { sum, get } from 'lodash'
import Loading from '../loading'
import whoCanViewAll from './who-can-view-all'

const setDisabled = (props) => (canShowMore(props) ? false : true)
const getStatusShowMore = (state) =>
  get(state, 'getDocuments.loadShowMore', false)
const getAuth = (state) => get(state, 'auth.user')

export default (props) => {
  const { t } = useTranslation()
  const user = useSelector(getAuth)
  const statusShowMore = useSelector(getStatusShowMore)
  const { onShowMore, page, salesOrganization, currency, onShowAll } = props

  return statusShowMore ? (
    <Loading size='20px' />
  ) : (
    <>
      <Button
        className='i-link i-link-text-uppercase'
        onClick={() =>
          onShowMore(sum([Number(page), 1]), salesOrganization, currency)
        }
        disabled={setDisabled(props)}>
        <span className='i-link-label'>{t('view-more')}</span>
        <i className='i-link-ico material-icons'>keyboard_arrow_down</i>
      </Button>
      {whoCanViewAll(user) ? (
        <Button
          className='i-link i-link-text-uppercase'
          onClick={() => onShowAll(salesOrganization, currency)}
          disabled={setDisabled(props)}>
          <span className='i-link-label'>{t('view-all')}</span>
          <i className='i-link-ico material-icons'>keyboard_arrow_down</i>
        </Button>
      ) : null}
    </>
  )
}
