import * as _ from 'lodash'
import { set, pipe } from 'lodash/fp'
import { get } from 'lodash'
import moment from 'moment'

export default (dataProvider, namesWeeks) => {

  const accounting = _.chain(dataProvider)
    .groupBy('week')
    .toPairs()
    .value()

  const dataProviderAcc = accounting.map((row) =>
    row[1].reduce((previous, next) => {
      const year = moment(get(next, 'referenceWeek')).format('YYYY/MM')

      return pipe(
        set('refWeek')(get(next, 'week')),
        set(`${year}`)(get(next, 'overdue')),
        set('referenceWeek')(namesWeeks[get(next, 'week')]),
        set('year')(year),
        set('numWeek')(get(next, 'ref-week'))
      )(previous)
    }, {})
  )

  const dataProviderOrdened = _.orderBy(dataProviderAcc, ['numWeek'], ['asc'])

  return dataProviderOrdened
}
