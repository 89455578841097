import { checkAvailableCustomerTypes } from '../../../domain/account/customer'

const SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE'
const SET_CUSTOMER_TYPE_LIST = 'SET_CUSTOMER_TYPE_LIST'

const changeCustomerTypeFilter = (selected) => ({
  type: SET_CUSTOMER_TYPE,
  selected
})

const applyChangeCustomerTypeFilter = (query) => (dispatch) => {
  dispatch(changeCustomerTypeFilter(query))
}

const setList = (list) => ({ type: SET_CUSTOMER_TYPE_LIST, list })

const fetchSelects = (query) => (dispatch) => {
  return checkAvailableCustomerTypes(query).then((res) =>
    dispatch(setList(res.data))
  )
}

export {
  SET_CUSTOMER_TYPE,
  SET_CUSTOMER_TYPE_LIST,
  changeCustomerTypeFilter,
  applyChangeCustomerTypeFilter,
  fetchSelects
}
