const SET_FETCH = 'SET_FETCH'
const ON_SELECT = 'ON_SELECT'
const RESET_SELECTS = 'RESET_SELECTS'
const SET_SELECTS = 'SET_SELECTS'
const ON_RESET_FIELD = 'ON_RESET_FIELD'

const setFetch = (fetching) => ({
  type: SET_FETCH,
  fetching
})

const onSelect = (selected) => ({
  type: ON_SELECT,
  selected
})

const resetSelects = () => ({
  type: RESET_SELECTS
})

const setSelects = (selected) => ({
  type: SET_SELECTS,
  selected
})

const onResetField = (selected) => ({
  type: ON_RESET_FIELD,
  selected
})

export {
  SET_SELECTS,
  SET_FETCH,
  ON_SELECT,
  ON_RESET_FIELD,
  RESET_SELECTS,
  setFetch,
  onSelect,
  onResetField,
  setSelects,
  resetSelects
}
