const CHANGE_QUOTATION_CRITERIA_FILTER = 'CHANGE_QUOTATION_CRITERIA_FILTER'
const CHANGE_QUOTATION_SELECT_FILTER = 'CHANGE_QUOTATION_SELECT_FILTER'
const CHANGE_QUOTATION_STATUS_FILTER = 'CHANGE_QUOTATION_STATUS_FILTER'
const RESET_QUOTATION_SEARCH = 'RESET_QUOTATION_SEARCH'
const UPDATE_QUOTATION_LOADING = 'UPDATE_QUOTATION_LOADING'

const changeCriteriaFilter = (criteria) => ({
  type: CHANGE_QUOTATION_CRITERIA_FILTER,
  criteria
})

const applyCriteriaFilter = (query) => (dispatch) => {
  dispatch(changeCriteriaFilter(query))
}

const changeSelectFilter = (selects) => ({
  type: CHANGE_QUOTATION_SELECT_FILTER,
  selects
})

const applySelectFilter = (query) => (dispatch) => {
  dispatch(changeSelectFilter(query))
}

const changeStatusFilter = (status) => ({
  type: CHANGE_QUOTATION_STATUS_FILTER,
  status
})

const applyStatusFilter = (query) => (dispatch) => {
  dispatch(changeStatusFilter(query))
}

const resetSelectsSearch = () => ({
  type: RESET_QUOTATION_SEARCH
})

const changeLoading = (loading) => ({
  type: UPDATE_QUOTATION_LOADING,
  loading
})

export {
  CHANGE_QUOTATION_CRITERIA_FILTER,
  CHANGE_QUOTATION_SELECT_FILTER,
  CHANGE_QUOTATION_STATUS_FILTER,
  RESET_QUOTATION_SEARCH,
  UPDATE_QUOTATION_LOADING,
  applyCriteriaFilter,
  applySelectFilter,
  applyStatusFilter,
  resetSelectsSearch,
  changeCriteriaFilter,
  changeSelectFilter,
  changeLoading
}
