import TypographyMUI from '@material-ui/core/Typography'
import BreadcrumbsMUI from '@material-ui/core/Breadcrumbs'
import LinkMUI from '@material-ui/core/Link'
import styled from 'styled-components'
import theme from '../theme'

const Typography = styled(TypographyMUI) `
  color: ${theme.colors.embColor};
  font-size: 2rem;
  font-weight: 900;
`;

const Breadcrumbs = styled(BreadcrumbsMUI) `
  padding: 30px 0 20px;
`;

const Link = styled(LinkMUI) `
  font-size: 2rem;
`;

export { Typography, Breadcrumbs, Link }