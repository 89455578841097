import { get, pipe, set } from 'lodash/fp'
import { GET_CARDS_ANALYTICAL_CUSTOMER } from './actions'

const INITIAL_STATE = {
  cards: []
}

const cards = [
  {
    title: 'Overdue',
    class: 'i-ico-overdue-color',
    ico: 'error_outline',
    key: 'overdue',
    type: 'number'
  },
  {
    title: 'Credit',
    class: 'i-ico-credit-color',
    ico: 'attach_money',
    key: 'credit',
    type: 'number'
  },
  {
    title: 'Dispute',
    class: 'i-ico-disput-color',
    ico: 'gavel',
    key: 'dispute',
    type: 'number'
  },
  {
    title: 'To be due Month',
    class: 'i-ico-not-overdue-color',
    ico: 'event_available',
    key: 'toBeDueMonth',
    type: 'number'
  },
  {
    title: 'To be due total',
    class: 'i-ico-disput-color',
    ico: 'error_outline',
    key: 'toBeDue',
    type: 'number'
  },
  {
    title: 'Updated At',
    class: 'i-ico-updated-at-color',
    ico: 'history',
    key: 'schedulerDate',
    type: 'date'
  }
]

const setCardsValues = (cardsValues) =>
  cards.map((card) => pipe(set('value')(get(card.key, cardsValues)))(card))

export const getCardsAnalyticalCustomer = (state = INITIAL_STATE, action) => {
  if (action.type === GET_CARDS_ANALYTICAL_CUSTOMER) {
    const builtCards = setCardsValues(action.cards.data.body)
    return { cards: [...builtCards] }
  }

  return state
}
