import React from 'react'
import { useTranslation } from 'react-i18next'
import renderHTML from 'react-render-html'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/Help'

import Tooltip from '../component/tooltip/'
import Breadcrumbs from '../component/breadcrumbs'
import DashboardFilter from '../container/dashboard-fixed-filter'

import ChartOverdueFinanceWeeklyMonthlyPosition from '../charts/overdue-finance-weekly-monthly-position'
import ChartOverduePerCustomers from '../charts/overdue-per-customers'
import ChartArSegment from '../charts/ar-segment'
import ChartArRegion from '../charts/ar-region'
import CardAnalytical from '../container/cards-analytical'
import ChartCustomerCredits from '../charts/customers-credits'
import FooterDashboard from '../container/footer-dashboard'
import CustomerPaymentInfo from '../charts/customer-payment-info'

import logoConfidential from '../assets/images/confidential.png'

export default () => {
  const { t } = useTranslation()

  const mountTooltip = () => t('accounts-receivable-text')

  return (
    <div>
      <div
        className='i-container'
        style={{ display: 'flex', marginTop: 32, alignItems: 'center' }}>
        <div>
          <Breadcrumbs />
        </div>
        <div>
          <img
            src={logoConfidential}
            alt='Confidential'
            style={{ width: 100, marginLeft: 16 }}
          />
        </div>
      </div>

      <DashboardFilter />

      <div className='i-container'>
        <div className='i-ar'>
          <h3 className='i-ar-title'>
            {t('accounts-receivable')}
            <Tooltip
              textTooltip={renderHTML(mountTooltip())}
              color='#ffffff'
              position='right'
              backgroundColor='rgba(0,0,0,0.75)'>
              <IconButton aria-label='Help'>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </h3>

          <CardAnalytical />
        </div>

        <div style={{ paddingTop: '40px', width: '100%' }}>
          <Grid container direction='row' alignItems='stretch'>
            <Grid item sm={12} style={{ width: '100%' }}>
              <ChartOverdueFinanceWeeklyMonthlyPosition
                title={t('overdue-graph-weekly-monthly')}
              />
            </Grid>
          </Grid>
        </div>

        <div style={{ paddingTop: '40px' }}>
          <Grid container direction='row' spacing={3}>
            <Grid item sm={12} md={6} style={{ width: '100%' }}>
              <Grid
                item
                sm={12}
                style={{ width: '100%', marginBottom: '24px' }}>
                <ChartCustomerCredits title={t('customer-credit')} />
              </Grid>
              <Grid
                item
                sm={12}
                style={{ width: '100%', marginBottom: '24px' }}>
                <ChartArSegment title={t('ar-segment')} />
              </Grid>

              <Grid
                item
                sm={12}
                style={{ width: '100%', marginBottom: '24px' }}>
                <ChartArRegion title={t('ar-region')} />
              </Grid>

              <Grid item sm={12} style={{ width: '100%' }}>
                <CustomerPaymentInfo
                  title={t('customer-dashboard-payment-info')}
                />
              </Grid>
            </Grid>

            <Grid item sm={12} md={6} style={{ width: '100%' }}>
              <Grid container direction='row' alignItems='stretch' spacing={3}>
                <Grid item sm={12} style={{ width: '100%' }}>
                  <ChartOverduePerCustomers
                    title={t('overdue-per-customers')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      <FooterDashboard />
    </div>
  )
}
