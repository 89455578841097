import React from 'react'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import TooltipMUI, { TransferTooltip } from './tooltip-style'

const Tooltip = (props) => {
  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '1.2rem',
          color: props.color,
          backgroundColor: props.backgroundColor
        }
      }
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
      {props.transfer ? (
        <TransferTooltip title={props.textTooltip} placement={props.position}>
          {props.children}
        </TransferTooltip>
      ) : (
        <TooltipMUI title={props.textTooltip} placement={props.position}>
          {props.children}
        </TooltipMUI>
      )}
    </MuiThemeProvider>
  )
}

export default Tooltip
