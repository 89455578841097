import ptBrLocale from "date-fns/locale/pt-BR";
import enUSLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import frLocale from "date-fns/locale/fr";
import zhCNLocale from "date-fns/locale/zh-CN";
import zhTWLocale from "date-fns/locale/zh-TW";

export default (locale) => {
  const locales = {
    'pt': ptBrLocale,
    'pt-BR': ptBrLocale,
    'en': enUSLocale,
    'en-US': enUSLocale,
    'es': esLocale,
    'fr': frLocale,
    'zh-CN': zhCNLocale,
    'zh-TW': zhTWLocale,
  }
  return locales[locale] || enUSLocale
}
