import React, { useState, useEffect } from 'react'
import { findAllFinancial } from '../../domain/financial'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import AmChartsPie from '../amcharts-pie'
import IsEmptyChart from '../amcharts-is-empty'
import config from './config.json'
import { isEmpty } from 'lodash'

const ChartCustomerCredits = (props) => {
  const [dataProvider, setDataProvider] = useState([])

  const loading = () =>
    findAllFinancial().then((dataProvider) => {
      setDataProvider(dataProvider.data)
    })

  useEffect(() => {
    loading()
  }, [])

  return !isEmpty(dataProvider) ? (
    <Card>
      <CardContent>
        <Typography
          gutterBottom
          style={{ fontSize: '1.9rem', color: '#666666' }}>
          {props.title}
        </Typography>

        <AmChartsPie dataConfig={config} dataProvider={dataProvider} />
      </CardContent>
    </Card>
  ) : (
    <IsEmptyChart title={props.title} />
  )
}

export default ChartCustomerCredits
