import { pipe, set, get } from 'lodash/fp'

import {
  GET_QUOTATIONS,
  GET_PAGINATION_QUOTATIONS,
  LOAD_SHOW_MORE
} from './actions'

const INITIAL_STATE = {
  loadShowMore: false
}

const _compose = (quotations) =>
  pipe(
    set('id')(get('id', quotations)),
    set('quotations')([
      ...get('quotations', INITIAL_STATE.quotations.get(get('id', quotations))),
      ...get('quotations', quotations)
    ]),
    set('page')(get('page', quotations)),
    set('pageSize')(get('pageSize', quotations)),
    set('count')(get('count', quotations))
  )({})

export const getQuotations = (state = INITIAL_STATE, action) => {
  if (action.type === GET_QUOTATIONS) {
    if (!INITIAL_STATE.quotations) INITIAL_STATE.quotations = new Map()
    INITIAL_STATE.quotations.set(action.row.id, action.row)
    return { ...state, quotations: INITIAL_STATE.quotations }
  }

  if (action.type === GET_PAGINATION_QUOTATIONS) {
    INITIAL_STATE.quotations.set(action.row.id, _compose(action.row))
    return { ...state, quotations: INITIAL_STATE.quotations }
  }

  if (action.type === LOAD_SHOW_MORE) {
    return { ...state, loadShowMore: action.loadShowMore }
  }

  return state
}
