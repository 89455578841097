import {
  UPDATE_FULL_NAME,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_CONFIRM,
  UPDATE_ERROR_FULL_NAME,
  UPDATE_ERROR_PASSWORD,
  UPDATE_ERROR_PASSWORD_CONFIRM,
  UPDATE_PHONE_NUMBER
} from './actions'

const initialState = {
  fullName: '',
  password: '',
  passwordConfirm: '',
  phoneNumber: '',
  errorFullName: false,
  errorPassword: false,
  errorPasswordConfirm: false
}

export const newUser = (state = initialState, action) => {
  const actions = {
    [UPDATE_FULL_NAME]: {
      ...state,
      fullName: action.fullName
    },
    [UPDATE_PASSWORD]: {
      ...state,
      password: action.password
    },
    [UPDATE_PASSWORD_CONFIRM]: {
      ...state,
      passwordConfirm: action.passwordConfirm
    },
    [UPDATE_ERROR_FULL_NAME]: {
      ...state,
      errorFullName: action.errorFullName
    },
    [UPDATE_ERROR_PASSWORD]: {
      ...state,
      errorPassword: action.errorPassword
    },
    [UPDATE_ERROR_PASSWORD_CONFIRM]: {
      ...state,
      errorPasswordConfirm: action.errorPasswordConfirm
    },
    [UPDATE_PHONE_NUMBER]: {
      ...state,
      phoneNumber: action.phoneNumber
    }
  }

  return actions[action.type] || state
}
