import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

import { PinCodeDefaultProps } from '.'

export const PinInputContainer = styled.div`
  display: flex;
  align-items: center;
`

export const PinCodeInput = styled(TextField)`
  margin-right: 1rem !important;

  &:last-child {
    margin-right: 0 !important;
  }
  input {
    font-size: 44px;
    text-align: center;
    font-weight: 900;

    ${({ completed, showState }) => {
      return completed && showState
        ? `&:valid {
      box-shadow: ${PinCodeDefaultProps.validBorderColor} 0px 0px 0px 1px;
      background-color: ${PinCodeDefaultProps.validBorderColor
        .replace('rgb', 'rgba')
        .replace(')', ',0.1)')};
    }`
        : ''
    }}
    ${({ showState }) => {
      return showState
        ? `&:invalid {
      box-shadow: ${PinCodeDefaultProps.errorBorderColor} 0px 0px 0px 1px;
      background-color: ${PinCodeDefaultProps.errorBorderColor
        .replace('rgb', 'rgba')
        .replace(')', ',0.1)')};
    }`
        : ''
    }}
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`
