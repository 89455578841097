import { getBearerAuthorization } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/documents`

export default async (query = {}) => {
  const setQueryPipe = { id: query }
  return await axios.post(`${url}/find-by-documents`, setQueryPipe, {
    headers: getBearerAuthorization()
  })
}
