import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

const TriggerIdocUI = styled.div`
  .trigger-idoc-link {
    text-align: right;

    span.MuiButton-label {
      font-weight: bold;
      text-decoration: underline;
      text-transform: capitalize;
    }
  }
`

export const FormTrigger = styled.form`
  .MuiDialogContent-root {
    width: 350px;
  }

  .MuiGrid-grid-md-3 {
    margin-top: 16px;
  }

  button {
    width: 100%;
  }
`

export const TextFieldUi = styled(TextField)`
  label {
    font-size: 16px;
  }

  input {
    font-size: 16px;
  }
`

export default TriggerIdocUI
