import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Swal from 'sweetalert2'
import axios from 'axios'
import validator from 'validator'

import Loading from '../../../component/loading'

import { FormLoginContainer } from './style'
import isUserDisabledResponse from '../../../helpers/is-user-disabled-response'

export default () => {
  const { t } = useTranslation()

  const history = useHistory()
  const params = useParams()

  const [passwordState, setPasswordState] = useState('')
  const [repeatPasswordState, setRepeatPasswordState] = useState('')
  const [loadingState, setLoading] = useState(false)
  const [passwordErrorState, setPasswordError] = useState('')
  const [token] = useState(() => {
    return params ? params.token : ''
  })

  const handleSubmitLogin = (e) => {
    e.preventDefault()
    setPasswordError('')
    setLoading(true)
    if (!passwordState || !repeatPasswordState) {
      Swal.fire(
        t('change-password-alert'),
        t('change-password-wrong-data'),
        'warning'
      )
      setLoading(false)
    } else if (passwordState.length < 11 && repeatPasswordState.length < 11) {
      Swal.fire(
        t('attention') + '!',
        t('new-user-password-not-length'),
        'warning'
      )
      setLoading(false)
    } else if (passwordState !== repeatPasswordState) {
      Swal.fire(
        t('change-password-alert'),
        t('change-password-confirm-error'),
        'warning'
      )
      setLoading(false)
    } else if (
      !validator.isStrongPassword(passwordState, {
        minLength: 11,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1
      })
    ) {
      setPasswordError(t('change-password-invalid-character'))
      setLoading(false)
    } else {
      axios
        .post(
          `${process.env.REACT_APP_INVOICES_API}/forgot-password/change-password`,
          {
            tokenId: token,
            password: passwordState,
            repeatPassword: repeatPasswordState
          }
        )
        .then((response) => {
          Swal.fire(t('success') + '!', t('change-password-success'), 'success')
          history.push('/login')
        })
        .catch((error) => {
          let response = error.response
          let msgResponse
          if (response && isUserDisabledResponse(response.data.body)) {
            msgResponse = t('new-user-user-disabled')
          } else {
            msgResponse = t('change-password-error')
          }
          Swal.fire(t('error'), msgResponse, 'error')
          history.push('/login')
        })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_INVOICES_API}/forgot-password/check-token/${token}`
        )
        .then((response) => {
          if (!response.data.body.token) {
            Swal.fire(
              t('attention') + '!',
              t('change-password-invalid-token'),
              'warning'
            )
            history.push('/login')
          }
        })
        .catch((error) => {
          let response = error.response
          let msgResponse
          if (response && isUserDisabledResponse(response.data.body)) {
            msgResponse = t('new-user-user-disabled')
          } else {
            msgResponse = t('change-password-invalid-token')
          }
          Swal.fire(t('attention') + '!', msgResponse, 'warning')
          history.push('/login')
        })
    }
  }, [token])

  return (
    <FormLoginContainer onSubmit={handleSubmitLogin} autoComplete='off'>
      <Grid container>
        <Grid item>
          <h2>{t('change-password-create-new')}</h2>
          <p>{`* ${t('change-password-invalid-character')}`}</p>
        </Grid>
      </Grid>
      <Grid container className='mb-16'>
        <Grid item xs={12}>
          <TextField
            label={t('change-password-insert-new-label')}
            fullWidth
            value={passwordState}
            onChange={(input) => setPasswordState(input.target.value)}
            required
            error={passwordErrorState !== ''}
            type='password'
          />
        </Grid>
      </Grid>
      <Grid container className='mb-16'>
        <Grid item xs={12}>
          <TextField
            label={t('change-password-confirmation')}
            fullWidth
            value={repeatPasswordState}
            onChange={(input) => setRepeatPasswordState(input.target.value)}
            required
            type='password'
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {loadingState ? (
            <Loading />
          ) : (
            <Button type='submit' fullWidth variant='contained' color='primary'>
              {t('reset')}
            </Button>
          )}
        </Grid>
      </Grid>
    </FormLoginContainer>
  )
}
