import React, { useEffect, useRef, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Divider from '@material-ui/core/Divider'
import { setToastMessage } from '../../container/snackbar/__redux__/actions'

import { getZipPdfs } from '../../domain/quotation'
import setIsFree from '../../domain/document/set-interest-free'
import { useStyles } from './styles'

import Modal from '../../container/modal-download-pdfs-quotation'

import './styles.css'
import { verifyAccess } from '../../domain/security'
import { rolesFullAccess } from '../../App'
import { get } from 'lodash'

const getAuth = (state) => get(state, 'auth.user')

const ButtonMenuList = ({ titleBtn, resetSelectAll, ...res }) => {
  const { t } = useTranslation()
  const dispatchRedux = useDispatch()

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [downloadZip, setDownloadZip] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [statusDownloadAll, setStatusDownloadAll] = useState(true)
  const [settingInterestFree, setSettingInterestFree] = useState(false)
  const anchorRef = useRef(null)
  const prevOpen = useRef(open)
  const user = useSelector(getAuth)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleOpenModal = (event) => {
    setOpenModal(!openModal)
    if (event) handleClose(event)

    if (!openModal === false) {
      resetSelectAll()
    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleDownloadZip = async () => {
    setDownloadZip(true)
    saveZipDownload()
  }

  const handleSetInterestFree = async () => {
    setSettingInterestFree(true)
    await setInterestFree()
    setSettingInterestFree(false)
    resetSelectAll()
  }

  const setInterestFree = async () => {
    const response = {}
    const documents = [...res.selected]
    const results = await Promise.allSettled(
      documents.map((docId) => setIsFree(docId))
    )
    results.forEach((r, index) => {
      if (r.status === 'rejected')
        response[documents[index]] = t(r.reason.message)
      else response[documents[index]] = t(r.value.message)
    })
  }

  async function saveZipDownload() {
    const zip = await getZipPdfs(res.selected)

    if (zip.data.body.zip) {
      const blob = new Blob([new Uint8Array(zip.data.body.zip.data).buffer], {
        type: 'application/zip'
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'downloads.zip')
      document.body.appendChild(link)
      link.click()
    } else {
      dispatchRedux(
        setToastMessage({
          open: true,
          variant: 'error',
          message: 'PDF not found for download!'
        })
      )
    }

    setDownloadZip(false)
    resetSelectAll()
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  useEffect(() => {
    res.selected.length > 0
      ? setStatusDownloadAll(false)
      : setStatusDownloadAll(true)
  }, [res])

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}>
          {titleBtn}
        </Button>
        <Popper
          className='zIndex-Popper'
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id='menu-list-grow'
                    onKeyDown={handleListKeyDown}>
                    <MenuItem
                      disabled={statusDownloadAll}
                      onClick={handleOpenModal}>
                      {t('footer-download-pdfs')}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      disabled={statusDownloadAll || downloadZip ? true : false}
                      onClick={handleDownloadZip}>
                      {downloadZip ? 'Loading...' : t('footer-download-zip')}
                    </MenuItem>

                    {verifyAccess(user, rolesFullAccess) ? (
                      <Fragment>
                        <Divider light />
                        <MenuItem
                          disabled={
                            statusDownloadAll || settingInterestFree
                              ? true
                              : false
                          }
                          onClick={handleSetInterestFree}>
                          {settingInterestFree
                            ? 'Loading...'
                            : t('footer-interest-free')}
                        </MenuItem>
                      </Fragment>
                    ) : null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <Modal
        open={openModal}
        handleClose={handleOpenModal}
        maxWidth={600}
        {...res}
      />
    </div>
  )
}

export default ButtonMenuList
