import { intersection, isString } from 'lodash'

export default (user, roles) => {
  if (!user) return false

  let profiles = user.profile
  if (isString(profiles)) profiles = profiles.split(',')
  if (isString(roles)) roles = roles.split(',')
  return intersection(roles, profiles).length > 0
}
