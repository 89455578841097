import React, { useState, useCallback } from 'react'
import { Grid } from '@material-ui/core'
import InputText from '../../../component/input'
import TextField from '../../../component/textField'
import StyledDropzone from '../../../component/dropzone'
import WrapForm from './styles'
import Button from '../../../component/button'
import { useTranslation } from 'react-i18next'
import FileList from '../file-list'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import Swal from 'sweetalert2'
import { sendSupportEmail } from '../../../domain/user'

const getAuth = (state) => get(state, 'auth.user')

const Form = () => {
  const { t } = useTranslation()

  const [descriptionValue, setDescriptionValue] = useState('')
  const [descriptionValueError, setDescriptionValueError] = useState(false)
  const [data, setData] = useState(new FormData())
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [uploadedTotalSize, setUploadedTotalSize] = useState(0)

  const user = useSelector(getAuth)

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      uploadedFiles.forEach((file) => {
        data.append('attach', file)
      })

      data.append('name', user.fullName)
      data.append('email', user.email)
      data.append('description', descriptionValue)

      user.customers.forEach((customer) => {
        data.append('customers', customer.code)
      })

      let errorTitle = t('customer-support-error-title')
      let errorMessage = t('customer-support-error-message')
      let iconType = 'error'
      try {
        if (!descriptionValue) {
          setDescriptionValueError(true)
          errorTitle = t('customer-support-attention-title')
          errorMessage = t('customer-support-description-error')
          iconType = 'warning'
          throw new Error()
        }

        await sendSupportEmail(data)

        Swal.fire({
          title: t('customer-support-success-title'),
          text: t('customer-support-success-message'),
          icon: 'success',
          confirmButtonText: 'OK'
        })

        clearSelects()
      } catch (err) {
        Swal.fire({
          title: errorTitle,
          text: errorMessage,
          icon: iconType,
          confirmButtonText: 'OK'
        })

        setData(new FormData())
      }
    },
    [descriptionValue, uploadedFiles, data]
  )

  const clearSelects = () => {
    setData(new FormData())
    setUploadedFiles([])
    setDescriptionValue('')
  }

  const handleAttach = useCallback(
    async (files) => {
      let totalFilesSize = 0
      files.forEach((file) => (totalFilesSize += file.size))
      uploadedFiles.forEach((file) => (totalFilesSize += file.size))

      setUploadedTotalSize(totalFilesSize)

      if (totalFilesSize > 25000000) {
        return Swal.fire({
          title: t('customer-support-max-size-exceeded-error'),
          text: t('customer-support-max-size-exceeded-message'),
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      if (uploadedFiles.length + files.length > 5) {
        return Swal.fire({
          title: t('customer-support-max-files-exceeded-error'),
          text: t('customer-support-max-files-exceeded-message'),
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      if (uploadedFiles.length > 4) {
        return Swal.fire({
          title: t('customer-support-max-files-reached-error'),
          text: t('customer-support-max-files-reached-message'),
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      return setUploadedFiles([
        ...uploadedFiles,
        ...files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ])
    },
    [uploadedFiles]
  )

  const handleDelete = useCallback(
    (index) => {
      const files = uploadedFiles
      files.splice(index, 1)
      setUploadedFiles([...files])

      let totalFilesSize = 0
      files.forEach((file) => (totalFilesSize += file.size))

      setUploadedTotalSize(totalFilesSize)
    },
    [uploadedFiles]
  )

  return (
    <WrapForm onSubmit={handleSubmit}>
      <Grid container spacing={2} className='form-fields'>
        <Grid item xs={12} lg={12}>
          <InputText
            defaultValue={user.fullName}
            label={t('customer-support-name')}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <InputText
            defaultValue={user.email}
            label={t('customer-support-email')}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            value={descriptionValue}
            placeholder={t('customer-support-description')}
            onChange={(input) => {
              const { value } = input.target
              setDescriptionValueError(false)
              value.length < 501
                ? setDescriptionValue(value)
                : setDescriptionValue(descriptionValue)
            }}
            required
            config={{
              multiline: true,
              maxRows: 6,
              error: descriptionValueError
            }}
            className='text-field-area'
          />
        </Grid>
      </Grid>

      <StyledDropzone
        className='files-dropzone'
        onDrop={handleAttach}
        accept='image/*'
        multiple
        t={t}
        message='customer-support-upload-message'
      />

      <FileList
        files={uploadedFiles}
        handleDelete={handleDelete}
        t={t}
        uploadedTotalSize={uploadedTotalSize}
      />

      <div className='submit-button-container'>
        <Button type='submit' className='submit-button' variant='contained'>
          {t('customer-support-send-button')}
        </Button>
      </div>
    </WrapForm>
  )
}

export default Form
