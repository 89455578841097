import React, { Fragment, useState } from 'react'
import Button from '../../../component/button'
import DialogAttachmentsGallery from './dialog-attachments-gallery'
import { useTranslation } from 'react-i18next'

export default (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <Fragment>
      <Button
        onClick={() => setIsDialogOpen(!props.isDialogOpen)}
        disabled={!props.attachments}
        className='btn-dispute'>
        {t('dispute-attachments-show-button')}
      </Button>
      <DialogAttachmentsGallery
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        attachments={props.attachments}
      />
    </Fragment>
  )
}
