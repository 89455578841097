import React from 'react'
import Grid from '@material-ui/core/Grid'
import InputSearch from '../../component/input-search'

export default (props) => (
  <Grid container direction="row" alignItems="center" style={{ padding: '14px 0 13px' }}>
    <Grid item xs={12} sm={8}>
      <h3 style={{ color: '#100690', paddingRight: '10px', display: 'inline-block' }}>{props.title}</h3>
      <span className="i-label-count">{props.subTitle}</span>
    </Grid>
    <Grid item xs={12} sm={4}>
      <InputSearch
        name={props.searchName}
        placeholder={props.searchPlaceholder}
        onClick={(inputValue) => props.onFilter(inputValue)}
      />
    </Grid>
  </Grid>
)