import React from 'react'
import Tooltip from '../../../component/tooltip'
import GavelIcon from '@material-ui/icons/Gavel'

export default () => (
  <Tooltip
    textTooltip='Dispute'
    color='#ffffff'
    position='bottom-end'
    backgroundColor='rgba(0,0,0,0.75)'>
    <GavelIcon fontSize='large' style={{ color: '#F9B32E', margin: '11px' }} />
  </Tooltip>
)
