import TableRowMui from '@material-ui/core/TableRow'
import styled from 'styled-components'

const TableRow = styled(TableRowMui)`
  @media (max-width: 1131px) {
    position: relative;
    margin-bottom: 10px;
    border-left: 5px solid #dedede;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    flex-wrap: wrap;

    &.class-overdue {
      border-left-color: rgba(245, 73, 79, 0.74);
    }
  }

  overflow-x: scroll;

  @media (max-width: 1131px) {
    padding-bottom: 70px;
  }
`

export default TableRow
