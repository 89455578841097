import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import BarChartIcon from '@material-ui/icons/BarChart'
import { IsEmpty } from './style'

const IsEmptyChart = (props) => {

  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom style={{ fontSize: "1.9rem", color: "#666666" }}>
          {props.title}
        </Typography>

        <IsEmpty overduePerCustomers={props.overduePerCustomers}>
          <Typography>
            <BarChartIcon />
            {t('no-data-chart')}
          </Typography>
        </IsEmpty>
      </CardContent>
    </Card>
  )
}

export default IsEmptyChart