import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Swal from 'sweetalert2'

import FormLoginContainer from '../../../component/form-login-container'
import isUserDisabledResponse from '../../../helpers/is-user-disabled-response'

export default () => {
  const { t } = useTranslation()

  const [emailState, setEmailState] = useState('')
  const [loadingState, setLoading] = useState(false)

  const handleSubmitLogin = (e) => {
    e.preventDefault()

    const sendingForgotPassword = {
      email: emailState.toLocaleLowerCase()
    }

    setLoading(true)

    axios
      .post(
        `${process.env.REACT_APP_INVOICES_API}/forgot-password`,
        sendingForgotPassword
      )
      .then((_) => {
        Swal.fire(
          t('success') + '!',
          t('forgot-password-recover-mail-sent'),
          'success'
        )
      })
      .catch((error) => {
        let msgResponse
        let response = error.response
        if (response) {
          msgResponse = isUserDisabledResponse(response.data.body)
            ? t('new-user-user-disabled')
            : t('forgot-password-reset-error')
        } else msgResponse = error.message

        Swal.fire(t('error') + '!', msgResponse, 'error')
      })
      .finally((msg) => {
        setLoading(false)
        setEmailState('')
      })
  }

  return (
    <FormLoginContainer
      onSubmit={handleSubmitLogin}
      autoComplete='off'
      pageName='forgot-password-reset-password'
      pageText='forgot-password-recover-link-email'
      value={emailState}
      loadingState={loadingState}
      onChange={(input) => setEmailState(input.target.value)}
      buttonText={t('forgot-password-send-email')}
    />
  )
}
