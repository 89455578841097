import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import AmChartsPie from '../amcharts-pie'
import IsEmptyChart from '../amcharts-is-empty'
import config from './config.json'
import { get, isEmpty } from 'lodash'
import { findPaymentPerformanceInfo } from '../../domain/document'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const getPaymentInfo = (state) =>
  get(state, 'getCustomerPaymentInfo.paymentInfo', [])

const ChartCustomerPaymentInfo = (props) => {
  const { t } = useTranslation()

  const paymentInfo = useSelector(getPaymentInfo)
  const [state, setState] = useState(false)
  const [dataProvider, setDataProvider] = useState()

  const transformPaymentInfo = (payInfo) => {
    const { paidBeforeDueDate, paidAfterDueDate } = payInfo

    return [
      { value: paidBeforeDueDate, description: t('paidBeforeDueDate') },
      { value: paidAfterDueDate, description: t('paidAfterDueDate') }
    ]
  }

  const loading = () =>
    findPaymentPerformanceInfo().then((dataProvider) => {
      setDataProvider(transformPaymentInfo(dataProvider.data.body))
    })

  useEffect(() => {
    loading()
    setState(true)
  }, [state])

  useEffect(() => {
    setDataProvider(transformPaymentInfo(paymentInfo))
  }, [paymentInfo])

  return !isEmpty(dataProvider) ? (
    <Card>
      <CardContent>
        <Typography
          gutterBottom
          style={{ fontSize: '1.9rem', color: '#666666' }}>
          {props.title}
        </Typography>

        <AmChartsPie dataConfig={config} dataProvider={dataProvider} />
      </CardContent>
    </Card>
  ) : (
    <IsEmptyChart title={props.title} />
  )
}

export default ChartCustomerPaymentInfo
