import { findAgg } from '../../../domain/document/'

const GET_CARDS_ANALYTICAL = "GET_CARDS_ANALYTICAL"
const getCardsAnalytical = cards => ({
  type: GET_CARDS_ANALYTICAL,
  cards
})

const fetchCards = (query) => (dispatch) => findAgg(query)
  .then(cards => dispatch(getCardsAnalytical(cards)))
 
export { GET_CARDS_ANALYTICAL, fetchCards }  