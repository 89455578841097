import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import HelpIcon from '@material-ui/icons/Help'
import IconButton from '@material-ui/core/IconButton'
import Swal from 'sweetalert2'
import TriggerIdocUI, { TextFieldUi, FormTrigger } from './style'

import { useTranslation } from 'react-i18next'

import { triggerIdoc } from '../../domain/account/customer'

import Button from '../../component/button'
import Tooltip from '../../component/tooltip'

const TriggerIdoc = () => {
  const { t } = useTranslation()

  const [mfirSap, setMfirSap] = useState('')
  const [openDialogTrigger, setOpenDialogTrigger] = useState(false)
  const [awaitConsulta, setAwaitConsulta] = useState(false)

  const handleClickTrigger = () => setOpenDialogTrigger(true)

  const handleClickClosedTrigger = () => {
    setOpenDialogTrigger(false)
    setMfirSap('')
  }

  const handleSubmitTrigger = (event) => {
    event.preventDefault()
    setAwaitConsulta(true)
    triggerIdoc(mfirSap).then((response) => {
      parseInt(response.data.body, 10) < 0
        ? Swal.fire('Oops...', t('message-trigger-error'), 'error')
        : Swal.fire('Yes...', t('message-trigger-success'), 'success')
      setAwaitConsulta(false)
      setOpenDialogTrigger(false)
      setMfirSap('')
    })
  }

  return (
    <TriggerIdocUI>
      <Grid container direction='row' alignItems='center'>
        <Grid item xs={11} className='trigger-idoc-link'>
          <Button onClick={handleClickTrigger}>Trigger SAP Company</Button>

          <Dialog
            open={openDialogTrigger}
            onClose={handleClickClosedTrigger}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>
              <h2 style={{ color: '#100690' }}>Trigger SAP Company</h2>
            </DialogTitle>
            <FormTrigger
              noValidate
              autoComplete='off'
              onSubmit={handleSubmitTrigger}>
              <DialogContent>
                {awaitConsulta ? (
                  <h5>await...</h5>
                ) : (
                  <Grid
                    container
                    direction='row'
                    justify='center'
                    alignItems='center'>
                    <Grid item xs={12} md={9}>
                      <TextFieldUi
                        id='standard-basic'
                        label='MFIR SAP'
                        value={mfirSap}
                        onChange={(input) => setMfirSap(input.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Button color='primary' type='submit'>
                        Ok
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClickClosedTrigger} color='primary'>
                  Close
                </Button>
              </DialogActions>
            </FormTrigger>
          </Dialog>
        </Grid>
        <Grid item xs={1}>
          <Tooltip
            textTooltip={
              'Caso não encontre o MFIr do SAP, utilizar essa funcionalidade para estimular o cadastro no Invoices.'
            }
            color='#ffffff'
            backgroundColor='rgba(0,0,0,0.5)'>
            <IconButton aria-label='Help'>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </TriggerIdocUI>
  )
}

export default TriggerIdoc
