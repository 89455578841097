import { findCustomerCreditLimit } from '../../../domain/user/'

const GET_CUSTOMER_CREDIT_LIMIT = 'GET_CUSTOMER_CREDIT_LIMIT'
const getCustomerCreditLimit = (customerDocuments) => ({
  type: GET_CUSTOMER_CREDIT_LIMIT,
  customerDocuments
})

const fetchCustomerCreditLimit = (query) => (dispatch) =>
  findCustomerCreditLimit(query).then((customerDocuments) =>
    dispatch(getCustomerCreditLimit(customerDocuments))
  )

export { GET_CUSTOMER_CREDIT_LIMIT, fetchCustomerCreditLimit }
