import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  setFullName,
  setPhoneNumber,
  setErrorFullName
} from '../__redux__/actions'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import Loading from '../../../component/loading'

import { UserInformationContainer } from './style'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()
  const dispatchRedux = useDispatch()

  const [loading, setLoading] = useState(true)

  const fullName = useSelector((state) => state.registerNewUser.fullName)
  const phoneNumber = useSelector((state) => state.registerNewUser.phoneNumber)
  const email = useSelector((state) => state.registerNewUser.registrationEmail)
  const errorFullName = useSelector(
    (state) => state.registerNewUser.errorFullName
  )

  const handleChangeFullName = (value) => {
    dispatchRedux(setFullName(value))

    if (value.length > 4) {
      dispatchRedux(setErrorFullName(false))
    }
  }

  const handleChangePhoneNumber = (value) => {
    dispatchRedux(setPhoneNumber(value))
  }

  useEffect(() => {
    setLoading(false)
  }, [])

  if (loading) return <Loading />

  return (
    <UserInformationContainer>
      <Grid container spacing={2} className='mb-16px'>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('user-information-full-name') + ' *'}
            onChange={(input) => handleChangeFullName(input.target.value)}
            value={`${fullName}`}
            error={errorFullName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            disabled
            variant='filled'
            label='E-mail *'
            value={email}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className='mb-16px'>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t('user-information-phone-number')}
            type='text'
            value={`${phoneNumber}`}
            onChange={(input) => handleChangePhoneNumber(input.target.value)}
          />
        </Grid>
      </Grid>
    </UserInformationContainer>
  )
}
