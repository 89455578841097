import { list } from '../../../../domain/identity'

const SET_SELECTED_CRM = 'SET_SELECTED_CRM'
const SET_CRM_LIST = 'SET_CRM_LIST'

const setSelected = (selected) => ({
  type: SET_SELECTED_CRM,
  selected
})
const setList = (list) => ({ type: SET_CRM_LIST, list })

const fetchList = (query) => (dispatch) => {
  return list({ ...query, type: 'CRM' }).then((res) =>
    dispatch(setList(res.data))
  )
}

export { SET_SELECTED_CRM, SET_CRM_LIST, setSelected, setList, fetchList }
