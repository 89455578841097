import { 
    GET_CUSTOMER_GROUPS, 
    GET_CUSTOMER_GROUPS_NOT_FOUND,
    SET_CUSTOMER_GROUP_SELECTED
} from "./actions"

const INITIAL_STATE = {
    customerGroups: [],
    customerSelectGroup: { value: '', label: '' }
}

export const getCustomerGroups = (state = INITIAL_STATE, action) => {
    if (action.type === SET_CUSTOMER_GROUP_SELECTED)
        return { ...state, customerSelectGroup: action.customerSelectGroup }

    if (action.type === GET_CUSTOMER_GROUPS)
        return { ...state, customerGroups: action.customerGroups }

    if (action.type === GET_CUSTOMER_GROUPS_NOT_FOUND)
        return { ...state, customerGroups: action.customerGroups }

    return state
}
