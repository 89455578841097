import { UPDATE_STATUS_SHOW_MENU, SET_STATUS_MENU } from './actions'

const INITIAL_STATE = {
  showMenuView: window.innerWidth > 1064 ? true : false
}

export const headerReducer = (state = INITIAL_STATE, action) => {
  const actions = {
    [UPDATE_STATUS_SHOW_MENU]: { ...state, showMenuView: !state.showMenuView },
    [SET_STATUS_MENU]: { ...state, showMenuView: action.status }
  }
  return actions[action.type] || state
}
