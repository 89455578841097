import axios from 'axios'
import { getBearerAuthorization } from '../auth'
import { pick, get } from 'lodash'
import { pipe, set } from 'lodash/fp'
import { stringify } from 'query-string'

const invoicesApiUrl = process.env.REACT_APP_INVOICES_API_HEROKU

export const createDispute = async (data) => {
  return axios.post(`${invoicesApiUrl}/v1/disputes/create-dispute-case`, data, {
    headers: {
      ...getBearerAuthorization(),
      'Content-Type': 'multipart/form-data'
    }
  })
}

const SEARCH_FIELDS = [
  'poNumber',
  'customer',
  'number',
  'status',
  'isOverdue',
  'page',
  'caseNumber'
]

const _composeFilter = ({ selects = [] }) =>
  selects
    ? selects.map((filter) =>
        pipe(set(get(filter, 'name'))(get(filter, 'selected.value')))({})
      )
    : {}

export const searchDisputeInformation = async (query = {}) => {
  const { salesOrganization, currency, page = 0 } = query
  query = Object.assign(
    {},
    { pageSize: 50, page },
    pick(query, SEARCH_FIELDS),
    ..._composeFilter(query)
  )

  return await axios.get(
    `${invoicesApiUrl}/v1/disputes/${salesOrganization}/${currency}?${stringify(
      query
    )}`,
    {
      headers: getBearerAuthorization()
    }
  )
}

export const listDisputeStatus = async (query = {}) => {
  return axios.get(
    `${invoicesApiUrl}/v1/disputes/list-status?${stringify(query)}`,
    {
      headers: getBearerAuthorization()
    }
  )
}

export const listCaseNumber = async (query = {}) => {
  return axios.get(
    `${invoicesApiUrl}/v1/disputes/list-case-number?${stringify(query)}`,
    {
      headers: getBearerAuthorization()
    }
  )
}

export const downloadAttachment = async (path) => {
  return axios.get(
    `${invoicesApiUrl}/v1/disputes/get-attachments?path=${path}`,
    {
      headers: getBearerAuthorization()
    }
  )
}
