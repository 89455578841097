import { GET_VIEW_TYPES } from "./actions"

const INITIAL_STATE = {
  viewTypes: []
}

export const getViewTypes = (state = INITIAL_STATE, action) => {
  if (action.type === GET_VIEW_TYPES) {
    return { viewTypes: [...action.viewTypes.data.body] }
  }
  return state
}