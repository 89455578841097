import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from '../../component/select'
import { fetchSegments } from './__redux__/actions'
import { orderData } from '../../domain/document/'
import { get } from 'lodash'

const getSegments = (state) => get(state, 'getSegments.segments', [])

export default (props) => {
  const { t } = useTranslation()
  const segments = useSelector(getSegments)
  const dispatch = useDispatch()

  const [inputValue, setInputValue] = useState('')
  const [segmentsConfig, setSegmentsConfig] = useState([])

  function handleInputValueChange(value) {
    setInputValue(value)
  }

  function onChangeValue(selected, name) {
    if (name) props.onSelect({ name, selected: selected || '' }, props.filters)

    selected && selected.value !== ''
      ? handleInputValueChange(selected.label)
      : handleInputValueChange('')
  }

  function configureSegments() {
    const segmentsRedux = segments.map((segment) => {
      segment.description = t(`busines-units-${segment.segment.toString()}`)
      return segment
    })

    const setNewSegments = orderData({
      collection: segmentsRedux,
      field: 'segment',
      order: 'asc'
    })

    setSegmentsConfig(setNewSegments)
  }

  useEffect(() => {
    dispatch(fetchSegments())
  }, [])

  useEffect(() => {
    if (props.updateValues) handleInputValueChange('')
  }, [props.updateValues])

  useEffect(() => {
    configureSegments()
  }, [segments])

  return (
    <Select
      handleInputChange={handleInputValueChange}
      onChangeValue={onChangeValue}
      inputSearchValue={inputValue}
      name='segment'
      suggestions={segmentsConfig}
      label={t('segment-label')}
      placeholder={t('segment-placeholder')}
      useAttributesAsValue={['segment']}
      showAttributesAsLabel={['description']}
      onSelect={props.onSelect}
      limitSuggestion={500}
      notifySuggestionNotFound={(notification) => {
        return { label: '', value: '' }
      }}
    />
  )
}
