import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Swel from 'sweetalert2'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import axios from 'axios'

import { validatePinCode } from '../../domain/new-user'

import HeaderCompanyInformation from '../../component/header-new-user'

import PinCodeInput from '../../component/pincode'

import { InformPinCodeContainer } from './style'

export default () => {
  const [pinValues, setPinValues] = useState(['', '', '', '', '', ''])
  const [pinValid, setPinValid] = useState(true)

  const { t } = useTranslation()
  const history = useHistory()

  const handlePinCode = (value, index, values) => setPinValues(values)

  const handleResendPincode = () => {
    const pinCode = pinValues.join('')
    axios
      .post(`${process.env.REACT_APP_INVOICES_API}/new-user/re-send-pin-code`, {
        pinCode
      })
      .then((result) => {
        const { resend } = result.data.body
        if (resend) {
          setPinValues(['', '', '', '', '', ''])
          setPinValid(true)
          return
        } else {
          Swel.fire(
            t('error') + '!',
            t('inform-pin-code-error', { message: 'Erro Send Pin Code' }),
            'error'
          )
        }
      })
      .catch((error) => {
        Swel.fire(
          t('error') + '!',
          t('inform-pin-code-error', { message: error.message }),
          'error'
        )
      })
  }

  useEffect(() => {
    if (pinValues.length <= 6 && !pinValues.includes('')) {
      setPinValid(true)
      const pinSend = pinValues.join('')

      validatePinCode(pinSend)
        .then((r) => {
          const { pinCode, pinCodeExpired } = r.data.body

          if (pinCodeExpired && pinCode) {
            return setPinValid(false)
          }

          setPinValues(['', '', '', '', '', ''])

          Swel.fire(
            t('inform-pin-code-success-validation'),
            '',
            'success'
          ).then((result) => {
            if (result.isConfirmed) {
              history.push('/app/invoices')
            }
          })
        })
        .catch((error) => {
          setPinValues(['', '', '', '', '', ''])
          Swel.fire(
            t('error') + '!',
            t('inform-pin-code-error', { message: error.message }),
            'error'
          )
        })
    }
  }, [pinValues])

  return (
    <InformPinCodeContainer>
      <div className='center-page'>
        <HeaderCompanyInformation>
          <h3>{t('inform-pin-code-company-registration-pin')}</h3>
          <p>{t('inform-pin-code-company-registration-message')}</p>
        </HeaderCompanyInformation>

        <div className='box-pin'>
          <p>{t('inform-pin-code-message-input')}</p>
          <PinCodeInput
            values={pinValues}
            type={'number'}
            mask={false}
            showState={false}
            autoFocus={true}
            autoTab={true}
            placeholder={'0'}
            onChange={handlePinCode}
          />

          {!pinValid && (
            <>
              <p className='pincodeError'>{t('inform-pin-code-expired')}</p>
              <Grid container className='mb-32'>
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    onClick={handleResendPincode}
                    fullWidth
                    variant='contained'
                    color='primary'
                    disabled={false}>
                    {false ? 'await...' : t('re-send-pin-code')}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </div>
    </InformPinCodeContainer>
  )
}
