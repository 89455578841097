import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import Swal from 'sweetalert2'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Pagination from '@material-ui/lab/Pagination'
import Button from '../../component/button'
import { CloudDownload } from '@material-ui/icons'

import { findHistoryEvents, downloadEmails } from '../../domain/historic-emails'

import TooltipHistoricEmails from '../../component/tooltip-historic-emails'

import InputText from '../../component/input'
import ButtonSearch from '../button-search'
import NoData from '../../component/no-data'
import Loading from '../../component/loading'

import {
  Container,
  GridContainer,
  TitlePage,
  FormSearchCustomer,
  SelectUiActivity,
  TableListErrosEmails,
  ContainerPagination
} from './styles'

export default () => {
  const { t } = useTranslation()

  const [activeEvent, setActiveEvent] = useState('all-errors')
  const [openSelect, setOpenSelect] = useState(false)
  const [statusLoading, setStatusLoading] = useState(true)
  const [inputEmailSearch, setInputEmailSearch] = useState('')
  const [inputCodeSearch, setInputCodeSearch] = useState('')
  const [listEvents, setListEvents] = useState([
    { email: '', type: '', customerCode: '', event: '', updatedAt: '' }
  ])
  const [listOrder, setListOrder] = useState('desc')
  const [page, setPage] = useState(1)
  const [totalEvents, setTotalEvents] = useState(1)

  const downloadBlob = (blob) => {
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'email-events.csv'
    a.click()
    a.remove()
  }

  const handleSubmitSearch = async (event) => {
    event.preventDefault()

    setPage(1)
    setListOrder('desc')
    setStatusLoading(true)

    handleFindHistoryEvents({
      inputEmailSearch,
      activeEvent,
      inputCodeSearch,
      page: 1,
      listOrder: 'desc'
    })
  }

  const handleSelectChange = (event) => {
    setActiveEvent(event.target.value)
  }

  const handleSelectOpenClose = () => {
    setOpenSelect(!openSelect)
  }

  const handleTooltipMessage = (event) => t(`historic-emails-${event}`)

  const handleOrderBy = () => {
    listOrder === 'asc' ? setListOrder('desc') : setListOrder('asc')
  }

  const handleFindHistoryEvents = async ({
    inputEmailSearch,
    activeEvent,
    inputCodeSearch,
    page,
    listOrder
  }) => {
    await findHistoryEvents({
      email: inputEmailSearch,
      event: activeEvent,
      code: inputCodeSearch,
      page: page,
      orderBy: listOrder
    }).then((response) => {
      setListEvents(response.data.rows)
      setStatusLoading(false)
      setTotalEvents(response.data.count)
    })
  }

  const handleSelectPage = async ({ event, p }) => {
    event.preventDefault()

    setStatusLoading(true)

    let activeEventSearchValue = activeEvent

    if (activeEvent === 'select') activeEventSearchValue = 'all-errors'

    handleFindHistoryEvents({
      inputEmailSearch,
      activeEvent: activeEventSearchValue,
      inputCodeSearch,
      page: p || page,
      listOrder
    })
  }

  const handleDownload = async () => {
    Swal.queue([
      {
        icon: 'question',
        title: t('customers-setup-historic-download-confirmation'),
        confirmButtonText: t('interest-free-confirm-button'),
        cancelButtonText: t('interest-free-cancel-button'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            let res = await downloadEmails()
            let blob = await res.blob()
            downloadBlob(blob)
            return Swal.insertQueueStep({
              icon: 'success',
              title: t('customers-setup-historic-success'),
              html: t('customers-setup-historic-download-success'),
              timer: 2000
            })
          } catch (err) {
            Swal.insertQueueStep({
              icon: 'error',
              title: t('customers-setup-historic-error'),
              html: t('customers-setup-historic-download-error')
            })
          }
        }
      }
    ])
  }

  useEffect(() => {
    if (!statusLoading) {
      setPage(1)
      setStatusLoading(true)
      setListOrder('desc')

      handleFindHistoryEvents({
        inputEmailSearch,
        activeEvent,
        inputCodeSearch,
        page: 1,
        listOrder: 'desc'
      })
    }
  }, [activeEvent])

  useEffect(() => {
    setPage(1)
    setStatusLoading(true)

    let activeEventSearchValue = activeEvent

    if (activeEvent === 'select') activeEventSearchValue = 'all-errors'

    handleFindHistoryEvents({
      inputEmailSearch,
      activeEvent: activeEventSearchValue,
      inputCodeSearch,
      page: 1,
      listOrder
    })
  }, [listOrder])

  return (
    <>
      <Container>
        <GridContainer container direction='row'>
          <Grid className='title-grid' item xs={12} md={6}>
            <TitlePage style={{ padding: '10px' }}>
              <h3>{t('customer-setup-historic-title')}</h3>
              <small>{`(Showing ${listEvents.length} ${
                listEvents.length > 1 ? 'entries' : 'entry'
              })`}</small>
            </TitlePage>
            <Button
              className='download-button'
              download='sample.csv'
              target='_blank'
              variant='contained'
              size='medium'
              startIcon={<CloudDownload />}
              onClick={handleDownload}>
              {t('customer-setup-historic-download-button')}
            </Button>
          </Grid>
          <Grid item xs={12} md={2} style={{ display: 'flex' }}>
            <SelectUiActivity>
              <FormControl>
                <Select
                  autoWidth
                  open={openSelect}
                  onClose={handleSelectOpenClose}
                  onOpen={handleSelectOpenClose}
                  value={activeEvent}
                  onChange={handleSelectChange}>
                  <MenuItem style={{ fontSize: 16 }} value={'select'} disabled>
                    Select a status
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'all'}>
                    All
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'all-errors'}>
                    Errors
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'bounce'}>
                    Bounces
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'blocked'}>
                    Blocks
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'spamreport'}>
                    Spam Reports
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'dropped'}>
                    Invalid E-mails
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'deferred'}>
                    Deferreds
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'delivered'}>
                    Delivereds
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'open'}>
                    Open
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'click'}>
                    Clicks
                  </MenuItem>
                </Select>
              </FormControl>
            </SelectUiActivity>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormSearchCustomer onSubmit={handleSubmitSearch}>
              <InputText
                type='text'
                placeholder={t('historic-emails-search-email')}
                defaultValue={inputEmailSearch}
                onChange={(input) => setInputEmailSearch(input.target.value)}
              />
            </FormSearchCustomer>
            <FormSearchCustomer onSubmit={handleSubmitSearch}>
              <InputText
                type='text'
                placeholder={t('historic-emails-search-mfir')}
                defaultValue={inputCodeSearch}
                onChange={(input) => setInputCodeSearch(input.target.value)}
              />
              <ButtonSearch type='submit' />
            </FormSearchCustomer>
          </Grid>
          <Container className='clear-container'>
            <Button
              variant='contained'
              className='btn-clear-history'
              onClick={() => {
                setInputCodeSearch('')
                setInputEmailSearch('')
                setPage(1)
                setListOrder('desc')
                setStatusLoading(true)
                let activeEventSearchValue = activeEvent
                if (activeEvent === 'select')
                  activeEventSearchValue = 'all-errors'
                handleFindHistoryEvents({
                  activeEvent: activeEventSearchValue,
                  page: 1,
                  listOrder: 'desc'
                })
              }}>
              Clear
            </Button>
          </Container>
        </GridContainer>
        {listEvents.length > 0 && statusLoading === false ? (
          <>
            <GridContainer container direction='row'>
              <Grid item xs={12}>
                <TableListErrosEmails>
                  <table width='100%' className='table table-customers'>
                    <thead>
                      <tr>
                        <th className='date-label'>
                          {t('historic-emails-table-date')}
                          <TableSortLabel
                            active={true}
                            onClick={handleOrderBy}
                            direction={listOrder}
                          />
                        </th>
                        <th>{t('historic-emails-table-email-type')}</th>
                        <th>EMAIL</th>
                        <th>STATUS</th>
                        <th>MFIR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listEvents.map((error, index) => (
                        <tr key={index}>
                          <td>
                            {moment(error.updatedAt).format(
                              'YYYY-MM-DD, h:mm:ss a'
                            )}
                          </td>
                          <td>{error.type}</td>
                          <td>{error.email}</td>
                          <td>
                            <TooltipHistoricEmails
                              textTooltip={handleTooltipMessage(error.event)}
                            />
                            {error.event}
                          </td>
                          <td>{error.customerCode}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableListErrosEmails>
              </Grid>
            </GridContainer>
            <ContainerPagination>
              <Pagination
                defaultPage={page}
                disabled={totalEvents === 1 ? true : false}
                count={Math.ceil(totalEvents / 10)}
                color='primary'
                onChange={(event, page) => {
                  setPage(page)
                  setStatusLoading(true)
                  handleSelectPage({ event, p: page })
                }}></Pagination>
            </ContainerPagination>
          </>
        ) : null}

        {listEvents.length === 0 && statusLoading === false ? <NoData /> : null}
        {statusLoading === true ? <Loading size='60px' /> : null}
      </Container>
    </>
  )
}
