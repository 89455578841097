import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import styled from 'styled-components'

const PickerDate = styled.div `
  display: block;
    position: relative;
    width: 100%;
    padding: 8px;
`;

const MuiPickersUtilsProviderUi = styled(MuiPickersUtilsProvider) `
`;

const KeyboardTimePickerUi = styled(KeyboardTimePicker) ``;

const KeyboardDatePickerUi = styled(KeyboardDatePicker) `
  width: 100%;
  margin: 0;

  label + .MuiInput-formControl {
    margin-top: 0;
  }

  .MuiFormLabel-root,
  .MuiInputBase-input {
    font-size: initial;
  }

  .MuiInputBase-input { color: #666666 }

  .MuiButtonBase-root .MuiSvgIcon-root {
    font-size: 1.9rem;
  }

  .MuiInputBase-input {
    padding-top: 15px;
    padding-bottom: 14px;
  }
`;

export {
  MuiPickersUtilsProviderUi,
  KeyboardTimePickerUi,
  KeyboardDatePickerUi,
  PickerDate
}