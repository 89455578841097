import styled from 'styled-components'

const ExpansionPanelFooter = styled.div `
  color: #666666;
  padding: 30px 10px 0;

  .i-collapsible-footer-top,
  .i-collapsible-footer-sub {
    display: flex;
    align-items: center;
  }

  .i-collapsible-footer-top {
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .i-label-count-invoices {
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    display: inline-block;
    background-color: #dedede;
    margin-right: 5px;
    border-radius: 50%;
    transition: 200ms;
  }

  .i-label-count-invoices-checked {
    color: #fff;
    background-color: #009898;
  }

  .i-collapsible-footer-sub {
    justify-content: flex-end;
  }

  .i-label-value-total {
    font-size: 1.6rem;
    font-weight: 500;
    display: inline-block;
    padding: 0 15px 0 5px;
  }

  .paynow-warning {
    margin: 0 auto 1.5rem auto;
    text-align: right;
  }
`;

export default ExpansionPanelFooter
