export const getSelectsOnLocalstorage = () => {
  const customerDispute = JSON.parse(localStorage.getItem('@customerDispute'))
  const poNumber = JSON.parse(localStorage.getItem('@poNumber'))
  const number = JSON.parse(localStorage.getItem('@number'))
  const status = JSON.parse(localStorage.getItem('@status'))
  const caseNumber = JSON.parse(localStorage.getItem('@caseNumber'))
  const financialStatus = JSON.parse(localStorage.getItem('@financialStatus'))

  return {
    customerDispute,
    poNumber,
    number,
    status,
    caseNumber,
    financialStatus
  }
}

export const mountSelectsToRedux = ({
  customerDispute,
  poNumber,
  number,
  status,
  caseNumber,
  financialStatus
}) => {
  const selectsStorage = []

  if (customerDispute && customerDispute.value !== '')
    selectsStorage.push({ name: 'customerDispute', selected: customerDispute })

  if (poNumber && poNumber.value !== '')
    selectsStorage.push({ name: 'poNumber', selected: poNumber })

  if (number && number.value !== '')
    selectsStorage.push({ name: 'number', selected: number })

  if (status && status.value !== '')
    selectsStorage.push({ name: 'status', selected: status })

  if (caseNumber && caseNumber.value !== '')
    selectsStorage.push({ name: 'caseNumber', selected: caseNumber })

  if (financialStatus && financialStatus.value !== '') {
    selectsStorage.push({ name: 'financialStatus', selected: financialStatus })
  }

  return selectsStorage
}
