import TooltipMui from '@material-ui/core/Tooltip'
import styled from 'styled-components'

const Tooltip = styled(TooltipMui)`
  vertical-align: sub;

  .MuiSvgIcon-root {
    font-size: 1.9rem;
  }
`
export const TransferTooltip = styled(TooltipMui)`
  vertical-align: middle;

  .MuiSvgIcon-root {
    font-size: 1.9rem;
  }
`

export default Tooltip
