import { AccordionSummary } from '@material-ui/core'
// import ExpansionPanelSummaryMui from '@material-ui/core/ExpansionPanelSummary'
import styled from 'styled-components'

const ExpansionPanelSummary = styled(AccordionSummary) `
  &.MuiButtonBase-root {
    color: #666;
    position: relative;
    padding: 0 15px 0 0;
  }

  .MuiExpansionPanelSummary-content,
  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiSvgIcon-root {
    font-size: 19px;
  }

  .i-collapsible-top {
    min-width: 100%;
    position: relative;
    padding: 2px 0 2px;
    display: flex;
    transition: 200ms;
    flex-wrap: wrap;

    h3 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    & > div:not(:first-child) {
      justify-content: flex-start;

      @media (max-width: 840px) {
        justify-content: initial;
      }

      @media (max-width: 630px) {
        flex-direction: column;
      } 
    }

    & > div {
      display: flex;
      align-items: center;
      width: 13.2%;
      padding: 10px;
      position: relative;

      &:first-child {
        width: 34%;
      }

      @media (max-width: 970px) {
        &:first-child {
          width: 60%;
        }

        &:nth-child(2) {
          width: 40%;
          justify-content: center;
        }

        width: 25%;
      }
      
      .i-collapsible-currency-area {
        flex-grow: initial;
        border-right: 2px solid #F1F1F1;
        border-left: 2px solid #F1F1F1;
  
        @media (max-width: 840px) {
          border-right: none;
          border-left: none;
        }
      }
    }

    .i-collapsible-label {
      @media (max-width: 970px) {
        text-align: center;
        width: 100%;
        display: block;
      }
    }
  }

  @media (max-width: 1131px) {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  }
`;

export default ExpansionPanelSummary