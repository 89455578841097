export const validateToPattern = (validate) => {
  if (Array.isArray(validate)) {
    const regex = new RegExp(validate.join('|')).toString()
    return regex.slice(1, regex.length - 1)
  } else if (typeof validate === 'string') {
    const regex = new RegExp(validate.split('').join('|')).toString()
    return regex.slice(1, regex.length - 1)
  } else if (validate instanceof RegExp) {
    const regex = validate.toString()
    return regex.slice(1, regex.length - 1)
  } else {
    return undefined
  }
}
