import React from 'react'
import Button from '@material-ui/core/Button'
import TableCell from './table-cell'
import { get } from 'lodash'

export default (row) => (props) => {
  const action = (
    <TableCell key={`${row.id}`} align='center'>
      <Button
        className='i-link i-link-text-uppercase'
        onClick={() => props.actionEvent(row)}>
        <i className='i-link-ico material-icons'>{props.actionIcon}</i>
      </Button>
    </TableCell>
  )

  const columns = props.columns.map((column, idx) => (
    <TableCell key={`${idx}`} align='left'>
      {get(row, column.field)}
    </TableCell>
  ))

  if (props.action) return [...columns, action]
  return columns
}
