import React, { useCallback } from 'react'
import Swal from 'sweetalert2'
import { Grid, Button } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'

import { downloadFileAgingReport } from '../../domain/document'

import { FooterDashboardUi } from './styles'

export default () => {
  const handleDownloadFileAgingReport = useCallback(async (source) => {
    try {
      const downloadFile = await downloadFileAgingReport(source)

      const blob = new Blob([
        new Uint8Array(downloadFile.data.body.file.Body.data).buffer
      ])
      const fileName = downloadFile.data.body.info.file
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName)
      } else {
        const link = document.createElement('a')
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    } catch (error) {
      Swal.fire(
        'Atention!',
        'Document not found.',
        'error'
      )
    }
  }, [])

  return (
    <FooterDashboardUi container>
      <Grid item xs={12}>
        <div className='botao-download-file-aging'>
          <Button
            style={{ marginTop: 24 }}
            variant='contained'
            color='primary'
            size='large'
            startIcon={<CloudDownload />}
            onClick={() => handleDownloadFileAgingReport('SAP')}>
            DOWNLOAD FILE AR FINANCE REPORT - SAP
          </Button>
          {/* <Button
            style={{ marginTop: 24 }}
            variant='contained'
            color='primary'
            size='large'
            startIcon={<CloudDownload />}
            onClick={() => handleDownloadFileAgingReport('QUANTUM')}>
            DOWNLOAD FILE AR FINANCE REPORT - QUANTUM
          </Button> */}
        </div>
      </Grid>
    </FooterDashboardUi>
  )
}
