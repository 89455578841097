import { list } from '../../../domain/account/customer'

const GET_CUSTOMERS = 'GET_CUSTOMERS'
const getCustomers = (customers) => ({
  type: GET_CUSTOMERS,
  customers
})

const SET_CUSTOMER_SELECTED = 'SET_CUSTOMER_SELECTED'
const setCustomerSelected = (customer) => {
  return {
    type: SET_CUSTOMER_SELECTED,
    customer
  }
}

const fetchCustomers = (query) => (dispatch) =>
  list(query).then((customers) => dispatch(getCustomers(customers.data)))

const GET_CUSTOMERS_NOT_FOUND = 'GET_CUSTOMERS_NOT_FOUND'
const getCustomersNotFound = (customers) => ({
  type: GET_CUSTOMERS_NOT_FOUND,
  customers
})

const fetchCustomersNotFound = (query) => (dispatch) =>
  list(query).then((customers) => {
    if (customers.data.length) dispatch(getCustomersNotFound(customers.data))
  })

export {
  GET_CUSTOMERS,
  GET_CUSTOMERS_NOT_FOUND,
  SET_CUSTOMER_SELECTED,
  fetchCustomers,
  fetchCustomersNotFound,
  setCustomerSelected
}
