import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import ButtonSearch from '../button-search'
import { WrapTextField } from '../../component/textField-invoices/textFieldStyle'
import InputSearch from '../../component/textField'
import { applyCriteriaFilter } from '../document-filter/__redux__/actions'
import { fetchCompanies } from '../company-documents/__redux__/actions'
import { closeSearch } from './__redux__/actions'

const getStatus = (state) => get(state, 'getFilters.status', [])
const getSelects = (state) => get(state, 'getFilters.selects', [])
const getCriteria = (state) => get(state, 'getFilters.criteria')
var typingTimer
var doneTypingInterval = 1000

export default function SearchFilter({ isMobile, inputSearch, setInputSearch }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const status = useSelector(getStatus)
  const selects = useSelector(getSelects)
  const initValue = useSelector(getCriteria)

  const verificaLocalStorageInputSearch = () => {
    const inputSearchValueStorage =
      localStorage.getItem('@inputSearch') || initValue
    if (inputSearchValueStorage) {
      setInputSearch(inputSearchValueStorage)
      dispatch(applyCriteriaFilter(inputSearchValueStorage))
    }
  }

  const handleChangeInputSearch = ({ target: { value } }) => {
    setInputSearch(value)
    clearTimeout(typingTimer)
    typingTimer = setTimeout(() => {
      dispatch(applyCriteriaFilter(value))
      dispatch(fetchCompanies({ status, selects, criteria: value }))
    }, doneTypingInterval)
  }

  useEffect(() => {
    verificaLocalStorageInputSearch()
    localStorage.removeItem('@inputSearch')
  }, [])

  return (
    <WrapTextField>
      <InputSearch
        name='input-search'
        label={t('input-search-label')}
        placeholder={t('input-search-placeholder')}
        onChange={handleChangeInputSearch}
        value={inputSearch}
      />
      <ButtonSearch
        onClick={() => {
          if (isMobile) dispatch(closeSearch())
          dispatch(applyCriteriaFilter(inputSearch))
          dispatch(fetchCompanies({ status, selects, criteria: inputSearch }))
        }}
      />
    </WrapTextField>
  )
}
