import { findAggByMaxAndMinReferenceDate } from '../../../domain/document/'

const GET_REFERENCE_DATE = 'GET_REFERENCE_DATE'
const getReferenceDate = (referenceDate) => ({
  type: GET_REFERENCE_DATE,
  referenceDate
})

const fetchReferenceDate = (query) => (dispatch) =>
  findAggByMaxAndMinReferenceDate(query).then((referenceDate) =>
    dispatch(getReferenceDate(referenceDate))
  )

const SET_DATE_FILTER = 'SET_DATE_FILTER'
const setReferenceDateFilter = (dateFilter) => ({
  type: SET_DATE_FILTER,
  dateFilter
})

export {
  GET_REFERENCE_DATE,
  fetchReferenceDate,
  setReferenceDateFilter,
  SET_DATE_FILTER
}
