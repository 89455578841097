import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import moment from 'moment'

import Filter from '../fixed-filter'
import CustomerSelect from '../customer-list'
import { createReport } from '../../domain/report'
import { resetSelectsSearch } from '../document-filter/__redux__/actions'

const getCustomers = (state) => get(state, 'getCustomers.customers', [])

export default ({ setReports, reports }) => {
  const { t } = useTranslation()
  const customers = useSelector(getCustomers)
  const [disabled, setDisabled] = useState(false)
  const dispatch = useDispatch()

  const handleReport = useCallback(
    async (search) => {
      if (search.length > 0 && search[0].selected.value !== '') {
        const id = search[0].selected.value
        const customer = customers.find((customer) => customer.code === id)
        const report = {
          id:
            customer.code +
            '_OPEN_' +
            moment().utc().format('YYYY_MM_DDTHH_mm_ss'),
          processStatus: 'processing',
          status: 'OPEN',
          customerName: customer.name,
          customer: customer.code,
          createdAt: moment().format()
        }
        setDisabled(true)
        try {
          if (customer) {
            const reportProcessing = [...reports, report]

            setReports(reportProcessing)

            const r = await createReport(report)
            
            if (r) {
              setReports([...reports, r.data])
              setDisabled(false)
              clearFilterInvoices()
            }
          }
        } catch (err) {
          setReports([
            ...reports,
            {
              ...report,
              status: 'error',
              processStatus: 'failed',
              message: get(err, 'response.data.error.error', err.message)
            }
          ])
          setDisabled(false)
          clearFilterInvoices()
        }
      }
    },
    [customers, reports]
  )

  const clearFilterInvoices = useCallback(() => {
    dispatch(resetSelectsSearch())
  }, [])

  return (
    <Filter
      buttonName='btn-report'
      filterFooter='drop-filter-footer-report'
      menuFilter='dashboard-filter'
      typeScreen='filterReport'
      interceptSelectedValue={true}
      disabled={disabled}
      applyTitle={t('generate')}
      buttonTitleCustomerFilter={t('customer')}
      onChangeFilter={handleReport}>
      <CustomerSelect />
    </Filter>
  )
}

