import React from 'react'
import { TextFieldUi } from './styles'

export default ({ defaultValue, onChange, label, variant, ...res }) => {
  return (
    <TextFieldUi
      {...res}
      variant={variant}
      label={label}
      value={defaultValue}
      onChange={onChange}
    />
  )
}
