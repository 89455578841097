import React from 'react'
import { Table } from '../../component/table'
import TableHeadRep from './table-head'

import TableBodyRep from './table-body'
import { deleteById } from '../../domain/report'
import NoData from '../../component/no-data'

export default ({ reports, setReports }) => {
  const deleteReportById = (id) => {
    deleteById(id).catch((err) => {
      setReports(reports.filter((report) => report.id !== id))
    })
  }

  return reports.length > 0 ? (
    <Table report='true'>
      <TableHeadRep />
      <TableBodyRep reports={reports} deleteReportById={deleteReportById} />
    </Table>
  ) : (
    <NoData report='true' />
  )
}
