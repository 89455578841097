import styled from 'styled-components'

export default styled.div`
  .portal-origin {
    margin-bottom: 30px;

    &-title {
      font-size: 1.5em;
      font-weight: bold;
      padding: 5px 0;
      color: rgb(16, 6, 144);
    }
  }

  .portal-origin-label {
    span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
      font-size: 1.5rem;
      font-weight: bold;
      color: #666666;
    }
  }
`
