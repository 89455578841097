import React, { useState, useEffect } from 'react'

import toFirstUppercase from '../../helpers/to-first-uppercase'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FormLabel from '@material-ui/core/FormLabel'
import CustomerTypeUi from './style'
import { get, toLower } from 'lodash'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import {
  applyChangeCustomerTypeFilter,
  fetchSelects
} from './__redux__/actions'
import { onSelect } from '../document-fixed-filter/__redux__/invoices-filter/actions'

const getFilter = (state) => get(state, 'invoicesFilters')
const getLoading = (state) => get(state, 'getFilters.loading')
const getList = (state) => get(state, 'customerType.list')
const getCustomerType = (state) => {
  return get(state, 'customerType.selected.value')
}

export default () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const customerTypes = useSelector(getList)
  const [openSelect, setOpenSelect] = useState(false)
  const activeCustomerType = useSelector(getCustomerType)
  const loading = useSelector(getLoading)

  const filters = useSelector(getFilter)

  const handleSelectChange = ({ target: { value } }) => {
    dispatch(
      applyChangeCustomerTypeFilter({ label: toFirstUppercase(value), value })
    )
    dispatch(
      onSelect({
        name: 'customerType',
        selected: { value: toLower(value) }
      })
    )
  }

  const handleSelectOpenClose = () => {
    setOpenSelect(!openSelect)
  }

  useEffect(() => {
    localStorage.removeItem('@customerType')
  }, [])

  function syncSelectOptions(selected) {
    let params = {}
    selected.forEach((s) => {
      if (s.name && s.name !== 'customerType') params[s.name] = s.selected.value
    })

    return dispatch(fetchSelects(params))
  }

  useEffect(() => {
    if (!loading) syncSelectOptions(filters.selected)
  }, [filters.selected])

  return (
    <CustomerTypeUi>
      <div className='label-position'>
        <FormLabel>{t('filter-by')}</FormLabel>
      </div>
      <FormControl>
        <Select
          autoWidth
          open={openSelect}
          onClose={handleSelectOpenClose}
          onOpen={handleSelectOpenClose}
          value={activeCustomerType}
          onChange={handleSelectChange}>
          {customerTypes.map((type) => (
            <MenuItem style={{ fontSize: 16 }} key={type} value={type}>
              {toFirstUppercase(type)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </CustomerTypeUi>
  )
}
