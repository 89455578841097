import { getBearerAuthorization } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/reports`

export default async (user = '') =>
  await axios.get(`${url}/by-user/${user}`, {
    headers: getBearerAuthorization()
  })
