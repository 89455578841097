import React from 'react'
import renderHTML from 'react-render-html'
import Tooltip from '../tooltip'

export default (props) => {
  return (
    <Tooltip
      textTooltip={renderHTML(props.textTooltip.toString())}
      color='#ffffff'
      position='top'
      backgroundColor='rgba(0,0,0,0.75)'>
      <span>
        <i className='material-icons i-ico-help-color'>help</i>
      </span>
    </Tooltip>
  )
}
