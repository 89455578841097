import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from '../../component/select'
import { fetchLeadersDash } from './__redux__/actions'
import { orderData } from '../../domain/document/'
import { get } from 'lodash'

const getLeadersDash = (state) => get(state, 'getLeadersDash.leadersDash', [])

export default (props) => {
  const { t } = useTranslation()
  const leadersDash = useSelector(getLeadersDash)

  const dispatch = useDispatch()
  const orderedLeadersDash = orderData({
    collection: leadersDash,
    field: 'leaderAccountManagerName',
    order: 'asc'
  })

  const [inputValue, setInputValue] = useState('')

  function handleInputValueChange(value) {
    setInputValue(value)
  }

  function onChangeValue(selected, name) {
    if (name) props.onSelect({ name, selected: selected || '' }, props.filters)

    selected && selected.value !== ''
      ? handleInputValueChange(selected.label)
      : handleInputValueChange('')
  }

  useEffect(() => {
    dispatch(fetchLeadersDash())
  }, [])

  useEffect(() => {
    if (props.updateValues) handleInputValueChange('')
  }, [props.updateValues])

  return (
    <Select
      handleInputChange={handleInputValueChange}
      onChangeValue={onChangeValue}
      inputSearchValue={inputValue}
      name='leaderAccountManagerLogin'
      suggestions={orderedLeadersDash}
      label={t('leader-cam-dash-label')}
      placeholder={t('leader-cam-dash-placeholder')}
      useAttributesAsValue={['leaderAccountManagerLogin']}
      showAttributesAsLabel={['leaderAccountManagerName']}
      onSelect={props.onSelect}
      limitSuggestion={500}
      notifySuggestionNotFound={(notification) => {
        return { label: '', value: '' }
      }}
    />
  )
}
