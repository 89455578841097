import {
  UPDATE_FULL_NAME,
  UPDATE_PHONE_NUMBER,
  UPDATE_COMPANY_NAME,
  UPDATE_ADDRESS,
  UPDATE_CITY,
  UPDATE_STATE_PROVINCE,
  UPDATE_COUNTRY,
  UPDATE_ERROR_FULL_NAME,
  UPDATE_ERROR_COMPANY_NAME,
  UPDATE_ERROR_ADDRESS,
  UPDATE_ERROR_CITY,
  UPDATE_ERROR_STATE_PROVINCE,
  UPDATE_ERROR_COUNTRY,
  REGISTRATION_EMAIL
} from './actions'

const initialState = {
  fullName: '',
  phoneNumber: '',
  companyName: '',
  address: '',
  city: '',
  stateProvince: '',
  country: '',
  errorFullName: false,
  errorCompanyName: false,
  errorAddress: false,
  errorCity: false,
  errorStateProvince: false,
  errorCountry: false,
  registrationEmail: ''
}

export const registerNewUser = (state = initialState, action) => {
  const actions = {
    [UPDATE_FULL_NAME]: {
      ...state,
      fullName: action.fullName
    },
    [UPDATE_PHONE_NUMBER]: {
      ...state,
      phoneNumber: action.phoneNumber
    },
    [UPDATE_COMPANY_NAME]: {
      ...state,
      companyName: action.companyName
    },
    [UPDATE_ADDRESS]: {
      ...state,
      address: action.address
    },
    [UPDATE_CITY]: {
      ...state,
      city: action.city
    },
    [UPDATE_STATE_PROVINCE]: {
      ...state,
      stateProvince: action.stateProvince
    },
    [UPDATE_COUNTRY]: {
      ...state,
      country: action.country
    },
    [UPDATE_ERROR_FULL_NAME]: {
      ...state,
      errorFullName: action.errorFullName
    },
    [UPDATE_ERROR_COMPANY_NAME]: {
      ...state,
      errorCompanyName: action.errorCompanyName
    },
    [UPDATE_ERROR_ADDRESS]: {
      ...state,
      errorAddress: action.errorAddress
    },
    [UPDATE_ERROR_CITY]: {
      ...state,
      errorCity: action.errorCity
    },
    [UPDATE_ERROR_STATE_PROVINCE]: {
      ...state,
      errorStateProvince: action.errorStateProvince
    },
    [UPDATE_ERROR_COUNTRY]: {
      ...state,
      errorCountry: action.errorCountry
    },
    [REGISTRATION_EMAIL]: {
      ...state,
      registrationEmail: action.registrationEmail
    }
  }

  return actions[action.type] || state
}
