import {
  ON_RESET_FIELD,
  ON_SELECT,
  RESET_SELECTS,
  SET_SELECTS
} from './actions'
import onSelect, { onResetField } from './handler-click'

const initialState = { selected: [] }

export default (state = initialState, action) => {
  if (action.type === ON_SELECT)
    return { ...state, selected: onSelect(state.selected)(action.selected) }
  if (action.type === RESET_SELECTS) return { ...state, selected: [] }
  if (action.type === SET_SELECTS)
    return { ...state, selected: [...action.selected] }
  if (action.type === ON_RESET_FIELD) {
    return { ...state, selected: onResetField(state.selected)(action.selected) }
  }
  return state
}
