import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { Tab } from '../tabs'
import tabs from './tabs.json'
import { applyStatusFilter } from '../document-filter/__redux__/actions'
import { fetchCompanies } from '../company-documents/__redux__/actions'

const getTab = (index) => tabs.find((tab) => tab.index === index)
const getSelects = (state) => get(state, 'getFilters.selects', [])
const getCriteria = (state) => get(state, 'getFilters.criteria')

export default (props) => {
  const dispatch = useDispatch()
  const selects = useSelector(getSelects)
  const criteria = useSelector(getCriteria)

  return (
    <Tab
      onChange={(index) => {
        if (props.activeChange) {
          const tabIndex = getTab(index)
          if(typeof tabIndex !== 'undefined') {
            dispatch(applyStatusFilter(tabIndex.label))
            dispatch(fetchCompanies({ selects, status: tabIndex.label, criteria }))
          }
        }
      }}>
      {props.children}
    </Tab>
  )
}
