import TablePaginationMui from '@material-ui/core/TablePagination'
import styled from 'styled-components'

const TablePagination = styled(TablePaginationMui) `
  color: #666666;
  font-size: 1.2rem;

  .MuiTypography-caption {
    font-size: inherit;
  }

  .MuiSvgIcon-root {
    font-size: 1.9rem;
  }
`;

export default TablePagination