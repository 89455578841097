import { createStore, compose, applyMiddleware } from 'redux'
import rootReducer from '../reducers'
import ReduxThunk from 'redux-thunk'

const composeEnhancers =
  process.env.REACT_APP_AMBIENTE === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25
      })
    : compose

export default (initialState = {}) =>
  composeEnhancers(applyMiddleware(ReduxThunk))(createStore)(
    rootReducer,
    initialState
  )
