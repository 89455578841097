const config = {
  graphs: [
    {
      balloonText:
        '<b>[[title]]</b><br><span>[[category]]: <b>[[value]] USD</b></span>',
      fillAlphas: 1,
      labelText: '[[value]]',
      lineAlpha: 0.3,
      title: 'Overdue',
      type: 'column',
      color: '#A9A9A9',
      valueField: 'overdue'
    },
    {
      balloonText:
        '<b>[[title]]</b><br><span>[[category]]: <b>[[value]] USD</b></span>',
      fillAlphas: 1,
      labelText: '[[value]]',
      lineAlpha: 0.3,
      title: 'Credit',
      type: 'column',
      valueField: 'credit',
      hidden: true
    },
    {
      balloonText:
        '<b>[[title]]</b><br><span>[[category]]: <b>[[value]] USD</b></span>',
      fillAlphas: 1,
      labelText: '[[value]]',
      lineAlpha: 0.3,
      title: 'Dispute',
      type: 'column',
      valueField: 'dispute',
      hidden: true
    },
    {
      balloonText:
        '<b>[[title]]</b><br><span>[[category]]: <b>[[value]] USD</b></span>',
      fillAlphas: 1,
      labelText: '[[value]]',
      lineAlpha: 0.3,
      title: 'Not Overdue',
      type: 'column',
      valueField: 'notOverdue',
      hidden: true
    }
  ],
  categoryField: 'segment'
}

export { config }
