import { AccordionDetails } from '@material-ui/core'
// import ExpansionPanelDetailsMui from '@material-ui/core/ExpansionPanelDetails'
import styled from 'styled-components'

const ExpansionPanelDetails = styled(AccordionDetails)`
  padding: 20px 10px;
  background-color: rgb(241, 243, 244);

  @media (max-width: 1244px) {
    padding: 20px 0;
  }

  @media (max-width: 1131px) {
    padding: 0 0 10px 0;
    border: none;
  }

  .table-dados-bancarios {
    border-collapse: collapse;
    border-spacing: 0;

    tbody {
      tr {
        td {
          border-bottom: 1px solid rgb(204, 196, 196);
        }

        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
`

export default ExpansionPanelDetails
