import styled, { css } from 'styled-components'

export const Loading = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: ${(props) => props.size};

    img {
        width: ${(props) => props.size};
    }

    ${(props) =>
      props.report &&
      css`
        height: 25px;
        img {
          width: 45px;
        }
      `}

    ${(props) =>
      props.downloadSmall &&
      css`
        height: 20px;
        img {
          width: 20px;
        }
      `}
        ${(props) =>
          props.downloadBig &&
          css`
            display: inline-block;

            height: 46px;
            img {
              width: 40px;
            }
          `}
`
