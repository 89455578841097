import onSelect, { onResetField } from './handler-click'

export default (state, action) => {
  if (action.type === 'handleClick')
    return { ...state, anchorEl: action.anchorEl }
  if (action.type === 'onSelect')
    return { ...state, selected: onSelect(state.selected)(action.selected) }
  if (action.type === 'onChange') return { ...state, search: action.search }
  if (action.type === 'onApply')
    return {
      ...state,
      page: action.page,
      rowsPerPage: +action.event.target.value
    }
  if (action.type === 'resetSelect') return { ...state, selected: [] }
  if (action.type === 'onResetField') {
    return { ...state, selected: onResetField(state.selected)(action.selected) }
  }
  return state
}
