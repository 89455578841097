const customStyle = {
  overrides: {
    MuiPickersToolbar: {toolbar: { backgroundColor: "#008989" }},
    MuiPickersDay: {
      current: { color: "#008989" },
      daySelected: { 
        backgroundColor: "#008989",
        "&:hover": { backgroundColor: "#008989" }
      }
    },
    MuiSvgIcon: {root: { fontSize: "1.9rem" }},
    MuiTypography: {
      subtitle1: { fontSize: "1.4rem" },
      body1: { fontSize: "1.4rem" },
      body2: { fontSize: "1.4rem" },
      caption: { fontSize: "1.4rem" }
    },
    MuiButton: {
      root: { 
        fontSize: "1.4rem" 
      },
      textPrimary: { color: "#008989" }
    }
  }
}

export { customStyle }