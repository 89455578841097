import { getBearerAuthorization } from '../auth'
import axios from "axios"

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/documents`

export default async (data) => {
  return await axios
    .post(`${url}/checkout`, data,  {
      headers: getBearerAuthorization()
    })
}
