const UPDATE_FULL_NAME = 'UPDATE_FULL_NAME'
const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER'
const UPDATE_COMPANY_NAME = 'UPDATE_COMPANY_NAME'
const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
const UPDATE_CITY = 'UPDATE_CITY'
const UPDATE_STATE_PROVINCE = 'UPDATE_STATE_PROVINCE'
const UPDATE_COUNTRY = 'UPDATE_COUNTRY'
const UPDATE_ERROR_FULL_NAME = 'UPDATE_ERROR_FULL_NAME'
const UPDATE_ERROR_COMPANY_NAME = 'UPDATE_ERROR_COMPANY_NAME'
const UPDATE_ERROR_ADDRESS = 'UPDATE_ERROR_ADDRESS'
const UPDATE_ERROR_CITY = 'UPDATE_ERROR_CITY'
const UPDATE_ERROR_STATE_PROVINCE = 'UPDATE_ERROR_STATE_PROVINCE'
const UPDATE_ERROR_COUNTRY = 'UPDATE_ERROR_COUNTRY'
const REGISTRATION_EMAIL = 'REGISTRATION_EMAIL'

const setFullName = (fullName) => {
  return {
    type: UPDATE_FULL_NAME,
    fullName
  }
}

const setCompanyName = (companyName) => {
  return {
    type: UPDATE_COMPANY_NAME,
    companyName
  }
}

const setAddress = (address) => {
  return {
    type: UPDATE_ADDRESS,
    address
  }
}

const setCity = (city) => {
  return {
    type: UPDATE_CITY,
    city
  }
}

const setStateProvince = (stateProvince) => {
  return {
    type: UPDATE_STATE_PROVINCE,
    stateProvince
  }
}

const setCountry = (country) => {
  return {
    type: UPDATE_COUNTRY,
    country
  }
}

const setPhoneNumber = (phoneNumber) => {
  return {
    type: UPDATE_PHONE_NUMBER,
    phoneNumber
  }
}

const setErrorFullName = (errorFullName) => {
  return {
    type: UPDATE_ERROR_FULL_NAME,
    errorFullName
  }
}

const setErrorCompanyName = (errorCompanyName) => {
  return {
    type: UPDATE_ERROR_COMPANY_NAME,
    errorCompanyName
  }
}

const setErrorAddress = (errorAddress) => {
  return {
    type: UPDATE_ERROR_ADDRESS,
    errorAddress
  }
}

const setErrorCity = (errorCity) => {
  return {
    type: UPDATE_ERROR_CITY,
    errorCity
  }
}

const setErrorStateProvince = (errorStateProvince) => {
  return {
    type: UPDATE_ERROR_STATE_PROVINCE,
    errorStateProvince
  }
}

const setErrorCountry = (errorCountry) => {
  return {
    type: UPDATE_ERROR_COUNTRY,
    errorCountry
  }
}

const setRegistrationEmail = (registrationEmail) => {
  return {
    type: REGISTRATION_EMAIL,
    registrationEmail
  }
}

export {
  setFullName,
  setCompanyName,
  setAddress,
  setErrorFullName,
  setCity,
  setStateProvince,
  setPhoneNumber,
  setCountry,
  setErrorCompanyName,
  setErrorAddress,
  setErrorCity,
  setErrorStateProvince,
  setErrorCountry,
  setRegistrationEmail,
  UPDATE_FULL_NAME,
  UPDATE_COMPANY_NAME,
  UPDATE_ADDRESS,
  UPDATE_ERROR_FULL_NAME,
  UPDATE_CITY,
  UPDATE_STATE_PROVINCE,
  UPDATE_COUNTRY,
  UPDATE_PHONE_NUMBER,
  UPDATE_ERROR_COMPANY_NAME,
  UPDATE_ERROR_ADDRESS,
  UPDATE_ERROR_CITY,
  UPDATE_ERROR_STATE_PROVINCE,
  UPDATE_ERROR_COUNTRY,
  REGISTRATION_EMAIL
}
