import React from 'react'
import { orderBy } from 'lodash'
import numeral from 'numeral'
import moment from 'moment'

import isSelected from './is-selected'
import getCellStyles from './get-cell-styles'
import getOverdueClass from './get-overdue-class'
import PdfCell from './pdf-cell'
import paidInCell from './paid-in-cell'
import customerCodeCell from './customer-code-cell'
import transactionNumberCell from './transaction-number-cell'
import clearingNumberCell from './clearing-number-cell'
import showCheckbox from './show-checkbox'

import TableBodyMUI from '../../../component/table/table-body'
import TableCell from '../../../component/table/table-cell'
import TableRow from '../../../component/table/table-row'

const TableBody = (props) => {
  return (
    <TableBodyMUI>
      {orderBy(props.rows, props.orderBy, props.order).map((row, index) => {
        const isItemSelected = isSelected(row.id)(props.selected)
        const labelId = `enhanced-table-checkbox-${index}`
        const canSelect = props.hasCustomerSelected

        return (
          <TableRow
            hover
            onClick={() => {
              props.onClick(row.id, canSelect)
              props.addRow(row, canSelect)
            }}
            role='checkbox'
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            className={getCellStyles(row, props.isClosed)}>
            {customerCodeCell({
              row,
              labelId,
              user: props.user,
              isClosed: props.isClosed
            })}
            <TableCell
              align='left'
              data-label-thead='Invoice'
              className={getCellStyles(row, props.isClosed)}>
              {row.number}
            </TableCell>
            <TableCell align='left' data-label-thead='Po Number'>
              {row.purchaseOrderNumber}
            </TableCell>
            <TableCell align='left' data-label-thead='Issued Date'>
              {row.issuedDate
                ? moment(row.issuedDate).format('YYYY-MM-DD')
                : ''}
            </TableCell>
            <TableCell
              align='left'
              data-label-thead='End Date'
              className={getOverdueClass(row, props.isClosed)}>
              {row.endDate ? moment(row.endDate).format('YYYY-MM-DD') : ''}
            </TableCell>
            {paidInCell(row, props.isClosed)}
            {transactionNumberCell(row, props.isClosed)}
            <PdfCell row={row} />
            <TableCell align='left' data-label-thead='Total'>
              {numeral(row.totalAmount).format('0,0.00')}
            </TableCell>
            {clearingNumberCell(row, props.isClosed)}
            <TableCell padding='checkbox' style={{ textAlign: 'right' }}>
              {showCheckbox({
                isItemSelected,
                labelId,
                canSelect,
                status: props.status
              })}
            </TableCell>
          </TableRow>
        )
      })}
    </TableBodyMUI>
  )
}

export default TableBody
