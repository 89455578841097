export const CHANGE_INPUT_SEARCH = 'CHANGE_INPUT_SEARCH'
export const RESET_INPUT_SEARCH = 'RESET_INPUT_SEARCH'
export const CLOSE_SEARCH = 'CLOSE_SEARCH'
export const INVERSE_MOBILE_SEARCH = 'INVERSE_MOBILE_SEARCH'

export const changeInputSearch = (textFilter) => ({
  type: CHANGE_INPUT_SEARCH,
  textFilter
})

export const resetInputSearch = () => ({
  type: RESET_INPUT_SEARCH
})

export const closeSearch = () => ({ type: CLOSE_SEARCH })
export const inverseMobileSearch = () => ({ type: INVERSE_MOBILE_SEARCH })
