import { CLOSE_MODAL, INVERSE_STATE_MODAL } from './actions'

const INITIAL_STATE = {
  open: false
}

export const getContainerFilter = (state = INITIAL_STATE, action) => {
  const actions = {
    [CLOSE_MODAL]: { ...state, open: false },
    [INVERSE_STATE_MODAL]: { ...state, open: !state.open }
  }
  return actions[action.type] || state
}
