import React, { Fragment, useEffect, useState } from 'react'
import Dialog from '../../../component/dialog'
import Loading from '../../../component/loading'
import { downloadAttachment } from '../../../domain/dispute'
import PdfViewer from '../../../component/pdf-viewer'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'

export default (props) => {
  const { t } = useTranslation()

  const [loadingStatus, setLoadingStatus] = useState(true)
  const [images, setImages] = useState([])

  SwiperCore.use([Navigation, Pagination])

  const handleClose = () => {
    props.setIsDialogOpen(!props.isDialogOpen)
  }

  const downloadAttachments = async () => {
    let files = []
    if (props.attachments) {
      for await (let attachment of props.attachments) {
        const file = await downloadAttachment(attachment.path)

        if (attachment.type === 'application/pdf') {
          const blob = new Blob(
            [new Uint8Array(file.data.body.file.data).buffer],
            {
              type: 'application/pdf'
            }
          )

          const url = window.URL.createObjectURL(blob)

          files.push({ pdf: url })
        } else {
          const blob = new Blob([
            new Uint8Array(file.data.body.file.data).buffer
          ])

          const url = URL.createObjectURL(blob)

          files.push({ original: url })
        }
      }

      setImages(files)
      setLoadingStatus(!loadingStatus)
    }
  }

  useEffect(() => {
    downloadAttachments()
  }, [])

  return (
    <Fragment>
      <Dialog
        open={props.isDialogOpen}
        labelClose
        onClose={handleClose}
        label={<h1>{t('dispute-attachments-label')}</h1>}
        maxWidth='lg'
        fullWidth
        style={{ zIndex: '500' }}>
        {loadingStatus ? (
          <Loading />
        ) : (
          <Swiper
            spaceBetween={10}
            pagination={{ clickable: true }}
            navigation={true}
            modules={[Pagination, Navigation]}
            slidesPerView={1}
            loop={true}>
            {images.map((image, index) => (
              <SwiperSlide
                key={index}
                style={{
                  padding: '20px',
                  display: image.original ? 'flex' : '',
                  justifyContent: 'center'
                }}>
                <div style={{ padding: '5px' }}>
                  {image.original ? (
                    <img
                      src={image.original}
                      alt={''}
                      style={{ maxWidth: '1200px' }}
                    />
                  ) : (
                    <PdfViewer source={`${image.pdf}`} />
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Dialog>
    </Fragment>
  )
}
