import React, { useReducer } from 'react'
import { Table as TableUI } from '../../component/table'
import TableBody from './body'
import useStyles from './style'
import reducer from './reducer'
import TableHeader from './header'
import TableFooter from './footer'
import { get } from 'lodash'

const initialState = {
  order: 'desc',
  orderBy: 'issuedDate',
  selected: [],
  page: 0,
  rowsPerPage: 50
}

function Table(props) {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)

  const rows = get(props, 'documents', [])
  const user = get(props, 'user')

  const updateOnClick = (id, isSelectable) =>
    isSelectable ? dispatch({ type: 'onClick', id }) : ''

  const updateRowsInvoices = (row, isSelectable) =>
    isSelectable ? dispatch({ type: 'addRow', row }) : ''

  const resetSelectAll = () => dispatch({ type: 'resetAll' })

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <TableUI
          className={`${classes.table} i-table-invoices`}
          aria-labelledby='tableTitle'>
          <TableHeader
            numSelected={state.selected.length}
            order={state.order}
            orderBy={state.orderBy}
            onSelectAllClick={(event) =>
              dispatch({ type: 'selectAll', event, rows })
            }
            onRequestSort={(event, property) =>
              dispatch({ type: 'sort', property })
            }
            rowsPerPage={props.pageSize}
            rowCount={get(props, 'count', 0)}
            rowDisplaySize={get(props, 'documents', []).length}
            id={props.id}
            user={user}
            statusCheckbox={state.statusCheckall}
          />

          <TableBody
            {...state}
            rows={props.documents}
            user={user}
            onClick={updateOnClick}
            addRow={updateRowsInvoices}
          />
        </TableUI>

        <TableFooter
          {...state}
          classes
          onChangePage={(event, page) => dispatch({ type: 'setPage', page })}
          onChangeRowsPerPage={(event) =>
            dispatch({ type: 'changeRowsPerPage', page: 0, event })
          }
          rowsPerPage={props.pageSize}
          rowCount={get(props, 'count', 0)}
          page={props.page}
          onShowMore={props.onShowMore}
          onShowAll={props.onShowAll}
          salesOrganization={props.salesOrganization}
          currency={props.currency}
          resetSelectAll={resetSelectAll}
          totalDocuments={props.count}
        />
      </div>
    </div>
  )
}

export default Table
