import { findAggByWeek } from '../../../domain/document/'

const GET_FINANCE_WEEKLY_POSITION = 'GET_FINANCE_WEEKLY_POSITION'
const getFinanceWeeklyPosition = (financeWeeklyPosition) => ({
  type: GET_FINANCE_WEEKLY_POSITION,
  financeWeeklyPosition
})

const fetchFinanceWeeklyPosition = (query) => (dispatch) =>
  findAggByWeek(query).then((financeWeeklyPosition) =>
    dispatch(getFinanceWeeklyPosition(financeWeeklyPosition))
  )

export { GET_FINANCE_WEEKLY_POSITION, fetchFinanceWeeklyPosition }
