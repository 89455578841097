import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Select from '../../component/select'
import { fetchViewTypes } from './__redux__/actions'
import { orderData } from '../../domain/document/'
import { get } from 'lodash'

const getViewTypes = (state) => get(state, 'getViewTypes.viewTypes', [])

export default (props) => {
  const { t } = useTranslation()
  const viewTypes = useSelector(getViewTypes)
  const dispatch = useDispatch()
  const orderedViewTypes = orderData({
    collection: viewTypes,
    field: 'name',
    order: 'asc'
  })
  orderedViewTypes.unshift({
    value: t('view-type-select-all'),
    name: t('view-type-select-all'),
    id: '0'
  })
  const [inputValue, setInputValue] = useState('')

  function handleInputValueChange(value) {
    setInputValue(value)
  }

  function onChangeValue(selected, name) {
    if (name) props.onSelect({ name, selected: selected || '' }, props.filters)

    if (selected !== null && selected.label === t('view-type-select-all'))
      handleInputValueChange('')

    selected && selected.value !== ''
      ? handleInputValueChange(selected.label)
      : handleInputValueChange('')
  }

  useEffect(() => {
    dispatch(fetchViewTypes())
  }, [])

  useEffect(() => {
    if (props.updateValues) handleInputValueChange('')
  }, [props.updateValues])

  return (
    <Select
      handleInputChange={handleInputValueChange}
      onChangeValue={onChangeValue}
      inputSearchValue={inputValue}
      disableOnChange={true}
      name='viewType'
      suggestions={orderedViewTypes}
      label={t('view-type-label')}
      placeholder={t('view-type-placeholder')}
      useAttributesAsObjectValue={[
        'sites',
        'segments',
        'paymentBlockCodeNotApplicable',
        'paymentBlockCode',
        'customerSupplierType'
      ]}
      showAttributesAsLabel={['name']}
      onSelect={(select) => {
        props.onSelect(select)
      }}
      limitSuggestion={500}
      notifySuggestionNotFound={(notification) => {
        return { label: '', value: '' }
      }}
    />
  )
}
