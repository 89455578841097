import CheckboxMui from '@material-ui/core/Checkbox'
import styled from 'styled-components'
import theme from '../theme'

const Checkbox = styled(CheckboxMui) `

  &.MuiCheckbox-colorSecondary {
    &.Mui-checked {
      color: ${theme.colors.techColor}
    }
  
    &:hover,
    &:hover.MuiCheckbox-colorSecondary {
      background-color: ${theme.colors.techColorHoverAlph}
    }
  
    .MuiSvgIcon-root {
      font-size: 2rem;
    }
  }
`;

export default Checkbox