import React from 'react'
import Checkbox from '../../../component/checkbox'

export default ({props, hasCustomerSelected}) => hasCustomerSelected
  ? <Checkbox
      indeterminate={props.numSelected > 0 && props.numSelected < props.rowDisplaySize}
      checked={props.statusCheckbox}
      onChange={props.onSelectAllClick}
      inputProps={{ 'aria-label': 'Select all documents' }}
      className="i-checkbox-table"
    />
  : <Checkbox disabled={true} checked={false}/> 