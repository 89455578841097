// import { combineReducers } from 'redux'
// import { getDocuments } from '../container/document-list/__redux__/reducer'
// import { getFilters } from '../container/document-filter/__redux__/reducer'
// import { getContainerFilter } from '../container/filter/__redux__/reducer'
// import { getViewTypes } from '../container/view-type/__redux__/reducer'
// import { getCamCsi } from '../container/cam-list-dash/__redux__/reducer'
// import { getLeadersDash } from '../container/leader-cam-list-dash/__redux__/reducer'
// import { getReferenceDate } from '../container/overdue-position/__redux__/reducer'
// import { getSegments } from '../container/segment/__redux__/reducer'
// import { getRegions } from '../container/region/__redux__/reducer'
// import { getOverduePerCustomers } from '../charts/overdue-per-customers/__redux__/reducer'
// import { getFinanceWeeklyPosition } from '../charts/overdue-finance-weekly-position/__redux__/reducer'
// import { getChartSegments } from '../charts/ar-segment/__redux__/reducer'
// import { getFiltersAnalytical } from '../container/dashboard-filter/__redux__/reducer'
// import { getCustomers } from '../container/customer-list/__redux__/reducer'
// import { getLeaders } from '../container/leader-cam-list/__redux__/reducer'
// import { getCams } from '../container/cam-list/__redux__/reducer'
// import { getCsis } from '../container/csi-list/__redux__/reducer'
// import { getSites } from '../container/site/__redux__/reducer'
// import { getCustomerGroups } from '../container/customer-group/__redux__/reducer'
// import { getCompanies } from '../container/company-documents/__redux__/reducer'
// import { getCardsAnalytical } from '../container/cards-analytical/__redux__/reducer'
// import { openToastMessage } from '../container/snackbar/__redux__/reducer'
// import { getAuth } from '../container/auth/__redux__/reducer'
// import { inputSearchRedux } from '../container/input-search/__redux__/reducer'
// import { dataTcs } from '../container/pdf/__redux__/reducer'
// import { headerReducer } from '../component/header/__redux__/reducer'
// import { newUser } from '../pages/new-user/__redux__/reducer'

// const rootReducer = combineReducers({
//   inputSearchRedux,
//   getViewTypes,
//   getCamCsi,
//   getLeadersDash,
//   getReferenceDate,
//   getSegments,
//   getRegions,
//   getOverduePerCustomers,
//   getFinanceWeeklyPosition,
//   getDocuments,
//   getFilters,
//   getContainerFilter,
//   getCustomers,
//   getCustomerGroups,
//   getLeaders,
//   getCams,
//   getCsis,
//   getCompanies,
//   getSites,
//   getCardsAnalytical,
//   getChartSegments,
//   getFiltersAnalytical,
//   openToastMessage,
//   auth: getAuth,
//   dataTcs,
//   headerReducer,
//   newUser
// })

// export default rootReducer

import { combineReducers } from 'redux'
import { getDocuments } from '../container/document-list/__redux__/reducer'
import { getQuotations } from '../container/quotation-list/__redux__/reducer'
import { getCompaniesQuotations } from '../container/company-quotations/__redux__/reducer'
import { getFilters } from '../container/document-filter/__redux__/reducer'
import { getFiltersQuotations } from '../container/quotation-filter/__redux__/reducer'
import { getContainerFilter } from '../container/filter/__redux__/reducer'
import { getViewTypes } from '../container/view-type/__redux__/reducer'
import { getCamCsi } from '../container/cam-list-dash/__redux__/reducer'
import { getLeadersDash } from '../container/leader-cam-list-dash/__redux__/reducer'
import { getReferenceDate } from '../container/overdue-position/__redux__/reducer'
import { getSegments } from '../container/segment/__redux__/reducer'
import { getRegions } from '../container/region/__redux__/reducer'
import { getOverduePerCustomers } from '../charts/overdue-per-customers/__redux__/reducer'
import { getFinanceWeeklyPosition } from '../charts/overdue-finance-weekly-position/__redux__/reducer'
import { getChartSegments } from '../charts/ar-segment/__redux__/reducer'
import { getFiltersAnalytical } from '../container/dashboard-filter/__redux__/reducer'
import { getCustomerPaymentInfo } from '../charts/customer-payment-info/__redux__/reducer'
import { getCustomerCreditLimit } from '../charts/credit-limit/__redux__/reducer'

import { getCustomers } from '../container/customer-list/__redux__/reducer'
import { getLeaders } from '../container/leader-cam-list/__redux__/reducer'
import { getCams } from '../container/cam-list/__redux__/reducer'
import { getCustomerGroups } from '../container/customer-group/__redux__/reducer'

import { getSites } from '../container/site/__redux__/reducer'
import { getCompanies } from '../container/company-documents/__redux__/reducer'
import { getCardsAnalytical } from '../container/cards-analytical/__redux__/reducer'
import { getCardsAnalyticalCustomer } from '../container/customer-dashboard-cards-analytical/__redux__/reducer'
import { openToastMessage } from '../container/snackbar/__redux__/reducer'
import { getAuth } from '../container/auth/__redux__/reducer'
import { inputSearchRedux } from '../container/input-search/__redux__/reducer'
import { dataTcs } from '../container/pdf/__redux__/reducer'
import { headerReducer } from '../component/header/__redux__/reducer'
import { newUser } from '../pages/new-user/__redux__/reducer'
import { registerNewUser } from '../pages/register-new-user/__redux__/reducer'
import { getDisputeRows } from '../container/dispute/__redux__/reducer'
import financialStatus from '../container/dispute-filter/__redux__/reducer'
import { getDisputesInformation } from '../container/disputes-information-list/__redux__/reducer'
import { getDisputeCompanies } from '../container/disputes-information/__redux__/reducer'

//Novos reducers - interface do invoices-------------------------------------------------
import customer from '../container/invoices-selects/__redux__/customer/reducer'
import crmLeader from '../container/invoices-selects/__redux__/crm-leader/reducer'
import csi from '../container/invoices-selects/__redux__/csi/reducer'
import crm from '../container/invoices-selects/__redux__/crm/reducer'
import customerGroup from '../container/invoices-selects/__redux__/customer-group/reducer'
import invoicesFilters from '../container/document-fixed-filter/__redux__/invoices-filter/reducer'
import invoicesCheckbox from '../container/document-fixed-filter/__redux__/checkbox/reducer'
import customerType from '../container/customer-type/__redux__/reducer'
import customerQuotation from '../container/quotations-selects/__redux__/customer/reducer'
import customerTypeQuotation from '../container/customer-quotation-type/__redux__/reducer'
import quotationsFilters from '../container/quotation-fixed-filter/__redux__/quotations-filter/reducer'
import customerDispute from '../container/dispute-selects/__redux__/customer-dispute/reducer'
import poNumber from '../container/dispute-selects/__redux__/po-number/reducer'
import number from '../container/dispute-selects/__redux__/number/reducer'
import status from '../container/dispute-selects/__redux__/status/reducer'
import caseNumber from '../container/dispute-selects/__redux__/case-number/reducer'
//---------------------------------------------------------------------------------------
//Novos reducers - interface do interest-free--------------------------------------------
import customerInterestFree from '../container/invoices-selects-interest-free/__redux__/customer/reducer'
import documentsInterestFree from '../container/document-list-interest-free/__redux__/reducer'
//---------------------------------------------------------------------------------------

const rootReducer = combineReducers({
  inputSearchRedux,
  getViewTypes,
  getCamCsi,
  getLeadersDash,
  getReferenceDate,
  getSegments,
  getRegions,
  getOverduePerCustomers,
  getFinanceWeeklyPosition,
  getDocuments,
  getQuotations,
  getCompaniesQuotations,
  getFilters,
  getFiltersQuotations,
  getContainerFilter,
  getLeaders,
  getCams,
  getCustomerGroups,
  getCustomers,
  //Novos reducers------
  customer,
  customerGroup,
  crmLeader,
  crm,
  csi,
  customerInterestFree,
  documentsInterestFree,
  invoicesFilters,
  invoicesCheckbox,
  customerType,
  customerTypeQuotation,
  customerQuotation,
  quotationsFilters,
  //--------------------
  getCompanies,
  getSites,
  getCardsAnalytical,
  getChartSegments,
  getFiltersAnalytical,
  openToastMessage,
  auth: getAuth,
  dataTcs,
  headerReducer,
  newUser,
  registerNewUser,
  getCardsAnalyticalCustomer,
  getCustomerPaymentInfo,
  getCustomerCreditLimit,
  getDisputeRows,
  financialStatus,
  getDisputesInformation,
  getDisputeCompanies,
  customerDispute,
  poNumber,
  number,
  status,
  caseNumber
})

export default rootReducer
