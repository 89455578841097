import { isEmployee } from '../../../domain/security'

const showOlyEmployee = (row, user) => row.showOnlyEmployee
  ? isEmployee(user)
  : true

export default (row) => ({ showFiscalNumber, isClosed, user }) => {
  if (row.id === 'fiscalNumber') return showFiscalNumber
  return row.showOnlyOnClosedView 
    ? isClosed
    : showOlyEmployee(row, user)
}