import React, { useState } from 'react'
import Downshift from 'downshift'
import Paper from '@material-ui/core/Paper'
import { DownshiftPosition } from './style'
import renderInput from './input-text'
import renderSuggestion from './menu-suggestion'
import getSuggestions from './get-suggestions'
import createItemProps from './create-item-props'
import { get, first } from 'lodash'
import removeDuplicate from './remove-duplicate'

export default (props) => {
  const [isOpenPaper, setIsOpenPaper] = useState(false)

  const getParameter = props.useAttributesAsObjectValue
    ? 'id'
    : first(props.useAttributesAsValue)

  const suggestions = removeDuplicate(props.suggestions, getParameter)

  const customStyle = {
    width: 'calc(100% - 20px)',
    position: 'absolute',
    zIndex: 10,
    maxHeight: 200,
    overflowY: 'auto'
  }

  const setSingleSuggestion = (suggestion) => {
    const getSuggestion = first(suggestion)

    const itemValueSuggestion = createItemProps(
      props.showAttributesAsLabel,
      props.useAttributesAsValue,
      props.useAttributesAsObjectValue
    )(getSuggestion)

    if (props.inputSearchValue !== itemValueSuggestion.label) {
      setPropsOnChangeSuggestion(itemValueSuggestion, props.name)
      setIsOpenPaper(false)
    }
  }

  const setPropsOnChangeSuggestion = (selected, propsName) => {
    props.onChangeValue(selected, propsName)
  }

  return (
    <DownshiftPosition>
      <Downshift
        inputValue={props.inputSearchValue}
        id={`downshift-options-${props.name}`}
        onChange={(selected) => {
          if (!props.dontBtnApplyFocus)
            document.getElementById('btn-apply').focus()
          setPropsOnChangeSuggestion(selected, props.name)
        }}
        itemToString={(item) => get(item, 'label', '')}
        onStateChange={(changes, stateAndHelpers) => {
          if (Object.prototype.hasOwnProperty.call(changes, 'isOpen'))
            setIsOpenPaper(changes.isOpen)
        }}>
        {({
          clearSelection,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          openMenu,
          selectedItem
        }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onChange: (event) => {
              if (event.target.value === '') {
                clearSelection()
                setPropsOnChangeSuggestion(null, props.name)
              }
              props.handleInputChange(event.target.value)
            },
            onFocus: openMenu,
            placeholder: props.placeholder
          })

          return (
            <div>
              {renderInput({
                fullWidth: true,
                label: props.label,
                InputLabelProps: getLabelProps({ shrink: true }),
                InputProps: {
                  onBlur,
                  onChange: (event) => {
                    onChange(event)
                  },
                  onFocus
                },
                inputProps
              })}

              <div {...getMenuProps()}>
                {isOpenPaper ? (
                  <Paper square style={customStyle}>
                    {getSuggestions(
                      inputValue,
                      { showEmpty: true },
                      suggestions,
                      props.notifySuggestionNotFound,
                      setSingleSuggestion,
                      props.showAttributesAsLabel,
                      props.limitSuggestion,
                      props.disableOnChange
                    ).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        attributesAsLabel: props.showAttributesAsLabel,
                        index,
                        itemProps: getItemProps({
                          item: createItemProps(
                            props.showAttributesAsLabel,
                            props.useAttributesAsValue,
                            props.useAttributesAsObjectValue
                          )(suggestion)
                        }),
                        highlightedIndex,
                        selectedItem
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )
        }}
      </Downshift>
    </DownshiftPosition>
  )
}
