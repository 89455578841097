import { listCompaniesQuotations } from '../../../domain/account/company'
import { changeLoading } from '../../quotation-filter/__redux__/actions'

const GET_COMPANIES_QUOTATIONS = 'GET_COMPANIES_QUOTATIONS'

const getCompaniesQuotations = (companies) => ({
  type: GET_COMPANIES_QUOTATIONS,
  companies
})

const fetchCompaniesQuotations = (query) => (dispatch) => {
  return listCompaniesQuotations(query).then((companies) => {
    if (companies.data.error) {
      dispatch(changeLoading(false))
      dispatch(getCompaniesQuotations([]))
    } else {
      dispatch(changeLoading(false))
      dispatch(getCompaniesQuotations(companies.data))
    }
  })
}

export { GET_COMPANIES_QUOTATIONS, fetchCompaniesQuotations, getCompaniesQuotations }
