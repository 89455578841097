import { listDisputeCustomers } from '../../../../domain/account/customer'

const SET_DISPUTE_CUSTOMER_LIST = 'SET_DISPUTE_CUSTOMER_LIST'
const SET_SELECTED_DISPUTE_CUSTOMER = 'SET_SELECTED_DISPUTE_CUSTOMER'
const SET_DISPUTE_CUSTOMER_NOT_FOUND_LIST =
  'SET_DISPUTE_CUSTOMER_NOT_FOUND_LIST'

const setList = (list) => ({
  type: SET_DISPUTE_CUSTOMER_LIST,
  list
})

const setSelected = (selected) => {
  return {
    type: SET_SELECTED_DISPUTE_CUSTOMER,
    selected
  }
}

const fetchList = (query) => async (dispatch) => {
  return listDisputeCustomers(query).then((res) =>
    dispatch(setList(res.data.body.rows))
  )
}

const setCustomersNotFoundList = (list) => ({
  type: SET_DISPUTE_CUSTOMER_NOT_FOUND_LIST,
  list
})

const fetchNotFoundList = (query) => (dispatch) =>
  listDisputeCustomers(query).then((customers) => {
    if (customers.data.body.rows.length)
      dispatch(setCustomersNotFoundList(customers.data.body.rows))
  })

export {
  SET_DISPUTE_CUSTOMER_LIST,
  SET_DISPUTE_CUSTOMER_NOT_FOUND_LIST,
  SET_SELECTED_DISPUTE_CUSTOMER,
  fetchNotFoundList,
  setSelected,
  fetchList,
  setList
}
