import { stringify } from 'query-string'
import { getBearerAuthorization } from '../../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/customers`

const list = (criteria = '') => {
  return axios.get(`${url}?${stringify(criteria)}`, {
    headers: getBearerAuthorization()
  })
}

const findAggByGroup = (criteria = '') =>
  axios.get(`${url}/find-agg-by-group?${stringify(criteria)}`, {
    headers: getBearerAuthorization()
  })

const findUnsynced = (origin = '', criteria = '') => {
  const portalOrigin = origin ? `origin=${origin}&` : ''
  return axios.get(`${url}/unsynced?${portalOrigin}criteria=${criteria}`, {
    headers: getBearerAuthorization()
  })
}

const sync = (account) =>
  axios({
    method: 'POST',
    headers: getBearerAuthorization(),
    data: account,
    url
  })

const unsync = (portalAccountId, accountId, portalOrigin) =>
  axios.delete(url, {
    data: { accountId, portalAccountId, portalOrigin },
    headers: getBearerAuthorization()
  })

const triggerIdoc = (code) =>
  axios({
    method: 'POST',
    headers: getBearerAuthorization(),
    url: `${url}/trigger-idoc/${code}`
  })

const checkAvailableCustomerTypes = (criteria = '') => {
  return axios.get(
    `${url}/check-availables-customer-types?${stringify(criteria)}`,
    {
      headers: getBearerAuthorization()
    }
  )
}

const listQuotationCustomers = (criteria = '') => {
  return axios.get(`${url}/quotation-customers?${stringify(criteria)}`, {
    headers: getBearerAuthorization()
  })
}

const findCustomerByCode = (code = '') => {
  return axios.get(`${url}/find-customer-by-code?code=${code}`, {
    headers: getBearerAuthorization()
  })
}

const listDisputeCustomers = (criteria = '') => {
  return axios.get(`${url}/dispute-customers?${stringify(criteria)}`, {
    headers: getBearerAuthorization()
  })
}

export {
  list,
  findAggByGroup,
  findUnsynced,
  sync,
  unsync,
  triggerIdoc,
  checkAvailableCustomerTypes,
  listQuotationCustomers,
  findCustomerByCode,
  listDisputeCustomers
}
