import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import { actions } from './__redux__'
import Select from '../../component/select'
import { list as fetchList } from '../../domain/account/customer'
const empty_value = { value: '', label: '' }

export default (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const list = useSelector((state) =>
    get(state, `${props.name}InterestFree.list`, [])
  )
  const selected = useSelector((state) =>
    get(state, `${props.name}InterestFree.selected`, { ...empty_value })
  )

  //Estado que controla o que está sendo mostrado no input de cada select
  const [inputValue, setInputValue] = useState('')
  const [listValue, setListValue] = useState([])

  //Handler da alteração no valor do input
  const handleInputChange = (value) => setInputValue(value)

  //Handler da seleção manual de um item da lista
  const handleChangeSelect = (selected, name) => {
    selected && selected.value !== '' && selected !== null
      ? select(selected)
      : select(null)
  }

  //Handler centralizado da seleção de itens. Toda atualização do item selecionado deve
  //acontecer através desse método
  const select = (selected, onSelect = true, onDispatch = true) => {
    const dispatchData =
      selected && selected.value ? selected : { ...empty_value }
    const selectedData = selected && selected.value ? selected : null
    if (onSelect) props.onSelect({ name: props.name, selected: selectedData })
    if (onDispatch) dispatch(actions[props.name].setSelected(dispatchData))
  }

  //Quando uma nova lista é carregada, se a lista contiver apenas um item,
  //seta o item como sendo o selecionado. Se a nova lista contiver mais de um item
  //e já houver um valor setado que não está na lista (esse resultado é inesperado, já que
  //na lógica central dos fetches , seleciona um valor vazio
  useEffect(() => {
    if (list.length === 1 && list[0]) {
      select(
        {
          label:
            props.name === 'customer' || props.name === 'customerGroup'
              ? list[0][props.showAttributesAsLabel[0]] +
                ' - ' +
                list[0][props.showAttributesAsLabel[1]]
              : list[0][props.labelFromItem],
          value: list[0][props.valueFromItem]
        },
        false
      )
      // } else if (
      //   selected.value &&
      //   !list.find((item) => item[props.valueFromItem] === selected.value)
      // ) {
    } else {
      setInputValue('')
      select(null, false)
    }
    setListValue(list)
  }, [list])

  //Quando o valor selecionado é alterado no redux (actions[props.name].setSelected),
  //setamos o valor do input para sempre estar sincronizado com o redux
  useEffect(() => {
    setInputValue(`${selected.label}`)
  }, [selected])

  const fetchNotFoundHandler = (query) => {
    fetchList(query).then((customers) => {
      if (customers.data.length) {
        let list = customers.data
        setListValue(list)
        if (customers.data.length === 1)
          select({
            label:
              props.name === 'customer' || props.name === 'customerGroup'
                ? list[0][props.showAttributesAsLabel[0]] +
                  ' - ' +
                  list[0][props.showAttributesAsLabel[1]]
                : list[0][props.labelFromItem],
            value: list[0][props.valueFromItem]
          })
      }
    })
  }

  return (
    <Select
      handleInputChange={handleInputChange}
      onChangeValue={handleChangeSelect}
      inputSearchValue={inputValue}
      name={props.name}
      suggestions={listValue}
      label={t(props.translationLabel)}
      placeholder={t(props.translationPlaceholder)}
      useAttributesAsValue={props.useAttributesAsValue}
      showAttributesAsLabel={props.showAttributesAsLabel}
      notifySuggestionNotFound={(notification) => {
        if (props.fetchNotFound) {
          fetchNotFoundHandler(notification)
        }
        // let fetchNotFoundList = actions[props.name].fetchNotFoundList
        // if (props.fetchNotFound && fetchNotFoundList) {
        //   dispatch(fetchNotFoundList(notification))
        // }
      }}
    />
  )
}
