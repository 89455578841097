import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'

import Swal from 'sweetalert2'

import {
  setCompanyName,
  setAddress,
  setCity,
  setStateProvince,
  setCountry,
  setErrorFullName,
  setErrorCompanyName,
  setErrorAddress,
  setErrorCity,
  setErrorStateProvince,
  setErrorCountry,
  setFullName,
  setPhoneNumber
} from './__redux__/actions'

import { sendAuthorizationRequestEmail } from '../../domain/new-user'

import HeaderCompanyInformation from '../../component/header-new-user'

import CompanyInformation from './company-information'
import UserInformation from './user-information'

import { NewUserContainer } from '../new-user/style'
import Loading from '../../component/loading'
import { useTranslation } from 'react-i18next'
import isUserDisabledResponse from '../../helpers/is-user-disabled-response'

export default () => {
  const { t } = useTranslation()

  const history = useHistory()
  const dispatchRedux = useDispatch()
  const fullName = useSelector((state) => state.newUser.fullName)
  const phoneNumber = useSelector((state) => state.newUser.phoneNumber)
  const companyName = useSelector((state) => state.registerNewUser.companyName)
  const companyAddress = useSelector((state) => state.registerNewUser.address)
  const companyCity = useSelector((state) => state.registerNewUser.city)
  const companyState = useSelector(
    (state) => state.registerNewUser.stateProvince
  )
  const companyCountry = useSelector((state) => state.registerNewUser.country)
  const email = useSelector((state) => state.registerNewUser.registrationEmail)

  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(true)
  const steps = [
    t('new-user-company-information'),
    t('new-user-user-information')
  ]

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <CompanyInformation />
      case 1:
        return <UserInformation />
      default:
        return <CompanyInformation />
    }
  }

  const handleNext = async () => {
    if (activeStep + 1 < steps.length) {
      if (
        companyName.length < 3 ||
        companyAddress.length < 5 ||
        companyCity.length < 4 ||
        companyState.length < 2 ||
        companyCountry.length < 4
      ) {
        if (companyName.length < 3) dispatchRedux(setErrorCompanyName(true))
        if (companyAddress.length < 5) dispatchRedux(setErrorAddress(true))
        if (companyCity.length < 4) dispatchRedux(setErrorCity(true))
        if (companyState.length < 2) dispatchRedux(setErrorStateProvince(true))
        if (companyCountry.length < 4) dispatchRedux(setErrorCountry(true))
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    }

    if (activeStep + 1 === 2) {
      if (fullName.length < 5) {
        dispatchRedux(setErrorFullName(true))
      } else {
        setLoading(true)

        try {
          const emailData = {
            fullName,
            userEmail: email,
            phoneNumber,
            companyName,
            companyAddress,
            companyCity,
            companyState,
            companyCountry
          }

          await sendAuthorizationRequestEmail(emailData)

          Swal.fire(
            t('success') + '!',
            t('authorization-request-sent'),
            'success'
          )

          history.push('/login')
        } catch (error) {
          let response = error.response
          let message = response && response.data.message
          if (message) {
            message = isUserDisabledResponse(message)
              ? t('new-user-user-disabled')
              : message
          } else message = error.message
          Swal.fire(t('attention') + '!', t('new-user-error-saving-data'))
          setLoading(false)
        }
      }
    }
  }

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const handleClickCancel = () => {
    window.location.href = `${process.env.REACT_APP_INVOICES_UI}/login`
  }

  const clearAllFields = () => {
    dispatchRedux(setCompanyName(''))
    dispatchRedux(setAddress(''))
    dispatchRedux(setCity(''))
    dispatchRedux(setStateProvince(''))
    dispatchRedux(setCountry(''))
    dispatchRedux(setFullName(''))
    dispatchRedux(setPhoneNumber(''))
  }

  useEffect(() => {
    clearAllFields()
    setLoading(false)
    if (!email)
      window.location.href = `${process.env.REACT_APP_INVOICES_UI}/login/register-now`
  }, [])

  if (loading) return <Loading />

  return (
    <NewUserContainer>
      <div className='new-user-center'>
        <HeaderCompanyInformation>
          <h3>{t('new-user-company-registration')}</h3>
          <p>{t('new-user-fill-name')}</p>
          <h6>{t('new-user-fill-name-p-2')}</h6>
        </HeaderCompanyInformation>

        <div className='body-new-user'>
          <div className='header-new-user'>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div className='load-steps'>{getStepContent(activeStep)}</div>
          <div className='footer-new-user'>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                {t('back')}
              </Button>
            </div>
            <div>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleClickCancel}>
                {t('button-cancel-self-registration')}
              </Button>
              <Button variant='contained' color='primary' onClick={handleNext}>
                {activeStep === steps.length - 1 ? t('submit') : t('next')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </NewUserContainer>
  )
}
