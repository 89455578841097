import { listDisputePoNumbers } from '../../../../domain/document'

const SET_PO_NUMBER_LIST = 'SET_PO_NUMBER_LIST'
const SET_SELECTED_PO_NUMBER = 'SET_SELECTED_PO_NUMBER'

const setList = (list) => ({
  type: SET_PO_NUMBER_LIST,
  list
})

const setSelected = (selected) => {
  return {
    type: SET_SELECTED_PO_NUMBER,
    selected
  }
}

const fetchList = (query) => async (dispatch) => {
  return listDisputePoNumbers(query).then((res) =>
    dispatch(setList(res.data.body.rows))
  )
}

export {
  SET_PO_NUMBER_LIST,
  SET_SELECTED_PO_NUMBER,
  setSelected,
  fetchList,
  setList
}
