import { getBearerAuthorization } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/reports`

export default async (id = '') =>
  await axios.delete(`${url}/${id}`, {
    headers: getBearerAuthorization()
  })
