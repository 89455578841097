import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import AmChartGroupColumns from '../amcharts-group-columns'
import IsEmptyChart from '../amcharts-is-empty'
import getButtonsApi from './get-buttons-api'
import { TotalWeeklyDatePicker } from './styles'
import settings from './settings'

const getFinanceWeeklyPosition = (state) =>
  get(state, 'getFinanceWeeklyPosition.financeWeeklyPosition', [])
const getFilters = (state) => get(state, 'getFiltersAnalytical.selects', [])
const getReferenceDate = (state) =>
  get(state, 'getReferenceDate.referenceDate', [])
const getDateFilter = (state) => get(state, 'getReferenceDate.dateFilter', '')

const OverdueFinanceWeeklyPosition = (props) => {
  const { t } = useTranslation()

  // eslint-disable-next-line
  const namesWeeks = {
    // eslint-disable-next-line
    ['first-week']: t('1st-week'),
    // eslint-disable-next-line
    ['second-week']: t('2nd-week'),
    // eslint-disable-next-line
    ['third-week']: t('3rd-week'),
    // eslint-disable-next-line
    ['fourth-week']: t('4th-week'),
    // eslint-disable-next-line
    ['fifth-week']: t('5th-week')
  }

  const dateFilter = useSelector(getDateFilter)
  const referenceDate = useSelector(getReferenceDate)

  const reduxDataProvider = useSelector(getFinanceWeeklyPosition)
  const reduxFilters = useSelector(getFilters)
  const [stateDataProvider, setStateDataProvider] = useState([])
  const [buttonActived, setButtonActived] = useState('weekly')
  const [configGraph, setconfigGraph] = useState([])
  const [selectedDate, setSelectedDate] = useState(moment())
  const [firstDate, setFirstDate] = useState(moment().format('YYYY-MM-DD'))

  const verifyDateFilterRedux = () => {
    if (
      dateFilter !== '' &&
      dateFilter.toString() !== '' &&
      dateFilter &&
      selectedDate
    )
      setSelectedDate(moment(dateFilter))
  }

  const getDataMonthYearSelected = async (date) => {
    const selectsFilters = {
      selects: [
        {
          name: 'executeAt',
          selected: {
            label: 'Overdue Position',
            value: moment(date).format('YYYY-MM-DD')
          }
        }
      ]
    }

    const dataProvider =
      (await getButtonsApi[buttonActived](selectsFilters)) || []

    const configInit = settings.initialGraphWeekly(dataProvider)
    const configDataProvider = settings.configDataProviderWeekly(
      dataProvider,
      namesWeeks
    )

    setconfigGraph(configInit)
    setStateDataProvider(configDataProvider)
  }

  const verifyActivedButton = (button, buttonState) => {
    return button === buttonState ? 'primary' : 'default'
  }

  const handleButtonActived = (nameButton) => setButtonActived(nameButton)

  const configGraphWeekly = async (buttonActived) => {
    const selectsFilters = { selects: reduxFilters }
    const dataProvider =
      (await getButtonsApi[buttonActived](selectsFilters)) || []
    const configInit = settings.initialGraphWeekly(dataProvider)
    const configDataProvider = settings.configDataProviderWeekly(
      dataProvider,
      namesWeeks
    )

    setconfigGraph(configInit)
    handleButtonActived(buttonActived)
    setStateDataProvider(configDataProvider)
  }

  const configGraphMonthly = async (buttonActived) => {
    const selectsFilters = { selects: reduxFilters }
    const dataProvider =
      (await getButtonsApi[buttonActived](selectsFilters)) || []

    const groupByMonthly = settings.groupByMonthly(dataProvider)
    const configDataProvider = settings.configureDataProvider(groupByMonthly)
    const configGraphs = settings.configGraphs(dataProvider, t('overdue'))

    const setConfig = {
      graphs: configGraphs,
      categoryField: 'monthTitle'
    }

    setconfigGraph(setConfig)
    handleButtonActived(buttonActived)
    setStateDataProvider(configDataProvider)
  }

  const handleChangeMonthly = (buttonActived) => {
    buttonActived === 'weekly'
      ? configGraphWeekly(buttonActived)
      : configGraphMonthly(buttonActived)
  }

  const configGraphWeeklyRedux = () => {
    const configInit = settings.initialGraphWeekly(reduxDataProvider)
    const configDataProvider = settings.configDataProviderWeekly(
      reduxDataProvider,
      namesWeeks
    )

    setconfigGraph(configInit)
    setStateDataProvider(configDataProvider)
  }

  useEffect(() => {
    configGraphWeekly('weekly')
  }, [])

  useEffect(() => {
    if (referenceDate.first_overdue_position)
      setFirstDate(
        moment(referenceDate.first_overdue_position)
          .add(3, 'day')
          .format('YYYY-MM-DD')
      )
  }, [referenceDate.first_overdue_position])

  useEffect(() => {
    configGraphWeeklyRedux()
  }, [reduxDataProvider])

  useEffect(() => {
    verifyDateFilterRedux()
  }, [dateFilter])

  return (
    <>
      <Card>
        <CardContent>
          <Grid container direction='row'>
            <Grid item xs={12} sm={9}>
              <Typography
                gutterBottom
                style={{ fontSize: '1.9rem', color: '#666666' }}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <ButtonGroup
                fullWidth
                aria-label='full width outlined button group'>
                <Button
                  onClick={() => handleChangeMonthly('weekly')}
                  color={verifyActivedButton('weekly', buttonActived)}>
                  {t('button-overdue-weekly')}
                </Button>
                <Button
                  onClick={() => handleChangeMonthly('monthly')}
                  color={verifyActivedButton('monthly', buttonActived)}>
                  {t('button-overdue-monthly')}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid container direction='row'>
            <Grid item xs={12}>
              <TotalWeeklyDatePicker>
                <div className='icon-date-picker'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      InputProps={{ disableUnderline: true }}
                      disableFuture
                      disableToolbar
                      autoOk
                      minDate={firstDate}
                      variant='inline'
                      openTo='year'
                      views={['year', 'month']}
                      value={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      onMonthChange={getDataMonthYearSelected}
                      error={false}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </TotalWeeklyDatePicker>
            </Grid>
          </Grid>

          {stateDataProvider.length > 0 ? (
            <AmChartGroupColumns
              dataConfig={configGraph}
              dataProvider={stateDataProvider}
            />
          ) : (
            <IsEmptyChart title='' />
          )}
        </CardContent>
      </Card>
    </>
  )
}

export default OverdueFinanceWeeklyPosition
