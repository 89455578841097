import {
  CHANGE_CRITERIA_FILTER,
  CHANGE_SELECT_FILTER,
  CHANGE_STATUS_FILTER,
  RESET_SEARCH,
  UPDATE_LOADING
} from './actions'

const INITIAL_STATE = {
  selects: [],
  status: 'OPEN',
  criteria: '',
  page: 0,
  loading: true
}

export const getFilters = (state = INITIAL_STATE, action) => {
  if (action.type === CHANGE_CRITERIA_FILTER)
    return { ...state, criteria: action.criteria }
  if (action.type === CHANGE_SELECT_FILTER)
    return { ...state, selects: [...action.selects] }
  if (action.type === CHANGE_STATUS_FILTER)
    return { ...state, status: action.status }
  if (action.type === RESET_SEARCH) return { ...state, selects: [], page: 0 }
  if (action.type === UPDATE_LOADING)
    return { ...state, loading: action.loading }
  return state
}
