import { getBearerAuthorization } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/documents`

export default async (creditNumber, customerCode) => {
  const setQueryPipe = { creditNumber, customerCode }
  return await axios.post(`${url}/find-by-credit-number`, setQueryPipe, {
    headers: getBearerAuthorization()
  })
}
