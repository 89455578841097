import React from 'react'
import CheckboxUI from './checkbox-style'

const Checkbox = (props) => {
  return (
    <CheckboxUI
      checked={props.checked}
      disabled={props.disabled}
      onChange={props.onChange}
      indeterminate={props.indeterminate}
    />
  )
}

export default Checkbox
