import { GET_REGIONS } from './actions'

const INITIAL_STATE = {
  regions: []
}

export const getRegions = (state = INITIAL_STATE, action) => {
  if (action.type === GET_REGIONS) {
    return { regions: [...action.regions.data.body] }
  }
  return state
}
