import styled from 'styled-components'

export const FormLoginContainer = styled.form`
  display: block;
  width: 100%;
  padding: 20px;

  h2 {
    text-align: center;
    color: #000000;
    font-size: 16px;
    display: flex;
    width: 100%;
    margin-bottom: 16px;
  }

  .link-logins {
    a {
      font-size: 14px;
      color: #003a70;

      &:hover {
        text-decoration: underline;
        color: #005baf;
      }
    }

    margin: 0px 5px 0px 5px;
  }

  .text-center {
    text-align: center;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }

  .MuiInputBase-input,
  .MuiInputLabel-formControl {
    font-size: 16px;
  }

  .MuiButton-containedPrimary {
    padding: 12px;
    background-color: #005baf;
    &:hover {
      background-color: #004b90;
    }
  }

  .flex-container {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .portal-link {
    width: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`
