import styled from 'styled-components'
import ListSearch from './list-search'

export default styled(ListSearch)`
  overflow: auto;
  max-height: 315px;

  .MuiTypography-body2 {
    color: #666;
    font-size: 1.6rem;
  }

  .MuiIconButton-colorSecondary {
    color: rgba(0, 0, 0, 0.54);
  }
`
