import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Filter from '../fixed-filter'
import ViewType from '../view-type'
import Leader from '../leader-cam-list-dash'
import Cam from '../cam-list-dash'
import Segment from '../segment'
import Region from '../region'
import Site from '../site'
import OverduePosition from '../overdue-position'
import { setReferenceDateFilter } from '../overdue-position/__redux__/actions'
import { applySelectFilterAnalytical } from './__redux__/actions'
import { fetchFinanceWeeklyPosition } from '../../charts/overdue-finance-weekly-position/__redux__/actions'
import { fetchOverduePerCustomers } from '../../charts/overdue-per-customers/__redux__/actions'
import { fetchChartSegments } from '../../charts/ar-segment/__redux__/actions'
import { fetchChartRegions } from '../../charts/ar-region/__redux__/actions'
import { fetchCards } from '../cards-analytical/__redux__/actions'
import { fetchLeadersDash } from '../leader-cam-list-dash/__redux__/actions'
import { fetchCamCsi } from '../cam-list-dash/__redux__/actions'
import { fetchSegments } from '../segment/__redux__/actions'
import { fetchRegions } from '../region/__redux__/actions'
import { fetchSites } from '../site/__redux__/actions'
import { fetchPaymentInfo } from '../../charts/customer-payment-info/__redux__/actions'
import { get } from 'lodash'

const applyAnalyticalFilter = (dispatch) => (selects) => {
  dispatch(applySelectFilterAnalytical(selects))
  dispatch(fetchFinanceWeeklyPosition({ selects }))
  dispatch(fetchOverduePerCustomers({ selects }))
  dispatch(fetchChartSegments({ selects }))
  dispatch(fetchChartRegions({ selects }))
  dispatch(fetchCards({ selects }))
}

const getFiltersAnalytical = (state) =>
  get(state, 'getFiltersAnalytical.selects', [])

export default () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [updateValueSelects, setUpdateValueSelects] = useState(false)
  const filters = useSelector(getFiltersAnalytical)

  function clearFilterDash() {
    dispatch(applySelectFilterAnalytical([]))
    dispatch(fetchFinanceWeeklyPosition())
    dispatch(fetchOverduePerCustomers())
    dispatch(fetchChartSegments())
    dispatch(fetchChartRegions())
    dispatch(fetchCards())
    dispatch(setReferenceDateFilter(moment().format('YYYY-MM-DD')))
    dispatch(fetchPaymentInfo())
    setUpdateValueSelects(true)
  }

  function syncSelectsUpdate(selected) {
    const selects = { selects: selected }
    dispatch(fetchLeadersDash(selects))
    dispatch(fetchCamCsi(selects))
    dispatch(fetchSegments(selects))
    dispatch(fetchRegions(selects))
    dispatch(fetchSites(selects))
    dispatch(fetchPaymentInfo(selects))
  }

  useEffect(() => {
    if (updateValueSelects) setUpdateValueSelects(false)
  }, [updateValueSelects])

  return (
    <Filter
      buttonName='btn-apply'
      filterFooter='drop-filter-footer'
      menuFilter='dashboard-filter'
      clearInput={true}
      searchInput={false}
      interceptSelectedValue={true}
      onChangeFilter={(selects) => applyAnalyticalFilter(dispatch)(selects)}
      onClearClick={() => clearFilterDash()}
      syncSelects={true}
      syncSelectsUpdate={syncSelectsUpdate}
      clearTitle={t('clear')}
      applyTitle={t('apply')}
      buttonTitleCustomerFilter={t('filter')}>
      <ViewType updateValues={updateValueSelects} filters={filters} />
      <Leader updateValues={updateValueSelects} filters={filters} />
      <Cam updateValues={updateValueSelects} filters={filters} />
      <OverduePosition updateValues={updateValueSelects} filters={filters} />
      <Segment updateValues={updateValueSelects} filters={filters} />
      <Region updateValues={updateValueSelects} filters={filters} />
      <Site updateValues={updateValueSelects} filters={filters} />
    </Filter>
  )
}
