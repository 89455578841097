import getPdfName from './get-pdf-name'
import { getBearerAuthorization } from '../auth'
import axios from 'axios'
import download from 'downloadjs'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/documents`

const quantumProxyApi = process.env.REACT_APP_QUANTUM_PROXY_API
const urlQuantum = `${quantumProxyApi}/api/v1/pdf`

const getPdf = async (url, pdfName) => {
	const response = await axios.get(`${url}`, {
		headers: getBearerAuthorization()
	})

	if(response.data.content.length > 0) {
		return download(
			`data:application/pdf;base64, ${response.data.content}`,
			`${pdfName}.pdf`,
			'application/pdf'
		)
	} else {
		throw Error('PDF not found.')
	}
}

export default async ({
	id,
	number,
	type,
	salesOrganization,
	billingNumber,
	assignmentNumber,
	fiscalNumber
}) => {
	const pdfName = getPdfName({ type, number, fiscalNumber, billingNumber, assignmentNumber })

	return /EEJS/.test(salesOrganization)
		? await getPdf(`${urlQuantum}?invoiceNumber=${billingNumber}`, pdfName)
		: await getPdf(`${url}/${id}/get-pdf`, pdfName)
}
