import TableCellMui from '@material-ui/core/TableCell'
import styled, { css } from 'styled-components'

const TableCell = styled(TableCellMui)`
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
  color: #666666;
  font-size: 1.4rem;

  .MuiTableSortLabel-root {
    white-space: pre;
  }

  @media (max-width: 1244px) {
    font-size: 1.3rem;
  }

  @media (max-width: 1131px) {
    width: calc(100% / 7);
    text-align: left;

    &:before {
      content: attr(data-label-thead);
      font-weight: 700;
      display: block;
      padding-bottom: 5px;
    }
  }

  @media (max-width: 1131px) {
    width: calc(100% / 4);

    &:last-child {
      position: absolute;
    }

    &:last-child {
      width: 100%;
      height: 70px;
      text-align: right;
      padding: 10px;
      bottom: 0;
      border-top: 1px solid #dedede;
      background-color: #fff;
    }

    &.clearing-number-icon {
      width: 100%;
      height: 70px;
      text-align: right;
      line-height: 47px;
      position: absolute;
      left: 0;
      bottom: 0;
      border-top: 1px solid rgb(222, 222, 222);
      background-color: #fff;
    }
  }

  @media (max-width: 1131px) {
    width: calc(100% / 3);
  }

  @media (max-width: 820px) {
    width: calc(100% / 2);
  }

  &.MuiTableCell-body:nth-child(4) {
    white-space: nowrap;
  }

  &.table-cell-view {
    white-space: nowrap;
  }
`

export default TableCell
