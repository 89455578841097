const CHANGE_DISPUTE_SELECTS = 'CHANGE_DISPUTE_SELECTS'
const SET_DOCUMENTS_DISPUTE = 'SET_DOCUMENTS_DISPUTE'

const changeDisputeSelects = (selects) => ({
  type: CHANGE_DISPUTE_SELECTS,
  selects
})

const setRowsForDisputeForm = (selects) => ({
  type: SET_DOCUMENTS_DISPUTE,
  selects
})

const applyDisputeSelects = (query) => (dispatch) => {
  dispatch(changeDisputeSelects(query))
}

const applyRowsForDisputeForm = (rows) => (dispatch) => {
  dispatch(setRowsForDisputeForm(rows))
}

export {
  CHANGE_DISPUTE_SELECTS,
  SET_DOCUMENTS_DISPUTE,
  applyDisputeSelects,
  applyRowsForDisputeForm
}
