import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Button, Grid, TextareaAutosize, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Dialog from '../../component/dialog'
import DisputeForm from './dispute-form'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import StyledDropzone from '../../component/dropzone'
import FileList from '../../pages/customer-support/file-list'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import Loading from '../../component/loading'
import ReviewTable from './review-table'
import Swal from 'sweetalert2'

const getDisputeRows = (state) => get(state, 'getDisputeRows.selects', [])
const getFilters = (state) => get(state, 'getFilters.selects', [])

export default (props) => {
  const { t } = useTranslation()

  const [activeStep, setActiveStep] = useState(0)
  const [description, setDescription] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [uploadedTotalSize, setUploadedTotalSize] = useState(0)
  const rows = useSelector(getDisputeRows)
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [formValues, setFormValues] = useState([])
  const [isReviewDialogOpen, setIsReviewDialogOpen] = useState(false)
  const [customerName, setCustomerName] = useState('')
  const filters = useSelector(getFilters)
  const customerSelected = filters.filter(
    (filter) => filter.name === 'customer'
  )
  const [descriptionError, setDescriptionError] = useState(false)

  const handleBack = async (event) => {
    event.preventDefault()

    let newValues = formValues
    newValues[activeStep] = {
      ...newValues[activeStep],
      description,
      ...rows[activeStep],
      uploadedFiles,
      uploadedTotalSize
    }

    setFormValues(newValues)

    setActiveStep(activeStep - 1)
    if (formValues[activeStep - 1]) {
      setDescription(formValues[activeStep - 1].description)
      setUploadedFiles(formValues[activeStep - 1].uploadedFiles)
      setUploadedTotalSize(formValues[activeStep - 1].uploadedTotalSize)
    }
  }

  const handleAttach = useCallback(
    async (files) => {
      let totalFilesSize = 0
      files.forEach((file) => (totalFilesSize += file.size))

      uploadedFiles.forEach((file) => (totalFilesSize += file.size))

      if (totalFilesSize > 25000000) {
        return Swal.fire(
          'Attention!',
          'The maximum size supportted is 25Mb, please select smaller files.',
          'warning'
        )
      }

      if (uploadedFiles.length + files.length > 5) {
        return Swal.fire(
          'Attention!',
          'The maximum amount of files has been exceeded, please select up to 5 files.',
          'warning'
        )
      }

      setUploadedTotalSize(totalFilesSize)

      return setUploadedFiles([
        ...uploadedFiles,
        ...files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ])
    },
    [uploadedFiles]
  )

  const onDropRejected = (rejectedFiles) => {
    const rejectedFileNames = rejectedFiles
      .map((file) => file.file.name)
      .join(', ')

    return Swal.fire(
      'Attention!',
      `The file format for ${rejectedFileNames} is not allowed. Please, select only pdf, jpeg and png formats with the maximum total size of 25mb.`,
      'warning'
    )
  }

  const handleDelete = useCallback(
    (index) => {
      const files = uploadedFiles
      files.splice(index, 1)
      setUploadedFiles([...files])

      let totalFilesSize = 0
      files.forEach((file) => (totalFilesSize += file.size))

      setUploadedTotalSize(totalFilesSize)
    },
    [uploadedFiles]
  )

  const DisputeFormMemo = useMemo(
    () => (
      <DisputeForm key={activeStep} docs={rows[activeStep]} filters={filters} />
    ),
    [activeStep, props.selecteds]
  )

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!description) return setDescriptionError(!descriptionError)

    let newValues = formValues
    newValues[activeStep] = {
      ...newValues[activeStep],
      description,
      ...rows[activeStep],
      uploadedFiles,
      uploadedTotalSize
    }

    setFormValues(newValues)

    if (activeStep + 1 === rows.length) {
      props.setIsDialogOpen(false)
      setIsReviewDialogOpen(!isReviewDialogOpen)

      if (!customerName && customerSelected.length > 0)
        setCustomerName(customerSelected[0].selected.label)

      return
    }

    setActiveStep(activeStep + 1)
    setUploadedFiles([])
    setUploadedTotalSize(0)

    const verify = newValues.find((e) => e.id === rows[activeStep + 1].id)

    if (!verify) {
      newValues[activeStep + 1] = {
        ...newValues[activeStep + 1],
        isSameOfPrevious: false,
        description: ''
      }
    }

    if (formValues[activeStep + 1]) {
      setDescription(formValues[activeStep + 1].description)
      setUploadedFiles(get(formValues[activeStep + 1], 'uploadedFiles', []))
      setUploadedTotalSize(
        get(formValues[activeStep + 1], 'uploadedTotalSize', 0)
      )
    } else {
      setDescription('')
    }
  }

  const handleDescription = (e) => {
    if (e.target.checked) {
      const checkedForm = [...formValues]
      checkedForm[activeStep].isSameOfPrevious = true

      setFormValues(checkedForm)
      if (descriptionError) setDescriptionError(!descriptionError)
      return setDescription(formValues[activeStep - 1].description)
    }
    const checkedForm = [...formValues]
    checkedForm[activeStep].isSameOfPrevious = false
    setFormValues(checkedForm)
    return setDescription('')
  }

  const clearValues = async () => {
    setDescription('')
    setUploadedFiles([])
    setActiveStep(0)
    setFormValues([])
  }

  const handleClose = () => {
    Swal.fire({
      title: 'Attention!',
      text: 'Are you sure that you want to cancel the current Dispute Case?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      setActiveStep(0)
      if (result.value) {
        if (props.isDialogOpen) props.setIsDialogOpen(!props.isDialogOpen)
        if (isReviewDialogOpen) setIsReviewDialogOpen(!isReviewDialogOpen)
        clearValues()
      }
    })
  }

  useEffect(() => {
    if (!customerName && customerSelected.length > 0)
      setCustomerName(customerSelected[0].selected.label)

    if (loadingStatus) {
      setLoadingStatus(false)
    }
  }, [rows, loadingStatus])

  return (
    <Fragment>
      <Dialog
        open={props.isDialogOpen}
        labelClose
        onClose={handleClose}
        label={<h1>Dispute Request</h1>}
        maxWidth='sm'
        style={{ zIndex: '500', minHeight: '500px' }}>
        {loadingStatus ? (
          <Loading />
        ) : (
          <Fragment>
            {DisputeFormMemo}
            {activeStep > 0 ? (
              <Grid item xs={12} lg={12}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      onChange={handleDescription}
                      checked={formValues[activeStep].isSameOfPrevious}
                      color='blue'
                      error={descriptionError}
                    />
                  }
                  label={t('dispute-checkbox-message')}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} lg={12}>
              <Typography variant='h6' color='blue' style={{ color: 'blue' }}>
                {`${t('dispute-reason-label')} *`}
              </Typography>
              <TextareaAutosize
                required
                name='description'
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value)
                  if (description && descriptionError)
                    setDescriptionError(!descriptionError)
                }}
                style={{
                  width: '100%',
                  minHeight: '100px',
                  maxHeight: '300px',
                  borderColor: descriptionError ? 'red' : 'initial'
                }}
              />
              {descriptionError ? (
                <Typography style={{ color: 'red', fontSize: '10px' }}>
                  {t('dispute-description-error-message')}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} lg={12} style={{ marginLeft: '22px' }}>
              <StyledDropzone
                className='files-dropzone'
                onDrop={handleAttach}
                accept={{
                  'image/*': ['.jpeg', '.jpg', '.png'],
                  'application/pdf': ['.pdf']
                }}
                multiple
                message='Drag and drop your attachments here.'
                onDropRejected={onDropRejected}
                t={t}
              />
            </Grid>
            <Grid item xs={12} lg={12} style={{ marginBottom: '10px' }}>
              <FileList
                files={uploadedFiles}
                handleDelete={handleDelete}
                t={t}
                uploadedTotalSize={uploadedTotalSize}
              />
            </Grid>
            <Grid
              item
              style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='contained'
                color='primary'
                disabled={activeStep === 0}
                onClick={handleBack}>
                {t('back')}
              </Button>
              {`${activeStep + 1}/${rows.length}`}
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}>
                {t('next')}
              </Button>
            </Grid>
          </Fragment>
        )}
      </Dialog>
      <Dialog
        open={isReviewDialogOpen}
        labelClose
        onClose={handleClose}
        label={<h1>Dispute Request - Review</h1>}
        maxWidth='sm'
        style={{ zIndex: '500' }}>
        <ReviewTable
          filters={filters}
          values={formValues}
          setIsReviewDialogOpen={setIsReviewDialogOpen}
          isReviewDialogOpen={isReviewDialogOpen}
          setFormValues={setFormValues}
          clearValues={clearValues}
          setDisputeRequestDialogOpen={props.setIsDialogOpen}
          setActiveStep={setActiveStep}
        />
      </Dialog>
    </Fragment>
  )
}
