import React from 'react'
import Checkbox from '../../../component/checkbox'

export default ({ isItemSelected, labelId, canSelect }) => {
  return (
    <Checkbox
      disabled={!canSelect}
      checked={isItemSelected}
      inputProps={{ 'aria-labelledby': labelId }}
      className='i-checkbox-table'
    />
  )
}
