import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

export const Container = styled.div`
  display: block;
`

export const GridContainer = styled(Grid)`
  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};

  .i-collapsible-top {
    padding: 16px;
  }

  .total-row {
    width: 100%;
  }

  .table-customers {
    width: 100%;

    thead tr th {
      font-weight: 400;
      color: #9d9d9d;
      border-bottom: 1px solid #e2e2e2;
    }

    tbody tr td {
      font-size: 14px;
      color: #9d9d9d;
      font-weight: 700;

      .MuiCheckbox-colorSecondary {
        padding: 0px;
      }
    }

    .table-buttons {
      white-space: nowrap;
      padding: 5.6px;
      min-width: 120px;
    }

    .last-update-icons {
      padding: 3.5px;
    }

    .send-report-checkbox {
      padding: 9px;
    }

    .table-bottom-items {
      padding-bottom: 23px;
    }

    .customers-emails {
      height: 38px;
    }

    .notification-origins {
      height: 38px;
    }

    .tooltip-user {
      maxwidth: 40px;
    }
  }

  .new-email {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 10px 0 0;
    width: ;
  }

  .email-text {
    width: 300px;
    padding-right: 20px;
  }
`

export const TitlePage = styled.div`
  display: flex;
  align-items: center;

  h3 {
    color: rgb(16, 6, 144);
    padding-right: 10px;
    font-size: 1.17em;
  }

  small {
    color: #666;
    font-size: 1.4rem;
  }
`

export const FormSearchCustomer = styled.form`
  display: flex;
  justify-content: center;
  position: relative;

  > div {
    padding-right: 61px;
  }

  button {
    position: relative;

    svg {
      font-size: 24px;
    }
  }
`

export const ButtonUi = styled(Button)`
  background-color: '#3f51b5';
  color: '#fff';

  svg {
    path {
      stroke: ${(props) => (props.disabled ? '#B7B7B7' : '#fff')};
    }
  }

  &:hover {
    background-color: '#2a3990';
  }
`

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;

  .MuiPaginationItem-root {
    font-size: 14px;
  }
`
export const Box = styled.div`
  width: 500px;
  height: auto;
  margin: 20px;
  max-height: 600px;

  .modal-modal-title {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }

  .buttons-modal {
    display: flex;
    justify-content: flex-end;
  }

  .modal-text {
    padding-bottom: 50px;
  }

  .modal-input-title {
    padding-top: 50px;
  }

  .email-format-error {
    font-size: 12px;
    position: relative;
    bottom: 40px;
    color: red;
  }

  .modal-customers-content {
    width: 500px;
    height: 700px;
    padding-bottom: 100%;
  }
`
export const IconButtonUi = styled(IconButton)`
  padding: 6px 6px;
`
