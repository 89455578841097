import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { findAggByRegion } from '../../domain/document/'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import AmChartsSerial from '../amcharts-serial'
import IsEmptyChart from '../amcharts-is-empty'
import config from './config.js'
import { get, isEmpty } from 'lodash'

const getRegions = (state) => get(state, 'getRegions.regions', [])

const ArRegion = (props) => {
  const { t } = useTranslation()
  const reduxDataProvider = useSelector(getRegions)
  const [stateDataProvider, setStateDataProvider] = useState(reduxDataProvider)
  const [state, setState] = useState(false)
  const [configGraph, setConfigGraph] = useState(config)

  const loading = () =>
    findAggByRegion().then((dataProvider) =>
      setStateDataProvider(dataProvider.data.body)
    )

  useEffect(() => {
    loading()
    setState(true)
  }, [state])

  useEffect(() => {
    setStateDataProvider(reduxDataProvider)
  }, [reduxDataProvider])

  useEffect(() => {
    const newConfigGraph = configGraph
    newConfigGraph.graphs[0].title = t('overdue')
    newConfigGraph.graphs[1].title = t('credit')
    newConfigGraph.graphs[2].title = t('dispute')
    newConfigGraph.graphs[3].title = t('notOverdue')
    setConfigGraph(newConfigGraph)
  }, [t('overdue')])

  return !isEmpty(stateDataProvider) ? (
    <Card>
      <CardContent>
        <Typography
          gutterBottom
          style={{ fontSize: '1.9rem', color: '#666666' }}>
          {props.title}
        </Typography>

        <AmChartsSerial
          dataConfig={configGraph}
          dataProvider={stateDataProvider}
        />
      </CardContent>
    </Card>
  ) : (
    <IsEmptyChart title={props.title} />
  )
}

export default ArRegion
