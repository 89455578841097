export default {
  usePrefixes: true,
  prefixesOfBigNumbers: [
    {
      number: 1e3,
      prefix: 'k'
    },
    {
      number: 1e6,
      prefix: 'm'
    },
    {
      number: 1e9,
      prefix: 'b'
    },
    {
      number: 1e12,
      prefix: 't'
    }
  ],
  valueAxes: [
    {
      id: 'ValueAxis-1',
      position: 'left',
      axisAlpha: 0,
      stackType: 'none',
      minimum: 0
    }
  ],
  type: 'serial',
  theme: 'light',
  startDuration: 2,
  legend: {
    align: 'center',
    useGraphSettings: true
  },
  chartCursor: {
    fullWidth: true,
    cursorAlpha: 0.1,
    categoryBalloonColor: '#100980',
    balloonPointerOrientation: 'horizontal'
  },
  categoryAxis: {
    autoRotateAngle: 43.2,
    autoRotateCount: 0,
    gridPosition: 'start',
    axisAlpha: 0,
    gridAlpha: 0
  },
  colors: ['#100980', '#DCDCDC', '#203D65', '#4080bf', '#A7D9E0', '#696969']
}
