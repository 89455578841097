import { list } from '../../../domain/account/company'
import { changeLoading } from '../../document-filter/__redux__/actions'

const GET_COMPANIES = 'GET_COMPANIES'

const getCompanies = (companies) => ({
  type: GET_COMPANIES,
  companies
})

const fetchCompanies = (query) => (dispatch) => {
  return list(query).then((companies) => {
    if (companies.data.error) {
      dispatch(changeLoading(false))
      dispatch(getCompanies([]))
    } else {
      dispatch(changeLoading(false))
      dispatch(getCompanies(companies.data))
    }
  })
}

export { GET_COMPANIES, fetchCompanies, getCompanies }
