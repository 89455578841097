import React, { useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { getFile } from '../../domain/report'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Loading from '../../component/loading'
import { setToastMessage } from '../snackbar/__redux__/actions'
import TooltipErrorMessage from '../../component/tooltip-error-message'

const ActionsButtons = ({ report, deleteReportById }) => {
  const [buttonState, setButtonState] = useState(false)
  const [stateError, setStateError] = useState(false)
  const dispatch = useDispatch()

  const downloadFile = async (fileLocation) => {
    setButtonState(true)
    setStateError(false)
    try {
      await getFile(fileLocation.split('/')['3'])
      setButtonState(false)
    } catch (err) {
      setStateError(true)
      dispatch(
        setToastMessage({
          open: true,
          variant: 'error',
          message: 'Error downloading report!'
        })
      )
      setButtonState(false)
    }
  }
  return (
    <Fragment>
      {buttonState ? (
        <IconButton disabled={buttonState}>
          <Loading downloadSmall />
        </IconButton>
      ) : (
        <IconButton onClick={() => downloadFile(report.fileLocation)}>
          <CloudDownloadIcon className='icon-actions' />
        </IconButton>
      )}
      {stateError ? (
        <TooltipErrorMessage error={'Document not found!'} />
      ) : null}
      <IconButton
        disabled={buttonState}
        onClick={() => deleteReportById(report.id)}>
        <DeleteIcon className='icon-actions' />
      </IconButton>
    </Fragment>
  )
}

export default ActionsButtons
