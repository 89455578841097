import React, { Fragment, Children, cloneElement, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLoading } from '../document-filter/__redux__/actions'
import { Tabs, Tab } from '../../component/tab/tab-style'
import { get } from 'lodash'

const getStatus = (state) => get(state, 'getFiltersQuotations.status', 'OPEN')

const a11yProps = (index) => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`
})

const valuesTabs = {
  'OPEN': 0,
  'CLOSED': 1
}

export default (props) => {
  const dispatch = useDispatch()
  const tabStatus = useSelector(getStatus)
  const [value, setValue] = useState(0)

  useEffect(() => {
    let tabStatusEf = valuesTabs[tabStatus]
    setValue(tabStatusEf)
  }, [tabStatus])

  return (
    <Fragment>
      <Tabs
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
          dispatch(changeLoading(true))
          props.onChange(newValue)
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {Children.map(props.children, child => (
          <Tab label={child.props.label} {...a11yProps(child.props.index)} />
        ))}
      </Tabs>
      {Children.map(props.children, child => cloneElement(child, { value }))}
    </Fragment>
  )
}
