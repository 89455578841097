import styled from 'styled-components'

export default styled.div`
  padding: 10px;

  .MuiFormControl-root {
    width: 100%;
  }

  label.MuiFormLabel-root {
    top: -7px;
    position: relative;
  }

  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    font-size: 16px;
    color: #666666;
    padding-bottom: 10px;
  }
`
