import React from 'react'

import { Route, Switch } from 'react-router-dom'

import logo from '../../assets/images/logo-embraer.png'

import ChangePassword from './change-password'
import FirstAccess from './first-access'
import ForgotPassword from './forgot-password'
import FormLogin from './form-login'
// import RegisterNow from './register-now'

import LoginAlert from '../../container/login-alert'
import { LoginContainer } from './styles'

export default () => {
  return (
    <LoginContainer>
      <div className='body-login'>
        <div className='logo-header'>
          <img src={logo} alt='Logo Embraer' />
        </div>
        <Switch>
          <Route component={LoginAlert} path='/login' exact />
        </Switch>
        <div className='forms-login'>
          <Switch>
            <Route component={FormLogin} path='/login' exact />
            <Route component={ForgotPassword} path='/login/forgot-password' />
            <Route
              component={ChangePassword}
              path='/login/change-password/:token'
            />
            {/* <Route component={RegisterNow} path='/login/register-now' /> */}
            <Route component={FirstAccess} path='/login/first-access' />
          </Switch>
        </div>
      </div>
    </LoginContainer>
  )
}
