import { CHANGE_SELECT_FILTER_ANALYTICAL  } from "./actions"

const INITIAL_STATE = {
    selects: [],
    status: 'OPEN'
}

export const getFiltersAnalytical = (state = INITIAL_STATE, action) => {
    if (action.type === CHANGE_SELECT_FILTER_ANALYTICAL) return { ...state, selects: [...action.selects] }
    return state
}