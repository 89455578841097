import styled from 'styled-components'
import logoFlyEmbraer from '../../assets/images/logo-embraer.png'
import theme from '../theme'

const Header = styled.header`
  padding: 8px 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background-color: ${theme.colors.antiFlashWhite};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  h1 {
    transform: translateX(-13px);
  }

  .i-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .i-logo-flyembraer {
    height: 48px;
    margin: 0;
    background: url(${logoFlyEmbraer}) no-repeat 0 0;
    background-size: contain;
  }

  .i-header-label {
    font-size: 1.4rem;
    font-weight: 400;
    vertical-align: middle;
    padding-left: 10px;
    border-left: 1px solid ${theme.colors.paleSilver};
    margin-left: 10px;
  }

  .i-header-action-filter {
    display: none;
  }

  @media (max-width: 650px) {
    background-color: ${theme.colors.embColor};

    .i-btn-toggle-aside {
      margin-right: 0;

      svg {
        fill: ${theme.colors.white};
      }
    }

    .i-logo-flyembraer {
      display: none;
    }

    .i-header-label {
      color: ${theme.colors.white};
      font-size: 1.9rem;
      padding: 0;
      margin: 0;
      border: none;
    }

    .i-profile {
      display: none;
    }

    .i-header-action-filter {
      display: block;

      .MuiSvgIcon-root {
        fill: ${theme.colors.white};
      }
    }
  }
`

export default Header
