import React from 'react'
import { get } from 'lodash'
import { pipe, set } from 'lodash/fp'

import {
  ExpansionPanel as ExpansionPanelComponent,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '../../component/expansion-panel'
import { SimpleTable } from '../../component/table'

const showAction = (value, show) => {
  return !get(value, 'id').match(/^9999\d+$/) && show
}

const ListExpansionPanel = (props) =>
  props.values.map((value, idx) => (
    <ExpansionPanelComponent className={props.className} key={`${idx}`}>
      <ExpansionPanelSummary
        aria-controls='panel1a-content'
        id='customer-setup-panel1a-header'>
        <div className={`i-collapsible-top`}>
          <p className='i-collapsible-top-label'>
            {get(value, 'id')} - {get(value, props.panelTitleField)} - {props.selectedPortal}
          </p>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <SimpleTable
          action={showAction(value, props.tableAction)}
          actionLabel={props.tableActionLabel}
          actionIcon={props.tableActionIcon}
          actionEvent={(selected) =>
            props.tableActionEvent(
              pipe(set(props.tableDataField)([selected]))(value)
            )
          }
          columns={props.tableColumns}
          data={get(value, props.tableDataField)}
        />
      </ExpansionPanelDetails>
    </ExpansionPanelComponent>
  ))

export default ListExpansionPanel
