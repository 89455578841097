import React, { Fragment } from 'react'

import ExpansionPanelFooter from '../../../component/expansion-panel/expansion-panel-footer'
import TableToolbar from './table-toolbar'

const TableFooter = (props) => {
  return (
    <Fragment>
      <ExpansionPanelFooter>
        <TableToolbar {...props} />
        {props.payNowWarning ? (
          <div className='paynow-warning'>{props.payNowWarning}</div>
        ) : null}
      </ExpansionPanelFooter>
    </Fragment>
  )
}

export default TableFooter
