import { pipe, set, get } from 'lodash/fp'
import {
  SET_DOCUMENTS_INTEREST_FREE,
  SET_PAGINATION_DOCUMENTS,
  LOAD_SHOW_MORE,
  TOGGLE_CONFIRM_SCREEN
} from '../__redux__/actions'

const INITIAL_STATE = {
  loadShowMore: false,
  confirmScreenOpen: false
}

const _compose = (state) => (documents) =>
  pipe(
    set('documents')([
      ...get('documents', state.list),
      ...get('documents', documents)
    ]),
    set('page')(get('page', documents)),
    set('pageSize')(get('pageSize', documents)),
    set('count')(get('count', documents))
  )({})

export default (state = INITIAL_STATE, action) => {
  if (action.type === SET_DOCUMENTS_INTEREST_FREE) {
    return { ...state, list: { ...action.payload } }
  }
  if (action.type === SET_PAGINATION_DOCUMENTS) {
    return { ...state, list: _compose(state)(action.payload) }
  }
  if (action.type === LOAD_SHOW_MORE) {
    return { ...state, loadShowMore: action.loadShowMore }
  }
  if (action.type === TOGGLE_CONFIRM_SCREEN) {
    return { ...state, confirmScreenOpen: !state.confirmScreenOpen }
  }
  return state
}
