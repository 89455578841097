import { getBearerAuthorization } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/documents/interest-free`

export const setInterest = async (id, isFree) => {
  const response = await axios.patch(
    `${url}/${id}/${isFree}`,
    {},
    {
      headers: getBearerAuthorization()
    }
  )
  if (response.status === 200) {
    return Promise.resolve({ message: 'set-interest-free-success' })
  } else {
    throw Error('set-interest-free-failure')
  }
}

export default async (id) => await setInterest(id, true)
