import styled from 'styled-components'

export const TotalWeeklyDatePicker = styled.div`
  display: flex;
  padding: 8px 0px 8px 0px;
  justify-content: flex-start;
  align-items: center;

  .MuiInputBase-root {
    .MuiFormHelperText-root {
      display: none;
    }

    .MuiInputBase-input {
      font-size: 1.9rem;
      color: rgb(102, 102, 102);
      width: 135px;
      cursor: default;
    }
  }
`
