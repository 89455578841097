import TableBodyMui from '@material-ui/core/TableBody'
import styled from 'styled-components'

const TableBody = styled(TableBodyMui)`
  border-right: 1px solid #dedede;

  .MuiTableCell-body {
    padding: 10px 5px;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;

    &.class-overdue {
      &:not(:nth-of-type(1)):not(:nth-of-type(2)) {
        color: rgba(245, 73, 79, 0.74);
      }
    }

    &:first-child {
      text-align: left;
      color: rgb(102, 102, 102);
      border-left: 5px solid #dedede;

      &.class-overdue,
      &.class-is-dispute {
        &.class-overdue {
          border-left-color: rgba(245, 73, 79, 0.74);
        }

        &.class-overdue,
        &.class-is-dispute {
          @media (max-width: 1131px) {
            border-left: none;
          }
        }
      }
    }

    @media (max-width: 1131px) {
      border: none;

      &:first-child {
        border-left: none;
      }
    }
  }

  @media (max-width: 1131px) {
    display: flex;
    flex-grow: 1;
    border: none;
    flex-direction: column;
  }
`

export default TableBody
