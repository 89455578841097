import { listManager } from '../../../domain/identity'

const GET_LEADERS = "GET_LEADERS"
const GET_LEADER_CAM_SELECTED = "GET_LEADER_CAM_SELECTED"
const GET_ACCOUNT_MANAGER = "GET_ACCOUNT_MANAGER"
const GET_CUSTOMER_GROUP = "GET_CUSTOMER_GROUP"
const GET_CUSTOMER = "GET_CUSTOMER"

const getLeaders = leaders => ({
  type: GET_LEADERS,
  leaders
})

const setLeadersSelected = leader => ({
  type: GET_LEADER_CAM_SELECTED,
  leader
})

const fetchLeaders = (query) => (dispatch) => listManager(query)
  .then(leaders => dispatch(getLeaders(leaders.data)))

export { 
  GET_LEADERS,
  GET_LEADER_CAM_SELECTED,
  GET_ACCOUNT_MANAGER,
  GET_CUSTOMER_GROUP,
  GET_CUSTOMER,
  
  fetchLeaders,
  setLeadersSelected
}




