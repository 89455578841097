import { getBearerAuthorization } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/accounting/download-file-aging`

const downloadFileAgingReport = async (source) => {
  return await axios.get(`${url}/${source}`, {
    headers: getBearerAuthorization()
  })
}

export default downloadFileAgingReport
