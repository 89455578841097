import { searchDisputeInformation } from '../../../domain/dispute'

const GET_DISPUTES_INFORMATION = 'GET_DISPUTES_INFORMATION'
const GET_PAGINATION_DISPUTES = 'GET_PAGINATION_DISPUTES'
const LOAD_SHOW_MORE_DISPUTES = 'LOAD_SHOW_MORE_DISPUTES'

const getDisputesInformation =
  ({ salesOrganization, currency }) =>
  (documents) => ({
    type: GET_DISPUTES_INFORMATION,
    row: {
      id: `${salesOrganization}-${currency}`,
      documents: documents.body.rows,
      page: parseInt(documents.body.page, 10),
      pageSize: parseInt(documents.body.pageSize, 10),
      count: documents.body.count
    }
  })

const getPaginationDocuments =
  ({ salesOrganization, currency }) =>
  (documents) => ({
    type: GET_PAGINATION_DISPUTES,
    row: {
      id: `${salesOrganization}-${currency}`,
      documents: documents.body.rows,
      page: parseInt(documents.body.page, 10),
      pageSize: parseInt(documents.body.pageSize, 10),
      count: documents.body.count
    }
  })

const setLoadShowMore = (loadShowMore) => ({
  type: LOAD_SHOW_MORE_DISPUTES,
  loadShowMore
})

const fetchDisputesInformation = (query) => (dispatch) =>
  searchDisputeInformation(query).then((documents) =>
    dispatch(getDisputesInformation(query)(documents.data))
  )

const fetchAllDocuments = (query) => (dispatch) =>
  searchDisputeInformation(query).then((documents) => {
    dispatch(getDisputesInformation(query)(documents.data))
    dispatch(setLoadShowMore(false))
  })

const fetchPaginationDocuments = (query) => (dispatch) =>
  searchDisputeInformation(query).then((documents) => {
    dispatch(getPaginationDocuments(query)(documents.data))
    dispatch(setLoadShowMore(false))
  })

export {
  GET_PAGINATION_DISPUTES,
  GET_DISPUTES_INFORMATION,
  fetchPaginationDocuments,
  fetchDisputesInformation,
  fetchAllDocuments,
  LOAD_SHOW_MORE_DISPUTES,
  setLoadShowMore
}
