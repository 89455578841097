import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { DialogTitle, DialogContent, DialogActions } from './dialog-style'

export default ({
  open,
  buttonClose,
  onClose,
  labelClose,
  label,
  fullWidth,
  maxWidth,
  iconLabelClose,
  children,
  ...otherProps
}) => (
  <Dialog
    onClose={onClose}
    open={open}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    {...otherProps}>
    <DialogTitle
      onClose={onClose}
      labelClose={labelClose}
      iconLabelClose={iconLabelClose}>
      {label}
    </DialogTitle>
    <DialogContent dividers>{children}</DialogContent>
    {buttonClose ? (
      <DialogActions>
        {onClose && labelClose ? (
          <Button autoFocus onClick={onClose} color='primary'>
            {labelClose}
          </Button>
        ) : null}
      </DialogActions>
    ) : null}
  </Dialog>
)
