const initialState = { 
  firstListChecked: [],
  secondListChecked: []
}

const reducer = (state, { type, checkeds }) => {
  if (type === 'firstListUpdate') return { ...state, firstListChecked: checkeds }
  if (type === 'secondListUpdate') return { ...state, secondListChecked: checkeds }
  return { ...state }
}

export { reducer, initialState }