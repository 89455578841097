import React from 'react'
import { useTranslation } from 'react-i18next'

import ButtonMenuList from '../../../component/button-menu-list'
import TablePagination from '../../../component/pagination'

import { ContainerFooterTop } from './styles'

const TableToolbar = (props) => {
  const { t } = useTranslation()

  return (
    <div className='i-collapsible-footer-top'>
      <ContainerFooterTop>
        <div className='btn-show-more-left'>
          <TablePagination {...props} className='i-pagination' />
        </div>

        <div className='btn-mais-opcoes-right'>
          <ButtonMenuList
            titleBtn={t('table-toolbar-btn-more-actions')}
            {...props}
          />
        </div>
      </ContainerFooterTop>
    </div>
  )
}

export default TableToolbar
