const SET_FINANCIAL_STATUS = 'SET_FINANCIAL_STATUS'
const SET_FINANCIAL_STATUS_LIST = 'SET_FINANCIAL_STATUS_LIST'

const changeFinancialStatusFilter = (selected) => ({
  type: SET_FINANCIAL_STATUS,
  selected
})

const applyChangeFinancialStatusFilter = (query) => (dispatch) => {
  dispatch(changeFinancialStatusFilter(query))
}

export {
  SET_FINANCIAL_STATUS,
  SET_FINANCIAL_STATUS_LIST,
  changeFinancialStatusFilter,
  applyChangeFinancialStatusFilter
}
