import React from 'react'
import TablePagination from '../../../component/pagination'

import { ContainerFooterTop } from './styles'

const TableToolbar = (props) => {
  return (
    <div className='i-collapsible-footer-top'>
      <ContainerFooterTop>
        <div className='btn-show-more-left'>
          <TablePagination {...props} className='i-pagination' />
        </div>
      </ContainerFooterTop>
    </div>
  )
}

export default TableToolbar
