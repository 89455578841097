import { getBearerAuthorization } from '../auth'
import axios from "axios"

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/types`

const findBySite = (query = {}) => axios
  .get(`${url}/find-by-site?${query}`, {
    headers: getBearerAuthorization()
  })

export {
  findBySite
}