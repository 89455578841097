import getSorted from './get-sorted'

export default (state, action) => {
  if (action.type === 'sort')
    return {
      ...state,
      ...getSorted(state.orderBy, state.order)(action.property)
    }
  if (action.type === 'changeRowsPerPage')
    return {
      ...state,
      page: action.page,
      rowsPerPage: +action.event.target.value
    }
  if (action.type === 'resetAll')
    return { ...state, selected: [], rowsSelecteds: [], statusCheckall: false }
  return state
}
