import { GET_CAMS, SET_CAM_SELECTED } from "./actions"

const INITIAL_STATE = {
  cams: [],
  camSelected: { label: "", value: "" }
}

export const getCams = (state = INITIAL_STATE, action) => {
  if (action.type === GET_CAMS) return { ...state, cams: action.cams }
  if (action.type === SET_CAM_SELECTED) return { ...state, camSelected: action.camSelected }
  return state
}
