import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TextField, Button } from '@material-ui/core'
import { IoIosArrowRoundBack } from 'react-icons/io'
import Loading from '../loading'

import { FormLoginContainer } from './style'
import { Link } from 'react-router-dom'

export default (props) => {
  const { t } = useTranslation()

  return (
    <FormLoginContainer onSubmit={props.onSubmit} autoComplete='off'>
      <Grid container>
        <Grid item>
          <h2>
            <Link to='/login'>
              <IoIosArrowRoundBack size='32' />
            </Link>
            {t(props.pageName)}
          </h2>
          <p>{t(props.pageText)}</p>
        </Grid>
      </Grid>
      <Grid container className='mb-16'>
        <Grid item xs={12}>
          <TextField
            label='E-mail'
            fullWidth
            value={props.value}
            onChange={props.onChange}
            required
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {props.loadingState ? (
            <Loading />
          ) : (
            <Button type='submit' fullWidth variant='contained' color='primary'>
              {props.buttonText}
            </Button>
          )}
        </Grid>
      </Grid>
    </FormLoginContainer>
  )
}
