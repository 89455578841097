import { findAggByGroup } from '../../../../domain/account/customer'

const SET_SELECTED_CUSTOMER_GROUP = 'SET_SELECTED_CUSTOMER_GROUP'
const SET_CUSTOMER_GROUP_LIST = 'SET_CUSTOMER_GROUP_LIST'
const SET_CUSTOMER_GROUP_PARAMS = 'SET_CUSTOMER_GROUP_PARAMS'
const SET_CUSTOMER_GROUP_NOT_FOUND_LIST = 'SET_CUSTOMER_GROUPS_NOT_FOUND_LIST'

const setSelected = (selected) => ({
  type: SET_SELECTED_CUSTOMER_GROUP,
  selected
})

const setList = (list) => ({
  type: SET_CUSTOMER_GROUP_LIST,
  list
})

const setParams = (params) => ({
  type: SET_CUSTOMER_GROUP_PARAMS,
  params
})

const fetchList = (query) => (dispatch) => {
  return findAggByGroup(query).then((customerGroups) => {
    dispatch(setList(customerGroups.data))
  })
}

const fetchParams = (query) => (dispatch) =>
  findAggByGroup(query).then((res) => {
    if (res.data.length) dispatch(setParams(res.data))
  })

const setNotFoundList = (list) => ({
  type: SET_CUSTOMER_GROUP_NOT_FOUND_LIST,
  list
})

const fetchNotFoundList = (query) => (dispatch) =>
  findAggByGroup(query).then((res) => {
    if (res.data.length) dispatch(setNotFoundList(res.data))
  })

export {
  SET_CUSTOMER_GROUP_LIST,
  SET_CUSTOMER_GROUP_PARAMS,
  SET_CUSTOMER_GROUP_NOT_FOUND_LIST,
  SET_SELECTED_CUSTOMER_GROUP,
  fetchList,
  setSelected,
  fetchParams,
  fetchNotFoundList
}
