import PopoverMui from '@material-ui/core/Popover'
import MenuItemMui from '@material-ui/core/MenuItem'
import theme from '../theme'
import styled, { css } from 'styled-components'

const WrapFilter = styled.div`
  margin-bottom: 20px;

  .i-container {
    display: block;

    .fixed-filter {
      display: flex;
    }

    #dashboard-filter {
      display: block;

      .drop-filter {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        grid-template-rows: auto;
        padding: 16px 5px;

        .drop-filter-footer {
          position: relative;
          text-align: right;
          display: block;
          padding: 16px 0px 0px 16px;
          .btn-clear {
            margin-right: 20px;
            justify-content: flex-end;
          }
          .btn-generate {
            margin-right: 16px;
          }
        }
        .drop-filter-footer-report {
          padding: 16px 0px 0px 16px;
          display: flex;

          .btn-generate {
            margin-right: 16px;
          }
        }
      }

      .drop-filter-customer-dashboard {
        display: grid;
        grid-template-columns: 50% 25%;
        grid-template-rows: auto;
        padding: 16px 5px;

        .drop-filter-footer {
          position: relative;
          text-align: right;
          display: block;
          padding: 16px 0px 0px 16px;
          .btn-clear {
            margin-right: 20px;
            justify-content: flex-end;
          }
          .btn-generate {
            margin-right: 16px;
          }
        }
        .drop-filter-footer-report {
          padding: 16px 0px 0px 16px;
          display: flex;

          .btn-generate {
            margin-right: 16px;
          }
        }
      }
    }
    #document-filter {
      display: block;

      .drop-filter {
        display: grid;
        grid-template-columns: 18% 18% 18% 16% 10% 20%;
        grid-template-rows: auto;
        padding: 16px 5px;

        @media screen and (min-width: 961px) and (max-width: 1368px) {
          grid-template-columns: 33% 33% 33%;
        }

        &-customer {
          grid-template-columns: 50% 20%;
        }

        @media screen and (min-width: 1441px) {
          &-customer {
            grid-template-columns: 35% 20%;
          }
        }

        .drop-filter-footer {
          position: relative;
          text-align: right;
          display: flex;
          padding: 16px 0px 0px 16px;

          .btn-clear {
            margin-right: 16px;
            justify-content: flex-end;
          }
          .btn-generate {
            margin-right: 16px;
          }
        }
      }
    }

    #dispute-filter {
      display: block;

      .drop-filter {
        display: grid;
        grid-template-columns: 18% 18% 18% 18% 18%;
        grid-template-rows: auto;
        padding: 16px 5px;

        @media screen and (min-width: 961px) and (max-width: 1368px) {
          grid-template-columns: 33% 33% 33%;
        }

        &-customer {
          grid-template-columns: 50% 20%;
        }

        @media screen and (min-width: 1441px) {
          &-customer {
            grid-template-columns: 35% 20%;
          }
        }

        .drop-filter-footer {
          position: relative;
          text-align: right;
          display: flex;
          padding: 16px 0px 0px 16px;

          .btn-clear {
            margin-right: 16px;
            justify-content: flex-end;
          }
          .btn-generate {
            margin-right: 16px;
          }
        }
      }
    }
  }

  .MuiTextField-root.MuiFormControl-marginDense {
    margin: 0;
    background-color: transparent;
  }

  @media (min-width: 0px) and (max-width: 640px) {
    .i-container {
      #dashboard-filter {
        .drop-filter {
          display: block;
        }
      }
    }
  }
  @media (min-width: 0px) and (max-width: 640px) {
    .i-container {
      #document-filter {
        .drop-filter {
          display: block;
        }
      }
    }
  }
  @media (min-width: 640.01px) and (max-width: 960px) {
    .i-container {
      #dashboard-filter {
        .drop-filter {
          grid-template-columns: 50% 50%;
        }
      }
    }
  }
  @media (min-width: 640.01px) and (max-width: 960px) {
    .i-container {
      #document-filter {
        .drop-filter {
          grid-template-columns: 50% 50%;
        }
      }
    }
  }
`

const Menu = styled(PopoverMui)`
  @media (max-width: 650px) {
    background-color: rgba(0, 0, 0, 0.5);

    .MuiButton-root {
      width: 100%;
    }
  }

  .MuiPopover-paper {
    width: 90%;
    overflow: initial;
    background-color: ${theme.colors.antiFlashWhite};

    @media (max-width: 650px) {
      width: auto;
      max-width: 280px;
      min-height: 100%;
      top: 0 !important;
      right: 0;
      left: auto !important;
      overflow: auto;
      border-radius: 0;
      background-color: ${theme.colors.white};
      transform: scale(1, 1) !important;
      overflow-y: auto;
    }

    ${(props) =>
      props.typeScreen === 'filterUserCustomer' &&
      css`
        width: 45%;

        &.MuiPopover-paper .drop-filter {
          > div {
            flex: 1 1 50%;

            @media (max-width: 650px) {
              flex: 1 1 100%;
            }
          }
        }
      `}

    ${(props) =>
      props.typeScreen === 'filterReport' &&
      css`
        width: 45%;
        min-width: 460px;

        @media (max-width: 650px) {
          min-width: auto;
        }

        &.MuiPopover-paper .drop-filter {
          > div {
            flex: 1 1 100%;
          }
        }
      `}
  }

  .MuiPopover-paper {
    padding: 20px 10px;

    .drop-filter {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      outline: none !important;

      > div {
        flex: 1 1 25%;
        padding: 0 10px 20px;
        position: relative;

        @media (max-width: 650px) {
          flex: 0 1 100%;
          padding: 0 10px 20px;
        }
      }

      .drop-filter-footer {
        padding-top: 7px;
        display: flex;
        justify-content: flex-end;

        .btn-clear {
          margin-right: 16px;
        }

        @media (max-width: 650px) {
          .MuiButtonBase-root {
            width: 100%;
          }
        }
      }
    }
  }

  .MuiMenuItem-root {
    color: #666666;
    font-size: 1.3rem;
    min-height: 39px;
    white-space: pre-line;

    &:hover {
      background-color: rgba(216, 216, 216, 0.5);
    }
  }
`

const MenuItem = styled(MenuItemMui)

export { WrapFilter, Menu, MenuItem }
