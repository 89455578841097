import React from 'react'
import Button from '../../component/button/index'
import SearchIco from '@material-ui/icons/Search'

const ButtonSearch = (props) => {
  return (
    <Button onClick={props.onClick} className="i-filter-button-search" type={props.type}>
      <SearchIco className="text-field-ico" />
    </Button>
  )
}

export default ButtonSearch