import { list } from '../../../../domain/account/customer'

const SET_CUSTOMER_LIST_INTEREST_FREE = 'SET_CUSTOMER_LIST_INTEREST_FREE'
const SET_SELECTED_CUSTOMER_INTEREST_FREE =
  'SET_SELECTED_CUSTOMER_INTEREST_FREE'
const SET_CUSTOMER_NOT_FOUND_LIST_INTEREST_FREE =
  'SET_CUSTOMER_NOT_FOUND_LIST_INTEREST_FREE'
const SET_PAGE_INTEREST_FREE = 'SET_PAGE_INTEREST_FREE'
const setList = (list) => ({
  type: SET_CUSTOMER_LIST_INTEREST_FREE,
  list
})

const setSelected = (selected) => {
  return {
    type: SET_SELECTED_CUSTOMER_INTEREST_FREE,
    selected
  }
}

const fetchList = (query) => (dispatch) => {
  return list(query).then((res) => dispatch(setList(res.data)))
}

const setCustomersNotFoundList = (list) => ({
  type: SET_CUSTOMER_NOT_FOUND_LIST_INTEREST_FREE,
  list
})

const fetchNotFoundList = (query) => (dispatch) =>
  list(query).then((customers) => {
    if (customers.data.length)
      dispatch(setCustomersNotFoundList(customers.data))
  })

export {
  SET_CUSTOMER_LIST_INTEREST_FREE,
  SET_CUSTOMER_NOT_FOUND_LIST_INTEREST_FREE,
  SET_SELECTED_CUSTOMER_INTEREST_FREE,
  SET_PAGE_INTEREST_FREE,
  fetchNotFoundList,
  setSelected,
  fetchList,
  setList
}
