import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import { Tab } from '../tabs-quotation'

import { applyStatusFilter } from '../quotation-filter/__redux__/actions'
import { fetchCompaniesQuotations } from '../company-quotations/__redux__/actions'

import tabs from './tabs.json'

const getTab = (index) => tabs.find((tab) => tab.index === index)
const getSelects = (state) => get(state, 'getFiltersQuotations.selects', [])
const getCriteria = (state) => get(state, 'getFiltersQuotations.criteria')

export default (props) => {
  const dispatch = useDispatch()
  const selects = useSelector(getSelects)
  const criteria = useSelector(getCriteria)
  return (
    <Tab
      onChange={(index) => {
        if (props.activeChange) {
          const tabIndex = getTab(index)
          if(typeof tabIndex !== 'undefined') {
            dispatch(applyStatusFilter(tabIndex.label))
            dispatch(fetchCompaniesQuotations({ selects, status: tabIndex.label, criteria }))
          }
        }
      }}>
      {props.children}
    </Tab>
  )
}
