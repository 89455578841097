import { SET_DOCUMENTS_DISPUTE, CHANGE_DISPUTE_SELECTS } from './actions'

const INITIAL_STATE = {
  selects: []
}

export const getDisputeSelects = (state = INITIAL_STATE, action) => {
  if (action.type === CHANGE_DISPUTE_SELECTS)
    return { ...state, selects: [...action.selects] }

  return state
}

export const getDisputeRows = (state = INITIAL_STATE, action) => {
  if (action.type === SET_DOCUMENTS_DISPUTE)
    return { ...state, selects: [...action.selects] }

  return state
}
