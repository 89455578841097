import React from 'react'
import AmCharts from '../amcharts'
import settings from './settings'

const amChartsPie = ({ dataConfig, dataProvider })=> {

  return (
    <AmCharts config={settings(dataConfig, dataProvider)} />
  )
}

export default amChartsPie