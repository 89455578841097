import { TOAST_MESSAGE } from './actions'

const INITIAL_STATE = {
  toastConfig: {
    open: false,
    variant: '',
    message: ''
  }
}

export const openToastMessage = (state = INITIAL_STATE, action = '') => {
  if (action.type === TOAST_MESSAGE) return { toastConfig: action.toastConfig }
  return state
}