import { GET_CUSTOMER_CREDIT_LIMIT } from './actions'

const INITIAL_STATE = {
  customerDocuments: []
}

export const getCustomerCreditLimit = (state = INITIAL_STATE, action) => {
  if (action.type === GET_CUSTOMER_CREDIT_LIMIT) {
    return { customerDocuments: [...action.customerDocuments.data.body] }
  }
  return state
}
