import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'

import translationPTBR from './pt-BR/translation.json'
import translationPt from './pt/translation.json'
import translationEn from './en/translation.json'
import translationENUS from './en-US/translation.json'

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,

    // lng: 'pt-BR',
    fallbackLng: ['en'],

    keySeparator: false,

    interpolation: {
      escapeValue: false
    },

    resources: {
      pt: { translations: translationPt },
      'pt-BR': { translations: translationPTBR },
      en: { translations: translationEn },
      'en-US': { translations: translationENUS }
    },

    ns: ['translations'],
    defaultNS: 'translations',

    react: {
      wait: true,
      useSuspense: false
    }
  })

export default i18n
