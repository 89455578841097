import React, { useState, useEffect } from 'react'

import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import ListItems from './list-items'
import ListSubHeader from './list-sub-header'
import getOr from './get-or'
import findCheckedById from './find-checked-by-id'
import findCheckedId from './find-checked-id'
import TriggerIdoc from '../trigger-idoc'

const ListSearch = (props) => {

  const [checked, setChecked] = useState([0])
  const [values, setValues] = useState(props.values)

  useEffect(() => {
    if (props.clean) setChecked([0])
  }, [props.clean])

  useEffect(() => {
    setValues(props.values)
  }, [props.values])

  return (
    <Grid item sm={12} md={6}>
      <Grid
        container
        direction='row'
        alignItems='center'
        style={{ padding: '14px 0 13px', minHeight: '72px' }}>
        <Grid item xs={7}>
          <h3
            style={{
              color: '#100690',
              paddingRight: '10px'
            }}>
            {props.title}
          </h3>
          <span className='i-label-count'>{props.subTitle}</span>
        </Grid>

        <Grid item xs={5}>
          {props.id === 'list-right-id' ? <TriggerIdoc /> : null}
        </Grid>
      </Grid>
      <Card>
        <List
          id={props.id}
          className={props.className}
          component='nav'
          aria-labelledby='nested-list-subheader'
          subheader={
            <ListSubHeader
              onFilter={async (filter) => {
                return filter
                  ? setValues(await getOr(props.values)(filter)(props.findOr))
                  : setValues(props.values)
              }}
              searchName={props.searchName}
              searchPlaceholder={props.searchPlaceholder}
            />
          }>
          <ListItems
            values={values}
            onChecked={(valueId) => {
              const checkedsId = findCheckedId(props)(checked)(valueId)
              setChecked(checkedsId)
              props.onChecked(findCheckedById(values)(checkedsId))
            }}
            checked={checked}
          />
        </List>
      </Card>
    </Grid>
  )
}

export default ListSearch
