import { GET_OVERDUE_PER_CUSTOMERS } from './actions'

const INITIAL_STATE = {
  overduePerCustomers: []
}

export const getOverduePerCustomers = (state = INITIAL_STATE, action) => {
  if(action.type === GET_OVERDUE_PER_CUSTOMERS) {
    return { overduePerCustomers: [...action.overduePerCustomers.data.body] }
  }
  return state
}