import styled from 'styled-components'

export const ContainerBankList = styled.section`
  table {
    width: 100%;

    thead {
      tr {
        th {
          text-align: left;
          font-weight: 400;
          color: #9E9E9E;
          font-size: 13px;
          padding: 12px;
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: left;
          font-weight: 400;
          color: #666666;
          font-size: 15px;
          padding: 12px;
        }
      }
    }
  }
`