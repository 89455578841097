import React from 'react'
import AmCharts from '../amcharts'
import settings from './settings'

const amChartsGroupColumns = (props) => {
  const dataConfig = props.dataConfig
  const dataProvider = props.dataProvider
  const height = props.height

  return (
    <AmCharts height={height} config={settings(dataConfig, dataProvider)} />
  )
}

export default amChartsGroupColumns
