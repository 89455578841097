import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '../../component/checkbox'
import PropTypes from 'prop-types'

const ListItems = (props) =>
  props.values.map((value) => (
    <ListItem
      key={value.id}
      role={undefined}
      dense
      button
      onClick={() => props.onChecked(value.id)}>
      <ListItemIcon>
        <Checkbox
          edge='start'
          checked={props.checked.indexOf(value.id) !== -1}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': value.id }}
        />
      </ListItemIcon>
      <ListItemText id={value.id} primary={`${value.id} - ${value.name}`} />
    </ListItem>
  ))

ListItems.propTypes = {
  values: PropTypes.array.isRequired,
  onChecked: PropTypes.func.isRequired,
  checked: PropTypes.array.isRequired
}

export default ListItems
