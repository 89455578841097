import styled from 'styled-components'

export const CompanyInformationContainer = styled.div`
  .mb-16px {
    margin-bottom: 16px;
  }

  .MuiInputLabel-filled.MuiInputLabel-shrink {
    font-size: 16px;
  }

  .MuiFilledInput-root.Mui-disabled {
    background: rgba(0, 0, 0, 0.05);
  }

  .MuiFilledInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.36);
  }

  .MuiInputBase-input.Mui-disabled {
    font-size: 16px;
  }
`
