import { getBearerAuthorization } from '../auth'
import axios from 'axios'
import download from 'downloadjs'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/reports`

export default async (id = '') => {
  const { data } = await axios.get(`${url}/${id}/xlsx`, {
    headers: getBearerAuthorization()
  })

  const base64 = data.message.replace(/"+/g, '')
  return download(
    `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${base64}`,
    id,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  )
}
