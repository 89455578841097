import styled, { css } from 'styled-components'

export const NoDataFound = styled.div`
    font-size: 18px;
    color: '#999';
    text-align: center;

    ${props =>
        props.report &&
        css`
            padding-top: 30px;
        `}
`;
