import { GET_CARDS_ANALYTICAL } from './actions'
import { get, set, pipe } from 'lodash/fp'

const INITIAL_STATE = {
  cards: []
}

const cards = [
  {
      title: 'Overdue',
      class: 'i-ico-overdue-color',
      ico: 'error_outline',
      key: 'overdue',
      type: 'number'
  },
  {
      title: 'Credit',
      class: 'i-ico-credit-color',
      ico: 'attach_money',
      key: 'credit',
      type: 'number'
  },
  {
      title: 'Dispute',
      class: 'i-ico-disput-color',
      ico: 'gavel',
      key: 'dispute',
      type: 'number'
  },
  {
      title: 'Not Overdue',
      class: 'i-ico-not-overdue-color',
      ico: 'event_available',
      key: 'notOverdue',
      type: 'number'
  },
  {
      title: 'Updated At',
      class: 'i-ico-updated-at-color',
      ico: 'history',
      key: 'schedulerDate',
      type: 'date'
  }
]

const setCardsValues = (cardsValues) => cards
  .map(card => pipe(
      set('value')(get(card.key, cardsValues))
    )(card)
  ) 

export const getCardsAnalytical = (state = INITIAL_STATE, action) => {
  if (action.type === GET_CARDS_ANALYTICAL) {
    const builtCards = setCardsValues(action.cards.data.body)
    return { cards: [...builtCards] }
  }

  return state
}