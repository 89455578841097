import React, { useState } from 'react'

import { MenuItem } from '@material-ui/core'
import { AccountCircle, Block, Email, Help, MoreVert } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { resendCollectionLetter, updateUserStatus } from '../../domain/user'

import Tooltip from '../tooltip'
import { ActionsButton, MenuContainer } from './styles'

import Swal from 'sweetalert2'

const ActionsMenu = ({
  email,
  idxAccount,
  userId,
  usersFromCustomersId,
  emailId,
  setUpdateOptions,
  customerCode,
  origin,
  notificationModal
}) => {
  const { t } = useTranslation()

  const [isMenuOpen, setIsMenuOpen] = useState()
  const open = Boolean(isMenuOpen)

  const handleClick = (event) => {
    setIsMenuOpen(event.currentTarget)
  }

  const handleClose = () => {
    setIsMenuOpen(null)
  }

  const handleUpdateUserStatusButton = async () => {
    Swal.fire({
      title: t('user-confirm-status-update'),
      text: usersFromCustomersId
        ? `${t('user-confirm-disable-message')}${email}`
        : `${t('user-confirm-enable-message')}${email}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: t('user-cancel-button'),
      confirmButtonText: t('user-update-status-confirm-button')
    }).then(async (result) => {
      if (result.value) {
        try {
          await updateUserStatus({
            email,
            usersFromCustomersId,
            userId,
            emailId,
            customerCode
          })

          Swal.fire(
            t('success'),
            usersFromCustomersId
              ? t('user-disabled-message')
              : t('user-enabled-message'),
            'success'
          )
          setUpdateOptions({ update: true, accountIndex: idxAccount })
        } catch (err) {
          Swal.fire(
            t('error'),
            usersFromCustomersId
              ? t('user-disable-error-message')
              : t('user-enable-error-message'),
            'error'
          )
        }
      }
    })

    handleClose()
  }

  const handleResendCollectionLetter = async () => {
    Swal.fire({
      title: t('user-confirm-status-update'),
      text: `${t('user-resend-collection-letter-message')} ${email}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: t('user-cancel-button'),
      confirmButtonText: t('user-update-status-confirm-button')
    }).then(async (result) => {
      if (result.value) {
        try {
          await resendCollectionLetter({
            customerCode,
            email
          })

          Swal.fire(
            t('success'),
            `Collection letter sent to ${email}`,
            'success'
          )
          setUpdateOptions({ update: true, accountIndex: idxAccount })
        } catch (err) {
          let response = err.response.data.body
          let message = ''

          if (/(User is no longer)/.test(response))
            message = t('user-disabled-ar-report-error')
          else if (/(There is nothing)/.test(response))
            message = t('customer-without-invoices-error')
          else message = t('user-collection-letter-error')

          Swal.fire(t('error'), message, 'error')
        }
      }
    })

    handleClose()
  }

  const handlerNotification = () => {
    notificationModal({ email, customerCode })
    handleClose()
  }

  return (
    <>
      <ActionsButton
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <MoreVert />
      </ActionsButton>
      <MenuContainer
        id='basic-menu'
        anchorEl={isMenuOpen}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        {origin === 'WEB' ? (
          <>
            <MenuItem onClick={handleUpdateUserStatusButton}>
              {usersFromCustomersId ? (
                <>
                  <Block className='action-icons' />
                  {t('user-disable-menu')}
                </>
              ) : (
                <>
                  <AccountCircle className='action-icons' />
                  {t('user-enable-menu')}
                </>
              )}
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem>
              <>
                <Help className='action-icons' />
                <Tooltip
                  textTooltip={t('user-sap-unavaiable-action')}
                  color='#ffffff'
                  position='top'
                  backgroundColor='rgba(0,0,0,0.75)'>
                  <span>{t('user-unavaiable-manage-user')}</span>
                </Tooltip>
              </>
            </MenuItem>
          </>
        )}
        <MenuItem onClick={handleResendCollectionLetter}>
          <Email className='action-icons' />
          {t('user-resend-email-menu')}
        </MenuItem>
        <MenuItem
          onClick={handlerNotification}>
          <Email className='action-icons' />{t('user-notification')}
        </MenuItem>
      </MenuContainer>
    </>
  )
}

export default ActionsMenu
