import React, { useState, useEffect } from 'react'
import {
  saveCustomerEmailJob,
  deleteEmailJob
} from '../../domain/account/customers-emails'

import Checkbox from '../../component/checkbox'

export default ({ email, disabled, checkboxAllSend, checkboxAllPay }) => {
  const [statusCheck, setStatusCheck] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleCheckboxJobs = async (emailId) => {
    await saveCustomerEmailJob(emailId)
    setStatusCheck(true)
  }

  const handleUnCheckboxJobs = async (emailId) => {
    await deleteEmailJob(emailId)
    setStatusCheck(false)
  }

  async function updatedAllCheck() {
    if (checkboxAllSend || checkboxAllPay) await handleCheckboxJobs(email.id)
    else await handleUnCheckboxJobs(email.id)
  }

  useEffect(() => {
    if (loading) updatedAllCheck()
  }, [checkboxAllSend, checkboxAllPay])

  useEffect(() => {
    setLoading(true)
    setStatusCheck(checkboxAllSend)
  }, [])

  return (
    <Checkbox
      disabled={disabled}
      onChange={() => {
        statusCheck
          ? handleUnCheckboxJobs(email.id)
          : handleCheckboxJobs(email.id)
      }}
      checked={statusCheck}
    />
  )
}
