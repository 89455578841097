import { findAggByLeaderAccountManager } from '../../../domain/document/'

const GET_LEADERS_DASH = "GET_LEADERS_DASH"
const getLeadersDash = leadersDash => ({
  type: GET_LEADERS_DASH,
  leadersDash
})

const fetchLeadersDash = (query) => (dispatch) => findAggByLeaderAccountManager(query)
  .then(leadersDash => dispatch(getLeadersDash(leadersDash)))

export { 
  GET_LEADERS_DASH,
  fetchLeadersDash
}