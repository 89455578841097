const CHANGE_CRITERIA_FILTER = 'CHANGE_CRITERIA_FILTER'
const CHANGE_SELECT_FILTER = 'CHANGE_SELECT_FILTER'
const CHANGE_STATUS_FILTER = 'CHANGE_STATUS_FILTER'
const RESET_SEARCH = 'RESET_SEARCH'
const UPDATE_LOADING = 'UPDATE_LOADING'

const changeCriteriaFilter = (criteria) => ({
  type: CHANGE_CRITERIA_FILTER,
  criteria
})

const applyCriteriaFilter = (query) => (dispatch) => {
  dispatch(changeCriteriaFilter(query))
}

const changeSelectFilter = (selects) => ({
  type: CHANGE_SELECT_FILTER,
  selects
})

const applySelectFilter = (query) => (dispatch) => {
  dispatch(changeSelectFilter(query))
}

const changeStatusFilter = (status) => ({
  type: CHANGE_STATUS_FILTER,
  status
})

const applyStatusFilter = (query) => (dispatch) => {
  dispatch(changeStatusFilter(query))
}

const resetSelectsSearch = () => ({
  type: RESET_SEARCH
})

const changeLoading = (loading) => ({
  type: UPDATE_LOADING,
  loading
})

export {
  CHANGE_CRITERIA_FILTER,
  CHANGE_SELECT_FILTER,
  CHANGE_STATUS_FILTER,
  RESET_SEARCH,
  UPDATE_LOADING,
  applyCriteriaFilter,
  applySelectFilter,
  applyStatusFilter,
  resetSelectsSearch,
  changeCriteriaFilter,
  changeSelectFilter,
  changeLoading
}
