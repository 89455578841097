const TOAST_MESSAGE = "TOAST_MESSAGE"
const setToast = (toast) => ({
  type: TOAST_MESSAGE,
  toastConfig: { ...toast }
})

const setToastMessage = (toast) => (dispatch) => {
  dispatch(setToast(toast))
}

export { 
  TOAST_MESSAGE,
  setToastMessage
}
