import deburr from 'lodash/deburr'
import { toArray, isEmpty, first, upperCase } from 'lodash'

const containsCharacter =
  (character = '') =>
  (words) =>
    !isEmpty(
      words.find((word = '') =>
        JSON.stringify(word).toLowerCase().includes(character.toLowerCase())
      )
    )

const shouldKeep =
  (value, suggestion, limitSuggestion) => (numberOfItemsShown) => {
    const getLimit = limitSuggestion ? limitSuggestion : 10

    return (
      numberOfItemsShown < getLimit &&
      containsCharacter(value)(toArray(suggestion))
    )
  }

const getSuggestion = (
  value,
  suggestions,
  limitSuggestion,
  disableFilterSuggestion
) => {
  if (disableFilterSuggestion) return suggestions

  let numberOfItemsShown = 0

  return suggestions.filter((suggestion) => {
    const keep = shouldKeep(
      value,
      suggestion,
      limitSuggestion
    )(numberOfItemsShown)
    if (keep) numberOfItemsShown += 1
    return keep
  })
}

const verifySingleSuggestion = (
  value,
  suggestion,
  setSingleSuggestion,
  showAttributesAsLabel
) => {
  const singleSuggestion = first(suggestion)

  for (let r = 0; r < showAttributesAsLabel.length; r++) {
    const nameIndex = showAttributesAsLabel[r]

    if (upperCase(singleSuggestion[nameIndex]) === upperCase(value))
      setSingleSuggestion(suggestion)
  }
}

export default (
  value,
  { showEmpty = false } = {},
  suggestions = [],
  notifySuggestionNotFound,
  setSingleSuggestion,
  showAttributesAsLabel,
  limitSuggestion,
  disableFilterSuggestion
) => {
  const inputValue = deburr(value.trim()).toLowerCase()
  const inputLength = inputValue.length
  const suggestion = getSuggestion(
    inputValue,
    suggestions,
    limitSuggestion,
    disableFilterSuggestion
  )

  if (disableFilterSuggestion) return suggestion
  else {
    if (inputLength === 0 && !showEmpty) return []

    if (!suggestion.length) notifySuggestionNotFound({ criteria: value })

    if (suggestion.length === 1)
      verifySingleSuggestion(
        value,
        suggestion,
        setSingleSuggestion,
        showAttributesAsLabel
      )

    return suggestion
  }
}
