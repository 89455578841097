import { GET_LEADERS_DASH } from "./actions"

const INITIAL_STATE = {
    leadersDash: []
}

export const getLeadersDash = (state = INITIAL_STATE, action) => {
  if (action.type === GET_LEADERS_DASH)
    return { ...state, leadersDash: [...action.leadersDash.data.body] }

  return state
}