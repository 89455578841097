import { list } from '../../../../domain/account/customer'

const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST'
const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER'
const SET_CUSTOMER_NOT_FOUND_LIST = 'SET_CUSTOMER_NOT_FOUND_LIST'

const setList = (list) => ({
  type: SET_CUSTOMER_LIST,
  list
})

const setSelected = (selected) => {
  return {
    type: SET_SELECTED_CUSTOMER,
    selected
  }
}

const fetchList = (query) => (dispatch) => {
  return list(query).then((res) => dispatch(setList(res.data)))
}

const setCustomersNotFoundList = (list) => ({
  type: SET_CUSTOMER_NOT_FOUND_LIST,
  list
})

const fetchNotFoundList = (query) => (dispatch) =>
  list(query).then((customers) => {
    if (customers.data.length)
      dispatch(setCustomersNotFoundList(customers.data))
  })

export {
  SET_CUSTOMER_LIST,
  SET_CUSTOMER_NOT_FOUND_LIST,
  SET_SELECTED_CUSTOMER,
  fetchNotFoundList,
  setSelected,
  fetchList,
  setList
}
