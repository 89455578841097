import styled from 'styled-components'

export const NewUserContainer = styled.div`
  background-color: #fafafa;
  display: flex;
  justify-content: center;

  .new-user-center {
    width: 874px;

    .header-new-user {
      padding: 16px 52px;
    }

    .body-new-user {
      display: block;
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      border-radius: 4px;

      .load-steps {
        padding: 16px 52px;
      }

      .footer-new-user {
        display: flex;
        padding: 16px 52px;
        justify-content: space-between;

        .MuiButton-containedPrimary {
          background-color: #005baf;
          &:hover {
            background-color: #004b90;
          }
        }
        
        .MuiButton-containedSecondary {
          background-color: #ff0000;
          margin-right: 8px;
          &:hover {
            background-color: #b91414;
          }
        }
      }

      .MuiStepIcon-root.MuiStepIcon-active,
      .MuiStepIcon-root.MuiStepIcon-completed {
        color: #003a70;
      }

      .MuiSvgIcon-root {
        width: 3rem;
        height: 3rem;

        .MuiStepIcon-text {
          font-size: 12px;
        }
      }

      .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        font-size: 14px;

        &.MuiStepLabel-active,
        &.MuiStepLabel-completed {
          font-weight: 700;
        }
      }
    }
  }
`
