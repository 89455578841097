import React from 'react'
import TableBody from './table-body'
import TableHead from './table-head'
import TableRow from './table-row'
import Table from './table'
import composeSimpleTableHead from './compose-simple-table-head'
import composeSimpleTableBody from './compose-simple-table-body'

export default (props) => (
  <Table className="simple-table" aria-label="simple table">
    <TableHead>
      <TableRow>{composeSimpleTableHead(props)}</TableRow>
    </TableHead>
    <TableBody>
      {props.data.map((row, idx) => (
        <TableRow key={`${idx}`}>
          {composeSimpleTableBody(row)(props)}
        </TableRow>
      ))}
    </TableBody>
  </Table>
)
