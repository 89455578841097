import React from 'react'
import {
  WrapAlert,
  AlertTitle,
  AlertInfo,
  AlertIcoClose,
  AlertBigIco
} from './alert-style'

const closable = ({ closable, onAlertClick }) => {
  return closable ? (
    <AlertIcoClose onClick={() => onAlertClick(false)}>
      <i className='material-icons close-ico'>close</i>
    </AlertIcoClose>
  ) : (
    ''
  )
}

const renderIco = (type) => {
  if (type === 'info') return 'info'
  if (type === 'warning') return 'warning'
  if (type === 'success') return 'check_circle'
  if (type === 'danger') return 'report_problem'
}

const Alert = ({ onAlertClick ,...props }) => {
  return props.showAlert ? (
    <WrapAlert {...props}>
      <AlertTitle>{props.title}</AlertTitle>

      <AlertBigIco className='material-icons alert-big-ico'>
        {renderIco(props.type)}
      </AlertBigIco>

      {closable({ ...props, onAlertClick})}

      <AlertInfo>
        {props.message}
        <a
          style={{ color: 'blue' }}
          href='mailto:accounts.receivable@embraer.com.br'>
          {props.email}
        </a>
      </AlertInfo>
    </WrapAlert>
  ) : null
}

export default Alert
