const UPDATE_FULL_NAME = 'UPDATE_FULL_NAME'
const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
const UPDATE_PASSWORD_CONFIRM = 'UPDATE_PASSWORD_CONFIRM'
const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER'
const UPDATE_ERROR_FULL_NAME = 'UPDATE_ERROR_FULL_NAME'
const UPDATE_ERROR_PASSWORD = 'UPDATE_ERROR_PASSWORD'
const UPDATE_ERROR_PASSWORD_CONFIRM = 'UPDATE_ERROR_PASSWORD_CONFIRM'

const setFullName = (fullName) => {
  return {
    type: UPDATE_FULL_NAME,
    fullName
  }
}

const setPassword = (password) => {
  return {
    type: UPDATE_PASSWORD,
    password
  }
}

const setPasswordConfirm = (passwordConfirm) => {
  return {
    type: UPDATE_PASSWORD_CONFIRM,
    passwordConfirm
  }
}

const setErrorFullName = (errorFullName) => {
  return {
    type: UPDATE_ERROR_FULL_NAME,
    errorFullName
  }
}

const setErrorPassword = (errorPassword) => {
  return {
    type: UPDATE_ERROR_PASSWORD,
    errorPassword
  }
}

const setErrorPasswordConfirm = (errorPasswordConfirm) => {
  return {
    type: UPDATE_ERROR_PASSWORD_CONFIRM,
    errorPasswordConfirm
  }
}

const setPhoneNumber = (phoneNumber) => {
  return {
    type: UPDATE_PHONE_NUMBER,
    phoneNumber
  }
}

export {
  setFullName,
  setPassword,
  setPasswordConfirm,
  setErrorFullName,
  setErrorPassword,
  setErrorPasswordConfirm,
  setPhoneNumber,

  UPDATE_FULL_NAME,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_CONFIRM,
  UPDATE_ERROR_FULL_NAME,
  UPDATE_ERROR_PASSWORD,
  UPDATE_ERROR_PASSWORD_CONFIRM,
  UPDATE_PHONE_NUMBER
}
