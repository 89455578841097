import { GET_LEADERS, GET_LEADER_CAM_SELECTED } from "./actions"

const INITIAL_STATE = {
  leaders: [],
  leader: { label: "", value: "" }
}

export const getLeaders = (state = INITIAL_STATE, action) => {
  if (action.type === GET_LEADERS) return { ...state, leaders: [...action.leaders] }

  if (action.type === GET_LEADER_CAM_SELECTED) return { ...state, leader: action.leader }

  return state
}