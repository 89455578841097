import { getBearerAuthorization } from '../auth'
import { pipe, set } from 'lodash/fp'
import { stringify } from 'query-string'
import { get } from 'lodash'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/user`

const updateUserStatus = async ({
  email,
  usersFromCustomersId,
  userId,
  emailId,
  customerCode
}) => {
  return await axios.put(
    `${url}/manage-user-availability`,
    { email, usersFromCustomersId, userId, emailId, customerCode },
    {
      headers: getBearerAuthorization()
    }
  )
}

const saveUser = async ({ email, customerCode }) => {
  await axios.post(
    `${url}/register-new-user`,
    { email, customerCode },
    {
      headers: getBearerAuthorization()
    }
  )
}

const resendCollectionLetter = async ({ customerCode, email }) => {
  return await axios.post(
    `${url}/resend-collection-letter`,
    { customerCode, email },
    {
      headers: getBearerAuthorization()
    }
  )
}

const sendSupportEmail = async (formData) => {
  return await axios.post(`${url}/send-support-email`, formData, {
    headers: {
      ...getBearerAuthorization(),
      'Content-Type': 'multipart/form-data'
    }
  })
}

const _composeFilter = ({ selects = [] }) =>
  selects
    ? selects.map((filter) =>
        pipe(set(get(filter, 'name'))(get(filter, 'selected.value')))({})
      )
    : {}

const findCustomerCreditLimit = async (query = {}) => {
  query = Object.assign({}, ..._composeFilter(query))

  return await axios.get(
    `${url}/find-customer-credit-limit?${stringify(query)}`,
    {
      headers: getBearerAuthorization()
    }
  )
}

export {
  updateUserStatus,
  saveUser,
  resendCollectionLetter,
  sendSupportEmail,
  findCustomerCreditLimit
}
