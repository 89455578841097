import { stringify } from 'query-string'
import { getBearerAuthorization } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/identities`

const list = (query = {}) => {
  return axios.get(`${url}?${stringify(query)}`, {
    headers: getBearerAuthorization()
  })
}

const listManager = (query = {}) =>
  axios.get(`${url}/find-manager?${stringify(query)}`, {
    headers: getBearerAuthorization()
  })

const verifyUserTcs = async ({ system, userLogin, userEmail, customers }) => {
  const customersMap = customers.map((ctm) => ctm.code)
  return await axios.post(`${process.env.REACT_APP_TCS_API}/verifyusersystem`, {
    system,
    userLogin,
    userEmail,
    customers: customersMap
  })
}
const findAllCrmCsi = (query = {}) =>
  axios.get(`${url}/find-every-crm-csi?${stringify(query)}`, {
    headers: getBearerAuthorization()
  })

export { list, listManager, verifyUserTcs, findAllCrmCsi }
