import * as _ from 'lodash'
import { pipe, set } from 'lodash/fp'
import moment from 'moment'
import formatter from './formatter'

const settings = {
  initialGraphWeekly: (dataProvider) => {
    const graphsMap = dataProvider.map((dado) => {
      const yearMonth = moment(dado.referenceWeek).format('YYYY/MM')
      return {
        fillAlphas: 1,
        lineAlpha: 0.3,
        title: `${yearMonth}`,
        type: 'column',
        valueField: `${yearMonth}`
      }
    })

    const uniqGraphs = _.uniqBy(graphsMap, 'valueField')

    const configInitial = {
      graphs: uniqGraphs,
      categoryField: 'referenceWeek',
      usePrefixes: true,
      prefixesOfBigNumbers: [
        {
          number: 1e3,
          prefix: 'k'
        },
        {
          number: 1e6,
          prefix: 'm'
        },
        {
          number: 1e9,
          prefix: 'b'
        },
        {
          number: 1e12,
          prefix: 't'
        }
      ],
      valueAxes: [
        {
          id: 'ValueAxis-1',
          position: 'left',
          axisAlpha: 0,
          stackType: 'none',
          minimum: 0
        }
      ],
      type: 'serial',
      theme: 'light',
      startDuration: 2,
      legend: {
        align: 'center',
        useGraphSettings: true
      },
      chartCursor: {
        fullWidth: true,
        cursorAlpha: 0.1,
        categoryBalloonColor: '#100980',
        balloonPointerOrientation: 'horizontal'
      },
      categoryAxis: {
        autoRotateAngle: 43.2,
        autoRotateCount: 0,
        gridPosition: 'start',
        axisAlpha: 0,
        gridAlpha: 0
      },
      colors: ['#100980', '#DCDCDC', '#203D65', '#4080bf', '#A7D9E0', '#696969']
    }

    return configInitial
  },

  configDataProviderWeekly: (dataProvider, namesWeeks) =>
    formatter(dataProvider, namesWeeks),

  groupByMonthly: (result) => {
    const accounting = _.chain(result)
      .groupBy('moth')
      .toPairs()
      .value()

    return accounting
  },

  configureDataProvider: (accounting) => {
    const dataProvider = accounting.map((row) =>
      row[1].reduce(
        (previous, next) =>
          pipe(
            set('id')(`${next.moth}-${next.year}`),
            set('month')(next.moth),
            set('monthTitle')(moment(next.moth, 'MM').format('MMM')),
            set(next.year)(next.amount)
          )(previous),
        {}
      )
    )

    const dataProviderOrdened = _.orderBy(dataProvider, ['id'], ['asc'])

    return dataProviderOrdened
  },

  configGraphs: (result, title) => {
    const graphs = _.orderBy(
      result.map((accounting) => {
        return {
          balloonText: `<b>${title}: [[value]] USD</b>`,
          fillAlphas: 1,
          lineAlpha: 0.3,
          title: accounting.year,
          type: 'column',
          valueField: accounting.year,
          hidden: false
        }
      }),
      ['valueField'],
      ['asc']
    )

    const uniqGraphs = _.uniqBy(graphs, 'valueField')

    const graphsSetHidden = uniqGraphs.map((graph, index) => {
      graph.hidden = index >= 0 ? false : true
      return graph
    })

    return graphsSetHidden
  }
}

export default settings
