import React, { useReducer, useState, useEffect } from 'react'
import { Table as TableUI } from '../../component/table'
import { hasGateway, hasAllowedCurrency } from '../../domain/document/'
import TableBody from './body'
import useStyles from './style'
import reducer from './reducer'
import TableHeader from './header'
import TableFooter from './footer'
import isClosed from './is-closed'
import showFiscalNumber from './show-fiscal-number'
import hasOneCustomerSelected from './has-one-customer-selected'
import getTotalAmount from './get-total-amount'
import getTotalCredit from './get-total-credit'
import isAmountValid from './amount-limit'
import isDisputeAlowed from './is-dispute-alowed'
import { get } from 'lodash'
import { useSelector } from 'react-redux'

const initialState = {
  order: 'desc',
  orderBy: 'paidIn',
  selected: [],
  rowsSelecteds: [],
  page: 0,
  rowsPerPage: 50,
  statusCheckall: false
}

const getInputSearch = (state) => get(state, 'inputSearchRedux.textFilter', '')
const getInputLeaderCamSelected = (state) =>
  get(state, 'crmLeader.selected', { label: '', value: '' })
const getAccountManagerSelected = (state) =>
  get(state, 'crm.selected', { label: '', value: '' })
const getCustomerGroupSelected = (state) =>
  get(state, 'customerGroup.selected', { label: '', value: '' })
const getCustomerSelected = (state) =>
  get(state, 'customer.selected', { label: '', value: '' })

const getCsiSelected = (state) =>
  get(state, 'csi.selected', { label: '', value: '' })

function Table(props) {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)

  const rows = get(props, 'documents', [])
  const customersSelected = get(props, 'customersSelected', [])
  const salesOrganization = get(props, 'salesOrganization', '')
  const currency = get(props, 'currency', '')
  const isCLosedStatus = isClosed(rows)
  const canShowFiscalNumber = showFiscalNumber(rows)
  const hasCustomerSelected = hasOneCustomerSelected(customersSelected)
  const status = get(props, 'status', 'OPEN')
  const user = get(props, 'user')

  const updateOnClick = (id, isSelectable) =>
    isSelectable ? dispatch({ type: 'onClick', id }) : ''

  const updateRowsInvoices = (row, isSelectable) =>
    isSelectable ? dispatch({ type: 'addRow', row }) : ''

  const resetSelectAll = () => dispatch({ type: 'resetAll' })

  const totalAmount = getTotalAmount(rows, state.selected)
  const totalAlowed = isDisputeAlowed(rows, state.selected)
  const { message, isValid } = isAmountValid({
    totalAmount: totalAmount.totalAmount,
    salesOrganization,
    currency
  })

  const inputSearchValueRedux = useSelector(getInputSearch)
  const selectInputLeaderCamSelected = useSelector(getInputLeaderCamSelected)
  const selectAccountManagerSelected = useSelector(getAccountManagerSelected)
  const selectCustomerGroupSelected = useSelector(getCustomerGroupSelected)
  const selectedCustomerSelected = useSelector(getCustomerSelected)
  const selectedCsiSelected = useSelector(getCsiSelected)

  const [inputSearch, setInputSearch] = useState(inputSearchValueRedux)
  const [inputLeaderCamSelected, setInputLeaderCamSelected] = useState(
    selectInputLeaderCamSelected
  )
  const [accountManagerSelected, setAccountManagerSelected] = useState(
    selectAccountManagerSelected
  )
  const [customerGroupSelected, setCustomerGroupSelected] = useState(
    selectCustomerGroupSelected
  )
  const [customerSelected, setCustomerSelected] = useState(
    selectedCustomerSelected
  )

  const [csiSelected, setCsiSelected] = useState(selectedCsiSelected)

  useEffect(() => {
    setInputSearch(inputSearchValueRedux)
    setInputLeaderCamSelected(selectInputLeaderCamSelected)
    setAccountManagerSelected(accountManagerSelected)
    setCustomerGroupSelected(selectCustomerGroupSelected)
    setCustomerSelected(selectedCustomerSelected)
    setCsiSelected(selectedCsiSelected)
  }, [
    inputSearchValueRedux,
    selectInputLeaderCamSelected,
    selectAccountManagerSelected,
    selectCustomerGroupSelected,
    selectedCustomerSelected,
    selectedCsiSelected
  ])

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <TableUI
          className={`${classes.table} i-table-invoices`}
          aria-labelledby='tableTitle'>
          <TableHeader
            numSelected={state.selected.length}
            order={state.order}
            orderBy={state.orderBy}
            onSelectAllClick={(event) =>
              dispatch({ type: 'selectAll', event, rows })
            }
            showFiscalNumber={canShowFiscalNumber}
            isClosed={isCLosedStatus}
            hasCustomerSelected={hasCustomerSelected}
            onRequestSort={(event, property) =>
              dispatch({ type: 'sort', property })
            }
            rowsPerPage={props.pageSize}
            rowCount={get(props, 'count', 0)}
            rowDisplaySize={get(props, 'documents', []).length}
            id={props.id}
            status={status}
            user={user}
            statusCheckbox={state.statusCheckall}
          />

          <TableBody
            {...state}
            rows={props.documents}
            showFiscalNumber={canShowFiscalNumber}
            isClosed={isCLosedStatus}
            hasCustomerSelected={hasCustomerSelected}
            status={status}
            user={user}
            onClick={updateOnClick}
            addRow={updateRowsInvoices}
            inputSearch={inputSearch}
            leader={inputLeaderCamSelected}
            camCsi={accountManagerSelected}
            customerGroup={customerGroupSelected}
            customer={customerSelected}
            csi={csiSelected}
          />
        </TableUI>

        <TableFooter
          {...state}
          classes
          isAmountValid={isValid}
          payNowWarning={message}
          hasCustomerSelected={hasCustomerSelected}
          hasGateway={hasGateway(salesOrganization)}
          hasAllowedCurrency={hasAllowedCurrency(currency)}
          getTotalAmount={totalAmount}
          getTotalCredit={getTotalCredit(rows, state.selected)}
          onChangePage={(event, page) => dispatch({ type: 'setPage', page })}
          onChangeRowsPerPage={(event) =>
            dispatch({ type: 'changeRowsPerPage', page: 0, event })
          }
          rowsPerPage={props.pageSize}
          rowCount={get(props, 'count', 0)}
          page={props.page}
          onShowMore={props.onShowMore}
          onShowAll={props.onShowAll}
          salesOrganization={props.salesOrganization}
          currency={props.currency}
          status={status}
          customersSelected={customersSelected}
          resetSelectAll={resetSelectAll}
          totalDocuments={props.count}
          inputSearch={inputSearch}
          leader={inputLeaderCamSelected}
          camCsi={accountManagerSelected}
          customerGroup={customerGroupSelected}
          customer={customerSelected}
          csi={csiSelected}
          isDisputeAlowed={totalAlowed}
        />
      </div>
    </div>
  )
}

export default Table
