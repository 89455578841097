import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { WrapTextField } from '../../component/textField/textFieldStyle'
import Input from '../../component/textField'
import ButtonSearch from '../../container/button-search'

const InputSearch = (props) => {
  const [text, setText] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    props.onClick(text)
  }

  return (
    <WrapTextField>
      <form onSubmit={handleSubmit}>
        <ButtonSearch type="submit" />
        <Input
          name={props.name}
          placeholder={props.placeholder}
          onChange={(input) => setText(input.target.value)}
          value={text}
        />
      </form>

    </WrapTextField>
  )
}

InputSearch.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

export default InputSearch