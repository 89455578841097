import { listCaseNumber } from '../../../../domain/dispute'

const SET_DISPUTE_CASE_NUMBER_LIST = 'SET_DISPUTE_CASE_NUMBER_LIST'
const SET_DISPUTE_CASE_NUMBER = 'SET_DISPUTE_CASE_NUMBER'

const setList = (list) => ({
  type: SET_DISPUTE_CASE_NUMBER_LIST,
  list
})

const setSelected = (selected) => {
  return {
    type: SET_DISPUTE_CASE_NUMBER,
    selected
  }
}

const fetchList = (query) => async (dispatch) => {
  return listCaseNumber(query).then((res) =>
    dispatch(setList(res.data.body.rows))
  )
}

export {
  SET_DISPUTE_CASE_NUMBER_LIST,
  SET_DISPUTE_CASE_NUMBER,
  setSelected,
  fetchList,
  setList
}
