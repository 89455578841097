import styled, { css } from 'styled-components'

const IsEmpty = styled.div `
  min-height: 400px;
  min-width: 100%;
  position: relative;
    
  p {
    font-size: 2rem;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    
    svg {
      color: #100690;
      font-size: 4rem;
      vertical-align: bottom;
    }
  }

  ${props => props.overduePerCustomers === true && css `
    min-height: 1397px;

    p {
      font-size: 2rem;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 14%;
      transform: translate(0, -50%);
    }
  `}
`
export { IsEmpty }