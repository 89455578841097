import React, { useState } from 'react'

import { MenuItem } from '@material-ui/core'
import { Email, MoreVert } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { ActionsButton, MenuContainer } from './styles'

import Swal from 'sweetalert2'

const NotificationsMenu = ({ account, handleOpen, handlerNotify }) => {
  const { t } = useTranslation()

  const [isMenuOpen, setIsMenuOpen] = useState()
  const open = Boolean(isMenuOpen)

  const handleClick = async (event) => {
    setIsMenuOpen(event.currentTarget)
  }

  const handleClose = () => {
    setIsMenuOpen(null)
  }

  const handlerNotification = async () => {
    const r = await handleOpen()

    const companiesText = r.map((company) => company.name).join(', ')

    if (companiesText.length === 0) {
      Swal.fire(t('error'), 'Nenhuma compania selecionada')
    }
    const companieCode = r.map((company) => company.code)
    const companieEmail = r.map((company) => company.emails)

    const emails = companieEmail[0].map((email) => email.email).join(', ')

    handlerNotify({ email: emails, customerCode: companieCode[0] })
    handleClose()
  }

  return (
    <>
      <ActionsButton
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <MoreVert />
      </ActionsButton>
      <MenuContainer
        id='basic-menu'
        anchorEl={isMenuOpen}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem
          onClick={handlerNotification}>
          <Email className='action-icons' />{t('user-notification')}
        </MenuItem>
      </MenuContainer>
    </>
  )
}

export default NotificationsMenu
