import TabsMUI from '@material-ui/core/Tabs'
import TabMUI from '@material-ui/core/Tab'
import BoxMUI from '@material-ui/core/Box'
import styled from 'styled-components'
import theme from '../theme'

const Tabs = styled(TabsMUI)`
  border-bottom: 1px solid ${theme.colors.paleSilver};
  margin: 0 32px;
  .MuiTabs-flexContainer {
    justify-content: left;
    
    
  }

  .MuiButtonBase-root {
    font-size: 1.4rem;
    font-weight: 400;
    color: ${theme.colors.sonicSilver};
  }

  .Mui-selected {
    font-weight: 900;

    &.MuiButtonBase-root {
      color: ${theme.colors.sonicSilver};
    }
  }

  .MuiTabs-indicator {
    height: 3px;
    background-color: ${theme.colors.techColor};
  }
`

const Tab = styled(TabMUI)``

const TabBox = styled(BoxMUI)`
  &.MuiBox-root {
    padding: 60px 0 20px;
  }

  @media (max-width: 840px) {
    padding: 20px 0 20px;
  }
`

export { Tabs, Tab, TabBox }
