import { findAggBySite } from '../../../domain/document/'

const GET_SITES = "GET_SITES"
const getSites = sites => ({
  type: GET_SITES,
  sites
})

const fetchSites = (query) => (dispatch) => findAggBySite(query)
  .then(sites => dispatch(getSites(sites)))

  export { GET_SITES, fetchSites }