import createItemAsValues from './create-item-as-values'
import createItemAsObjectValue from './create-item-as-object-value'

export default (
  attributesAsLabel,
  attributeAsValue,
  attributesAsObjectValue
) => (suggestion) => {
  if (attributesAsObjectValue)
    return createItemAsObjectValue(
      attributesAsLabel,
      attributesAsObjectValue,
      suggestion
    )

  return createItemAsValues(attributesAsLabel, attributeAsValue, suggestion)
}
