import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import formatCardValue from './format-cards'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const getCustomerSelected = (state) => get(state, 'getFilters.selects')

const renderCards = (cards) => {
  const { t } = useTranslation()
  const filters = useSelector(getCustomerSelected)

  const handleGoToInvoices = (key) => {
    if (filters.length === 1) {
      if (filters[0].name === 'code') {
        const customer = filters[0].selected
        localStorage.setItem('@customer', JSON.stringify(customer))
      }
    }

    localStorage.setItem(
      '@financialStatus',
      JSON.stringify({
        label: 'financialStatus',
        value: ['toBeDueMonth', 'toBeDue', 'notOverdue'].includes(key)
          ? 'NOT OVERDUE'
          : key.toUpperCase()
      })
    )

    return (window.location.href = `${process.env.REACT_APP_INVOICES_UI}/app/invoices`)
  }

  return cards.map((card) =>
    card.key !== 'toBeDueMonth' ? (
      <Card
        key={card.key}
        className='i-ar-card'
        onClick={() => handleGoToInvoices(card.key)}
        style={card.key !== 'schedulerDate' ? { cursor: 'pointer' } : null}>
        <CardContent>
          <Typography className={`i-ar-card-title`} gutterBottom>
            {t(card.key)}
          </Typography>

          <div className='i-ar-card-value'>
            <i className={`${card.class} material-icons i-ar-card-ico`}>
              {card.ico}
            </i>
            <span className='i-ar-value'>{formatCardValue(card)}</span>
          </div>
        </CardContent>
      </Card>
    ) : null
  )
}

export default renderCards
