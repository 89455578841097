import React from 'react'
import './amchart'
import AmCharts from '@amcharts/amcharts3-react'

const AmChartsComponent = (props) => {
  const isHeight = (height) => {
    return height ? height : '400px'
  }

  return (
    <AmCharts.React
      style={{
        minWidth: '100%',
        minHeight: isHeight(props.height)
      }}
      options={props.config}
    />
  )
}

export default AmChartsComponent
