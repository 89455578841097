import React from 'react'
import Tooltip from '../tooltip'
import ReportProblem from '@material-ui/icons/ReportProblem'
import IconButton from '@material-ui/core/IconButton'

export default (props) => {
  return (
    <IconButton>
      <Tooltip
        textTooltip={props.error}
        color='#ffffff'
        position='bottom-end'
        backgroundColor='rgba(0,0,0,0.75)'>
        <ReportProblem className='icon-actions' style={{ color: '#f66166' }} />
      </Tooltip>
    </IconButton>
  )
}
