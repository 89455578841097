import { GET_AR_SEGMENTS } from './actions'

const INITIAL_STATE = {
  segments: []
}

export const getChartSegments = (state = INITIAL_STATE, action) => {
  if (action.type === GET_AR_SEGMENTS) {
    return { segments: [...action.segments.data.body] }
  }
  return state
}
