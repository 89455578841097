const permitedSites = process.env.REACT_APP_PERMITTED_SALES_ORGANIZATIONS.split(
  ','
)

export default (salesOrganization) => {
  const checkHasGateway =
    permitedSites.indexOf(salesOrganization) >= 0 ? true : false

  return checkHasGateway
}
