import { search, searchAll } from '../../../domain/document/'

const GET_DOCUMENTS = 'GET_DOCUMENTS'
const GET_PAGINATION_DOCUMENTS = 'GET_PAGINATION_DOCUMENTS'
const LOAD_SHOW_MORE = 'LOAD_SHOW_MORE'

const getDocuments = ({ salesOrganization, currency }) => (documents) => ({
  type: GET_DOCUMENTS,
  row: {
    id: `${salesOrganization}-${currency}`,
    documents: documents.rows,
    page: parseInt(documents.page, 10),
    pageSize: parseInt(documents.pageSize, 10),
    count: documents.count
  }
})

const getPaginationDocuments = ({ salesOrganization, currency }) => (
  documents
) => ({
  type: GET_PAGINATION_DOCUMENTS,
  row: {
    id: `${salesOrganization}-${currency}`,
    documents: documents.rows,
    page: parseInt(documents.page, 10),
    pageSize: parseInt(documents.pageSize, 10),
    count: documents.count
  }
})

const setLoadShowMore = (loadShowMore) => ({
  type: LOAD_SHOW_MORE,
  loadShowMore
})

const fetchDocuments = (query) => (dispatch) =>
  search(query).then((documents) =>
    dispatch(getDocuments(query)(documents.data))
  )

const fetchAllDocuments = (query) => (dispatch) =>
  searchAll(query).then((documents) => {
    dispatch(getDocuments(query)(documents.data))
    dispatch(setLoadShowMore(false))
  })

const fetchPaginationDocuments = (query) => (dispatch) =>
  search(query).then((documents) => {
    dispatch(getPaginationDocuments(query)(documents.data))
    dispatch(setLoadShowMore(false))
  })

export {
  GET_PAGINATION_DOCUMENTS,
  GET_DOCUMENTS,
  fetchPaginationDocuments,
  fetchDocuments,
  fetchAllDocuments,
  LOAD_SHOW_MORE,
  setLoadShowMore
}
