import React from 'react'

export default ({ salesOrganization, totalAmount, currency }) => {
  let message = ''
  let isValid = true
  if (
    totalAmount > 299999 &&
    salesOrganization === 'LBG1' &&
    currency === 'USD'
  ) {
    message = (
      <>
        The maximum you can process in a single transaction is $299,999.00.
        <br />
        If you need to pay more than this amount value in a day, please split
        into multiple transactions.
      </>
    )
    isValid = false
  }
  return { isValid, message }
}
